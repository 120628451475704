import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";
import { WebMenuAuthValueList } from "../../use-check-web-auth-fn";

type AimMultiTabType = {
  name: string;
  isSelected: boolean;
};

const useAimMultiTab = () => {
  const {
    signInReducer: { webMenuList }
  } = useSelector((state: Reducers) => state);
  const [list, setList] = useState<AimMultiTabType[]>([
    { name: "인사정보", isSelected: true }
  ]);

  const handleSelected = useCallback(
    (payload: AimMultiTabType) => {
      const newList = list.map(item => {
        if (item.name === payload.name) {
          return payload;
        } else {
          const newItem: AimMultiTabType = { ...item, isSelected: false };
          return newItem;
        }
      });
      setList(newList);
    },
    [list]
  );

  const selectedItem = useMemo(() => {
    const item = list.find(item => item.isSelected === true);
    return item;
  }, [list]);

  useEffect(() => {
    if (
      webMenuList[0]?.web_etc_flag &&
      Boolean(
        webMenuList[0]?.web_etc_flag & WebMenuAuthValueList.annualSettingEtcFlag
      )
    ) {
      setList(data => {
        let newList = [...data];
        newList.push({ name: "연차정보", isSelected: false });
        return newList;
      });
    }
  }, [webMenuList]);
  return { list, selectedItem, handleSelected };
};

export default useAimMultiTab;
