import * as React from "react";
import moment from "moment";
import MainViewContext from "../../store";
import { queryContentReducer } from "./queryReducer";
import {
  getQueryList,
  executeQuery,
  downloadCsv
} from "../../../../Utils/commonAxiosCall";
import uiString from "../string.json";
import {
  IAlertOpen,
  ITableData,
  IQueryList,
  IMainViewContext,
  OnChangeEvent,
  ITotalProps,
  IProps,
  PagenationInfo
} from "./StatsTypes";

import HandleDate from "./HandleDate";
import { downloadFileFromServer } from "../Utils";
import { useLocation } from "react-router-dom";
import AdminStatistics from "../admin-statistics/admin-statistics";
import UserStatistics from "../user-statistics/user-statistics";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";

const USER_PATH_NAME = "userstatistics";

const AdminStatsContainer = (props: IProps) => {
  // getReducer
  const { employee_id: employeeId } = useSelector(
    (state: Reducers) => state.signInReducer
  );

  const pathName = useLocation().pathname.split("/")[2];

  const rootStore: IMainViewContext = React.useContext(MainViewContext);
  const [selectedItem, setSelectedItem] = React.useState<number>(0);
  const [tableData, setTableData] = React.useState<ITableData>();
  const [targetPage, setTargetPage] = React.useState<number>(1);
  const [isLoading, setIsloading] = React.useState<boolean>(false);
  const [employeeSelectorOpen, openEmployeeSelector] =
    React.useState<boolean>(false);
  const [pagenationInfo, setPagenationInfo] = React.useState<PagenationInfo>({
    page: 0,
    offset: 10
  });
  const [customQueryList, setCustomQueryList] = React.useState<IQueryList[]>(
    []
  );
  const [departmentSelectorOpen, openDepartmentSelector] =
    React.useState<boolean>(false);
  const [alertOpen, openAlertDlg] = React.useState<IAlertOpen>({
    open: false,
    title: "",
    msg: "",
    type: "",
    singleButton: false
  });
  const [employeeDlgKey, setEmpDlgKey] = React.useState(
    `emp_${new Date().getTime()}`
  );
  const [departmentDlgKey, setDepDlgKey] = React.useState(
    `dep_${new Date().getTime()}`
  );
  const [selectedItemContent, setSelectedItemContent] =
    React.useState<IQueryList>({
      base_query: "",
      description: "",
      index: 0,
      publish: undefined,
      readonly: 1,
      show: 0,
      title: "",
      where_date: null,
      where_department_id: null,
      where_employee_id: null,
      where_process_result: null
    });

  const [queryContent, setQueryContent] = React.useReducer(
    queryContentReducer,
    {
      title: "",
      description: "",
      base_query: "",
      employees: [],
      departments: [],
      approval_result: undefined,
      start_date: moment().subtract(7, "days"),
      end_date: moment(),
      publish: 0
    }
  );
  const handleDate = HandleDate({ setQueryContent });

  const isMounted = React.useRef<boolean>(false);

  const handleApprovalResult = React.useCallback((value: string) => {
    // 999 : 전체
    // 1 : 처리
    // 3 : 미처리
    const option = parseInt(value, 10);
    setQueryContent({
      type: "approval_result",
      approval_result: option === 1 || option === 3 ? option : undefined
    });
  }, []);

  const handlePagenationInfo = React.useCallback((value: PagenationInfo) => {
    setPagenationInfo(value);
  }, []);

  const handleSelectedItemContent = React.useCallback((value: IQueryList) => {
    setQueryContent({ type: "title", title: value.title });
    setQueryContent({ type: "description", description: value.description });
    setSelectedItemContent(value);
  }, []);

  const generateDlgKeys = React.useCallback(() => {
    setEmpDlgKey(`emp_${new Date().getTime()}`);
    setDepDlgKey(`dep_${new Date().getTime()}`);
  }, []);

  const handleSelectedItem = React.useCallback((value: number) => {
    setSelectedItem(value);
  }, []);

  const handleListItemClick = React.useCallback(
    value => {
      handleSelectedItem(value.index);
      setQueryContent({ type: "title", title: value.title });
      setQueryContent({ type: "description", description: value.description });
      setQueryContent({ type: "base_query", base_query: value.base_query });
      generateDlgKeys();
      setQueryContent({ type: "employees", employees: [] });
      setQueryContent({ type: "approval_result", approval_result: undefined });

      if (value.where_date) {
        setQueryContent({
          type: "start_date",
          start_date: handleDate.startDate
        });
        setQueryContent({ type: "end_date", end_date: handleDate.endDate });
      } else {
        setQueryContent({
          type: "start_date",
          start_date: ""
        });
        setQueryContent({ type: "end_date", end_date: "" });
      }

      const content = customQueryList.find(
        element => element.index === value.index
      );
      if (content) {
        handleSelectedItemContent(content);
      }
    },
    [
      handleDate,
      customQueryList,
      generateDlgKeys,
      handleSelectedItem,
      handleSelectedItemContent
    ]
  );

  const handleTableData = React.useCallback((content: ITableData) => {
    setTableData(content);
  }, []);

  const handleOpenAlertDig = React.useCallback(content => {
    openAlertDlg(content);
  }, []);

  const handleDepartmentSelector = React.useCallback(
    (value: boolean, selectedIds: string[]) => {
      if (!value && selectedIds) {
        setQueryContent({ type: "departments", departments: selectedIds });
      }
      openDepartmentSelector(value);
    },
    []
  );

  const handleDepartmentSelectorClose = React.useCallback(
    (_, selectedIds: string[]) => () => {
      handleDepartmentSelector(false, selectedIds);
    },
    [handleDepartmentSelector]
  );

  const handleEmployeeSelectorOpen = React.useCallback(
    (value: boolean, selectedIds: string[]) => {
      if (!value && selectedIds) {
        setQueryContent({ type: "employees", employees: selectedIds });
      }
      openEmployeeSelector(value);
    },
    []
  );

  const handleEmployeeSelectorClose = React.useCallback(
    (_, selectedIds: string[]) => () => {
      handleEmployeeSelectorOpen(false, selectedIds);
    },
    [handleEmployeeSelectorOpen]
  );

  const handleChangeTargetPage = React.useCallback(
    (event: OnChangeEvent) => {
      if (tableData && tableData.totalCount) {
        const page = parseInt(event.target.value, 10);
        const lastPage = Math.ceil(
          tableData.totalCount / pagenationInfo.offset
        );
        if (page > lastPage) {
          setTargetPage(lastPage);
          return;
        }
        if (page < 1) {
          setTargetPage(1);
          return;
        }
        setTargetPage(page);
      }
    },
    [tableData, pagenationInfo.offset]
  );

  const handleCustomQueryList = React.useCallback((data: IQueryList[]) => {
    setCustomQueryList(data);
  }, []);

  const handleLoading = React.useCallback((value: boolean) => {
    setIsloading(value);
  }, []);

  const getData = React.useCallback(() => {
    let params: boolean | undefined;
    if (!props.isAdmin) {
      params = true;
    }

    getQueryList(params)
      .then(response => {
        if (isMounted.current === false) {
          return;
        }

        if (response.status === 200 && response.data) {
          setSelectedItem(response.data[0].index);
          setQueryContent({
            type: "title",
            title: response.data[0].title
          });
          setQueryContent({
            type: "description",
            description: response.data[0].description
          });
          setQueryContent({
            type: "base_query",
            base_query: response.data[0].base_query
          });
          setQueryContent({
            type: "publish",
            publish: response.data[0].publish
          });
          handleSelectedItemContent(response.data[0]);
          handleCustomQueryList(response.data);
        }
      })
      .catch(e => console.log(e));
  }, [handleCustomQueryList, handleSelectedItemContent, props.isAdmin]);

  const refreshData = React.useCallback(() => {
    let params: any;
    if (!props.isAdmin) {
      params = true;
    }

    getQueryList(params)
      .then(response => {
        if (isMounted.current === false) {
          return;
        }

        if (response.status === 200 && response.data) {
          handleCustomQueryList(response.data);
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].index === selectedItem) {
              handleSelectedItemContent(response.data[i]);
              setQueryContent({
                type: "title",
                title: response.data[i].title
              });
              setQueryContent({
                type: "description",
                description: response.data[i].description
              });
              setQueryContent({
                type: "base_query",
                base_query: response.data[i].base_query
              });
              setQueryContent({
                type: "publish",
                publish: response.data[i].publish
              });
              break;
            }
          }
        }
      })
      .catch(e => console.log(e));
  }, [
    selectedItem,
    handleCustomQueryList,
    handleSelectedItemContent,
    props.isAdmin
  ]);

  const handleCountSearchtDocs = React.useCallback(() => {
    return {
      data: {
        totalNumber: tableData?.totalCount
      }
    };
  }, [tableData]);

  const searchWithPagenation = React.useCallback(
    (pagenation: { limit?: number; offset?: number }) => {
      if (queryContent.base_query) {
        handleLoading(true);
        const deleteIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("delete ");
        const updateIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("update ");
        const insertIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("insert ");
        const createIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("create ");
        if (
          deleteIdx !== -1 ||
          updateIdx !== -1 ||
          insertIdx !== -1 ||
          createIdx !== -1
        ) {
          handleLoading(false);
          return handleOpenAlertDig({
            open: true,
            title: uiString.dialog.sqlRulViolation,
            msg: uiString.dialog.sqlRulViolationDescription,
            type: "sqlRulViolation",
            singleButton: false
          });
        }
        let departments: any[] = [];
        if (queryContent?.departments && queryContent.departments.length > 0) {
          departments = queryContent.departments;
        } else if (pathName === USER_PATH_NAME) {
          departments = [rootStore.signInReducer.department_id];
        }
        executeQuery({
          index: selectedItem,
          base_query: queryContent.base_query,
          employees:
            pathName === USER_PATH_NAME
              ? [`${employeeId}`]
              : queryContent.employees,
          departments,
          approval_result: queryContent.approval_result,
          start_date: queryContent.start_date,
          end_date: queryContent.end_date,
          pagenation
        })
          .then(response => {
            if (response.status === 200) {
              if (!response.data) {
                handleOpenAlertDig({
                  open: true,
                  title: uiString.dialog.noData,
                  msg: uiString.dialog.noDataDesc,
                  type: "noData",
                  singleButton: true
                });
                handleLoading(false);
                return;
              }
              handleLoading(false);
              handleTableData({
                rows: response.data.rows.map((data: any) => {
                  delete data["employee_id"];
                  delete data["department_id"];
                  return data;
                }),
                totalCount: response.data.totalCount[0].ascount
              });
            }
          })
          .catch(e => {
            handleLoading(false);
            console.log(e);
          });
      }
    },
    [
      handleLoading,
      handleOpenAlertDig,
      handleTableData,
      selectedItem,
      queryContent,
      pathName,
      employeeId,
      rootStore.signInReducer
    ]
  );

  const exeQuery = React.useCallback(
    (download: boolean = false) => {
      if (queryContent.base_query) {
        handleLoading(true);
        const deleteIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("delete ");
        const updateIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("update ");
        const insertIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("insert ");
        const createIdx = queryContent.base_query
          .toLowerCase()
          .indexOf("create ");
        if (
          deleteIdx !== -1 ||
          updateIdx !== -1 ||
          insertIdx !== -1 ||
          createIdx !== -1
        ) {
          handleLoading(false);
          return handleOpenAlertDig({
            open: true,
            title: uiString.dialog.sqlRulViolation,
            msg: uiString.dialog.sqlRulViolationDescription,
            type: "sqlRulViolation",
            singleButton: false
          });
        }

        if (download) {
          downloadCsv({
            index: selectedItem,
            base_query: queryContent.base_query,
            employees:
              pathName === USER_PATH_NAME
                ? [`${employeeId}`]
                : queryContent.employees,
            departments: queryContent.departments,
            approval_result: queryContent.approval_result,
            start_date: queryContent.start_date,
            end_date: queryContent.end_date,
            pagenation: null
          })
            .then(res => {
              handleLoading(false);
              downloadFileFromServer(
                res.data,
                `${moment().format("YYYY-MM-DD-hh-mm-ss")}-adminstats.csv`
              );
            })
            .catch(e => {
              handleLoading(false);
              console.log(e);
            });
        }
      }
    },
    [
      handleLoading,
      handleOpenAlertDig,
      selectedItem,
      queryContent,
      pathName,
      employeeId
    ]
  );

  const transferToCSV = React.useCallback(() => {
    exeQuery(true);
  }, [exeQuery]);

  const newProps: ITotalProps = React.useMemo(
    () => ({
      rootStore,
      isLoading,
      handleLoading,
      customQueryList,
      handleCustomQueryList,
      selectedItem,
      handleSelectedItem,
      targetPage,
      handleChangeTargetPage,
      employeeSelectorOpen,
      handleEmployeeSelectorOpen,
      refreshData,
      queryContent,
      setQueryContent,
      handleEmployeeSelectorClose,
      departmentSelectorOpen,
      handleDepartmentSelector,
      handleDepartmentSelectorClose,
      alertOpen,
      handleOpenAlertDig,
      tableData,
      handleTableData,
      selectedItemContent,
      pagenationInfo,
      employeeDlgKey,
      departmentDlgKey,
      generateDlgKeys,
      handleListItemClick,
      handleSelectedItemContent,
      handlePagenationInfo,
      exeQuery,
      handleApprovalResult,
      transferToCSV,
      ...handleDate,
      searchWithPagenation,
      handleCountSearchtDocs
    }),
    [
      rootStore,
      isLoading,
      handleLoading,
      customQueryList,
      handleCustomQueryList,
      selectedItem,
      handleSelectedItem,
      targetPage,
      handleChangeTargetPage,
      employeeSelectorOpen,
      handleEmployeeSelectorOpen,
      refreshData,
      queryContent,
      setQueryContent,
      handleEmployeeSelectorClose,
      departmentSelectorOpen,
      handleDepartmentSelector,
      handleDepartmentSelectorClose,
      alertOpen,
      handleOpenAlertDig,
      tableData,
      handleTableData,
      selectedItemContent,
      pagenationInfo,
      employeeDlgKey,
      departmentDlgKey,
      generateDlgKeys,
      handleListItemClick,
      handleSelectedItemContent,
      handlePagenationInfo,
      exeQuery,
      handleApprovalResult,
      transferToCSV,
      handleDate,
      searchWithPagenation,
      handleCountSearchtDocs
    ]
  );

  React.useEffect(() => {
    if (!isMounted.current) {
      const resetSelectedItemContent = {
        base_query: "",
        description: "",
        index: 0,
        publish: undefined,
        readonly: 1,
        show: 0,
        title: "",
        where_date: null,
        where_department_id: null,
        where_employee_id: null,
        where_process_result: null
      };
      handleSelectedItemContent(resetSelectedItemContent);
      handleSelectedItem(0);
    }
  }, [props.isAdmin, handleSelectedItem, handleSelectedItemContent]);

  React.useEffect(() => {
    isMounted.current = true;
    getData();
    return () => {
      isMounted.current = false;
    };
  }, [getData]);

  return (
    <>
      {props.isAdmin ? (
        <AdminStatistics {...newProps} />
      ) : (
        <UserStatistics {...newProps} />
      )}
    </>
  );
};

export default AdminStatsContainer;
