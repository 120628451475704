import styled from "styled-components";

interface IProps {
  title: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  gap: 5px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const Point = styled.span`
  font-size: 5px;
  font-weight: bold;
  color: ${props => props.theme.colors.green};
`;

function SubTitle({ title }: IProps) {
  return (
    <Container>
      <Point>●</Point>
      <Title>{title}</Title>
    </Container>
  );
}

export default SubTitle;
