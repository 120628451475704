import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import settingsApi from "../../../../api/settings-api";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import { setUserInfo } from "../../../../redux/modules/sign-in/signInReducer";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import MenuCheckBoxList from "../MenuCheckBoxList";
import CheckBoxListContainer from "./checkbox-list-container";

type OnChange = React.ChangeEvent<HTMLInputElement>;
enum IsActive {
  SHOW = 1,
  HIDE
}
const MOBILE = "MOBILE";

type MobileMenuList = [
  {
    app_commute_flag: number;
    app_realtime_flag: number;
    app_webview_flag: number;
  }
];

function MobileMenuSettings() {
  const isMount = useRef<boolean>(false);
  const {
    signInReducer: { mobileMenuList, webMenuList }
  } = useSelector((state: Reducers) => state);

  const [mobileMenuStatus, setMobileMenuStatus] =
    useState<MobileMenuList>(mobileMenuList);

  const mobileList = useMemo(() => {
    if (mobileMenuStatus && mobileMenuStatus.length > 0) {
      const listKeys = Object.entries(mobileMenuStatus[0]);

      return [
        {
          mainTitle: "메인기능",
          data: [
            {
              title: "출퇴근",
              key: listKeys[0][0],
              status: listKeys[0][1],
              isCheck: listKeys[0][1] === IsActive.SHOW ? true : false
            },
            {
              title: "시작종료",
              key: listKeys[1][0],
              status: listKeys[1][1],
              isCheck: listKeys[1][1] === IsActive.SHOW ? true : false
            },
            {
              title: "웹보기",
              key: listKeys[2][0],
              status: listKeys[2][1],
              isCheck: listKeys[2][1] === IsActive.SHOW ? true : false
            },
            {
              title: "차량운행관리",
              key: listKeys[3][0],
              status: listKeys[3][1],
              isCheck: listKeys[3][1] === IsActive.SHOW ? true : false
            }
          ]
        }
      ];
    }
  }, [mobileMenuStatus]);

  const handleOnChange = useCallback(
    () => (event: OnChange) => {
      if (isMount.current) {
        if (mobileMenuStatus) {
          const key = event.target.name;
          const value =
            event.target.value === "1" ? IsActive.HIDE : IsActive.SHOW;
          const list = mobileMenuStatus[0];
          const newState: any = { ...list };
          newState[key] = value;
          setMobileMenuStatus([newState]);
        }
      }
    },
    [mobileMenuStatus]
  );

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const dispatch = useDispatch();
  const setUser = useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );

  const updateMenuList = useCallback(() => {
    settingsApi
      .updateAppMenuList(mobileMenuStatus[0])
      .then(({ data }) => {
        if (data.ok) {
          setUser({
            menuList: [{ ...webMenuList[0], ...mobileMenuStatus[0] }],
            webMenuList,
            mobileMenuList: mobileMenuStatus
          });
          handleToast(
            "APP 메뉴 리스트를 업데이트 했습니다.",
            MessageTypes.SUCCESS
          );
        } else {
          handleToast(data.error, MessageTypes.ERROR);
        }
      })
      .catch(error => {
        console.log(error);
        handleToast(
          "APP 메뉴 리스트를 업데이트를 하지 못했습니다.",
          MessageTypes.ERROR
        );
      });
  }, [setUser, handleToast, webMenuList, mobileMenuStatus]);

  useEffect(() => {
    if (mobileMenuList && mobileMenuList.length > 0) {
      isMount.current = true;
      setMobileMenuStatus(mobileMenuList);
    }
    return () => {
      isMount.current = false;
    };
  }, [mobileMenuList]);

  return (
    <CheckBoxListContainer>
      <MenuCheckBoxList
        title={MOBILE}
        list={mobileList}
        handleOnChange={handleOnChange()}
        updateMenuList={updateMenuList}
      />
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </CheckBoxListContainer>
  );
}

export default MobileMenuSettings;
