import { useCallback, useContext, useState } from "react";
import Store from "../../../components/main-view/WorkManagementInfo/Store";
import { MessageTypes } from "../../../components/toast-message/toast-message";
import {
  updateDepartmentWorkingTmplate,
  updatePcWorkingTemplate,
  updateUserWorkingTemplate
} from "../../../Utils/changeWorkingTemplate";
import { IHandleConfirmMessage } from "../../confirm-dialog-hook/use-confirm-dialog";

type OffsetWithLimit = { limit: number; offset: number };
interface IProps {
  selectedDepartment?: number;
  selectedUsers?: string[];
  selectedEndpointIds?: string[];
  handleIsOpen: (value: boolean) => void;
  handleConfirmMessage: ({
    title,
    p,
    messageTypes
  }: IHandleConfirmMessage) => void;
  handleIsToastMessageOpen: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
  getUsers?: ({ limit, offset }: OffsetWithLimit) => void;
  handleEvent?: (value: boolean) => void;
}

export type Template = { working_templete_idx: number; name: string };

const useWorkingTemplateManagement = <P extends IProps>({
  selectedDepartment,
  selectedUsers,
  selectedEndpointIds,
  handleIsOpen,
  handleConfirmMessage,
  handleIsToastMessageOpen,
  handleMessage,
  handleToastMessageType,
  getUsers,
  handleEvent
}: P) => {
  const { setIsUpdateTreeData } = useContext(Store);
  const [selectedWorkingTemplate, setSelectedWorkingTemplate] =
    useState<Template>();

  const selectTemplate = useCallback(
    (temp: Template) => () => {
      handleConfirmMessage({
        title: `근무정책변경`,
        p: `${temp.name}으로 변경하시겠습니까?`,
        messageTypes: MessageTypes.INFO
      });
      setSelectedWorkingTemplate(temp);
      handleIsOpen(true);
    },
    [handleIsOpen, handleConfirmMessage]
  );

  const handlePcWorkingTemplate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      updatePcWorkingTemplate(
        selectedEndpointIds,
        selectedWorkingTemplate?.working_templete_idx
      )
        .then(() => {
          setIsUpdateTreeData(true);
          handleToastMessageType(MessageTypes.SUCCESS);
          handleMessage(
            `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
          );
        })
        .catch(error => {
          handleToastMessageType(MessageTypes.ERROR);
          handleMessage(
            `${
              selectedWorkingTemplate?.name || "미사용"
            }으로 변경하지 못했습니다.`
          );
        })
        .finally(() => {
          handleIsOpen(false);
          handleIsToastMessageOpen(true);
        });
    },
    [
      selectedWorkingTemplate,
      handleIsOpen,
      setIsUpdateTreeData,
      handleToastMessageType,
      handleMessage,
      handleIsToastMessageOpen,
      selectedEndpointIds
    ]
  );

  const handleUserWorkingTemplate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      updateUserWorkingTemplate(
        selectedUsers,
        selectedWorkingTemplate?.working_templete_idx
      )
        .then(() => {
          setIsUpdateTreeData(true);
          handleToastMessageType(MessageTypes.SUCCESS);
          handleMessage(
            `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
          );
          const params = {
            limit: 10,
            offset: 0
          };
          if (handleEvent) {
            handleEvent(true);
          }
          if (getUsers) {
            getUsers(params);
          }
        })
        .catch(error => {
          handleToastMessageType(MessageTypes.ERROR);
          handleMessage(
            `${
              selectedWorkingTemplate?.name || "미사용"
            }으로 변경하지 못했습니다.`
          );
        })
        .finally(() => {
          handleIsOpen(false);
          handleIsToastMessageOpen(true);
        });
    },
    [
      selectedWorkingTemplate,
      handleIsOpen,
      setIsUpdateTreeData,
      handleToastMessageType,
      handleMessage,
      handleIsToastMessageOpen,
      selectedUsers,
      getUsers,
      handleEvent
    ]
  );

  const handleDepartmentWorkingTemplate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      updateDepartmentWorkingTmplate(
        [selectedDepartment],
        selectedWorkingTemplate?.working_templete_idx
      )
        .then(() => {
          setIsUpdateTreeData(true);
          handleToastMessageType(MessageTypes.SUCCESS);
          handleMessage(
            `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
          );
        })
        .catch(error => {
          handleToastMessageType(MessageTypes.ERROR);
          handleMessage(
            `${
              selectedWorkingTemplate?.name || "미사용"
            }으로 변경하지 못했습니다.`
          );
        })
        .finally(() => {
          handleIsOpen(false);
          handleIsToastMessageOpen(true);
        });
    },
    [
      selectedWorkingTemplate,
      selectedDepartment,
      handleIsOpen,
      setIsUpdateTreeData,
      handleToastMessageType,
      handleMessage,
      handleIsToastMessageOpen
    ]
  );

  return {
    selectedWorkingTemplate,
    selectTemplate,
    handleDepartmentWorkingTemplate,
    handleUserWorkingTemplate,
    handlePcWorkingTemplate
  };
};

export default useWorkingTemplateManagement;
