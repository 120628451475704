import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  Approval_Sort,
  Type_Control_Approval_Form,
  useGetListOfApprovalFormLazyQuery,
  useGetListOfOtTimeTypeCategoryLazyQuery
} from "../../../generated/graphql";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import StyleInput from "../../inputs/style-input";
import StyleTextarea from "../../inputs/style-textarea";
import FormRow from "../../shared/form-row/form-row";
import FormTextRow from "../../shared/form-text-row/form-text-row";
import ApprovalSettingButtons from "./approval-setting-buttons";
import { IApprovalTemplateEntity, Row } from "./list-of-approval-template";

interface IProps {
  selectedRow?: ICompactRow<IApprovalTemplateEntity>;
  controlType: Type_Control_Approval_Form;
  checkedRows: Row<IApprovalTemplateEntity>[];
  handleControlType: (type: Type_Control_Approval_Form) => void;
  handleInitCheckedRows: () => void;
}

export interface IApprovalTemplate {
  templateTitle: string;
  otTImeTypeCategoryId: number;
  formIdx: number;
  approvalType: string;
  description: string;
}

export const APPROVAL_TYPE = {
  APPROVAL_LINE: "결재선",
  AUTO_APPROVAL: "자동결재"
} as const;

export type T_KEY_APPROVAL_TYPE = keyof typeof APPROVAL_TYPE;

const Container = styled.form`
  display: flex;
  flex: 5;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const Section = styled.div`
  display: flex;
`;

const Description = styled(Section)`
  flex: 2;
  textarea {
    min-height: 100px;
  }
`;

const Subscription = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding: 10px;
  white-space: pre-line;
  white-space: pre-wrap;
`;

function ApprovalTemplateDetail({
  selectedRow,
  controlType,
  checkedRows,
  handleControlType,
  handleInitCheckedRows
}: IProps) {
  const [getListOfOtTimeTypeCategory, { data: otTimeTypeCategoryData }] =
    useGetListOfOtTimeTypeCategoryLazyQuery();

  const [getListOfApprovalForm, { data: listOfApprovalFormData }] =
    useGetListOfApprovalFormLazyQuery();

  const listOfCategory = useMemo(() => {
    return otTimeTypeCategoryData?.getListOfOtTimeTypeCategory.list ?? [];
  }, [otTimeTypeCategoryData]);

  const listOfApprovalForm = useMemo(() => {
    return listOfApprovalFormData?.getListOfApprovalForm.list ?? [];
  }, [listOfApprovalFormData]);

  const { register, setValue, handleSubmit } = useForm<IApprovalTemplate>({
    mode: "onSubmit",
    defaultValues: {
      approvalType: Approval_Sort.ApprovalLine
    }
  });

  useEffect(() => {
    getListOfOtTimeTypeCategory();
    getListOfApprovalForm();
  }, [getListOfOtTimeTypeCategory, getListOfApprovalForm]);

  useEffect(() => {
    if (controlType === Type_Control_Approval_Form.Edit && selectedRow) {
      setValue("templateTitle", selectedRow.original.templateTitle);
      setValue("description", selectedRow.original?.description ?? "");
      setValue("approvalType", selectedRow.original.approvalType);
    }
  }, [setValue, selectedRow, controlType]);

  useEffect(() => {
    if (listOfCategory && listOfCategory.length > 0) {
      if (controlType === Type_Control_Approval_Form.Add) {
        setValue("otTImeTypeCategoryId", listOfCategory[0].categoryId);
      } else if (selectedRow) {
        const category = listOfCategory.find(
          category => category.name === selectedRow.original.name
        );
        if (category) {
          setValue("otTImeTypeCategoryId", category.categoryId);
        }
      }
    }
  }, [setValue, listOfCategory, controlType, selectedRow]);

  useEffect(() => {
    if (listOfApprovalForm && listOfApprovalForm.length > 0) {
      if (controlType === Type_Control_Approval_Form.Add) {
        setValue("formIdx", listOfApprovalForm[0].formIdx);
      } else if (selectedRow) {
        const approvalForm = listOfApprovalForm.find(
          approvalForm =>
            approvalForm.formName === selectedRow.original.formName
        );
        if (approvalForm) {
          setValue("formIdx", approvalForm.formIdx);
        }
      }
    }
  }, [controlType, setValue, listOfApprovalForm, selectedRow]);
  return (
    <Container>
      <Section>
        <FormRow title="관리 이름" isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.templateTitle}</span>
          ) : (
            <StyleInput
              {...register("templateTitle", { required: true })}
              required={true}
              placeholder="관리 이름"
            />
          )}
        </FormRow>
        <FormRow title="정보 구분" isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.name}</span>
          ) : (
            <select {...register("otTImeTypeCategoryId", { required: true })}>
              {listOfCategory.map(category => (
                <option value={category.categoryId} key={category.categoryId}>
                  {category.name}
                </option>
              ))}
            </select>
          )}
        </FormRow>
      </Section>
      <Section>
        <FormRow title="결재 방식" isCenter>
          {selectedRow?.original.approvalType &&
            controlType === Type_Control_Approval_Form.Delete && (
              <span>{APPROVAL_TYPE[selectedRow?.original.approvalType]}</span>
            )}
          {controlType !== Type_Control_Approval_Form.Delete && (
            <select {...register("approvalType", { required: true })}>
              <option value={Approval_Sort.ApprovalLine}>결재선</option>
              <option value={Approval_Sort.AutoApproval}>자동결재</option>
            </select>
          )}
        </FormRow>
        <FormRow title="결재 양식" isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <span>{selectedRow?.original.formName}</span>
          ) : (
            <select {...register("formIdx", { required: true })}>
              {listOfApprovalForm.map(approvalForm => (
                <option value={approvalForm.formIdx}>
                  {approvalForm.formName}
                </option>
              ))}
            </select>
          )}
        </FormRow>
      </Section>
      <Description>
        <FormTextRow title="설명" isCenter>
          {controlType === Type_Control_Approval_Form.Delete ? (
            <Subscription>{selectedRow?.original.description}</Subscription>
          ) : (
            <StyleTextarea
              {...register("description", { required: true })}
              required={true}
              placeholder="설명"
            />
          )}
        </FormTextRow>
      </Description>
      <ApprovalSettingButtons
        checkedRows={checkedRows}
        controlType={controlType}
        handleControlType={handleControlType}
        handleSubmit={handleSubmit}
        selectedRow={selectedRow}
        handleInitCheckedRows={handleInitCheckedRows}
      />
    </Container>
  );
}

export default ApprovalTemplateDetail;
