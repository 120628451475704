import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CheckMark = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 35%;
    top: 20%;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBoxContainer = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
`;

export const CheckBox = styled.input`
  position: absolute;
  margin: 0;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
  :disabled ~ ${CheckMark} {
    background-color: #636e72;
  }
  :checked ~ ${CheckMark} {
    ::after {
      display: block;
    }
    background-color: #2196f3;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
`;

export const ContentTitle = styled.span``;

export const Title = styled.h3`
  margin: 0;
  padding: 8px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.4);
`;

export const ListContainer = styled.div`
  flex: 1;
`;

export const ListTitle = styled.h4``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
