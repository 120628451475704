import { useReactiveVar } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import styled from "styled-components";
import { TOKEN } from "../../../apollo/apollo";
import listOfApolloVar from "../../../apollo/apollo-var";
import {
  ApprovalDetailInformationEntity,
  ApprovalEntity,
  ApprovalTemplateEntity,
  Approval_Status,
  Approval_User_Type,
  ListOfApprovalTemporaryStorageEntity,
  SignType,
  useGetApprovalPasswordAndSignSettingInfoLazyQuery,
  useGetUserLazyQuery,
  UserEntity
} from "../../../generated/graphql";
import routes from "../../../routes";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import Button from "../../globalComponents/Button";
import StyleInput from "../../inputs/style-input";
import StyleTextarea from "../../inputs/style-textarea";
import TextInput from "../../inputs/text-input";
import { ApprovalType } from "../approval-detail-popup";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";

export interface IApprovalFormFieldValue {
  title: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  description: string;
}

interface IProps {
  approvalTemporaryStorageData?: ListOfApprovalTemporaryStorageEntity;
  data?: ApprovalEntity;
  approvalType?: ApprovalType;
  isRequest?: boolean;
  template?: ApprovalTemplateEntity;
  handleIsReceiveAndReference?: (value: boolean) => void;
  listOfUser?: IEmployeeInformation[];
  listOfReferenceUserName: string[];
  listOfReceiveUserName: string[];
  listOfReceiveDepartmentName: string[];
  listOfReferenceDepartmentName: string[];
  title: ControllerRenderProps<IApprovalFormFieldValue, "title">;
  startDate: ControllerRenderProps<IApprovalFormFieldValue, "startDate">;
  endDate: ControllerRenderProps<IApprovalFormFieldValue, "endDate">;
  startTime: ControllerRenderProps<IApprovalFormFieldValue, "startTime">;
  endTime: ControllerRenderProps<IApprovalFormFieldValue, "endTime">;
  description: ControllerRenderProps<IApprovalFormFieldValue, "description">;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
}
export interface IApprovalDetailMessage {
  type: ApprovalType;
  data: ApprovalEntity;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 20;
  max-height: 550px;
  padding: 10px;
`;

const ApprovalListContainer = styled.div`
  display: grid;
  align-self: flex-end;
  grid-template-columns: 30px repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  border: 1px solid ${props => props.theme.colors.darkGrey};
`;

const MainHead = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: bold;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
  background-color: ${props => props.theme.colors.grey};
  text-align: center;
`;

const Approver = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.darkGrey};
  flex-direction: column;
`;

const Head = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  font-size: 14px;
  font-weight: bold;
  min-height: 20px;
  max-height: 20px;
`;

const Body = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  flex-direction: column;
  gap: 5px;
  min-height: 62px;
`;

const StampImg = styled.img`
  display: flex;
  width: 40px;
`;

const ApprovalTitle = styled.div`
  display: flex;
  align-self: center;
  flex: 1;
  h1 {
    font-size: 30px;
  }
`;

const MainSection = styled.div`
  display: grid;
  flex: 10;
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
  border-right: 1px solid ${props => props.theme.colors.darkGrey};
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.darkGrey};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  :nth-child(7) {
    grid-row: span 2;
  }
  :nth-child(10) {
    color: ${props => props.theme.colors.darkGrey};
  }
  :nth-child(11) {
    color: ${props => props.theme.colors.darkGrey};
  }
  :nth-child(12) {
    grid-column: span 3;
  }
  :nth-child(13) {
    grid-row: span 5;
    grid-column: span 3;
  }
  :nth-child(14) {
    grid-column: span 3;
  }
  :nth-child(15) {
    grid-column: span 3;
  }
  :last-child {
    grid-column: span 3;
    grid-row: span 3;
  }
`;

const Label = styled.label<{ isSpacing?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${props => props.theme.colors.darkGrey};
  font-size: 14px;
  font-weight: bold;
  background-color: ${props => props.theme.colors.grey};
  min-height: max-content;
  min-width: 100px;
  max-width: 100px;
  word-spacing: ${props => props.isSpacing && "20px"};
`;
const Subscription = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding: 5px 10px;
  white-space: pre-line;
  white-space: pre-wrap;
  input {
    border: 1px solid ${props => props.theme.colors.darkGrey};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const DisplaySign = styled.span<{ isReturn?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${props =>
    props.isReturn ? props.theme.colors.darkRed : props.theme.colors.black};
  align-items: center;
  text-align: center;
  text-transform: lowercase;
  font-family: "궁서체";
  font-weight: bold;
`;

const DisplayUsers = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  gap: 5px;
  padding-left: 10px;
`;

const DisplayName = styled.span`
  display: flex;
  justify-content: center;
  width: 70px;
`;

function ApprovalForm({
  data,
  isRequest,
  template,
  handleIsReceiveAndReference,
  listOfReceiveUserName,
  listOfReferenceUserName,
  listOfReceiveDepartmentName,
  listOfReferenceDepartmentName,
  title,
  startDate,
  endDate,
  startTime,
  endTime,
  description,
  approvalDetailInformation,
  approvalTemporaryStorageData,
  listOfUser = []
}: IProps) {
  const FormName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [user, setUser] = useState<UserEntity>();
  const [getUser, { data: userData }] = useGetUserLazyQuery();
  const [signImage, setSignImage] = useState<string | null>();
  const [signText, setSignText] = useState<string | null>();
  const [signType, setSignType] = useState<SignType | null>();
  const [isActiveTime, setIsActiveTime] = useState(true);
  const departmentName: string = useMemo(() => {
    const newDepartmentName = user?.fullDepartmentName?.split(">").at(-1);
    return newDepartmentName || "";
  }, [user?.fullDepartmentName]);
  const drafter = useMemo(() => {
    let newDrafter = {
      name: user?.name ?? "",
      signImage,
      signText,
      signType,
      approvalTime: ""
    };
    if (
      listOfUser &&
      listOfUser[0]?.approverType === Approval_User_Type.Drafter
    ) {
      newDrafter = {
        name: listOfUser[0].name,
        signImage: listOfUser[0].signImage,
        signText: listOfUser[0].signText,
        signType: listOfUser[0].signType,
        approvalTime: listOfUser[0]?.approvalTime ?? ""
      };
    }
    if (newDrafter.name.length > 10) {
      let name = newDrafter.name;
      name = name.slice(0, 9);
      name += "...";
      newDrafter.name = name;
    }
    return newDrafter;
  }, [listOfUser, signImage, signText, signType, user]);

  const listOfApprover = useMemo(() => {
    return listOfUser.filter(
      item => item.approverType !== Approval_User_Type.Drafter
    );
  }, [listOfUser]);

  const formName = useMemo(() => {
    if (template?.formIdx) {
      return FormName.get(template?.formIdx);
    }
    if (data?.formIdx) {
      return FormName.get(data?.formIdx);
    }
    if (approvalTemporaryStorageData?.formIdx) {
      return FormName.get(approvalTemporaryStorageData.formIdx);
    }
    return "";
  }, [template, data, approvalTemporaryStorageData]);

  const [
    getApprovalPasswordAndSignSettingInfo,
    { data: approvalPasswordAndSignSettingInfo }
  ] = useGetApprovalPasswordAndSignSettingInfoLazyQuery();

  const formTemplateIdx = useMemo(() => {
    const idx =
      data?.formTemplateIdx ??
      template?.formTemplateIdx ??
      approvalTemporaryStorageData?.formTemplateIdx;
    if (idx) {
      return `:${idx}`;
    }
    return "";
  }, [data, template, approvalTemporaryStorageData]);

  const handleIsActiveTime = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number =
      template?.formIdx ??
      data?.formIdx ??
      approvalTemporaryStorageData?.formIdx ??
      initFormIdx;
    switch (FormName.get(formIdx)) {
      case FormName.get(3):
        setIsActiveTime(false);
        break;
      case FormName.get(4):
        setIsActiveTime(false);
        break;
      case FormName.get(5):
        setIsActiveTime(false);
        break;
    }
  }, [template, data, approvalTemporaryStorageData]);

  useEffect(() => {
    handleIsActiveTime();
  }, [handleIsActiveTime]);

  useEffect(() => {
    if (
      approvalPasswordAndSignSettingInfo?.getApprovalPasswordAndSignSettingInfo
        .info
    ) {
      const { signType, signImage, signText } =
        approvalPasswordAndSignSettingInfo
          ?.getApprovalPasswordAndSignSettingInfo.info;
      setSignType(signType);
      if (signType === SignType.Image) {
        setSignImage(signImage);
      } else {
        setSignText(signText);
      }
    }
  }, [approvalPasswordAndSignSettingInfo]);

  useEffect(() => {
    if (userData?.getUser.user) {
      setUser(userData?.getUser.user[0]);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      getUser({
        variables: {
          getUserId: decodedData.employee_id
        }
      });
      getApprovalPasswordAndSignSettingInfo({
        variables: {
          employeeId: decodedData.employee_id
        }
      });
    }
  }, [getUser, getApprovalPasswordAndSignSettingInfo]);

  const returnApprovalTypeString = useCallback(
    (type: Approval_User_Type, isLast: boolean) => {
      if (type === Approval_User_Type.Agreement) {
        return "합의";
      }
      if (type === Approval_User_Type.Approval) {
        if (isLast) {
          return "결재";
        }
        return "전결";
      }
    },
    []
  );

  return (
    <Container>
      <ApprovalListContainer>
        <MainHead>
          <span>결</span>
          <span>재</span>
        </MainHead>
        <Approver>
          <Head>기안자</Head>
          <Body>
            {drafter.signType === SignType.Image && drafter.signImage && (
              <StampImg
                src={
                  drafter.signImage.includes("base64")
                    ? drafter.signImage
                    : `data:image/png;base64, ${drafter.signImage}`
                }
                alt="서명"
              />
            )}
            {drafter.signType === SignType.Text && drafter.signText && (
              <DisplaySign>{drafter.signText}</DisplaySign>
            )}
            <DisplayName>{drafter.name}</DisplayName>
            {drafter.approvalTime && <span>{`(${drafter.approvalTime})`}</span>}
          </Body>
        </Approver>
        {listOfApprover.map((item, index) => {
          const approvalType = item.approvalType;
          const signType = item.signType;
          const signImage = item.signImage;
          const signText = item.signText;
          let name = item.name;
          const approvalTime = item.approvalTime
            ? item.approvalTime.split(" ").at(0)?.split("-").slice(1).join("/")
            : "";
          let isReturn = false;
          if (name.length > 10) {
            name = name.slice(0, 9);
            name += "...";
          }

          if (item.approvalResult === Approval_Status.Return) {
            isReturn = true;
          }
          return (
            <Approver key={index}>
              <Head>
                {returnApprovalTypeString(
                  approvalType,
                  listOfApprover.length - 1 === index
                )}
              </Head>
              <Body>
                {signType === SignType.Image &&
                  signImage &&
                  item.approvalResult &&
                  !isReturn && (
                    <StampImg
                      src={
                        signImage.includes("base64")
                          ? signImage
                          : `data:image/png;base64, ${signImage}`
                      }
                      alt="서명"
                    />
                  )}
                {signType === SignType.Text &&
                  item.approvalResult &&
                  signText &&
                  !isReturn && <DisplaySign>{signText}</DisplaySign>}
                {isReturn && (
                  <DisplaySign isReturn={isReturn}>{`반려`}</DisplaySign>
                )}
                <DisplayName>{name}</DisplayName>
                {approvalTime && <span>{`(${approvalTime})`}</span>}
              </Body>
            </Approver>
          );
        })}
      </ApprovalListContainer>
      <ApprovalTitle>
        <h1>{formName}</h1>
      </ApprovalTitle>
      <MainSection>
        <Content>
          <Label isSpacing>{`소   속`}</Label>
          <Subscription>{departmentName}</Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`이   름`}</Label>
          <Subscription>{user?.name}</Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`직   급`}</Label>
          <Subscription>{user?.userPosition}</Subscription>
        </Content>
        <Content>
          <Label>문서번호</Label>
          <Subscription>{data?.docNumber}</Subscription>
        </Content>
        <Content>
          <Label>작 성 일</Label>
          <Subscription>
            {approvalDetailInformation?.signUpDateTime ||
              moment().format("YYYY-MM-DD")}
          </Subscription>
        </Content>
        <Content>
          <Label>정보구분</Label>
          <Subscription>
            {template?.name ?? approvalDetailInformation?.categoryName}
          </Subscription>
        </Content>
        <Content>
          <Label>관리이름</Label>
          <Subscription>
            {template?.templateTitle ??
              approvalDetailInformation?.formTemplateTitle}
          </Subscription>
        </Content>
        <Content>
          <Label>시작일자</Label>
          {isRequest ? (
            <InputContainer>
              <TextInput type="date" {...endDate} />
            </InputContainer>
          ) : (
            <Subscription>{data?.extendStartDate}</Subscription>
          )}
        </Content>
        <Content>
          <Label>종료일자</Label>
          {isRequest ? (
            <InputContainer>
              <TextInput type="date" {...startDate} />
            </InputContainer>
          ) : (
            <Subscription>{data?.extendEndDate}</Subscription>
          )}
        </Content>
        <Content>
          <Label>시작시간</Label>
          <Subscription>
            {data?.extendStartDateTime ?? (
              <TextInput type="time" {...startTime} disabled={isActiveTime} />
            )}
          </Subscription>
        </Content>
        <Content>
          <Label>종료시간</Label>
          <Subscription>
            {data?.extendEndDateTime ?? (
              <TextInput type="time" {...endTime} disabled={isActiveTime} />
            )}
          </Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`제   목`}</Label>
          <Subscription>
            {isRequest ? (
              <StyleInput {...title} minWidth="300px" maxWidth="300px" />
            ) : (
              <span>{title.value}</span>
            )}
          </Subscription>
        </Content>
        <Content>
          {approvalDetailInformation?.extendDescription && (
            <Subscription>
              {approvalDetailInformation?.extendDescription}
            </Subscription>
          )}
          {isRequest && <StyleTextarea {...description} />}
        </Content>
        <Content>
          <Label isSpacing>{`수   신`}</Label>
          <Subscription>
            {isRequest && (
              <Button
                customMinHeight="20px"
                customMinWidth="50px"
                onClick={() => {
                  if (handleIsReceiveAndReference) {
                    handleIsReceiveAndReference(true);
                  }
                  window.open(
                    `${routes.pageRoutes.approvalProcess}${formTemplateIdx}`,
                    "",
                    "width=960, height=650"
                  );
                }}
              >
                수신선택
              </Button>
            )}
            <DisplayUsers>
              {listOfReceiveDepartmentName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReceiveDepartmentName.length - 1 === index
                    ? listOfReceiveUserName.length > 0
                      ? `${item},`
                      : item
                    : `${item},`}
                </span>
              ))}
              {listOfReceiveUserName.map((item, index) => (
                <span key={`${item} ${index}`}>
                  {listOfReceiveUserName.length - 1 === index
                    ? item
                    : `${item},`}
                </span>
              ))}
            </DisplayUsers>
          </Subscription>
        </Content>
        <Content>
          <Label isSpacing>{`참   조`}</Label>
          <Subscription>
            {isRequest && (
              <Button
                customMinHeight="20px"
                customMinWidth="50px"
                onClick={() => {
                  if (handleIsReceiveAndReference) {
                    handleIsReceiveAndReference(true);
                  }
                  window.open(
                    `${routes.pageRoutes.approvalProcess}${formTemplateIdx}`,
                    "",
                    "width=960, height=650"
                  );
                }}
              >
                참조선택
              </Button>
            )}
            <DisplayUsers>
              {listOfReferenceDepartmentName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReferenceDepartmentName.length - 1 === index
                    ? listOfReferenceUserName.length > 0
                      ? `${item},`
                      : item
                    : `${item},`}
                </span>
              ))}
              {listOfReferenceUserName.map((item, index) => (
                <span key={`${index} ${item}`}>
                  {listOfReferenceUserName.length - 1 === index
                    ? item
                    : `${item},`}
                </span>
              ))}
            </DisplayUsers>
          </Subscription>
        </Content>
        <Content>
          {/* <Label>첨부파일</Label> */}
          {/* <Subscription></Subscription> */}
        </Content>
      </MainSection>
    </Container>
  );
}

export default ApprovalForm;
