import { useCallback, useState } from "react";
import styled from "styled-components";
import {
  ApprovalDetailInformationEntity,
  ApprovalEntity,
  ApprovalTemplateEntity,
  Approval_User_Type,
  IReceiveAndReferenceForDepartment,
  IReceiveAndReferenceForUser,
  ListOfApprovalTemporaryStorageEntity,
  useGetApprovalFormDetailLazyQuery
} from "../../generated/graphql";
import { useMemo } from "react";
import { useEffect } from "react";
import EChannel from "../../Utils/EChannel/e-channel";
import ListOfApprovalHistory from "./select-approval-form-popup/list-of-approval-history";
import useOpenDialog from "../../hooks/use-open-dialog";
import PopupController from "./popup-controller";
import ApprovalPopupButtons from "./approval-popup-buttons";
import ApprovalForm, {
  IApprovalFormFieldValue
} from "./approval-form/approval-form";
import { IEmployeeInformation } from "./approval-process/list-of-selected-approval-user";
import {
  IEmployeeInformationForReceiveAndReference,
  ReceiveAndReferenceType
} from "./approval-receive-and-reference-form/list-of-selected-approval-user-for-receive-and-reference-form";
import ApprovalDetailPopupStore from "./approval-detail-popup-store.ctx";
import { useController, useForm } from "react-hook-form";
import moment from "moment";
import { TOKEN } from "../../apollo/apollo";
import { handleDecodedToken } from "../../Utils/tokenMaker";
import ApprovalSignAndRejectDialog from "./approval-sign-and-reject-dialog/approval-sign-and-reject-dialog";

export enum ApprovalType {
  APPROVAL_REQUEST_POPUP = "APPROVAL_REQUEST_POPUP",
  APPROVAL_DETAIL_POPUP = "APPROVAL_DETAIL_POPUP",
  APPROVAL_WAITING_POPUP = "APPROVAL_WAITING_POPUP",
  APPROVAL_RECEIVE_REFERENCE = "APPROVAL_RECEIVE_REFERENCE",
  MY_APPROVAL = "MY_APPROVAL",
  COMPLETE = "COMPLETE",
  RECEIVE = "RECEIVE",
  REFERENCE = "REFERENCE",
  APPROVAL_WAITING_IN_LIST = "APPROVAL_WAITING_IN_LIST",
  APPROVAL_PROCESS_POPUP = "APPROVAL_PROCESS_POPUP",
  APPROVAL_PROCESS_POPUP_FOR_RECEIVE_REFERENCE = "APPROVAL_PROCESS_POPUP_FOR_RECEIVE_REFERENCE",
  REQUEST_APPROVAL_RECEIVE_REFERENCE = "REQUEST_APPROVAL_RECEIVE_REFERENCE",
  PREVIEW_POPUP = "PREVIEW_POPUP",
  MOVE_TO_TEMPORARY_STORAGE_PAGE = "MOVE_TO_TEMPORARY_STORAGE_PAGE",
  RESET_CACHE = "RESET_CACHE"
}

export interface IApprovalDetailMessage {
  type: ApprovalType;
  formTemplateIdx?: string;
  data?: ApprovalEntity | ListOfApprovalTemporaryStorageEntity;
  isRequest?: boolean;
  template?: ApprovalTemplateEntity;
  listOfEmployeeInformation?: IEmployeeInformation[];
  listOfEmployeeInformationForReceiveAndReference?: IEmployeeInformationForReceiveAndReference[];
  isTemporaryStorage?: boolean;
}

export interface IIsFormMessage {
  isOpen?: boolean;
  formTemplateIdx?: number;
  approvalRequestIdx?: number;
}

interface Props {
  handleShowApprovalPopup?: (value: boolean) => void;
  approvalData?: ApprovalEntity;
  newApprovalType?: ApprovalType;
  handleSelectForm?: (isNext: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const ContentSection = styled(Container)`
  display: flex;
  flex: 20;
  flex-direction: column;
  gap: 10px;
  border: none;
`;

// 수신 및 참조를 위한 flag 값
let isReceiveAndReference = false;

function ApprovalDetailPopup({
  newApprovalType,
  approvalData,
  handleShowApprovalPopup,
  handleSelectForm
}: Props) {
  const [isUserChecked, setIseUserChecked] = useState<boolean>(false);
  const [approvalDetailInformation, setApprovalDetailInformation] =
    useState<ApprovalDetailInformationEntity>();

  const [isSignOrReject, setIsSignOrReject] = useState<boolean>(true);

  const handleIsSignOrReject = useCallback((value: boolean) => {
    setIsSignOrReject(value);
  }, []);

  const [data, setData] = useState<ApprovalEntity | undefined>();
  const [template, setTemplate] = useState<ApprovalTemplateEntity>();
  const [listOfUser, setListOfUser] = useState<IEmployeeInformation[]>([]);

  // 상세 결재 양식 가져오는 로직 구현하기
  const [getApprovalFormDetail, { data: approvalFormDetailData }] =
    useGetApprovalFormDetailLazyQuery();
  const [
    listOfUserForReceiveAndReference,
    setListOfUserForReceiveAndReference
  ] = useState<IEmployeeInformationForReceiveAndReference[]>([]);
  // 선택된 수신자 사용자 표시
  const [listOfReceiveUserName, setListOfReceiveUserName] = useState<string[]>(
    []
  );
  // 선택된 참조자 사용자 표시
  const [listOfReferenceUserName, setListOfReferenceUserName] = useState<
    string[]
  >([]);
  // 선택된 수신자 부서 표시
  const [listOfReceiveDepartmentName, setListOfReceiveDepartmentName] =
    useState<string[]>([]);
  // 선택된 참조자 부서 표시
  const [listOfReferenceDepartmentName, setListOfReferenceDepartmentName] =
    useState<string[]>([]);
  const [listOfReceiveUser, setListOfReceiveUser] = useState<
    IReceiveAndReferenceForUser[]
  >([]);
  const [listOfReceiveDepartment, setListOfReceiveDepartment] = useState<
    IReceiveAndReferenceForDepartment[]
  >([]);
  const [listOfReferenceUser, setListOfReferenceUser] = useState<
    IReceiveAndReferenceForUser[]
  >([]);
  const [listOfReferenceDepartment, setListOfReferenceDepartment] = useState<
    IReceiveAndReferenceForDepartment[]
  >([]);

  const [approvalType, setApprovalType] = useState<ApprovalType>(
    newApprovalType || ApprovalType.APPROVAL_WAITING_POPUP
  );

  const { control, getValues, setValue } = useForm<IApprovalFormFieldValue>();

  const handleSubmit = useCallback(
    (handler: (data: IApprovalFormFieldValue) => void) => {
      const data = getValues();
      handler(data);
    },
    [getValues]
  );

  const { field: title } = useController({
    name: "title",
    defaultValue: "",
    control
  });

  const { field: startDate } = useController({
    name: "startDate",
    defaultValue: moment().format("YYYY-MM-DD"),
    control
  });

  const { field: endDate } = useController({
    name: "endDate",
    defaultValue: moment().format("YYYY-MM-DD"),
    control
  });

  const { field: startTime } = useController({
    name: "startTime",
    defaultValue: moment().format("HH:mm"),
    control
  });
  const { field: endTime } = useController({
    name: "endTime",
    defaultValue: moment().format("HH:mm"),
    control
  });

  const { field: description } = useController({
    name: "description",
    defaultValue: "",
    control
  });

  const { isOpen, handleOpenDialog } = useOpenDialog();
  const {
    isOpen: isOpenSignAndRejectDialog,
    handleOpenDialog: handleOpenSignAndRejectDialog
  } = useOpenDialog();

  const employeeId = useMemo((): string => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData.employee_id;
    }
    return "";
  }, []);

  const bc = useMemo(() => new BroadcastChannel(EChannel.APPROVAL), []);
  const isPopUpBc = useMemo(() => new BroadcastChannel(EChannel.POP_UP), []);
  const handleIsReceiveAndReference = useCallback((value: boolean) => {
    isReceiveAndReference = value;
  }, []);

  const closeWindow = useCallback(() => {
    if (bc) {
      bc.close();
    }
    if (isPopUpBc) {
      isPopUpBc.close();
    }
    window.close();
  }, [bc, isPopUpBc]);

  useEffect(() => {
    if (isPopUpBc && data) {
      isPopUpBc.postMessage({
        isOpen: true,
        approvalRequestIdx: data?.approvalRequestIdx
      });
      isPopUpBc.onmessage = event => {
        const message: IIsFormMessage = event.data;
        if (message.approvalRequestIdx === data.approvalRequestIdx) {
          window.close();
        }
      };
    }
  }, [isPopUpBc, data]);

  useEffect(() => {
    if (bc && !handleShowApprovalPopup) {
      const newMessage: IApprovalDetailMessage = {
        type: ApprovalType.APPROVAL_DETAIL_POPUP
      };
      bc.postMessage(newMessage);
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.APPROVAL_PROCESS_POPUP) {
          setListOfUser(message.listOfEmployeeInformation ?? []);
        } else if (
          message.type ===
          ApprovalType.APPROVAL_PROCESS_POPUP_FOR_RECEIVE_REFERENCE
        ) {
          setListOfUserForReceiveAndReference(
            message.listOfEmployeeInformationForReceiveAndReference ?? []
          );
        } else if (
          message.type === ApprovalType.APPROVAL_RECEIVE_REFERENCE &&
          isReceiveAndReference
        ) {
          const newMessage: IApprovalDetailMessage = {
            type: ApprovalType.REQUEST_APPROVAL_RECEIVE_REFERENCE
          };
          bc.postMessage(newMessage);
          isReceiveAndReference = false;
        } else if (message.type === ApprovalType.MY_APPROVAL) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (message.type === ApprovalType.COMPLETE) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (message.type === ApprovalType.RECEIVE) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (message.type === ApprovalType.REFERENCE) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (message.type === ApprovalType.APPROVAL_DETAIL_POPUP) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (
          message.type === ApprovalType.APPROVAL_WAITING_IN_LIST ||
          message.type === ApprovalType.APPROVAL_WAITING_POPUP
        ) {
          setApprovalType(message.type);
          setData(message.data as ApprovalEntity);
        } else if (message.type === ApprovalType.PREVIEW_POPUP) {
          setApprovalType(message.type);
          setTemplate(message.template);
        }
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.postMessage("close");
        bc.close();
      }
    };
  }, [bc, handleShowApprovalPopup]);

  useEffect(() => {
    if (listOfUserForReceiveAndReference) {
      const newListOfReceiveUser: IReceiveAndReferenceForUser[] = [];
      const newListOfReferenceUser: IReceiveAndReferenceForUser[] = [];
      const newListOfReceiveDepartment: IReceiveAndReferenceForDepartment[] =
        [];
      const newListOfReferenceDepartment: IReceiveAndReferenceForDepartment[] =
        [];
      const newListOfReceiveUserName: string[] = [];
      const newListOfReferenceUserName: string[] = [];
      const newListOfReceiveDepartmentName: string[] = [];
      const newListOfReferenceDepartmentName: string[] = [];
      listOfUserForReceiveAndReference.forEach(
        ({ employeeId, departmentId, type, name, department }) => {
          if (type === ReceiveAndReferenceType.RECEIVE) {
            if (employeeId) {
              newListOfReceiveUser.push({ employee_id: employeeId });
            }
            if (!employeeId && departmentId) {
              newListOfReceiveDepartment.push({
                department_id: parseInt(departmentId)
              });
            }
            if (name) {
              newListOfReceiveUserName.push(name);
            }
            if (!employeeId && department) {
              const departmentName = department.split(">").at(-1);
              newListOfReceiveDepartmentName.push(departmentName ?? "");
            }
          }
          if (type === ReceiveAndReferenceType.REFERENCE) {
            if (employeeId) {
              newListOfReferenceUser.push({ employee_id: employeeId });
            }
            if (!employeeId && departmentId) {
              newListOfReferenceDepartment.push({
                department_id: parseInt(departmentId)
              });
            }
            if (name) {
              newListOfReferenceUserName.push(name);
            }
            if (!employeeId && department) {
              const departmentName = department.split(">").at(-1);
              newListOfReferenceDepartmentName.push(departmentName ?? "");
            }
          }
        }
      );
      setListOfReceiveUser(newListOfReceiveUser);
      setListOfReferenceUser(newListOfReferenceUser);
      setListOfReceiveDepartment(newListOfReceiveDepartment);
      setListOfReferenceDepartment(newListOfReferenceDepartment);
      setListOfReceiveUserName(newListOfReceiveUserName);
      setListOfReferenceUserName(newListOfReferenceUserName);
      setListOfReceiveDepartmentName(newListOfReceiveDepartmentName);
      setListOfReferenceDepartmentName(newListOfReferenceDepartmentName);
    }
  }, [listOfUserForReceiveAndReference]);

  useEffect(() => {
    if (data?.approvalTitle) {
      setValue("title", data.approvalTitle);
    }
  }, [data]);

  useEffect(() => {
    if (approvalFormDetailData?.getApprovalFormDetail) {
      if (approvalFormDetailData.getApprovalFormDetail.information) {
        const information =
          approvalFormDetailData.getApprovalFormDetail.information;
        setApprovalDetailInformation(information);
        setValue("startDate", information.extendSDate || "");
        setValue("endDate", information.extendEDate || "");
        setValue("startTime", information.extendSDateTime || "");
        setValue("endTime", information.extendEDateTime || "");
        setValue("description", information.extendDescription || "");
      }
      if (approvalFormDetailData.getApprovalFormDetail.listOfApprovalUser) {
        let newIsUserChecked = false;
        const newListOfUser =
          approvalFormDetailData.getApprovalFormDetail.listOfApprovalUser.map(
            item => {
              if (
                item.approverType !== Approval_User_Type.Drafter &&
                item.viewTime &&
                !newIsUserChecked
              ) {
                newIsUserChecked = true;
                setIseUserChecked(newIsUserChecked);
              } else {
                setIseUserChecked(checked => {
                  if (newIsUserChecked) {
                    return true;
                  }
                  return false;
                });
              }
              return {
                employeeId: item.approverId,
                userPosition: "",
                department: "",
                approvalType: item.approverType,
                checked: false,
                ...item
              };
            }
          );
        setListOfUser(newListOfUser);
      }

      if (approvalFormDetailData.getApprovalFormDetail.listOfReceiveUser) {
        const receiveUser =
          approvalFormDetailData.getApprovalFormDetail.listOfReceiveUser.map(
            item => ({
              employee_id: item.employeeId
            })
          );
        const userName =
          approvalFormDetailData.getApprovalFormDetail.listOfReceiveUser.map(
            item => item.employeeName
          );
        setListOfReceiveUser(receiveUser);
        setListOfReceiveUserName(userName);
      }
      if (
        approvalFormDetailData.getApprovalFormDetail.listOfReceiveDepartment
      ) {
        const newListOfReceiveDepartment =
          approvalFormDetailData.getApprovalFormDetail.listOfReceiveDepartment.map(
            item => ({
              department_id: item.departmentId
            })
          );
        const listOfDepartmentName =
          approvalFormDetailData.getApprovalFormDetail.listOfReceiveDepartment.map(
            item => item.departmentName
          );
        setListOfReceiveDepartment(newListOfReceiveDepartment);
        setListOfReceiveDepartmentName(listOfDepartmentName);
      }
      if (
        approvalFormDetailData.getApprovalFormDetail.listOfReferenceDepartment
      ) {
        const newListOfReferenceDepartment =
          approvalFormDetailData.getApprovalFormDetail.listOfReferenceDepartment.map(
            item => ({
              department_id: item.departmentId
            })
          );
        const newListOfReferenceDepartmentName =
          approvalFormDetailData.getApprovalFormDetail.listOfReferenceDepartment.map(
            item => item.departmentName
          );
        setListOfReferenceDepartment(newListOfReferenceDepartment);
        setListOfReferenceDepartmentName(newListOfReferenceDepartmentName);
      }

      if (approvalFormDetailData.getApprovalFormDetail.listOfReferenceUser) {
        const newListOfReferenceUser =
          approvalFormDetailData.getApprovalFormDetail.listOfReferenceUser.map(
            item => ({ employee_id: item.employeeId })
          );
        const newListOfReferenceUserName =
          approvalFormDetailData.getApprovalFormDetail.listOfReferenceUser.map(
            item => item.employeeName
          );
        setListOfReferenceUser(newListOfReferenceUser);
        setListOfReferenceUserName(newListOfReferenceUserName);
      }
    }
  }, [approvalFormDetailData]);

  useEffect(() => {
    if (employeeId && data?.approvalRequestIdx) {
      getApprovalFormDetail({
        variables: {
          employeeId,
          approvalRequestIdx: data.approvalRequestIdx
        }
      });
    }
  }, [getApprovalFormDetail, employeeId, data]);

  useEffect(() => {
    setData(approvalData);
  }, [approvalData]);

  return (
    <ApprovalDetailPopupStore.Provider
      value={{
        listOfReceiveUser,
        listOfReceiveDepartment,
        listOfReferenceUser,
        listOfReferenceDepartment,
        listOfUser,
        approvalRequestIdx: data?.approvalRequestIdx,
        formTemplateIdx: data?.formTemplateIdx
      }}
    >
      <Container>
        {handleShowApprovalPopup && (
          <PopupController
            handleShowApprovalPopup={handleShowApprovalPopup}
            handleSelectForm={handleSelectForm}
          />
        )}
        <ApprovalPopupButtons
          handleShowApprovalPopup={handleShowApprovalPopup}
          approvalType={approvalType}
          handleOpenDialog={handleOpenDialog}
          isRequest={false}
          closeWindow={closeWindow}
          handleSubmit={handleSubmit}
          isActiveApplicationButton={listOfUser.length < 1 || !title.value}
          handleOpenSignAndRejectDialog={handleOpenSignAndRejectDialog}
          handleIsSignOrReject={handleIsSignOrReject}
          isUserChecked={isUserChecked}
        />
        <ContentSection>
          <ApprovalForm
            data={data}
            template={template}
            approvalType={approvalType}
            isRequest={false}
            listOfUser={listOfUser}
            handleIsReceiveAndReference={handleIsReceiveAndReference}
            listOfReferenceUserName={listOfReferenceUserName}
            listOfReceiveUserName={listOfReceiveUserName}
            title={title}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            description={description}
            listOfReceiveDepartmentName={listOfReceiveDepartmentName}
            listOfReferenceDepartmentName={listOfReferenceDepartmentName}
            approvalDetailInformation={approvalDetailInformation}
          />
        </ContentSection>
        <ApprovalPopupButtons
          handleShowApprovalPopup={handleShowApprovalPopup}
          approvalType={approvalType}
          handleOpenDialog={handleOpenDialog}
          isRequest={false}
          closeWindow={closeWindow}
          handleSubmit={handleSubmit}
          isActiveApplicationButton={listOfUser.length < 1 || !title.value}
          handleOpenSignAndRejectDialog={handleOpenSignAndRejectDialog}
          handleIsSignOrReject={handleIsSignOrReject}
          isUserChecked={isUserChecked}
        />
        {handleShowApprovalPopup && (
          <PopupController
            handleShowApprovalPopup={handleShowApprovalPopup}
            handleSelectForm={handleSelectForm}
          />
        )}
        {isOpen && (
          <ListOfApprovalHistory
            handleClose={handleOpenDialog}
            approvalDetailInformation={approvalDetailInformation}
          />
        )}
        {isOpenSignAndRejectDialog && data?.approvalRequestIdx && (
          <ApprovalSignAndRejectDialog
            handleClose={handleOpenSignAndRejectDialog}
            approvalDetailInformation={approvalDetailInformation}
            isSignOrReject={isSignOrReject}
            approvalRequestIdx={data.approvalRequestIdx}
            handleShowApprovalPopup={handleShowApprovalPopup}
          />
        )}
      </Container>
    </ApprovalDetailPopupStore.Provider>
  );
}
export default ApprovalDetailPopup;
