import * as React from "react";
import {
  getWorkingTimeSchedule,
  updateUsersWorkingTimeShceduleWithDepartmentId,
  updateUserWorkingTimeSchedule
} from "../../../../../api/working-time-schedule-api";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import RightMenuButton from "../../../../Rightmenu/RightMenuButton";

type Template = {
  employee_id: string;
  time_templete_idx: number;
  timename: string;
};

export type List = { title: string; id: number | null };

interface IProps {
  selectedUsers: string[];
  selectedDepartment?: string;
  getUsers: ({ title }: { title: string }) => void;
  handleEvent: (value: boolean) => void;
}

const UpdateWorkingTimeScheduleContainer = (props: IProps) => {
  const { selectedUsers, selectedDepartment, getUsers, handleEvent } = props;
  const isMount = React.useRef<boolean>(false);
  const [list, setList] = React.useState<List[]>([]);
  const [subList, setSubList] = React.useState<List[]>([]);
  const {
    isOpen,
    handleIsOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const getData = React.useCallback(() => {
    let subList: List[] = [];
    getWorkingTimeSchedule().then(({ data: { ok, data, error } }) => {
      if (ok) {
        data.forEach((item: Template) => {
          subList.push({ title: item.timename, id: item.time_templete_idx });
        });
      } else {
        console.log(error);
      }
      const list: List[] = [];
      list.push({ title: "미사용", id: 0 });
      list.push({ title: "개인근무표", id: -1 });
      if (isMount.current) {
        setList(list);
        setSubList(subList);
      }
    });
  }, []);

  const handleUpdateData = React.useCallback(
    (item: List) => () => {
      const params = {
        selectedEmployeeIds: selectedUsers,
        timeTemplateIdx: item.id
      };
      // 근무시간계획 업데이트를 해주는 쿼리 변경해주기
      updateUserWorkingTimeSchedule(params)
        .then(() => {
          getUsers({ title: item.title });
          handleMessage(`${item.title} 근무시간정책으로 변경되었습니다.`);
          handleToastMessageType(MessageTypes.SUCCESS);
          handleIsOpen(true);
          handleEvent(true);
        })
        .catch(error => {
          handleIsOpen(true);
          handleMessage(`${item.title} 근무시간정책으로 변경하지 못했습니다.`);
          handleToastMessageType(MessageTypes.ERROR);
          console.log(error);
        });
    },
    [
      getUsers,
      selectedUsers,
      handleIsOpen,
      handleMessage,
      handleToastMessageType,
      handleEvent
    ]
  );

  const handleUpdateDataWithDepartmentId = React.useCallback(
    (item: List) => () => {
      if (selectedDepartment) {
        const params = {
          selectedDepartmentId: selectedDepartment,
          timeTemplateIdx: item.id
        };
        // 근무시간계획 업데이트를 해주는 쿼리 변경해주기
        updateUsersWorkingTimeShceduleWithDepartmentId(params)
          .then(() => {
            handleMessage("근무시간계획 정책 변경되었습니다.");
            handleToastMessageType(MessageTypes.SUCCESS);
            handleIsOpen(true);
          })
          .catch(error => {
            handleMessage("근무시간계획 정책 변경이 실패했습니다.");
            handleToastMessageType(MessageTypes.ERROR);
            handleIsOpen(true);
            console.log(error);
          });
      }
    },
    [selectedDepartment, handleIsOpen, handleMessage, handleToastMessageType]
  );

  React.useEffect(() => {
    isMount.current = true;
    getData();
    return () => {
      isMount.current = false;
    };
  }, [getData]);
  return (
    <>
      <RightMenuButton
        isDivide={true}
        title="근무시간계획정책"
        list={list}
        subList={subList}
        listHandleClick={
          selectedDepartment
            ? handleUpdateDataWithDepartmentId
            : handleUpdateData
        }
      />
      <ToastMessage
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        message={message}
        messageTypes={toastMessageType}
      />
    </>
  );
};

export default UpdateWorkingTimeScheduleContainer;
