// 작성자 : yellowgg2
// MACOS와 통신하기위한 클래스
// EPacketType, IButtonAction에 항목이 새로 추가되면 항상 README에 업데이트 한다

declare global {
  interface Window {
    webkit: any;
  }
}

export enum EPacketType {
  ButtonAction = "buttonAction", // 버튼 클릭 시
  WindowResizeAction = "windowResizeAction", // 윈도우 사이즈 변경 시
  OpenUrl = "openUrl" // url을 기본 브라우져로 열때
}

export interface IButtonAction {
  whichButton: string; // 어떤 버튼인가? (ex: close, minimize, etc)
  additionalAction?: string; // 버튼의 기본 동작 외에 어떤 동작을 해야하는가 (ex: 데이터 전송 등, 아직 어디에 쓸지 모름)
}

export interface IResizeWindow {
  width: number;
  height: number;
}

export interface IOpenUrlInExternalBrowser {
  url: string;
}

export interface ISendToMac {
  type: EPacketType; // 어떤 종류의 패킷인가 (버튼, 에디트 박스???)
  payload: IButtonAction | IResizeWindow | IOpenUrlInExternalBrowser;
}

class CommunicationWithMac {
  private static instance?: CommunicationWithMac;

  private constructor() {}

  static getInstance(): CommunicationWithMac | undefined {
    if (!CommunicationWithMac.instance) {
      if (window.webkit?.messageHandlers) {
        CommunicationWithMac.instance = new CommunicationWithMac();
      } else {
        console.log("This is not MACOS webview");
      }
    }
    return CommunicationWithMac.instance;
  }

  sendCommunicationPacket(toMac: ISendToMac) {
    let stringifiedPacket = JSON.stringify(toMac);
    window.webkit.messageHandlers.asonicChannel.postMessage(stringifiedPacket);
  }
}

export default CommunicationWithMac;
