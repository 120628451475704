import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TitleDiv = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 1px;
  justify-content: center;
  border-bottom: 1px solid white;
  border-radius: 3px;
  border: 1;
  background-color: #4193cc;
  font-family: "Gothic", sans-serif;
`;

export const BtnIcon = styled(FontAwesomeIcon)`
  color: white;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: white;
`;

export const Title = styled.h3`
  color: white;
  margin-left: 10px;
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    ${Title}, ${Icon} {
      color: #feca57;
    }
  }
`;

export const DownListItemIcon = styled.div`
  color: white;
  font-weight: bold;
`;

export const DownListItemText = styled.span`
  margin-left: 4px;
`;

export const MainViewRootDiv = styled.div`
  @media screen and (max-width: 1024px) {
    min-width: 1600px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const LeftNavigationBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${props => props.theme.colors.midNightBlue};
`;

export const RightContentsDiv = styled.div`
  display: flex;
  width: 100%;
  min-width: 1600px;
  min-height: 900px;
  overflow: auto;
  flex-direction: column;
`;

export const RightContentsChildViewDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
`;

export const StickyDiv = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Btn = styled.button`
  cursor: pointer;
  align-self: center;
  justify-self: flex-end;
  width: 100%;
  color: white;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  border: none;
  border-top: 1px solid ${props => props.theme.colors.black};
  margin-top: auto;
  background-color: ${props => props.theme.colors.darkMidNightBlue};
  :hover {
    background-color: ${props => props.theme.colors.lightMidNightBlue};
    color: white;
    svg {
      color: white;
    }
  }
  padding: 10px;
`;

export const BtnTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  svg {
    margin-right: 10px;
  }
`;

export const List = styled.div`
  flex: 26;
`;

export const Drawer = styled.aside<{ open: boolean }>`
  flex-shrink: 0;
  transition: width 0.2s ease-in-out;
  width: ${props => (props.open ? "200px" : "3.5%")};
`;
