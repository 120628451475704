import * as ReactTable from "react-table";
import AsonicTableRow from "./asonic-table-row";
import { colors } from "../GlobalStyle/GlobalStyle";
import AsonicTableData from "./asonic-table-data";

export interface IAsonicRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}
export interface IAsonicRenderRowProps<P extends {}> {
  prepareRow: (row: IAsonicRow<P>) => void;
  handleSelectRow?: (row?: IAsonicRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: IAsonicRow<P>;
  flexIndex?: number;
}
interface IRenderRow<P extends {}> {
  data: IAsonicRow<P>[];
  index: number;
  style: object;
}

function AsonicRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex
}: IAsonicRenderRowProps<P>) {
  return ({ index, data, style }: IRenderRow<P>) => {
    const row: IAsonicRow<P> = data[index];
    prepareRow(row);
    const backgroundColor = row.isSelected ? colors.green : "none";
    const color = row.isSelected ? colors.white : colors.black;
    const initStyle = { ...style, backgroundColor, color };
    const multiSelect = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const contextButton = 2;
      if (event.type === "contextmenu" || event.button === contextButton) {
        event.preventDefault();
      }
      if (row.toggleRowSelected) {
        if (
          row.isSelected &&
          (event.type !== "contextmenu" || event.button !== contextButton)
        ) {
          data.forEach(item => {
            if (item.isSelected && item.toggleRowSelected) {
              item.toggleRowSelected(false);
            }
          });
          row.toggleRowSelected(false);
        } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
          row.toggleRowSelected(true);
          if (
            (event.type === "contextmenu" || event.button === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
          }
        } else if (selectedRow && event.shiftKey) {
          data.forEach(item => {
            if (
              selectedRow.id < item.id &&
              item.id <= row.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              } else if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
            if (
              item.id < selectedRow.id &&
              row.id <= item.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              }
            }
            if (
              (event.type !== "contextmenu" ||
                event.buttons !== contextButton) &&
              handleSelectRow
            ) {
              handleSelectRow(row);
            }
          });
        } else {
          if (selectedRow) {
            data.forEach(item => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
          }
          if (
            (event.type !== "contextmenu" || event.buttons !== contextButton) &&
            handleSelectRow
          ) {
            row.toggleRowSelected(true);
            handleSelectRow(row);
          }
          if (
            (event.type === "contextmenu" || event.buttons === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
            row.toggleRowSelected(true);
          }
        }
      }
    };
    return (
      <AsonicTableRow {...row.getRowProps({ style: initStyle })}>
        {row.cells.map((cell, rowIndex) => (
          <AsonicTableData
            {...cell.getCellProps()}
            key={`${index} ${Math.random() * 10}`}
            id={`${data[index].id}-context-menu`}
            onContextMenu={multiSelect}
            onClick={multiSelect}
            isFlex={flexIndex === rowIndex}
            isLastFlex={flexIndex && flexIndex >= 0 ? false : true}
          >
            {cell.render("Cell")}
          </AsonicTableData>
        ))}
      </AsonicTableRow>
    );
  };
}

export default AsonicRenderRow;
