import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GridContainer = styled.div`
  flex: 4;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-template-rows: repeat(9, 1fr); */
  grid-auto-rows: 1fr;
  gap: 10px;
  grid-gap: 10px;
  padding: 10px;
  background-color: #f3f5f5;
`;

export const ButtonContainer = styled.div`
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.button`
  outline: none;
  border: none;
  background-color: #354e5a;
  color: white;
  padding: 8px 20px 8px 20px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: #598194;
  }
  :active {
    transform: scale(0.98);
  }
`;
