import AsonicTable from "../../../asonic-table/asonic-table";
import {
  CountListOfEmployeeInformation,
  CountListOfEmployeeInformationVariables
} from "../../../../__generated__/CountListOfEmployeeInformation";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import ContextMenu from "../../../context-menu/context-menu";
import { downloadFileFromServer } from "../../statistics/Utils";
import {
  DownloadListOfEmployeeInformationExcel,
  DownloadListOfEmployeeInformationExcelVariables
} from "../../../../__generated__/DownloadListOfEmployeeInformationExcel";
import {
  GetEmployeeInformation,
  GetEmployeeInformationVariables,
  GetEmployeeInformation_getEmployeeInformation_listOfEmployee
} from "../../../../__generated__/GetEmployeeInformation";
import gql from "graphql-tag";
import MainViewContext from "../../store";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import moment from "moment";
import styled from "styled-components";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import * as ReactTable from "react-table";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import { IAsonicRow } from "../../../asonic-table/asonic-render-row";
import { TYPE_OF_EMPLOYEE_INFORMATION } from "../../../../__generated__/globalTypes";
import { SearchFieldValues } from "../../../asonic-table/asonic-search";
import useContextMenu from "../../../../hooks/use-context-menu/use-context-menu";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useWorkingTemplate, {
  IWorkingTemplate
} from "../../../../hooks/use-working-template/use-working-template";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import WorkingTemplateManagementMenu from "../../../Rightmenu/working-template-management-menu/working-template-management-menu";
import { updateUserWorkingTemplate } from "../../../../Utils/changeWorkingTemplate";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ShowGraphMenu from "../../../Rightmenu/show-graph-menu/show-graph-menu";
import ChartDialogContainer from "../../../Rightmenu/ChartDialogContainer";
import PhoneNumberRegister from "../../../Rightmenu/phone-number-register/phone-number-register";
import PhoneNumberRegisterDialogContianer from "../../../Rightmenu/PhoneNumberRegisterDialogContainer";
import EmergencyKeyIssuance from "../../../Rightmenu/emergency-key-Issuance/emergency-key-Issuance";
import { Role } from "../../../../user-types";
import InitPassword from "../../../Rightmenu/init-password/init-password";
import useInitPassword from "../../../../hooks/use-init-password";
import UpdateWorkingTimeScheduleContainer from "../UpdateWorkingTimeSchedule/UpdateWorkingTimeScheduleContainer";
import useEvent from "../../../../hooks/use-event/use-event";
import useSortBy from "../../../../hooks/use-sort-by/use-sort-by";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import EmergencyKeyDialog from "../EmergencyKeyDialog";
import listOfApolloVar from "../../../../apollo/apollo-var";
import AsonicUserProfile from "../../../asonic-table/asonic-user-profile-of-table";
// import AsonicGraphOfTable from "../../../asonic-table/asonic-graph-of-table";

interface IProps {
  userInfoHeight: number;
  userWindowMouseDown: () => void;
}

enum COLUMN_FOR_VALUE {
  name = "성명",
  employeeId = "아이디",
  departName = "부서명",
  workingName = "근무정책",
  asTimeTemplateName = "근무시간계획정책",
  endpointCount = "Endpoint수량"
}

export type TYPE_FOR_DASH_BOARD_TABLE = keyof typeof COLUMN_FOR_VALUE;

const DragContainer = styled.div<{ userInfoHeight: number }>`
  position: relative;
  display: flex;
  height: ${props =>
    props.userInfoHeight
      ? `${
          props.userInfoHeight > 500
            ? 500
            : props.userInfoHeight < 180
            ? 180
            : props.userInfoHeight
        }px`
      : `50%`};
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

const DragLine = styled.div`
  position: sticky;
  cursor: row-resize;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-bottom: 2px solid ${props => props.theme.colors.frameGrey};
  :hover {
    border-bottom: 2px solid ${props => props.theme.colors.lightMidNightBlue};
  }
  :active {
    border-bottom: 2px solid ${props => props.theme.colors.lightGreen};
  }
`;

const QUERY_COUNT_LIST_OF_EMPLOYEE_INFORMATION = gql`
  query CountListOfEmployeeInformation(
    $selectedDepartmentId: String!
    $searchType: TYPE_OF_EMPLOYEE_INFORMATION
    $searchValue: String
  ) {
    countListOfEmployeeInformation(
      selectedDepartmentId: $selectedDepartmentId
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_GET_EMPLOYEE_INFORMATION = gql`
  query GetEmployeeInformation(
    $page: Float = 1
    $take: Float = 10
    $selectedDepartmentId: String!
    $searchType: TYPE_OF_EMPLOYEE_INFORMATION
    $searchValue: String
    $fieldSort: FieldSort
  ) {
    getEmployeeInformation(
      page: $page
      take: $take
      selectedDepartmentId: $selectedDepartmentId
      searchType: $searchType
      searchValue: $searchValue
      fieldSort: $fieldSort
    ) {
      ok
      error
      listOfEmployee {
        employeeId
        name
        departmentId
        fullDepartmentName
        departName
        endpointCount
        insertType
        workingName
        workingTemplateIdx
        asTimeTemplateName
        approvalDefine
        col3
        col2
        col1
        col4
        col5
        col6
        col7
        col8
        col9
        col10
        col11
        col12
        col13
        col14
        col15
        wtAsextto
        weekMaxExtWorkHour
        wtTot
        weekMaxWorkHour
        todayWorkingTime
      }
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_INFORMATION_EXCEL = gql`
  query DownloadListOfEmployeeInformationExcel(
    $selectedDepartmentId: String!
    $searchType: TYPE_OF_EMPLOYEE_INFORMATION
    $searchValue: String
  ) {
    downloadListOfEmployeeInformationExcel(
      selectedDepartmentId: $selectedDepartmentId
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "직원정보";

function UserInfoSection({ userInfoHeight, userWindowMouseDown }: IProps) {
  const isChangedSelectedDepartment = useRef(false);
  const selectedDepartment = listOfApolloVar.selectedDepartmentVar();
  const { fieldSort, handleFieldSort } = useSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [currentSearchType, setCurrentSearchType] =
    useState<TYPE_OF_EMPLOYEE_INFORMATION>(TYPE_OF_EMPLOYEE_INFORMATION.name);
  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const {
    listOfWorkingTemplate,
    selectedWorkingTemplate,
    handleSelectWorkingTemplate
  } = useWorkingTemplate();
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: { loginIdInfo: authCheck, checkWebAuthFn }
  } = rootStore;
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: rootStore?.signInReducer?.webMenuList[0]?.web_etc_flag
  });
  const { handleEvent } = useEvent();
  const [isEmergencyKeyDialog, setIsEmergencyKeyDialog] = useState(false);
  const handleOpenEmergencyKeyDialog = useCallback((isTrue: boolean) => {
    setIsEmergencyKeyDialog(isTrue);
  }, []);

  const [isOpenChartDialog, setIsOpenChartDialog] = useState<boolean>(false);
  const [isOpenPhoneNumberRegisterDialog, setIsOpenPhoneNumberRegisterDialog] =
    useState<boolean>(false);

  const handleOpenChartDialog = useCallback((isTrue: boolean) => {
    setIsOpenChartDialog(isTrue);
  }, []);

  const handlePhoneNumberRegisterDialog = useCallback((isTrue: boolean) => {
    setIsOpenPhoneNumberRegisterDialog(isTrue);
  }, []);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [getTotal, { data: totalData }] = useLazyQuery<
    CountListOfEmployeeInformation,
    CountListOfEmployeeInformationVariables
  >(QUERY_COUNT_LIST_OF_EMPLOYEE_INFORMATION);

  const [getEmployeeInformation, { data, loading, client }] = useLazyQuery<
    GetEmployeeInformation,
    GetEmployeeInformationVariables
  >(QUERY_GET_EMPLOYEE_INFORMATION);

  const [downloadListOfEmployeeInformationExcel] = useLazyQuery<
    DownloadListOfEmployeeInformationExcel,
    DownloadListOfEmployeeInformationExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_INFORMATION_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfEmployeeInformationExcel.ok &&
        data.downloadListOfEmployeeInformationExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfEmployeeInformationExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-list-of-employee.csv`
        );
      }
    }
  });

  const columns: ReactTable.Column<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      let newListOfColumn: ReactTable.Column<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>[] =
        [];
      listOfColumn.forEach(item => {
        let width = 120;
        if (
          item === "departName" ||
          item === "workingName" ||
          item === "asTimeTemplateName"
        ) {
          width = 220;
        }
        if (item === "endpointCount") {
          width = 100;
        }
        const newColumn = {
          Header: COLUMN_FOR_VALUE[item as TYPE_FOR_DASH_BOARD_TABLE],
          accessor: item as TYPE_FOR_DASH_BOARD_TABLE,
          width,
          Cell(
            cell: ReactTable.Cell<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>
          ) {
            const INHERITANCE = 100000000;
            if (cell.column.Header === "근무시간계획정책" && !cell.value) {
              return "미사용";
            }
            if (cell.column.Header === "근무정책" && !cell.value) {
              if (cell.row.original.workingTemplateIdx === INHERITANCE) {
                return "상속";
              }
              return "미사용";
            }
            if (cell.column.Header === "성명") {
              return <AsonicUserProfile name={cell.value} />;
            }
            return cell.value;
          }
        };
        newListOfColumn.push(newColumn);
      });

      return newListOfColumn;
    }, []);

  const list: GetEmployeeInformation_getEmployeeInformation_listOfEmployee[] =
    useMemo(() => {
      return data?.getEmployeeInformation.listOfEmployee || [];
    }, [data]);

  const downloadExcel = useCallback(() => {
    if (selectedDepartment) {
      downloadListOfEmployeeInformationExcel({
        variables: {
          selectedDepartmentId: selectedDepartment,
          searchType: currentSearchType,
          searchValue: currentSearchValue
        }
      });
    }
  }, [
    selectedDepartment,
    downloadListOfEmployeeInformationExcel,
    currentSearchType,
    currentSearchValue
  ]);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>
    ) => {},
    []
  );

  const total = useMemo(() => {
    if (
      totalData?.countListOfEmployeeInformation.ok &&
      totalData?.countListOfEmployeeInformation.total
    ) {
      return totalData?.countListOfEmployeeInformation.total;
    }
    return 0;
  }, [totalData]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;
        setCurrentSearchType(data.type as TYPE_OF_EMPLOYEE_INFORMATION);
        handleCurrentPage(INIT_PAGE);
        setCurrentSearchValue(data.value as string);
        getTotal({
          variables: {
            selectedDepartmentId: selectedDepartment,
            searchType: data.type as TYPE_OF_EMPLOYEE_INFORMATION,
            searchValue: data.value
          }
        });
      }
    },
    [getTotal, selectedDepartment, handleCurrentPage]
  );

  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();
  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleHideColumn,
    columns: userColumns,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } =
    useDnd<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>({
      columns: visibleColumns,
      setColumnOrder,
      title: `${title}-for-ordering-column`
    });

  const selectedRow:
    | ReactTable.Row<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const selectTemplate = useCallback(
    (temp: IWorkingTemplate) => () => {
      handleSelectWorkingTemplate(temp);
      handleConfirmMessage({
        title: `근무정책변경`,
        p: `${temp.name}으로 변경하시겠습니까?`,
        messageTypes: MessageTypes.INFO
      });
      handleIsOpen(true);
    },
    [handleSelectWorkingTemplate, handleConfirmMessage, handleIsOpen]
  );

  const handleConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const listOfUserId = selectedFlatRows.map(item => {
        return item.original.employeeId;
      }, []);
      updateUserWorkingTemplate(
        listOfUserId,
        selectedWorkingTemplate?.working_templete_idx
      )
        .then(() => {
          listOfUserId.forEach(item => {
            client?.cache.modify({
              id: `EmployeeInformation:${item}`,
              fields: {
                workingName() {
                  return selectedWorkingTemplate?.name;
                }
              }
            });
          });
          handleToastMessageType(MessageTypes.SUCCESS);
          handleMessage(
            `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
          );
        })
        .catch(() => {
          handleToastMessageType(MessageTypes.WARNING);
          handleMessage(
            `${
              selectedWorkingTemplate?.name || "미사용"
            }으로 변경하지 못했습니다.`
          );
        })
        .finally(() => {
          handleIsOpen(false);
          handleIsToastMessageOpen(true);
        });
    },
    [
      selectedFlatRows,
      selectedWorkingTemplate,
      handleIsOpen,
      handleToastMessageType,
      handleMessage,
      handleIsToastMessageOpen,
      client
    ]
  );

  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { handleInitPassword } = useInitPassword({
    handleMessage,
    handleIsToastMessageOpen,
    handleToastMessageType,
    selectedEmployeeId: listOfEmployeeId[listOfEmployeeId.length - 1]
  });

  const getUsers = useCallback(
    ({ title }) => {
      listOfEmployeeId.forEach(item => {
        client?.cache.modify({
          id: `EmployeeInformation:${item}`,
          fields: {
            asTimeTemplateName() {
              return title;
            }
          }
        });
      });
    },
    [client, listOfEmployeeId]
  );

  useEffect(() => {
    listOfApolloVar.selectedListOfEmployeeIdVar(
      selectedFlatRows.map(item => item.original.employeeId)
    );
    listOfApolloVar.selectedListOfEmployeeVar(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    if (selectedDepartment) {
      getTotal({
        variables: {
          selectedDepartmentId: selectedDepartment,
          searchType: TYPE_OF_EMPLOYEE_INFORMATION.name,
          searchValue: ""
        }
      });
    }
  }, [getTotal, selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment && !isChangedSelectedDepartment.current) {
      handleCurrentPage(1);
      isChangedSelectedDepartment.current = true;
    }
    return () => {
      isChangedSelectedDepartment.current = false;
    };
  }, [selectedDepartment, handleCurrentPage]);

  useEffect(() => {
    if (selectedDepartment) {
      isChangedSelectedDepartment.current = false;
      getEmployeeInformation({
        variables: {
          page: currentPage,
          take: take,
          selectedDepartmentId: selectedDepartment,
          searchType: currentSearchType,
          searchValue: currentSearchValue,
          fieldSort
        }
      });
    }
  }, [
    selectedDepartment,
    getEmployeeInformation,
    currentPage,
    take,
    currentSearchType,
    currentSearchValue,
    fieldSort
  ]);

  useEffect(() => {
    if (
      !data?.getEmployeeInformation.ok &&
      data?.getEmployeeInformation.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.getEmployeeInformation.error);
      handleIsToastMessageOpen(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsToastMessageOpen]);

  return (
    <DragContainer userInfoHeight={userInfoHeight}>
      <Container>
        <AsonicTable<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>
          title={title}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          take={take}
          handleTake={handleTake}
          total={total}
          totalPage={Math.ceil(total / take)}
          downloadExcel={downloadExcel}
          handleSelectRow={handleSelectRow}
          isLoading={loading}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          handleContextMenu={handleContextMenu}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          handleFieldSort={handleFieldSort}
          fieldSort={fieldSort}
          columns={
            userColumns as TColumn<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>[]
          }
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
        />
      </Container>
      <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
        {webMenuAuthList.serviceManagementInCtx && (
          <WorkingTemplateManagementMenu
            workingTemplate={listOfWorkingTemplate}
            selectTemplate={selectTemplate}
          />
        )}
        {webMenuAuthList.showGraph && (
          <ShowGraphMenu handleChartDialog={handleOpenChartDialog} />
        )}
        {webMenuAuthList.phoneNumber && authCheck && (
          <PhoneNumberRegister
            handlePhoneNumberRegisterDialog={handlePhoneNumberRegisterDialog}
          />
        )}
        {authCheck === Role.SUPER_ADMIN &&
          webMenuAuthList.emergencyKeyIssuance && (
            <EmergencyKeyIssuance
              handleOpenEmergencyKeyDialog={handleOpenEmergencyKeyDialog}
            />
          )}
        {webMenuAuthList.initPassword && (
          <InitPassword handleInitPassword={handleInitPassword} />
        )}
        {webMenuAuthList.workingHourPlanningPolicyInCtx && (
          <UpdateWorkingTimeScheduleContainer
            selectedUsers={listOfEmployeeId}
            getUsers={getUsers}
            handleEvent={handleEvent}
          />
        )}
      </ContextMenu>
      <DragLine onMouseDown={userWindowMouseDown} />
      {selectedWorkingTemplate && isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpenChartDialog && (
        <ChartDialogContainer handleChartDialog={handleOpenChartDialog} />
      )}
      {isOpenPhoneNumberRegisterDialog && (
        <PhoneNumberRegisterDialogContianer
          handlePhoneNumberRegisterDialog={handlePhoneNumberRegisterDialog}
          authCheck={authCheck}
        />
      )}
      {isEmergencyKeyDialog && (
        <EmergencyKeyDialog
          isEmergencyKeyDialog={isEmergencyKeyDialog}
          handleOpenEmergencyKeyDialog={handleOpenEmergencyKeyDialog}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </DragContainer>
  );
}

export default UserInfoSection;
