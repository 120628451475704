import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as ReactTable from "react-table";
import { Container } from "./approval-notification-employee-section";
import ApprovalNotificationManagementDialogCtx from "./approval-notification-management-dialog.ctx";
import { downloadFileFromServer } from "../../../statistics/Utils";
import moment from "moment";
import ApprovalNotificationDepartmentFooter from "./approval-notification-department-footer";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import {
  ListOfApprovalNotificationDepartment,
  ListOfApprovalNotificationDepartmentVariables,
  ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list
} from "../../../../../__generated__/ListOfApprovalNotificationDepartment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import {
  CountlistOfApprovalNotificationDepartment,
  CountlistOfApprovalNotificationDepartmentVariables
} from "../../../../../__generated__/CountlistOfApprovalNotificationDepartment";
import { TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT } from "../../../../../__generated__/globalTypes";
import AsonicTable from "../../../../asonic-table/asonic-table";
import { SearchFieldValues } from "../../../../asonic-table/asonic-search";
import {
  DownloadlistOfApprovalNotificationDepartmentExcel,
  DownloadlistOfApprovalNotificationDepartmentExcelVariables
} from "../../../../../__generated__/DownloadlistOfApprovalNotificationDepartmentExcel";
import useSortBy from "../../../../../hooks/use-sort-by/use-sort-by";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../../hooks/use-dnd/use-dnd";

export type ApprovalNotificationDepartmentColumns =
  ReactTable.Column<ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list>[];

enum COLUMN_FOR_VALUE {
  departName = "부서명",
  alarmName = "결재 알림 정책"
}

const QUERY_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT = gql`
  query ListOfApprovalNotificationDepartment(
    $templateIdx: Int!
    $page: Float
    $take: Float
    $searchType: TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT
    $searchValue: String
    $fieldSort: FieldSort
  ) {
    listOfApprovalNotificationDepartment(
      templateIdx: $templateIdx
      page: $page
      take: $take
      searchType: $searchType
      searchValue: $searchValue
      fieldSort: $fieldSort
    ) {
      ok
      error
      cacheId
      list {
        departmentId
        templateIdx
        departName
        alarmName
      }
    }
  }
`;

const QUERY_COUNT_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT = gql`
  query CountlistOfApprovalNotificationDepartment(
    $templateIdx: Int!
    $searchValue: String
    $searchType: TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT
  ) {
    countlistOfApprovalNotificationDepartment(
      templateIdx: $templateIdx
      searchValue: $searchValue
      searchType: $searchType
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT_EXCEL = gql`
  query DownloadlistOfApprovalNotificationDepartmentExcel(
    $templateIdx: Int!
    $searchType: TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT
    $searchValue: String
  ) {
    downloadlistOfApprovalNotificationDepartmentExcel(
      templateIdx: $templateIdx
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "정책적용부서";

function ApprovalNotificationDepartmentSection() {
  const { fieldSort, handleFieldSort } = useSortBy();
  const { selectedTemplate } = useContext(
    ApprovalNotificationManagementDialogCtx
  );

  const [currentSearchType, setCurrentSearchType] =
    useState<TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT>(
      TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT.departName
    );
  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const {
    isOpen: isOpenToast,
    handleIsOpen: handleIsOpenToast,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [getListOfApprovalNotificationDepartment, { data, loading }] =
    useLazyQuery<
      ListOfApprovalNotificationDepartment,
      ListOfApprovalNotificationDepartmentVariables
    >(QUERY_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT);

  const [countListOfApprovalNotificationDepartment, { data: totalData }] =
    useLazyQuery<
      CountlistOfApprovalNotificationDepartment,
      CountlistOfApprovalNotificationDepartmentVariables
    >(QUERY_COUNT_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT, {
      fetchPolicy: "cache-and-network"
    });

  const [downloadlistOfApprovalNotificationDepartmentExcel] = useLazyQuery<
    DownloadlistOfApprovalNotificationDepartmentExcel,
    DownloadlistOfApprovalNotificationDepartmentExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_APPROVAL_NOTIFICATION_DEPARTMENT_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadlistOfApprovalNotificationDepartmentExcel.ok &&
        data.downloadlistOfApprovalNotificationDepartmentExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadlistOfApprovalNotificationDepartmentExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-department-applied-to-the-template.csv`
        );
      }
    }
  });

  const total: number = useMemo(() => {
    return totalData?.countlistOfApprovalNotificationDepartment.total ?? 0;
  }, [totalData]);

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const downloadExcel = useCallback(() => {
    if (selectedTemplate) {
      downloadlistOfApprovalNotificationDepartmentExcel({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          searchType: currentSearchType,
          searchValue: currentSearchValue
        }
      });
    }
  }, [
    selectedTemplate,
    currentSearchType,
    currentSearchValue,
    downloadlistOfApprovalNotificationDepartmentExcel
  ]);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type && selectedTemplate) {
        const INIT_PAGE = 1;
        setCurrentSearchType(
          data.type as TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT
        );
        handleCurrentPage(INIT_PAGE);
        setCurrentSearchValue(data.value as string);
        countListOfApprovalNotificationDepartment({
          variables: {
            templateIdx: selectedTemplate?.templateIdx,
            searchType: data.type as TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT,
            searchValue: data.value
          }
        });
      }
    },
    [
      countListOfApprovalNotificationDepartment,
      selectedTemplate,
      handleCurrentPage
    ]
  );

  const columns: ApprovalNotificationDepartmentColumns = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    return listOfColumn.map(item => {
      return {
        Header:
          COLUMN_FOR_VALUE[item as TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT],
        accessor: item as TYPE_OF_APPROVAL_NOTIFICATION_DEPARTMENT,
        width: 120
      };
    });
  }, []);

  const list: ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list[] =
    useMemo(() => {
      return data?.listOfApprovalNotificationDepartment.list ?? [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: departmentColumns,
    toggleHideColumn,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } =
    useDnd<ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list>(
      {
        columns: visibleColumns,
        setColumnOrder,
        title: `${title}-for-ordering-column`
      }
    );

  const selectedRow:
    | ReactTable.Row<ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  useEffect(() => {
    if (selectedTemplate) {
      countListOfApprovalNotificationDepartment({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          searchType: currentSearchType,
          searchValue: currentSearchValue
        }
      });
    }
  }, [
    selectedTemplate,
    countListOfApprovalNotificationDepartment,
    currentSearchType,
    currentSearchValue
  ]);

  useEffect(() => {
    if (selectedTemplate) {
      getListOfApprovalNotificationDepartment({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          page: currentPage,
          take,
          searchType: currentSearchType,
          searchValue: currentSearchValue,
          fieldSort
        }
      });
    }
  }, [
    selectedTemplate,
    getListOfApprovalNotificationDepartment,
    currentPage,
    take,
    currentSearchType,
    currentSearchValue,
    fieldSort
  ]);

  useEffect(() => {
    if (
      !data?.listOfApprovalNotificationDepartment.ok &&
      data?.listOfApprovalNotificationDepartment.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfApprovalNotificationDepartment.error);
      handleIsOpenToast(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsOpenToast]);

  return (
    <Container>
      <AsonicTable<ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={() => {}}
        isLoading={loading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        fieldSort={fieldSort}
        handleFieldSort={handleFieldSort}
        columns={
          departmentColumns as TColumn<ListOfApprovalNotificationDepartment_listOfApprovalNotificationDepartment_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      />
      <ApprovalNotificationDepartmentFooter
        selectedTemplate={selectedTemplate}
        selectedDeFromTable={selectedFlatRows[selectedFlatRows.length - 1]}
      />
      <ToastMessage
        message={message}
        isOpen={isOpenToast}
        handleIsOpen={handleIsOpenToast}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalNotificationDepartmentSection;
