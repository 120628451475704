import AsonicTable from "../../../asonic-table/asonic-table";
import {
  CountListOfEmployeeCommuteManagement,
  CountListOfEmployeeCommuteManagementVariables
} from "../../../../__generated__/CountListOfEmployeeCommuteManagement";
import {
  DownloadListOfEmployeeCommuteManagementExcel,
  DownloadListOfEmployeeCommuteManagementExcelVariables
} from "../../../../__generated__/DownloadListOfEmployeeCommuteManagementExcel";
import { downloadFileFromServer } from "../../statistics/Utils";
import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import {
  ListOfEmployeeCommuteInformation,
  ListOfEmployeeCommuteInformationVariables,
  ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list
} from "../../../../__generated__/ListOfEmployeeCommuteInformation";
import { IDates } from "../../../asonic-table/asonic-search-with-date";
import * as ReactTable from "react-table";
import {
  ApprovalFlag,
  CommuteType
} from "../../../../__generated__/globalTypes";
import { SLink } from "../Content/styled";
import ContextMenu from "../../../context-menu/context-menu";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useContextMenu from "../../../../hooks/use-context-menu/use-context-menu";
import CommuteApprovalMenu from "../DetailView/CommuteContainer/CommuteApprovalMenu";
import { AddCommuteHistoryMenu } from "../DetailView/CommuteContainer/add-commute-history-menu/add-commute-history-menu";
import useIsOpenHook from "../../../../hooks/isOpenHook";
import { AddCommuteHistoryDialog } from "../DetailView/CommuteContainer/add-commute-history-dialog/add-commute-history-dialog";
import CommuteEditorContainer from "../DetailView/CommuteContainer/CommuteEditorDialog/CommuteEditorDialogContainer";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useSortBy from "../../../../hooks/use-sort-by/use-sort-by";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";

enum COLUMN_FOR_VALUE {
  name = "이름",
  date = "날짜",
  time = "시간",
  commuteType = "구분",
  deviceType = "출/퇴근기기",
  approvalFlag = "출퇴근 인정여부",
  checkDateTimeModify = "출/퇴근 일시",
  commuteAddress = "위치"
}

type TYPE_OF_COMMUTE_MANAGEMENT = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-y: hidden;
`;

const QUERY_LIST_OF_EMPLOYEE_COMMUTE_INFORMATION = gql`
  query ListOfEmployeeCommuteInformation(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
    $page: Float
    $take: Float
    $fieldSort: FieldSort
  ) {
    listOfEmployeeCommuteInformation(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      fieldSort: $fieldSort
    ) {
      ok
      error
      list {
        name
        employeeId
        checkDateTime
        date
        time
        commuteType
        deviceType
        gpsLat
        gpsLong
        commuteAddress
        approvalFlag
        checkDateTimeModify
      }
      cacheId
    }
  }
`;

const QUERY_COUNT_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT = gql`
  query CountListOfEmployeeCommuteManagement(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    countListOfEmployeeCommuteManagement(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      total
      cacheId
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT_EXCEL = gql`
  query DownloadListOfEmployeeCommuteManagementExcel(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    downloadListOfEmployeeCommuteManagementExcel(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      excel
      error
      ok
    }
  }
`;

const title = "출퇴근관리정보";

function CommuteViewSection() {
  const {
    signInReducer: { checkWebAuthFn, webMenuList, loginIdInfo, employee_id }
  } = useSelector((state: Reducers) => state);

  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: webMenuList[0]?.web_etc_flag
  });
  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();

  const { isOpen, handleIsOpen } = useIsOpenHook();
  const { isOpen: isOpenDialog, handleIsOpen: handleIsOpenDialog } =
    useIsOpenHook();

  const { fieldSort, handleFieldSort } = useSortBy();

  const {
    isOpen: isOpenToast,
    handleIsOpen: handleIsOpenToast,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const handleDate = useCallback((payload: IDates) => {
    setStartDate(payload.startDate);
    setEndDate(payload.endDate);
  }, []);

  const [getListOfEmployeeCommuteInformation, { data, loading }] = useLazyQuery<
    ListOfEmployeeCommuteInformation,
    ListOfEmployeeCommuteInformationVariables
  >(QUERY_LIST_OF_EMPLOYEE_COMMUTE_INFORMATION);

  const [countListOfEmployeeCommuteManagement, { data: totalData }] =
    useLazyQuery<
      CountListOfEmployeeCommuteManagement,
      CountListOfEmployeeCommuteManagementVariables
    >(QUERY_COUNT_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT, {
      fetchPolicy: "cache-and-network"
    });

  const [downloadListOfEmployeeCommuteManagementExcel] = useLazyQuery<
    DownloadListOfEmployeeCommuteManagementExcel,
    DownloadListOfEmployeeCommuteManagementExcelVariables
  >(QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_COMMUTE_MANAGEMENT_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfEmployeeCommuteManagementExcel.ok &&
        data.downloadListOfEmployeeCommuteManagementExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfEmployeeCommuteManagementExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-commute-management.csv`
        );
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (listOfEmployeeId) {
      downloadListOfEmployeeCommuteManagementExcel({
        variables: {
          listOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [
    downloadListOfEmployeeCommuteManagementExcel,
    listOfEmployeeId,
    startDate,
    endDate
  ]);

  const total: number = useMemo(() => {
    return totalData?.countListOfEmployeeCommuteManagement.total ?? 0;
  }, [totalData]);

  const handleAddress = useCallback(
    (
      cell: ReactTable.Cell<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>
    ) => {
      const title = cell.row.original.commuteAddress,
        lat = cell.row.original.gpsLat,
        long = cell.row.original.gpsLong;
      let address = "";
      let isAddress = false;
      if (title) {
        isAddress = true;
      }
      if (lat && long) {
        address = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      }
      return (
        <>
          {isAddress ? (
            <SLink href={`${address}`} target="_blank">
              {title}
            </SLink>
          ) : (
            <p>{title}</p>
          )}
        </>
      );
    },
    []
  );

  const columns: ReactTable.Column<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let width = 120;
        if (item === "approvalFlag" || item === "checkDateTimeModify") {
          width = 150;
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_COMMUTE_MANAGEMENT],
          accessor: item as TYPE_OF_COMMUTE_MANAGEMENT,
          width,
          Cell(
            cell: ReactTable.Cell<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>
          ) {
            if (cell.column.Header === COLUMN_FOR_VALUE.approvalFlag) {
              if (
                cell.row.original.approvalFlag ===
                ApprovalFlag.ORIGIN_RECOGNITION
              ) {
                return "원본인정";
              }
              if (
                cell.row.original.approvalFlag ===
                ApprovalFlag.CORRECTED_VERSION
              ) {
                return "수정본인정";
              }
              if (
                cell.row.original.approvalFlag === ApprovalFlag.NO_RECOGNITION
              ) {
                return "미인정";
              }
            }
            if (cell.column.Header === COLUMN_FOR_VALUE.commuteAddress) {
              return handleAddress(cell);
            }
            if (cell.column.Header === COLUMN_FOR_VALUE.commuteType) {
              if (cell.value === CommuteType.ATTENDANCE) {
                return "출근";
              }
              if (cell.value === CommuteType.LEAVE) {
                return "퇴근";
              }
            }
            return cell.value;
          }
        };
      });
    }, [handleAddress]);

  const list: ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list[] =
    useMemo(() => {
      return data?.listOfEmployeeCommuteInformation.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: commuteColumns,
    toggleHideColumn,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } =
    useDnd<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>(
      {
        columns: visibleColumns,
        setColumnOrder,
        title: `${title}-for-ordering-column`
      }
    );

  const selectedRow:
    | ReactTable.Row<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  useEffect(() => {
    if (loginIdInfo === -1) {
      countListOfEmployeeCommuteManagement({
        variables: {
          listOfEmployeeId: [employee_id],
          startDate,
          endDate
        }
      });
    }
    if (loginIdInfo !== -1 && listOfEmployeeId.length > 0) {
      countListOfEmployeeCommuteManagement({
        variables: {
          listOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [
    listOfEmployeeId,
    countListOfEmployeeCommuteManagement,
    startDate,
    endDate,
    loginIdInfo,
    employee_id
  ]);

  useEffect(() => {
    if (loginIdInfo === -1) {
      getListOfEmployeeCommuteInformation({
        variables: {
          listOfEmployeeId: [employee_id],
          startDate,
          endDate,
          page: currentPage,
          take,
          fieldSort
        }
      });
    }
    if (loginIdInfo !== -1 && listOfEmployeeId.length > 0) {
      getListOfEmployeeCommuteInformation({
        variables: {
          listOfEmployeeId,
          startDate,
          endDate,
          page: currentPage,
          take,
          fieldSort
        }
      });
    }
  }, [
    listOfEmployeeId,
    getListOfEmployeeCommuteInformation,
    startDate,
    endDate,
    currentPage,
    take,
    fieldSort,
    loginIdInfo,
    employee_id
  ]);

  useEffect(() => {
    if (
      !data?.listOfEmployeeCommuteInformation.ok &&
      data?.listOfEmployeeCommuteInformation.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfEmployeeCommuteInformation.error);
      handleIsOpenToast(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsOpenToast]);
  return (
    <Container>
      <AsonicTable<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={() => {}}
        isLoading={loading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleDate={handleDate}
        handleContextMenu={handleContextMenu}
        fieldSort={fieldSort}
        handleFieldSort={handleFieldSort}
        columns={
          commuteColumns as TColumn<ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      />
      {webMenuAuthList.changeCommutingRecord && (
        <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
          <CommuteApprovalMenu handleIsOpen={handleIsOpen} />
          <AddCommuteHistoryMenu handleIsOpen={handleIsOpenDialog} />
        </ContextMenu>
      )}
      {isOpen && (
        <CommuteEditorContainer
          handleClose={handleIsOpen}
          row={selectedRow && selectedRow.original}
        />
      )}
      {isOpenDialog && (
        <AddCommuteHistoryDialog
          handleClose={handleIsOpenDialog}
          selectedRow={selectedRow && selectedRow.original}
          handleMessage={handleMessage}
          handleToastMessageType={handleToastMessageType}
          handleIsOpenToast={handleIsOpenToast}
          listIdForCache={data?.listOfEmployeeCommuteInformation.cacheId}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isOpenToast}
        handleIsOpen={handleIsOpenToast}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default CommuteViewSection;
