import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  ApprovalSearchType,
  GetListOfApprovalTemporaryStorageQueryVariables,
  ListOfApprovalTemporaryStorageEntity,
  useCountListOfApprovalTemporaryStorageLazyQuery,
  useGetListOfApprovalTemporaryStorageLazyQuery
} from "../../generated/graphql";
import usePageControl from "../../hooks/use-page-control/use-page-control";
import EChannel from "../../Utils/EChannel/e-channel";
import { SearchFieldValues } from "../asonic-table/asonic-search";
import * as ReactTable from "react-table";
import { Cell } from "../../../types/@react-table/react-table/react-table";
import routes from "../../routes";
import { Icon } from "@iconify/react";
import ExternalLinkIcon from "@iconify-icons/eva/external-link-outline";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../hooks/use-dnd/use-dnd";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../apollo/apollo-var";
import ApprovalRequestDetailPopup from "./approval-request-detail-popup/approval-request-detail-popup";
import { ApprovalType, IApprovalDetailMessage } from "./approval-detail-popup";
import { client } from "../../apollo/apollo";
import useNewSortBy from "../../hooks/use-new-sort-by/use-new-sort-by";
import NewAsonicTable from "../new-asonic-table/new-asonic-table";

export const ENUM_COMMON_LIST_OF_APPROVAL = {
  signUpDatetime: "작성일",
  formIdx: "양식",
  approvalTitle: "제목",
  attachedFileName: "첨부"
} as const;

export type ENUM_COMMON_LIST_OF_APPROVAL =
  typeof ENUM_COMMON_LIST_OF_APPROVAL[keyof typeof ENUM_COMMON_LIST_OF_APPROVAL];

export type TYPE_COMMON_LIST_OF_APPROVAL =
  keyof typeof ENUM_COMMON_LIST_OF_APPROVAL;

const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
`;

export const ItemForTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  svg {
    font-size: 20px;
  }
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
`;

const Content = styled.div<{ showApprovalPopup: boolean }>`
  display: flex;
  height: ${props => {
    return props.showApprovalPopup ? "none" : "50%";
  }};
  padding: 10px;
  flex: 1;
  min-width: 790px;
`;

const PopUpContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
`;

const title = "임시 저장함";

function ApprovalTemporaryDocument() {
  const isRequest = useRef<boolean>(false);
  const approvalTemporaryStorage = useReactiveVar(
    listOfApolloVar.approvalTemporaryStorageVar
  );
  const approvalType = useReactiveVar(listOfApolloVar.approvalTypeVar);
  const FormName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [showApprovalPopup, setShowApprovalPopup] = useState<boolean>(false);
  const { fieldSort, handleFieldSort } = useNewSortBy();
  const [selectedRow, setSelectedRow] = useState<
    ListOfApprovalTemporaryStorageEntity | undefined
  >(undefined);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  const handleSelectedRowIndex = useCallback((index: number) => {
    setSelectedRowIndex(index);
  }, []);

  const handleShowApprovalPopup = useCallback((value: boolean) => {
    setShowApprovalPopup(value);
  }, []);

  const handleSelectedRow = useCallback(
    (row: ListOfApprovalTemporaryStorageEntity) => {
      setSelectedRow(row);
    },
    []
  );

  const [searchType, setSearchType] = useState<ApprovalSearchType>(
    ApprovalSearchType.ApprovalForm
  );

  const [searchValue, setSearchValue] = useState("");

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;
        setSearchType(data.type as ApprovalSearchType);
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value as string);
      }
    },
    [handleCurrentPage]
  );

  const listOfSearchType = useMemo(() => {
    return [
      {
        value: ApprovalSearchType.ApprovalForm,
        name: "양식"
      },
      {
        value: ApprovalSearchType.Title,
        name: "제목"
      }
    ];
  }, []);

  const bc = useMemo(() => {
    if (selectedRow) {
      return new BroadcastChannel(EChannel.APPROVAL);
    }
    return;
  }, [selectedRow]);

  const bcForReset = useMemo(() => {
    return new BroadcastChannel(EChannel.RESET);
  }, []);

  const [getListOfApprovalTemporaryStorage, { data, loading }] =
    useGetListOfApprovalTemporaryStorageLazyQuery({
      onError(error) {
        console.log(error.message);
      },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (
          data.getListOfApprovalTemporaryStorage.ok &&
          data.getListOfApprovalTemporaryStorage.list
        ) {
          if (isRequest.current) {
            setSelectedRowIndex(
              data.getListOfApprovalTemporaryStorage.list?.length - 1
            );
            isRequest.current = false;
          }
        }
      }
    });

  const [
    countListOfApprovalTemporaryStorage,
    { data: countData, loading: countLoading }
  ] = useCountListOfApprovalTemporaryStorageLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const list: ListOfApprovalTemporaryStorageEntity[] = useMemo(() => {
    return data?.getListOfApprovalTemporaryStorage?.list || [];
  }, [data]);

  const total: number = useMemo(() => {
    return countData?.countListOfApprovalTemporaryStorage.count ?? 0;
  }, [countData]);

  const columns: ReactTable.Column<ListOfApprovalTemporaryStorageEntity>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(ENUM_COMMON_LIST_OF_APPROVAL);
      return listOfColumn.map(item => {
        let width = 120;
        if (
          ENUM_COMMON_LIST_OF_APPROVAL[item as TYPE_COMMON_LIST_OF_APPROVAL] ===
          ENUM_COMMON_LIST_OF_APPROVAL.approvalTitle
        ) {
          width = 300;
        }
        return {
          Header:
            ENUM_COMMON_LIST_OF_APPROVAL[item as TYPE_COMMON_LIST_OF_APPROVAL],
          accessor: item as TYPE_COMMON_LIST_OF_APPROVAL,
          width,
          Cell(cell: Cell<ListOfApprovalTemporaryStorageEntity>) {
            if (
              ENUM_COMMON_LIST_OF_APPROVAL[
                item as TYPE_COMMON_LIST_OF_APPROVAL
              ] === ENUM_COMMON_LIST_OF_APPROVAL.approvalTitle
            ) {
              return (
                <ItemForTitle>
                  <span
                    onClick={() => {
                      listOfApolloVar.approvalTemporaryStorageVar(
                        cell?.row?.original
                      );
                      setShowApprovalPopup(true);
                    }}
                  >
                    {cell.value}
                  </span>
                  <IconContainer
                    onClick={() => {
                      handleSelectedRow(cell?.row?.original);
                      window.open(
                        routes.pageRoutes.approvalRequestPopup,
                        "",
                        "width=800, height=880, scrollbars=yes"
                      );
                    }}
                  >
                    <Icon icon={ExternalLinkIcon} />
                  </IconContainer>
                </ItemForTitle>
              );
            }
            if (
              ENUM_COMMON_LIST_OF_APPROVAL[
                item as TYPE_COMMON_LIST_OF_APPROVAL
              ] === ENUM_COMMON_LIST_OF_APPROVAL.formIdx
            ) {
              const newValue = FormName.get(cell.value) ?? "";
              return <Item>{`${newValue}`}</Item>;
            }

            return <Item>{`${cell.value}`}</Item>;
          }
        };
      });
    }, []);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    toggleHideColumn,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<ListOfApprovalTemporaryStorageEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } = useDnd<ListOfApprovalTemporaryStorageEntity>({
    columns: visibleColumns,
    setColumnOrder,
    title: `${title}-for-ordering-column`
  });

  const totalPage = useMemo(() => Math.ceil(total / take), [total, take]);

  const handleSelectForm = useCallback(
    (isNext: boolean) => {
      if (isNext) {
        if (
          typeof selectedRowIndex === "number" &&
          selectedRowIndex < list.length - 1
        ) {
          const nextIndex = selectedRowIndex + 1;
          handleSelectedRowIndex(nextIndex);
        } else if (currentPage < totalPage) {
          handleSelectedRowIndex(0);
          handleCurrentPage(currentPage + 1);
        }
      } else {
        if (typeof selectedRowIndex === "number" && selectedRowIndex > 0) {
          const preIndex = selectedRowIndex - 1;
          handleSelectedRowIndex(preIndex);
        } else if (currentPage > 1) {
          handleCurrentPage(currentPage - 1);
          if (!isRequest.current) {
            isRequest.current = true;
          }
        }
      }
    },
    [selectedRowIndex, list, handleSelectedRowIndex, currentPage]
  );

  useEffect(() => {
    if (list.length > 0 && !isRequest.current) {
      const selectedRow = list[selectedRowIndex];
      listOfApolloVar.approvalTemporaryStorageVar(selectedRow);
    }
  }, [list, selectedRowIndex]);

  useEffect(() => {
    const payload: GetListOfApprovalTemporaryStorageQueryVariables = {
      searchType,
      searchValue,
      take,
      page: currentPage,
      fieldSort
    };

    getListOfApprovalTemporaryStorage({
      variables: payload
    });
  }, [getListOfApprovalTemporaryStorage, take, currentPage, fieldSort]);

  useEffect(() => {
    const payload = {
      searchType,
      searchValue
    };
    countListOfApprovalTemporaryStorage({
      variables: payload
    });
  }, [countListOfApprovalTemporaryStorage, searchType, searchValue]);

  useEffect(() => {
    if (bc && selectedRow) {
      bc.onmessage = () => {
        const newMessage: IApprovalDetailMessage = {
          type: ApprovalType.APPROVAL_REQUEST_POPUP,
          data: selectedRow,
          isRequest: false,
          isTemporaryStorage: true
        };
        bc.postMessage(newMessage);
        setSelectedRow(undefined);
        bc.close();
      };
    }
    return () => {
      if (bc) {
        bc.close();
      }
    };
  }, [bc, selectedRow]);

  useEffect(() => {
    if (bcForReset) {
      bcForReset.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.RESET_CACHE) {
          client.resetStore();
        }
      };
    }
    return () => {
      if (bcForReset) {
        bcForReset.close();
      }
    };
  }, [bcForReset]);

  return (
    <Container>
      <Content showApprovalPopup={showApprovalPopup}>
        {showApprovalPopup ? (
          <PopUpContainer>
            <ApprovalRequestDetailPopup
              approvalTemporaryStorage={approvalTemporaryStorage}
              newApprovalType={approvalType}
              handleShowApprovalPopup={handleShowApprovalPopup}
              handleSelectForm={handleSelectForm}
            />
          </PopUpContainer>
        ) : (
          <NewAsonicTable<ListOfApprovalTemporaryStorageEntity>
            title={title}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
            take={take}
            handleTake={handleTake}
            total={total}
            totalPage={Math.ceil(total / take)}
            // downloadExcel={downloadExcel}
            // handleSelectRow={handleSelectRow}
            isLoading={loading || countLoading}
            listOfSearchType={listOfSearchType}
            handleSearch={handleSearch}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            // selectedRow={selectedRow}
            handleFieldSort={handleFieldSort}
            fieldSort={fieldSort}
            columns={columns as TColumn<ListOfApprovalTemporaryStorageEntity>[]}
            toggleHideColumn={toggleHideColumn}
            moveColumn={moveColumn}
          />
        )}
      </Content>
    </Container>
  );
}

export default ApprovalTemporaryDocument;
