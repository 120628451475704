import styled from "styled-components";
import {
  ApprovalDetailInformationEntity,
  ApprovalHistoryEntity,
  ApprovalStatus,
  useGetListOfApprovalHistoryLazyQuery
} from "../../../generated/graphql";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import SubTitle from "../../shared/sub-title/sub-title";
import { AutoSizer } from "react-virtualized";
import { VariableSizeList as List } from "react-window";
import RenderApprovalHistory from "./render-approval-history";
import { useCallback, useEffect, useMemo } from "react";
import Button from "../../globalComponents/Button";

export interface IApprovalHistory {
  title: string;
  status: ApprovalStatus;
  description?: string;
}

interface IProps {
  handleClose: (value: boolean) => void;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 5;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function ListOfApprovalHistory({
  handleClose,
  approvalDetailInformation
}: IProps) {
  const [getListOfApprovalHistory, { data }] =
    useGetListOfApprovalHistoryLazyQuery();

  const list = useMemo(() => {
    let listOfApprovalHistory: ApprovalHistoryEntity[] =
      data?.getListOfApprovalHistory.list ?? [];
    listOfApprovalHistory = [...listOfApprovalHistory].slice(1);
    return listOfApprovalHistory;
  }, [data]);

  const getItemSize = useCallback(
    (index: number): number => {
      const item = list[index];
      if (item?.descriptionComment) {
        return 50;
      }
      return 30;
    },
    [list]
  );

  useEffect(() => {
    if (approvalDetailInformation?.approvalRequestIdx) {
      getListOfApprovalHistory({
        variables: {
          approvalRequestIdx: approvalDetailInformation?.approvalRequestIdx
        }
      });
    }
  }, [approvalDetailInformation?.approvalRequestIdx]);
  return (
    <AsonicDialog
      title={"결재 이력"}
      handleClose={handleClose}
      width="420px"
      minWidth="420px"
      height="400px"
      minHeight="400px"
    >
      <Container>
        <SubTitle title="결재선 의견" />
        <Content>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  itemCount={list.length}
                  itemData={list}
                  width={width}
                  itemSize={getItemSize}
                >
                  {RenderApprovalHistory}
                </List>
              );
            }}
          </AutoSizer>
        </Content>
        <ButtonContainer
          onClick={() => {
            handleClose(false);
          }}
        >
          <Button>확인</Button>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
}

export default ListOfApprovalHistory;
