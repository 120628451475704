import React, { useState, useMemo, useCallback } from "react";
import DetailView from "../../../../components/main-view/WorkManagementInfo/DetailView";
import DetailViewContext from "./DetailViewContext";

const DetailViewContainer = props => {
  const [viewType, setViewType] = useState("pc");
  const handleViewType = useCallback(
    value => () => {
      setViewType(value);
    },
    [setViewType]
  );

  const makeStore = useCallback(() => {
    const store = {
      ...props,
      viewType,
      handleViewType
    };
    return store;
  }, [props, handleViewType, viewType]);

  const store = useMemo(makeStore, [makeStore]);

  return (
    <DetailViewContext.Provider value={store}>
      <DetailView viewType={viewType} />
    </DetailViewContext.Provider>
  );
};

export default DetailViewContainer;
