import { useContext } from "react";
import { Container, ItemContainer } from "./styled";
import uiString from "./string.json";
import DetailViewContext from "../../../../containers/main-view/WorkManagementInfoContainer/DetailViewContainer/DetailViewContext";
import useTitleBarMenu from "../../../../hooks/use-menu-by-etc-flag";
import { Role } from "../../../../user-types";

export enum ViewType {
  PC = "pc",
  WORK_MANAGEMENT = "workManagement",
  COMMUTE = "commute",
  PLACE_FREE_MANAGEMENT = "placeFreeManagement",
  ADDITIONAL_INFORMATION_MANAGEMENT = "additionalInformationManagement",
  APPROVAL_NOTIFICATION = "approvalNotification"
}

const ViewPanel = () => {
  const {
    handleViewType,
    viewType,
    signInReducer: { menuList, loginIdInfo }
  } = useContext(DetailViewContext);
  const { menu } = useTitleBarMenu({ webEtcFlag: menuList[0]?.web_etc_flag });

  return (
    <Container>
      <ItemContainer
        onClick={handleViewType(ViewType.PC)}
        currentViewType={viewType}
        viewType={ViewType.PC}
      >
        <span>{uiString.pcInfo.ko}</span>
      </ItemContainer>
      {menu.serviceManagementInfo && (
        <ItemContainer
          onClick={handleViewType(ViewType.WORK_MANAGEMENT)}
          currentViewType={viewType}
          viewType={ViewType.WORK_MANAGEMENT}
        >
          <span>{uiString.workingInfo.ko}</span>
        </ItemContainer>
      )}
      {menu.commutingManagementInfo && (
        <ItemContainer
          onClick={handleViewType(ViewType.COMMUTE)}
          currentViewType={viewType}
          viewType={ViewType.COMMUTE}
        >
          <span>{uiString.commute.ko}</span>
        </ItemContainer>
      )}
      {menu.placeFreeManagement && (
        <ItemContainer
          onClick={handleViewType(ViewType.PLACE_FREE_MANAGEMENT)}
          currentViewType={viewType}
          viewType={ViewType.PLACE_FREE_MANAGEMENT}
        >
          <span>{uiString.placeFreeManagement.ko}</span>
        </ItemContainer>
      )}
      <ItemContainer
        onClick={handleViewType(ViewType.ADDITIONAL_INFORMATION_MANAGEMENT)}
        currentViewType={viewType}
        viewType={ViewType.ADDITIONAL_INFORMATION_MANAGEMENT}
      >
        <span>{uiString.additionalInformationManagement.ko}</span>
      </ItemContainer>
      {menu.approvalNotification && loginIdInfo === Role.SUPER_ADMIN && (
        <ItemContainer
          onClick={handleViewType(ViewType.APPROVAL_NOTIFICATION)}
          currentViewType={viewType}
          viewType={ViewType.APPROVAL_NOTIFICATION}
        >
          <span>{uiString.approvalNotificationManagement.ko}</span>
        </ItemContainer>
      )}
    </Container>
  );
};

export default ViewPanel;
