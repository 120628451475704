import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewType } from "./ViewPanel";

interface IProps {
  currentViewType: ViewType;
  viewType: ViewType;
}

export const ItemContainer = styled.div<IProps>`
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  transition: min-width 0.2s ease-in;
  justify-content: space-around;
  align-items: center;
  min-width: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 14px;
  color: ${props =>
    props.currentViewType === props.viewType ? "#EEF6FB" : "#707070"};
  background-color: ${props =>
    props.currentViewType === props.viewType
      ? props.theme.colors.green
      : props.theme.colors.white};
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.frameGrey};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in;
  gap: 10px;
  padding: 10px 10px 0px 10px;
  background-color: ${props => props.theme.colors.lightGrey};
  height: 50px;
`;

export const Icon = styled(FontAwesomeIcon)`
  flex: 1;
`;

export const Span = styled.span`
  display: none;
  flex: 2;
  font-weight: bold;
`;
