import { Container, ButtonContainer, Button, GridContainer } from "./styled";
import ElectronDayWorkingStatus from "./electron-day-working-status";
import ElectronWeeklyTotalWorkingHour from "./electron-day-working-status/electron-weekly-total-working-hour";
import useGoShortcutButton from "../../../hooks/electron-hook/service-status-hook/use-go-shortcuts-button";

const ServiceStatus = () => {
  const { handleShortCutButton } = useGoShortcutButton();
  return (
    <Container data-testid="service-status">
      <GridContainer>
        <ElectronDayWorkingStatus />
        <ElectronWeeklyTotalWorkingHour />
        <ButtonContainer>
          <Button onClick={handleShortCutButton}>근무현황 바로가기</Button>
        </ButtonContainer>
      </GridContainer>
    </Container>
  );
};

export default ServiceStatus;
