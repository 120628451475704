import styled from "styled-components";
import AsonicTableHeader from "./asonic-table-header";

const AsonicTableData = styled(AsonicTableHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default AsonicTableData;
