import React, { Suspense, lazy, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { RootDiv } from "./custom-theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MobileMainViewContainer } from "./moContainer";
import routes from "./routes";
import { lightTheme } from "./components/GlobalStyle/GlobalStyle";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import listOfApolloVar from "./apollo/apollo-var";
import {
  Chart as ChartJS,
  LineController,
  CategoryScale,
  BarElement,
  LineElement,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDrop } from "react-dnd";
import Chat, { initChatRoomName } from "./components/chat/chat";
import MasterWorkManagementPage from "./components/main-view/master-work-management-page";
import PersonalWorkDashboardPage from "./components/main-view/personal-work-dashboard-page";
import WorkManagementInfoContainer from "./containers/main-view/WorkManagementInfoContainer";
import SuperAdminSettingsPage from "./components/super-admin-settings-page";
import VehicleDrivingManagementScreen from "./screens/vehicle-driving-management.screen";
import ReservationManagementScreen from "./screens/reservation-management.screen";
import AsonicCalendarMonth from "./components/asonic-calendar/asonic-calendar-month";
import Approval from "./screens/approval";
import { SettingsContainer } from "./containers";
import StatsContainer from "./components/main-view/statistics/StatsContainer";
import MobileSummaryContainer from "./moContainer/main-view/MobileSummaryContainer";
import MobileRequestContainer from "./moContainer/main-view/MobileRequestContainer";
import MobileApprovalContainer from "./moContainer/main-view/MobileApprovalContainer";
import MobileSettingContainer from "./moContainer/main-view/MobileSettingContainer";
import ApprovalDashBoard from "./components/approval/approval-dash-board";
import ApprovalList from "./components/approval/approval-list/approval-list";
import ApprovalTemporaryDocument from "./components/approval/approval-temporary-document";
import ApprovalSetting from "./components/approval/approval-setting/approval-setting";
import ApprovalDetailPopup from "./components/approval/approval-detail-popup";
import SelectApprovalFormPopup from "./components/approval/select-approval-form-popup/select-approval-form-popup";
import ApprovalProcess from "./components/approval/approval-process";
import ApprovalRequestDetailPopup from "./components/approval/approval-request-detail-popup/approval-request-detail-popup";
import AnnualSettingScreen from "./screens/annual-setting.screen";
import ServiceStatus from "./components/electron/service-status/service-status";
import CalendarInWidget from "./components/electron/calendar-in-widget/calendar-in-widget";

// 차트에서 사용해줄 기능 등록하기
ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const ElectronMainPage = React.lazy(() => import("./components/electron"));

const ChartContainer = React.lazy(
  () => import("./components/main-view/ChartContainer")
);
const MainViewContainer = lazy(
  () => import("./containers/main-view/MainViewContainer")
);

const SignInContainer = lazy(
  () => import("./containers/sign-in/SignInContainer")
);
const RoomsContainer = lazy(() => import("./containers/WebRTC/RoomsContainer"));
// DatePicker에서 달력이나 시간 ui를 custom하기위한 function

const QUERY_GET_CHAT_SERVER_IP = gql`
  query GetChatServerIp {
    getChatServerIp {
      ok
      error
      chatServerIp
    }
  }
`;

const App = () => {
  const approvalData = useReactiveVar(listOfApolloVar.approvalDataVar);
  const approvalType = useReactiveVar(listOfApolloVar.approvalTypeVar);

  const { data } = useQuery(QUERY_GET_CHAT_SERVER_IP);
  const [, drop] = useDrop(() => ({
    accept: initChatRoomName,
    drop(_, monitor) {
      const alpha = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta?.x && delta.y && alpha) {
        const left = `${Math.round(alpha.x + delta.x)}px`;
        const top = `${Math.round(alpha.y + delta.y)}px`;

        listOfApolloVar.chatPosition({
          top,
          left
        });
      }
      return undefined;
    },
    collect: monitor => ({
      isOver: !monitor.isOver()
    })
  }));

  useEffect(() => {
    if (data?.getChatServerIp.ok) {
      if (data.getChatServerIp.chatServerIp) {
        listOfApolloVar.chatServerIp(data.getChatServerIp.chatServerIp);
      }
    }
  }, [data]);
  return (
    <ThemeProvider theme={lightTheme}>
      <RootDiv ref={drop}>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Routes>
              <Route
                path={routes.pageRoutes.electron}
                element={<ElectronMainPage />}
              >
                <Route
                  path={routes.pageRoutes.electronServiceStatus}
                  element={<ServiceStatus />}
                />
                <Route
                  path={routes.pageRoutes.electronCalendar}
                  element={<CalendarInWidget />}
                />
                <Route
                  path={routes.pageRoutes.electronChat}
                  element={<Chat />}
                />
              </Route>
              <Route
                path={routes.pageRoutes.approvalPopup}
                element={
                  <ApprovalDetailPopup
                    approvalData={approvalData}
                    newApprovalType={approvalType}
                  />
                }
              />
              <Route
                path={routes.pageRoutes.approvalRequestPopup}
                element={
                  <ApprovalRequestDetailPopup newApprovalType={approvalType} />
                }
              />
              <Route
                path={routes.pageRoutes.selectApprovalFormPopup}
                element={<SelectApprovalFormPopup />}
              />
              <Route
                path={`${routes.pageRoutes.approvalProcess}:formTemplateIdx`}
                element={<ApprovalProcess />}
              />
              <Route
                path={`${routes.pageRoutes.main}${routes.pageRoutes.chart}`}
                element={<ChartContainer />}
              />
              <Route
                path={`${routes.pageRoutes.main}${routes.pageRoutes.rtc}`}
                element={<RoomsContainer />}
              />
              <Route
                path={routes.pageRoutes.main}
                element={<MainViewContainer />}
              >
                <Route
                  path={routes.pageRoutes.home}
                  element={<MasterWorkManagementPage />}
                />
                <Route
                  path={routes.pageRoutes.personal}
                  element={<PersonalWorkDashboardPage />}
                />
                <Route
                  path={routes.pageRoutes.workManagementInformation}
                  element={<WorkManagementInfoContainer />}
                />
                <Route
                  path={routes.pageRoutes.superAdminSettings}
                  element={<SuperAdminSettingsPage />}
                />
                <Route
                  path={routes.pageRoutes.vehicleDrivingManagement}
                  element={<VehicleDrivingManagementScreen />}
                />
                <Route
                  path={routes.pageRoutes.reservationManagement}
                  element={<ReservationManagementScreen />}
                />
                <Route
                  path={routes.pageRoutes.calendar}
                  element={<AsonicCalendarMonth />}
                />
                <Route path={routes.pageRoutes.approval} element={<Approval />}>
                  <Route
                    path={routes.pageRoutes.approvalDashboard}
                    element={<ApprovalDashBoard />}
                  />
                  <Route
                    path={routes.pageRoutes.approvalList}
                    element={<ApprovalList />}
                  />
                  <Route
                    path={routes.pageRoutes.approvalTemporaryDocument}
                    element={<ApprovalTemporaryDocument />}
                  />
                  <Route
                    path={routes.pageRoutes.approvalSetting}
                    element={<ApprovalSetting />}
                  />
                </Route>
                <Route
                  path={routes.pageRoutes.settings}
                  element={<SettingsContainer />}
                />
                <Route
                  path={routes.pageRoutes.annualEnvSetting}
                  element={<AnnualSettingScreen />}
                />
                <Route
                  path={`${routes.pageRoutes.statisticsUser}`}
                  element={<StatsContainer isAdmin={false} />}
                />
                <Route
                  path={`${routes.pageRoutes.statisticsAdmin}`}
                  element={<StatsContainer isAdmin={true} />}
                />
              </Route>
              <Route
                path={`${routes.pageRoutes.mobile}`}
                element={<MobileMainViewContainer />}
              >
                <Route
                  path={routes.pageRoutes.mobileHome}
                  element={<MobileSummaryContainer />}
                />
                <Route
                  path={routes.pageRoutes.mobileRequest}
                  element={<MobileRequestContainer />}
                />
                <Route
                  path={routes.pageRoutes.mobileApproval}
                  element={<MobileApprovalContainer />}
                />
                <Route
                  path={routes.pageRoutes.mobileSettings}
                  element={<MobileSettingContainer />}
                />
              </Route>
              <Route
                path={routes.pageRoutes.noAuth}
                element={<SignInContainer />}
              />

              <Route
                path={routes.pageRoutes.root}
                element={<SignInContainer />}
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
        <Chat />
      </RootDiv>
    </ThemeProvider>
  );
};

export default App;
