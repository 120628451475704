import * as React from "react";
import { ListItem } from "../../../../hooks/use-super-admin/use-web-menu-list";
import Button from "../../../globalComponents/Button";
import {
  Container,
  CheckBoxContainer,
  CheckBox,
  CheckMark,
  ContentContainer,
  ContentTitle,
  Title,
  ListContainer,
  ListTitle,
  ButtonContainer
} from "./styled";

type OnChange = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  title?: string;
  list?: ListItem[];
  handleOnChange: (event: OnChange) => void;
  updateMenuList?: () => void;
}

const MenuCheckBoxList = (props: IProps) => {
  const { title, list, handleOnChange, updateMenuList } = props;
  return (
    <Container>
      <Title>{title}</Title>
      {list &&
        list.map((item, index) => (
          <ListContainer key={index}>
            <ListTitle>{item.mainTitle}</ListTitle>
            {item.data.map((subItem, subindex) => (
              <ContentContainer key={subindex}>
                <CheckBoxContainer>
                  <CheckBox
                    type="checkbox"
                    title={subItem.title}
                    id={subItem.key}
                    name={subItem.key}
                    value={subItem.status}
                    checked={subItem.isCheck}
                    onChange={handleOnChange}
                  />
                  <CheckMark />
                </CheckBoxContainer>
                <ContentTitle>{subItem.title}</ContentTitle>
              </ContentContainer>
            ))}
          </ListContainer>
        ))}
      <ButtonContainer>
        <Button onClick={updateMenuList}>변경</Button>
      </ButtonContainer>
    </Container>
  );
};

export default MenuCheckBoxList;
