import styled from "styled-components";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import { FieldSort, SortColumType } from "../../__generated__/globalTypes";
import AsonicTableHeader from "./asonic-table-header";
import { Icon } from "@iconify/react";
import sortIcon from "@iconify/icons-fa-solid/sort";
import descIcon from "@iconify/icons-fa-solid/sort-amount-down";
import ascIcon from "@iconify/icons-fa-solid/sort-amount-up";
import { useDrag, useDrop } from "react-dnd";

export type TMoveColumn = ({
  dragItem,
  dropItem
}: {
  dragItem: TColumn<any>;
  dropItem: TColumn<any>;
}) => void;

interface IProps<P extends {}> {
  column: TColumn<P>;
  index: number;
  fieldSort?: FieldSort;
  handleFieldSort?: (payload?: FieldSort) => void;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  title: string;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
`;

function AsonicTableColumn<P extends object>({
  column,
  index,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  title
}: IProps<P>) {
  const DND_ITEM_TYPE = `${title} - COLUMN`;
  const [, drag] = useDrag(() => ({
    type: DND_ITEM_TYPE,
    item: { index, column }
  }));
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_ITEM_TYPE,
    drop(item: { index: number; column: TColumn<P> }) {
      const dragIndex = item.index;
      const dropIndex = index;
      if (dragIndex === dropIndex) {
        return;
      }
      if (moveColumn) {
        moveColumn({
          dragItem: item.column,
          dropItem: column
        });
      }
    },
    collect: monitor => ({
      isOver: !monitor.isOver()
    })
  }));

  return (
    <AsonicTableHeader
      {...column.getHeaderProps()}
      ref={moveColumn && drag}
      isOver={isOver}
      isLastFlex
    >
      <HeaderContainer ref={drop}>
        {column.render("Header")}
        {handleFieldSort && !listOfColumnDisabled?.includes(column.id) && (
          <IconContainer
            onClick={() => {
              let newFieldSort: FieldSort = {
                name: column.id,
                sort: SortColumType.DESC
              };
              if (!fieldSort) {
                handleFieldSort(newFieldSort);
              }
              if (fieldSort && column.id !== fieldSort?.name) {
                newFieldSort.name = column.id;
                handleFieldSort(newFieldSort);
              }
              if (
                column.id === fieldSort?.name &&
                fieldSort.sort === SortColumType.DESC
              ) {
                newFieldSort.sort = SortColumType.ASC;
                handleFieldSort(newFieldSort);
              }
              if (
                column.id === fieldSort?.name &&
                fieldSort.sort === SortColumType.ASC
              ) {
                handleFieldSort();
              }
            }}
          >
            {column.id !== fieldSort?.name && <Icon icon={sortIcon} />}
            {column.id === fieldSort?.name &&
              fieldSort.sort === SortColumType.DESC && <Icon icon={descIcon} />}
            {column.id === fieldSort?.name &&
              fieldSort.sort === SortColumType.ASC && <Icon icon={ascIcon} />}
          </IconContainer>
        )}
      </HeaderContainer>
    </AsonicTableHeader>
  );
}

export default AsonicTableColumn;
