import { useCallback, useEffect, useState } from "react";
import { TMoveColumn } from "../../components/asonic-table/asonic-table-column";
import { TColumn } from "../use-hide-columns/use-hide-columns";
import * as ReactTable from "react-table";

interface IProps<P extends object> {
  columns: ReactTable.ColumnInstance<P>[];
  setColumnOrder: (
    updater: ((columnOrder: string[]) => string[]) | string[]
  ) => void;
  title: string;
}

interface IDndObj {
  drag: {
    item: TColumn<any>;
  };
  drop: {
    item: TColumn<any>;
  };
}

function useDnd<P extends object>({
  columns,
  setColumnOrder,
  title
}: IProps<P>) {
  const [dndObj, setDndObj] = useState<IDndObj>();
  const [isDrop, setIsDrop] = useState<boolean>(false);

  const moveColumn: TMoveColumn = useCallback(({ dragItem, dropItem }) => {
    setDndObj({
      drag: {
        item: dragItem
      },
      drop: {
        item: dropItem
      }
    });
    setIsDrop(true);
  }, []);

  useEffect(() => {
    if (dndObj && isDrop) {
      let newColumns = columns.map(item => item.id);
      const dragItem = newColumns.find(item => {
        return item === dndObj.drag.item.id;
      }) as ReactTable.IdType<P>;
      const dragItemIndex = newColumns.findIndex(item => {
        return item === dndObj.drag.item.id;
      });
      const dropItem = newColumns.find(item => {
        return item === dndObj.drop.item.id;
      }) as ReactTable.IdType<P>;

      const dropItemIndex = newColumns.findIndex(item => {
        return item === dndObj.drop.item.id;
      });

      newColumns.splice(dragItemIndex, 1, dropItem);
      newColumns.splice(dropItemIndex, 1, dragItem);
      if (setColumnOrder) {
        setColumnOrder(newColumns);
        localStorage.setItem(title, JSON.stringify(newColumns));
      }
      setIsDrop(false);
    }
  }, [columns, dndObj, isDrop, setColumnOrder, title]);

  useEffect(() => {
    const localListOfColumnId = localStorage.getItem(title);
    if (localListOfColumnId) {
      setColumnOrder(JSON.parse(localListOfColumnId));
    }
  }, [setColumnOrder, title]);

  return { moveColumn };
}

export default useDnd;
