import * as React from "react";

type Menu = {
  calendar: boolean;
  serviceManagementInfo: boolean;
  commutingManagementInfo: boolean;
  placeFreeManagement: boolean;
  additionalInformationManagement: boolean;
  approvalNotification: boolean;
};

type Props = {
  webEtcFlag: number;
};

export enum WEB_ETC_FLAG_VALUES {
  CALENDAR = 1,
  SET_SMTP_FOR_EMAIL_NOTIFICATION = 2,
  SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION = 4,
  DESIGNATE_PROXY_APPROVER = 8,
  SERVICE_MANAGEMENT_INFO_VALUE = 16,
  COMMUTING_MANAGEMENT_INFO_VALUE = 32,
  PLACE_FREE_MANAGEMENT_VALUE = 64,
  ADDITIONAL_INFORMATION_MANAGEMENT = 128,
  APPROVAL_NOTIFICATION_VALUE = 1024,
  WEB_CHAT_ETC_FLAG = 8192
}

const useMenuByWebEtcFlag = <P extends Props>({ webEtcFlag }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [menu, setMenu] = React.useState<Menu>({
    calendar: false,
    serviceManagementInfo: false,
    commutingManagementInfo: false,
    placeFreeManagement: false,
    additionalInformationManagement: false,
    approvalNotification: false
  });

  const handleMenu = React.useCallback(() => {
    const calculateWebEtcFlag = (value: number) => {
      return webEtcFlag & value ? true : false;
    };
    const newMenu = {
      calendar: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.CALENDAR),
      setSMTPForEmailNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SET_SMTP_FOR_EMAIL_NOTIFICATION
      ),
      setCreatorListForEmailNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION
      ),
      designateProxyApprover: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.DESIGNATE_PROXY_APPROVER
      ),
      serviceManagementInfo: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SERVICE_MANAGEMENT_INFO_VALUE
      ),
      commutingManagementInfo: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.COMMUTING_MANAGEMENT_INFO_VALUE
      ),
      placeFreeManagement: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.PLACE_FREE_MANAGEMENT_VALUE
      ),
      additionalInformationManagement: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.ADDITIONAL_INFORMATION_MANAGEMENT
      ),
      approvalNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.APPROVAL_NOTIFICATION_VALUE
      )
    };

    if (isMount.current) {
      setMenu(newMenu);
    }
  }, [webEtcFlag]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleMenu();
    return () => {
      isMount.current = false;
    };
  }, [handleMenu]);

  return { menu };
};

export default useMenuByWebEtcFlag;
