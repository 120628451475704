import { useMemo } from "react";
import styled from "styled-components";
import { ApprovalEntity, ApprovalStatus } from "../../../generated/graphql";
import { FieldSort } from "../../../generated/graphql";
import AsonicTable from "../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { APPROVAL_STATUS } from "../approval-awaiting-summary-table";
import listOfApolloVar from "../../../apollo/apollo-var";
import { ApprovalType } from "../approval-detail-popup";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import { Icon } from "@iconify/react";
import ExternalLinkIcon from "@iconify-icons/eva/external-link-outline";
import routes from "../../../routes";
import { TColumn } from "../../../hooks/use-hide-columns/use-hide-columns";
import { SearchFieldValues } from "../../asonic-table/asonic-search";
import useDnd from "../../../hooks/use-dnd/use-dnd";
import { useReactiveVar } from "@apollo/client";
import NewAsonicTable from "../../new-asonic-table/new-asonic-table";

export const ENUM_COMMON_LIST_OF_APPROVAL = {
  docNumber: "문서번호",
  formIdx: "양식",
  approvalTitle: "제목",
  name: "기안자",
  extendStartDate: "결재일",
  approvalStatus: "상태"
} as const;

export type ENUM_COMMON_LIST_OF_APPROVAL =
  typeof ENUM_COMMON_LIST_OF_APPROVAL[keyof typeof ENUM_COMMON_LIST_OF_APPROVAL];

export type TYPE_COMMON_LIST_OF_APPROVAL =
  keyof typeof ENUM_COMMON_LIST_OF_APPROVAL;

interface IProps {
  title: string;
  isLoading?: boolean;
  handleShowApprovalPopup: (value: boolean) => void;
  handleSelectedRow: (row: ApprovalEntity) => void;
  list: ApprovalEntity[];
  currentPage: number;
  handleCurrentPage: (page: number) => void;
  take: number;
  handleTake: (value: string) => void;
  handleSearch: (data: SearchFieldValues) => void;
  total?: number;
  handleSelectedRowIndex: (index: number) => void;
  fieldSort?: FieldSort;
  handleFieldSort: (payload?: FieldSort | undefined) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
`;

export const ItemForTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  span {
    cursor: pointer;
    :hover {
      color: ${props => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.98);
    }
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  svg {
    font-size: 20px;
  }
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.95);
  }
`;

function ApprovalListForm({
  list,
  title,
  isLoading,
  handleShowApprovalPopup,
  handleSelectedRow,
  currentPage,
  handleCurrentPage,
  take,
  handleTake,
  handleSearch,
  handleSelectedRowIndex,
  fieldSort,
  handleFieldSort,
  total = 0
}: IProps) {
  const FormName = useReactiveVar(listOfApolloVar.approvalFormNameVar);

  const listOfSearchType = useMemo(() => {
    return [
      {
        value: "DOCUMENT_NUMBER",
        name: "문서번호"
      },
      {
        value: "APPROVAL_FORM",
        name: "양식"
      },
      {
        value: "DRAFTER",
        name: "기안자"
      },
      {
        value: "TITLE",
        name: "제목"
      }
    ];
  }, []);

  const columns: ReactTable.Column<ApprovalEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(ENUM_COMMON_LIST_OF_APPROVAL);
    return listOfColumn.map(item => {
      let width = 120;
      if (
        ENUM_COMMON_LIST_OF_APPROVAL[item as TYPE_COMMON_LIST_OF_APPROVAL] ===
        ENUM_COMMON_LIST_OF_APPROVAL.docNumber
      ) {
        width = 150;
      } else if (
        ENUM_COMMON_LIST_OF_APPROVAL[item as TYPE_COMMON_LIST_OF_APPROVAL] ===
        ENUM_COMMON_LIST_OF_APPROVAL.approvalTitle
      ) {
        width = 300;
      }
      return {
        Header:
          ENUM_COMMON_LIST_OF_APPROVAL[item as TYPE_COMMON_LIST_OF_APPROVAL],
        accessor: item as TYPE_COMMON_LIST_OF_APPROVAL,
        width,
        Cell(cell: Cell<ApprovalEntity>) {
          if (
            ENUM_COMMON_LIST_OF_APPROVAL[
              item as TYPE_COMMON_LIST_OF_APPROVAL
            ] === ENUM_COMMON_LIST_OF_APPROVAL.approvalStatus
          ) {
            let value = APPROVAL_STATUS.APPROVAL;
            switch (cell.value) {
              case ApprovalStatus.Outstanding:
                value = APPROVAL_STATUS.OUTSTANDING;
                break;
              case ApprovalStatus.InTheMiddleOfReturn:
                value = APPROVAL_STATUS.IN_THE_MIDDLE_OF_RETURN;
                break;
              case ApprovalStatus.Return:
                value = APPROVAL_STATUS.RETURN;
                break;
              case ApprovalStatus.Waiting:
                value = APPROVAL_STATUS.WAITING;
                break;
            }
            return <Item>{`${value}`}</Item>;
          }
          if (
            ENUM_COMMON_LIST_OF_APPROVAL[
              item as TYPE_COMMON_LIST_OF_APPROVAL
            ] === ENUM_COMMON_LIST_OF_APPROVAL.approvalTitle
          ) {
            return (
              <ItemForTitle>
                <span
                  onClick={() => {
                    if (title === "나의 결재") {
                      listOfApolloVar.approvalTypeVar(ApprovalType.MY_APPROVAL);
                    } else if (title === "결재 완료함") {
                      listOfApolloVar.approvalTypeVar(ApprovalType.COMPLETE);
                    } else if (title === "수신" || title === "참조") {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_RECEIVE_REFERENCE
                      );
                    } else {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_WAITING_IN_LIST
                      );
                    }
                    handleSelectedRowIndex(cell?.row?.index);
                    listOfApolloVar.approvalDataVar(cell?.row?.original);
                    handleShowApprovalPopup(true);
                  }}
                >
                  {cell.value}
                </span>
                <IconContainer
                  onClick={() => {
                    if (
                      cell.row.original.approvalStatus ===
                      ApprovalStatus.Outstanding
                    ) {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_WAITING_POPUP
                      );
                    } else if (title === "나의 결재") {
                      listOfApolloVar.approvalTypeVar(ApprovalType.MY_APPROVAL);
                    } else if (title === "결재 완료함") {
                      listOfApolloVar.approvalTypeVar(ApprovalType.COMPLETE);
                    } else if (title === "수신" || title === "참조") {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_RECEIVE_REFERENCE
                      );
                    } else {
                      listOfApolloVar.approvalTypeVar(
                        ApprovalType.APPROVAL_WAITING_IN_LIST
                      );
                    }
                    listOfApolloVar.approvalDataVar(cell?.row?.original);
                    handleSelectedRow(cell?.row?.original);
                    window.open(
                      routes.pageRoutes.approvalPopup,
                      "",
                      "width=800, height=880, scrollbars=yes"
                    );
                  }}
                >
                  <Icon icon={ExternalLinkIcon} />
                </IconContainer>
              </ItemForTitle>
            );
          }
          if (
            ENUM_COMMON_LIST_OF_APPROVAL[
              item as TYPE_COMMON_LIST_OF_APPROVAL
            ] === ENUM_COMMON_LIST_OF_APPROVAL.formIdx
          ) {
            return <Item>{`${FormName.get(cell.value)}`}</Item>;
          }
          return <Item>{`${cell.value}`}</Item>;
        }
      };
    });
  }, [handleShowApprovalPopup, FormName]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    toggleHideColumn,
    columns: approvalColumns,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<ApprovalEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } = useDnd<ApprovalEntity>({
    columns: visibleColumns,
    setColumnOrder,
    title: `${title}-for-ordering-column`
  });

  return (
    <Container>
      <NewAsonicTable<ApprovalEntity>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        // downloadExcel={downloadExcel}
        // handleSelectRow={handleSelectRow}
        isLoading={isLoading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        // selectedRow={selectedRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={approvalColumns as TColumn<ApprovalEntity>[]}
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
        flexIndex={2}
      />
    </Container>
  );
}

export default ApprovalListForm;
