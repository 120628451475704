import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePageControl from "../../hooks/use-page-control/use-page-control";
import useSortBy from "../../hooks/use-sort-by/use-sort-by";
import {
  GetListOfReservationInformation,
  GetListOfReservationInformationVariables,
  GetListOfReservationInformation_getListOfReservationInformation_list
} from "../../__generated__/GetListOfReservationInformation";
import {
  GetTotalListOfReservationType,
  GetTotalListOfReservationTypeVariables
} from "../../__generated__/GetTotalListOfReservationType";
import { SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE } from "../../__generated__/globalTypes";
import { IAsonicRow } from "../asonic-table/asonic-render-row";
import { SearchFieldValues } from "../asonic-table/asonic-search";
import AsonicTable from "../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { TColumn } from "../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../hooks/use-dnd/use-dnd";
import {
  DownloadListOfReservationInformation,
  DownloadListOfReservationInformationVariables
} from "../../__generated__/DownloadListOfReservationInformation";
import { downloadFileFromServer } from "../main-view/statistics/Utils";
import moment from "moment";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import { useSelector } from "react-redux";
import { Reducers } from "../../../types/reducers";
import EditIcon from "@iconify/icons-clarity/note-edit-line";
import { Icon } from "@iconify/react";
import AsonicDialog from "../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../hooks/use-open-dialog";
import ReservationInformationController from "./reservation-information-controller";

const title = "예약종류";

interface IProps {
  handleSelectedReservationInfo: (
    row:
      | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
      | undefined
  ) => void;
}

enum COLUMN_FOR_VALUE {
  groupName = "예약 종류",
  categoryName = "예약 상세 항목",
  categoryDescription = "상세설명"
}

export type TYPE_FOR_VEHICLE_INFORMATION_TABLE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 0px;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => props.theme.colors.veryDarkBlue};
  :active {
    transform: scale(0.95);
  }
  :hover {
    background-color: ${props => props.theme.colors.skyBlue};
  }
  svg {
    color: ${props => props.theme.colors.white};
    font-size: 20px;
  }
`;

const QUERY_GET_LIST_OF_RESERVATION_INFORMATION = gql`
  query GetListOfReservationInformation(
    $page: Float
    $take: Float
    $fieldSort: FieldSort
    $searchType: SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE
    $searchValue: String
  ) {
    getListOfReservationInformation(
      page: $page
      take: $take
      fieldSort: $fieldSort
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      list {
        groupIdx
        groupName
        parentGroupIdx
        categoryIdx
        categoryName
        categoryDescription
      }
    }
  }
`;

const QUERY_GET_TOTAL_LIST_OF_RESERVATION_TYPE = gql`
  query GetTotalListOfReservationType(
    $searchType: SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE
    $searchValue: String
  ) {
    getTotalListOfReservationType(
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      total
    }
  }
`;

const QUERY_DOWNLOAD_LIST_OF_RESERVATION_INFORMATION = gql`
  query DownloadListOfReservationInformation(
    $searchType: SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE
    $searchValue: String
  ) {
    downloadListOfReservationInformation(
      searchType: $searchType
      searchValue: $searchValue
    ) {
      ok
      error
      excel
    }
  }
`;

const IS_RESERVATION_CONTROL_FLAG = 1048576;

function ReservationInformation({ handleSelectedReservationInfo }: IProps) {
  const { signInReducer } = useSelector((state: Reducers) => state);
  const isActive: boolean = useMemo(() => {
    return Boolean(
      signInReducer.checkWebAuthFn?.working_auth_web &
        IS_RESERVATION_CONTROL_FLAG
    );
  }, [signInReducer.checkWebAuthFn?.working_auth_web]);

  const { fieldSort, handleFieldSort } = useSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const [searchType, setSearchType] =
    useState<SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE>(
      SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE.groupName
    );

  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();

  const [searchValue, setSearchValue] = useState("");

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleToast,
    toastMessageType
  } = useOpenToastMessage();

  const [downloadListOfReservationInformation] = useLazyQuery<
    DownloadListOfReservationInformation,
    DownloadListOfReservationInformationVariables
  >(QUERY_DOWNLOAD_LIST_OF_RESERVATION_INFORMATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (
        data.downloadListOfReservationInformation.ok &&
        data.downloadListOfReservationInformation.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfReservationInformation.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )}-list-of-reservation-information.csv`
        );
      }
      if (
        !data.downloadListOfReservationInformation.ok &&
        data.downloadListOfReservationInformation.error
      ) {
        handleToast(
          data.downloadListOfReservationInformation.error,
          MessageTypes.ERROR
        );
        handleIsToastMessageOpen(true);
      }
    }
  });

  const [getListOfReservationInformation, { data, loading }] = useLazyQuery<
    GetListOfReservationInformation,
    GetListOfReservationInformationVariables
  >(QUERY_GET_LIST_OF_RESERVATION_INFORMATION, {
    onError(error) {
      handleToast("예약정보를 가져오지 못했습니다.", MessageTypes.ERROR);
    },
    onCompleted(data) {
      if (
        !data.getListOfReservationInformation.ok &&
        data.getListOfReservationInformation.error
      ) {
        handleToast(
          data.getListOfReservationInformation.error,
          MessageTypes.ERROR
        );
      }
    }
  });

  const [
    getTotalListOfReservationType,
    { data: totalData, loading: totalLoading }
  ] = useLazyQuery<
    GetTotalListOfReservationType,
    GetTotalListOfReservationTypeVariables
  >(QUERY_GET_TOTAL_LIST_OF_RESERVATION_TYPE);

  const downloadExcel = useCallback(() => {
    downloadListOfReservationInformation({
      variables: {
        searchType,
        searchValue
      }
    });
  }, [downloadListOfReservationInformation, searchType, searchValue]);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (data.type) {
        const INIT_PAGE = 1;

        setSearchType(
          data.type as SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE
        );
        handleCurrentPage(INIT_PAGE);
        setSearchValue(data.value as string);
        getTotalListOfReservationType({
          variables: {
            searchType:
              data.type as SEARCH_TYPE_FOR_RESERVATION_INFORMATION_TYPE,
            searchValue: data.value
          }
        });
      }
    },
    [getTotalListOfReservationType, handleCurrentPage]
  );

  const total = useMemo(() => {
    if (
      totalData?.getTotalListOfReservationType.ok &&
      totalData?.getTotalListOfReservationType.total
    ) {
      return totalData?.getTotalListOfReservationType.total;
    }
    return 0;
  }, [totalData]);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfReservationInformation_getListOfReservationInformation_list>
    ) => {},
    []
  );

  const listOfSearchType = useMemo(() => {
    return Object.keys(COLUMN_FOR_VALUE).map(item => ({
      value: item,
      name: COLUMN_FOR_VALUE[item as keyof typeof COLUMN_FOR_VALUE]
    }));
  }, []);

  const columns: ReactTable.Column<GetListOfReservationInformation_getListOfReservationInformation_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map(item => {
        let width = 120;
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_FOR_VEHICLE_INFORMATION_TABLE],
          accessor: item as TYPE_FOR_VEHICLE_INFORMATION_TABLE,
          width
        };
      });
    }, []);

  const list: GetListOfReservationInformation_getListOfReservationInformation_list[] =
    useMemo(() => {
      return data?.getListOfReservationInformation.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleHideColumn,
    columns: reservationInformationColumn,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<GetListOfReservationInformation_getListOfReservationInformation_list>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow:
    | ReactTable.Row<GetListOfReservationInformation_getListOfReservationInformation_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      handleSelectedReservationInfo(
        selectedFlatRows[selectedFlatRows.length - 1]
      );
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    handleSelectedReservationInfo(undefined);
    return;
  }, [selectedFlatRows, handleSelectedReservationInfo]);

  const { moveColumn } =
    useDnd<GetListOfReservationInformation_getListOfReservationInformation_list>(
      {
        columns: visibleColumns,
        setColumnOrder,
        title: `${title}-for-ordering-column`
      }
    );

  useEffect(() => {
    getTotalListOfReservationType({
      variables: {
        searchType,
        searchValue
      }
    });
  }, [getTotalListOfReservationType, searchValue, searchType]);

  useEffect(() => {
    getListOfReservationInformation({
      variables: {
        page: currentPage,
        take: take,
        searchType: searchType,
        searchValue: searchValue,
        fieldSort
      }
    });
  }, [
    getListOfReservationInformation,
    currentPage,
    take,
    searchType,
    searchValue,
    fieldSort
  ]);
  return (
    <Container>
      <AsonicTable<GetListOfReservationInformation_getListOfReservationInformation_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={handleSelectRow}
        isLoading={loading || totalLoading}
        listOfSearchType={listOfSearchType}
        handleSearch={handleSearch}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        handleFieldSort={handleFieldSort}
        fieldSort={fieldSort}
        columns={
          reservationInformationColumn as TColumn<GetListOfReservationInformation_getListOfReservationInformation_list>[]
        }
        toggleHideColumn={toggleHideColumn}
        moveColumn={moveColumn}
      />
      {isActive && (
        <IconContainer
          onClick={() => {
            handleOpenDialog(true);
          }}
        >
          <Icon icon={EditIcon} />
        </IconContainer>
      )}
      {isOpenDialog && (
        <AsonicDialog
          title="예약종류설정"
          handleClose={handleOpenDialog}
          height="450px"
          minHeight="450px;"
          width="914px"
          minWidth="914px"
        >
          <ReservationInformationController />
        </AsonicDialog>
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ReservationInformation;
