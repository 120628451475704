import { useCallback } from "react";
import { useState } from "react";

function useEvent() {
  const [isEvent, setIsEvent] = useState<boolean>(false);
  const handleEvent = useCallback((value: boolean) => {
    setIsEvent(value);
  }, []);
  return { isEvent, handleEvent };
}

export default useEvent;
