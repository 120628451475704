import React from "react";
import { LoadingContainer, LoadingChild } from "./styled";

const Loader = () => {
  return (
    <LoadingContainer>
      <LoadingChild />
      <LoadingChild />
      <LoadingChild />
      <LoadingChild />
    </LoadingContainer>
  );
};

export default Loader;
