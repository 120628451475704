import PropTypes from "prop-types";
import { ContentsContainer } from "./styled";
import TreeContainer from "../../../containers/main-view/TreeContainer";
import DetailViewContainer from "../../../containers/main-view/WorkManagementInfoContainer/DetailViewContainer";
import Store from "./Store";
import UserInfoSection from "./user-info-section/user-info-section";
import styled from "styled-components";
import { useEffect } from "react";
import { client } from "../../../apollo/apollo";
import listOfApolloVar from "../../../apollo/apollo-var";
import { useOutletContext } from "react-router-dom";

const Container = styled.div`
  height: 100%;
  width: 100vw;
  overflow: hidden;
  display: flex;
  cursor: ${props => {
    if (props.isTreeWindowClick) {
      return "col-resize";
    } else if (props.isUserInfoWindowClicked) {
      return "row-resize";
    }
  }};
`;

const WorkManagementInfo = props => {
  const {
    isTreeWindowClick,
    windowMouseMove,
    userInfoHeight,
    isUserInfoWindowClicked,
    signInReducer: { loginIdInfo, department_id, employee_id }
  } = props;
  const { drawerOpen: isMenuOpen } = useOutletContext();
  useEffect(() => {
    return () => {
      client.clearStore();
      listOfApolloVar.selectedDepartmentVar(undefined);
    };
  }, []);

  useEffect(() => {
    // 사용자 권한이 -1일 경우 기본적으로 로그인한 사용자의 정보로 셋팅하기
    if (loginIdInfo === -1 && department_id && employee_id) {
      listOfApolloVar.selectedDepartmentVar(department_id.toString());
      listOfApolloVar.selectedListOfEmployeeIdVar([employee_id]);
    }
  }, [loginIdInfo, department_id, employee_id]);

  return (
    <Store.Provider value={props}>
      <Container
        onMouseMove={windowMouseMove}
        isMenuOpen={isMenuOpen}
        isTreeWindowClick={isTreeWindowClick}
        userInfoHeight={userInfoHeight}
        isUserInfoWindowClicked={isUserInfoWindowClicked}
      >
        {loginIdInfo !== -1 && <TreeContainer {...props} />}
        <ContentsContainer>
          {loginIdInfo !== -1 && (
            <UserInfoSection
              userInfoHeight={userInfoHeight}
              userWindowMouseDown={props.userWindowMouseDown}
            />
          )}
          <DetailViewContainer {...props} />
        </ContentsContainer>
      </Container>
    </Store.Provider>
  );
};

WorkManagementInfo.propTypes = {
  treeWidth: PropTypes.string,
  windowMouseMove: PropTypes.func.isRequired,
  isTreeWindowClick: PropTypes.bool.isRequired,
  userInfoHeight: PropTypes.string,
  isUserInfoWindowClicked: PropTypes.bool.isRequired
};

export default WorkManagementInfo;
