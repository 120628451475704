import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
};

/** 연차 설정 정보 타입 */
export enum Annual_Accrual_Type {
  BasedOnFiscalYear = 'BASED_ON_FISCAL_YEAR',
  BasedOnTheDateOfJoining = 'BASED_ON_THE_DATE_OF_JOINING'
}

/** 연차발생방식설정 */
export enum Annual_Accrual_Type_For_Less_Than_A_Year {
  FixDate = 'FIX_DATE',
  Monthly = 'MONTHLY'
}

/** 연차발생방식설정 타입 */
export enum Annual_Accrual_Type_For_Next_Year_Of_Employment {
  ApplyAdminIndividual = 'APPLY_ADMIN_INDIVIDUAL',
  ApplyTheSameBasicDay = 'APPLY_THE_SAME_BASIC_DAY',
  GiveFixedDate = 'GIVE_FIXED_DATE',
  Monthly = 'MONTHLY'
}

/** 연차확정설정 */
export enum Annual_Fix_Type {
  AutoConfirmNextYear = 'AUTO_CONFIRM_NEXT_YEAR',
  FullAutoConfirm = 'FULL_AUTO_CONFIRM',
  IndividualConfirmByManager = 'INDIVIDUAL_CONFIRM_BY_MANAGER',
  MoreThanAnYearConfirm = 'MORE_THAN_AN_YEAR_CONFIRM'
}

/** 대시보드 결재목록 미리보기에 대한 설정 타입 */
export enum Approval_Admin_Setting_Preview_Type {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 결재 상태별 목록에 대한 타입 */
export enum Approval_For_List_Type {
  Complete = 'COMPLETE',
  My = 'MY',
  Receive = 'RECEIVE',
  Reference = 'REFERENCE',
  Waiting = 'WAITING'
}

/** 결재 종류 */
export enum Approval_Sort {
  ApprovalLine = 'APPROVAL_LINE',
  AutoApproval = 'AUTO_APPROVAL'
}

/** 결재 결과 타입 */
export enum Approval_Status {
  Approval = 'APPROVAL',
  InTheMiddleOfReturn = 'IN_THE_MIDDLE_OF_RETURN',
  Outstanding = 'OUTSTANDING',
  Return = 'RETURN',
  Waiting = 'WAITING'
}

/** 사용자 결재 타입(기안자, 전결, 합의, 결재) */
export enum Approval_User_Type {
  Agreement = 'AGREEMENT',
  Approval = 'APPROVAL',
  DecideArbitrarily = 'DECIDE_ARBITRARILY',
  Drafter = 'DRAFTER'
}

/** 알람 추가 요청 결과 */
export type AddAlarmEventOutput = {
  __typename?: 'AddAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 알람 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 결재선 추가 요청 결과 */
export type AddApprovalLineOutput = {
  __typename?: 'AddApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재선 idx */
  lineIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 추가 요청 결과 */
export type AddApprovalNotificationTemplateOutput = {
  __typename?: 'AddApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재정보 알림 템플릿 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 채팅 방 추가하기 요청 결과 */
export type AddChatRoomOutput = {
  __typename?: 'AddChatRoomOutput';
  /** 채팅방 Idx */
  chatRoomIdx?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  /** 채팅방 이름 */
  nameOfRoom?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 기록 추가 요청 결과 */
export type AddCommuteDateTimeOutput = {
  __typename?: 'AddCommuteDateTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 추가 요청 결과 */
export type AddCommuteGpsInfoOutput = {
  __typename?: 'AddCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 사용자 출퇴근 지역 정보 추가 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 정책 적용된 직원 추가 요청 결과 */
export type AddDepartmentAppliedToTheTemplateOutput = {
  __typename?: 'AddDepartmentAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책에 적용할 직원 추가 요청 결과 */
export type AddEmployeeAppliedToTheTemplateOutput = {
  __typename?: 'AddEmployeeAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 추가 요청 결과 */
export type AddListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'AddListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재정보 알림 계획 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 예약목록 추가 요청 결과 */
export type AddReservationCategoryOutput = {
  __typename?: 'AddReservationCategoryOutput';
  /** 예약목록 IDX */
  categoryIdx: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 등록을 위한 요청 */
export type AddReservationOutput = {
  __typename?: 'AddReservationOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 예약 Idx */
  infoIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 추가 요청 */
export type AddReservationTypeOutput = {
  __typename?: 'AddReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 예약그룹 idx */
  groupIdx?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 정보 요청 결과 */
export type AddTemplateScheduleInfoOutput = {
  __typename?: 'AddTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 근무계획 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 추가 요청 결과 */
export type AddTimeTemplateOutput = {
  __typename?: 'AddTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 템플릿 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 대화 상대 초대 요청 결과 */
export type AddUserFromChatRoomOutput = {
  __typename?: 'AddUserFromChatRoomOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 추가를 위한 요청 결과 */
export type AddVehicleDrivingInformationOutput = {
  __typename?: 'AddVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** logIdx */
  logIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

export type AddVehicleInformationOutput = {
  __typename?: 'AddVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 설정 정보 */
export type AlarmSettingEntity = {
  __typename?: 'AlarmSettingEntity';
  /** 알람 설명 */
  description?: Maybe<Scalars['String']>;
  /** 알람 종류 */
  eventType?: Maybe<Scalars['Int']>;
  /** 링크 URL */
  linkUrl?: Maybe<Scalars['String']>;
  /** 알람 시간 */
  pushMin?: Maybe<Scalars['Int']>;
  /** 알람 설정 index */
  settingIndex: Scalars['Int'];
  /** 적용 장치 */
  targetDevice?: Maybe<Scalars['Int']>;
  /** 알람 이름 */
  title?: Maybe<Scalars['String']>;
};

/** 알람 ON/OFF 상태값 */
export enum AlarmStatus {
  Off = 'OFF',
  On = 'ON'
}

/** 연차 설정 정보 Entity */
export type AnnualEnvInfoEntity = {
  __typename?: 'AnnualEnvInfoEntity';
  /** 회계기준일 */
  accountingMonth: Scalars['Int'];
  /** 연차 발생기준 설정 값 */
  annualAccrualType: Annual_Accrual_Type;
  /** 연차차감우선순위 */
  annualDeductionPriorityType: Scalars['Int'];
  /** 연차 확정 설정 */
  annualFixType: Annual_Fix_Type;
  /** 최대연차제한 */
  annualMaxCount: Scalars['Int'];
};

/** 변경된 연차 이력 */
export type AnnualHistoryEntity = {
  __typename?: 'AnnualHistoryEntity';
  /** 가산일수 */
  addDay: Scalars['Int'];
  /** 귀속년도 */
  applyYear: Scalars['String'];
  /** 기본일수 */
  basicDay: Scalars['Int'];
  /** 기본조정 */
  basicModifyDay: Scalars['Int'];
  /** 생성자 */
  createSubject: Scalars['String'];
  /** 사용자 사번 */
  employeeId: Scalars['String'];
  /** 이월연차 */
  leaveCarriedForwardDay: Scalars['Int'];
  /** logIdx */
  logIdx: Scalars['Int'];
  /** 변경 날짜 및 시간 */
  modifyDatetime: Scalars['String'];
  /** 변경사유 */
  modifyReason?: Maybe<Scalars['String']>;
  /** 연차 종료일 */
  periodateEnd: Scalars['String'];
  /** 연차 시작일 */
  periodateStart: Scalars['String'];
  /** 상태 */
  statusFlag: Scalars['String'];
  /** 사용조정 */
  useModifyDay: Scalars['Int'];
};

/** 결재 상신 요청 결과 */
export type ApplicationApprovalOutput = {
  __typename?: 'ApplicationApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 양식 상세 정보 */
export type ApprovalDetailInformationEntity = {
  __typename?: 'ApprovalDetailInformationEntity';
  /** 결재 idx */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 정보 구분 이름 */
  categoryName: Scalars['String'];
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 문서 번호 */
  docNumber?: Maybe<Scalars['String']>;
  /** 기안자 ID */
  employeeIdRequest: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
  /** 상세 설명 */
  extendDescription?: Maybe<Scalars['String']>;
  /** 연장 종료 날짜 */
  extendEDate?: Maybe<Scalars['String']>;
  /** 연장 종료 시간 */
  extendEDateTime?: Maybe<Scalars['String']>;
  /** 연장 시작 날짜 */
  extendSDate?: Maybe<Scalars['String']>;
  /** 연장 시작 시간 */
  extendSDateTime?: Maybe<Scalars['String']>;
  /** 제 목 */
  formName?: Maybe<Scalars['String']>;
  /** 결재양식 관리 이름 */
  formTemplateTitle: Scalars['String'];
  /** 작성일 */
  signUpDateTime?: Maybe<Scalars['String']>;
  /** 보존년한 */
  storePeriod?: Maybe<Scalars['String']>;
  /** 임시 저장 상세 설명 */
  temporaryExtendDescription?: Maybe<Scalars['String']>;
  /** 사용자 직책 */
  userPosition?: Maybe<Scalars['String']>;
};

/** 결재 데이터 양식 */
export type ApprovalEntity = {
  __typename?: 'ApprovalEntity';
  /** 결재 ID */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 상태 */
  approvalStatus: ApprovalStatus;
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 결재 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 문서 번호 */
  docNumber?: Maybe<Scalars['String']>;
  /** 연장 종료 날짜 */
  extendEndDate: Scalars['String'];
  /** 연장 종료 날짜 시간 */
  extendEndDateTime: Scalars['String'];
  /** 연장 시작 날짜 */
  extendStartDate: Scalars['String'];
  /** 연장 시작 날짜 시간 */
  extendStartDateTime: Scalars['String'];
  /** 근무 결재신청양식 IDX */
  formIdx?: Maybe<Scalars['Int']>;
  /** 결재선 템플릿 idx */
  formTemplateIdx: Scalars['Int'];
  /** 기안자 */
  name: Scalars['String'];
  /** 입사일 */
  signUpDateTime?: Maybe<Scalars['String']>;
};

/** 출퇴근 인정 여부 enum 타입 */
export enum ApprovalFlag {
  CorrectedVersion = 'CORRECTED_VERSION',
  NoRecognition = 'NO_RECOGNITION',
  OriginRecognition = 'ORIGIN_RECOGNITION'
}

/** 결재 이력 정보 */
export type ApprovalHistoryEntity = {
  __typename?: 'ApprovalHistoryEntity';
  /** 결재 결과 */
  approvalResult?: Maybe<Approval_Status>;
  /** 결재 시간 */
  approvalTime?: Maybe<Scalars['String']>;
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 결재 승인 및 반려 사유 */
  descriptionComment?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 결재선 순서 */
  numRank: Scalars['Int'];
  /** 사용자 직책 */
  userPosition?: Maybe<Scalars['String']>;
  /** 열람 시간 */
  viewTime?: Maybe<Scalars['String']>;
};

/** 결재 이력 정보 요청 결과 */
export type ApprovalHistoryOutput = {
  __typename?: 'ApprovalHistoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 이력 정보 목록 */
  list?: Maybe<Array<ApprovalHistoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 추가, 수정, 삭제를 위한 타입 */
export enum ApprovalLineControlType {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

/** 결재선 정보 */
export type ApprovalLineEntity = {
  __typename?: 'ApprovalLineEntity';
  /** 기본결재 */
  basicLine: Is_Default_Approval_Line;
  /** 결재선 idx */
  lineIdx: Scalars['Int'];
  /** 근무 시간 타입 이름 */
  newTitle: Scalars['String'];
};

/** 결재선 양식 정보 */
export type ApprovalLineFormEntity = {
  __typename?: 'ApprovalLineFormEntity';
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 결재선 양식 설명 */
  descr?: Maybe<Scalars['String']>;
  /** 결재선 양식 idx */
  formIdx: Scalars['Int'];
  /** 양식 이름 */
  formName: Scalars['String'];
  /** 결재선 템플릿 idx */
  formTemplateIdx: Scalars['Int'];
  /** 이름 */
  name: Scalars['String'];
  /** 결재선 순서 */
  numRank: Scalars['Int'];
  /** 결재선 타이틀 */
  templateTitle: Scalars['String'];
};

/** 결재선 사용자 정보 */
export type ApprovalLineUserEntity = {
  /** 사용자 id */
  employeeid: Scalars['String'];
  /** 사용자 결재선 순서 */
  order: Scalars['Int'];
  /** 사용자 결재선 타입 */
  type: Approval_User_Type;
};

/** 정책적용된 부서 정보 */
export type ApprovalNotificationDepartment = {
  __typename?: 'ApprovalNotificationDepartment';
  /** 알람 이름 */
  alarmName: Scalars['String'];
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 템플릿 Idx */
  templateIdx: Scalars['Int'];
};

/** 결재정보 알림 계획 */
export type ApprovalNotificationScheduleByTemplate = {
  __typename?: 'ApprovalNotificationScheduleByTemplate';
  /** 결재 시작/종료 시간 기준 선택 */
  approvalTimeType?: Maybe<ApprovalTimeType>;
  /** 구분 ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** 알림 날짜 */
  dayCount?: Maybe<Scalars['Int']>;
  /** 알림 날짜 시작/종료 구분 */
  dayType?: Maybe<ApprovalTimeType>;
  /** 알림 계획 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** APP 알림 사용유무 */
  isApp?: Maybe<IsUsingNotification>;
  /** Mail 알림 사용유무 */
  isMail?: Maybe<IsUsingNotification>;
  /** PC 알림 사용유무 */
  isPc?: Maybe<IsUsingNotification>;
  /** 알림 시간 */
  minCount?: Maybe<Scalars['Int']>;
  /** 알림 시간 시작/종료 구분 */
  minType?: Maybe<ApprovalTimeType>;
  /** 결재정보 알림 계획 식별정보 */
  scheduleIdx: Scalars['Int'];
  /** 결재정보 알림 계획의 템플릿 식별정보 */
  templateIdx: Scalars['Int'];
};

/** 결재정보 알림 템플릿 정보 */
export type ApprovalNotificationTemplate = {
  __typename?: 'ApprovalNotificationTemplate';
  /** 결재정보 알림 이름 */
  alarmName: Scalars['String'];
  /** 결재정보 알림 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 결재정보 알림 템플릿 index */
  templateIdx: Scalars['Int'];
};

/** 결재 비밀번호 및 서명 설정 정보 */
export type ApprovalPasswordAndSignSettingInfoEntity = {
  __typename?: 'ApprovalPasswordAndSignSettingInfoEntity';
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 결재 이메일 알림 사용여부 */
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  /** 결재 모바일 알림 사용여부 */
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  /** 결재 비밀번호 사용여부 */
  isSignPassword: Is_Sign_Password;
  /** 사용자 서명 이미지 */
  signImage?: Maybe<Scalars['String']>;
  /** 결재 비밀번호 */
  signPassword: Scalars['String'];
  /** 사용자 서명 텍스트 */
  signText?: Maybe<Scalars['String']>;
  /** 서명 타입 */
  signType: SignType;
  /** 서명 image */
  sign_image?: Maybe<Scalars['String']>;
};

/** 결재 검색 타입 */
export enum ApprovalSearchType {
  All = 'ALL',
  ApprovalForm = 'APPROVAL_FORM',
  DocumentNumber = 'DOCUMENT_NUMBER',
  Drafter = 'DRAFTER',
  Status = 'STATUS',
  Title = 'TITLE'
}

/** 결재 상태 타입 */
export enum ApprovalStatus {
  Approval = 'APPROVAL',
  InTheMiddleOfReturn = 'IN_THE_MIDDLE_OF_RETURN',
  Outstanding = 'OUTSTANDING',
  Return = 'RETURN',
  Waiting = 'WAITING'
}

/** 결재 양식 */
export type ApprovalTemplateEntity = {
  __typename?: 'ApprovalTemplateEntity';
  /** 결재 종류 */
  approvalType: Approval_Sort;
  /** 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 결재 양식 idx */
  formIdx: Scalars['Int'];
  /** 결재 양식 이름 */
  formName: Scalars['String'];
  /** 결재 템플릿 idx */
  formTemplateIdx: Scalars['Float'];
  /** 근무 타입 이름 */
  name: Scalars['String'];
  /** 양식 랭크 */
  numRank: Scalars['Float'];
  /** 결재 템플릿 이름 */
  templateTitle: Scalars['String'];
};

/** 결재 시작/종료 시간 구분 */
export enum ApprovalTimeType {
  End = 'END',
  Start = 'START'
}

/** 사용자 정보 */
export type BasicUserInfoEntity = {
  __typename?: 'BasicUserInfoEntity';
  /** 부서 id */
  departmentId: Scalars['Int'];
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 입사 일자 */
  joiningDate?: Maybe<Scalars['String']>;
  /** 퇴사 일자 */
  leavingDate?: Maybe<Scalars['String']>;
  /** 사용자 사진 */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 사진 */
  my_picture?: Maybe<Array<Scalars['String']>>;
  /** 서명(한자) */
  nameChinese?: Maybe<Scalars['String']>;
  /** 서명(영문) */
  nameEnglish?: Maybe<Scalars['String']>;
  /** 전화번호 */
  phoneNum?: Maybe<Scalars['String']>;
  /** 이메일 */
  userEmail?: Maybe<Scalars['String']>;
  /** 직책 */
  userJob?: Maybe<Scalars['String']>;
  /** 직위 */
  userPosition?: Maybe<Scalars['String']>;
  /** 직급(호봉) */
  userRank?: Maybe<Scalars['String']>;
};

/** 이월연자적용범위 타입 */
export enum Carriedforward_Range_Type {
  MinusAndPlusAnnualLeave = 'MINUS_AND_PLUS_ANNUAL_LEAVE',
  MinusAnnualLeaveOnly = 'MINUS_ANNUAL_LEAVE_ONLY',
  PlusAnnualLeaveOnly = 'PLUS_ANNUAL_LEAVE_ONLY'
}

/** 이월 연차 적용 설정 타입 */
export enum Carriedforward_Type {
  Apply = 'APPLY',
  NotApplied = 'NOT_APPLIED'
}

/** 채팅 메시지 타입 */
export type ChatMessageEntity = {
  __typename?: 'ChatMessageEntity';
  /** chat room attendee user ID */
  chatroom_attendee_employeeid: Scalars['String'];
  /** chat room Idx */
  chatroom_idx: Scalars['Int'];
  /** message */
  chatroom_message_data?: Maybe<Scalars['String']>;
  /** The date of message created */
  chatroom_message_datetime?: Maybe<Scalars['String']>;
  message_idx?: Maybe<Scalars['Int']>;
  /** user name */
  name: Scalars['String'];
  /** The count of message unread */
  notreadcount?: Maybe<Scalars['Int']>;
};

/** 출퇴근 지역 반경설정 ON/OFF 타입 */
export enum CommuteAreaUseType {
  Off = 'OFF',
  On = 'ON'
}

/** 출퇴근관리 데이터 타입 */
export type CommuteManagement = {
  __typename?: 'CommuteManagement';
  /** 출퇴근 인정여부 */
  approvalFlag: ApprovalFlag;
  /** 출퇴근 날짜 및 시간 */
  checkDateTime?: Maybe<Scalars['String']>;
  /** 수정된 출퇴근 날짜 및 시간 */
  checkDateTimeModify?: Maybe<Scalars['String']>;
  /** 출퇴근 주소 */
  commuteAddress?: Maybe<Scalars['String']>;
  /** 출퇴근타입 */
  commuteType: CommuteType;
  /** 날짜 */
  date?: Maybe<Scalars['String']>;
  /** 출퇴근 기기 */
  deviceType: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 위도 */
  gpsLat?: Maybe<Scalars['String']>;
  /** 경도 */
  gpsLong?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name: Scalars['String'];
  /** 시간 */
  time?: Maybe<Scalars['String']>;
};

/** 출퇴근 enum 타입 */
export enum CommuteType {
  Attendance = 'ATTENDANCE',
  Leave = 'LEAVE'
}

/** 회사 정보 entity */
export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  /** 회사 코드 */
  companyCode: Scalars['String'];
  /** 회사 이름 */
  companyName: Scalars['String'];
};

/** 결재 비밀번호 일치 확인 요청 결과 */
export type ConfirmIsApprovalPasswordOutput = {
  __typename?: 'ConfirmIsApprovalPasswordOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 양식 추가 요청 결과 */
export type ControlApprovalFormOutput = {
  __typename?: 'ControlApprovalFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 템플릿 idx */
  formTemplateIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 적책적용된 부서 목록 요청 결과 */
export type CountListOfApprovalNotificationDepartmentOutput = {
  __typename?: 'CountListOfApprovalNotificationDepartmentOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 정책적용된 부서 카운트한 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재 목록 카운트 요청 결과 */
export type CountListOfApprovalOutput = {
  __typename?: 'CountListOfApprovalOutput';
  /** 결재 목록 카운트 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 임시 저장 목록 카운트 요청 결과 */
export type CountListOfApprovalTemporaryStorageOutput = {
  __typename?: 'CountListOfApprovalTemporaryStorageOutput';
  /** 결재 임시 저장 목록 카운트 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 채팅방 목록 카운트값 요청 결과 */
export type CountListOfChatRoomOutput = {
  __typename?: 'CountListOfChatRoomOutput';
  /** 카운트 채팅방 목록 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 목록 카운트 정보 요청 결과 */
export type CountListOfCommuteGpsInfoOutput = {
  __typename?: 'CountListOfCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 사용자 출퇴근 지역 목록 카운트 값 */
  total?: Maybe<Scalars['Int']>;
};

/** 정책적용직원 카운트 요청 결과 */
export type CountListOfEmployeeAppliedToTheTemplateOutput = {
  __typename?: 'CountListOfEmployeeAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  total?: Maybe<Scalars['Float']>;
};

/** 출퇴근 관리정보 카운트 요청 결과 */
export type CountListOfEmployeeCommuteManagementOutput = {
  __typename?: 'CountListOfEmployeeCommuteManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 출퇴근 관리정보 카운트 데이터 */
  total?: Maybe<Scalars['Float']>;
};

/** 직원 정보 목록 카운트 요청 결과 */
export type CountListOfEmployeeInformationOutput = {
  __typename?: 'CountListOfEmployeeInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 직원 정보 목록 카운트한 값 */
  total?: Maybe<Scalars['Float']>;
};

/** PC 정보 목록 카운트 요청 결과 */
export type CountListOfPcInformationOutput = {
  __typename?: 'CountListOfPcInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** PC 정보 목록 카운트한 값 */
  total?: Maybe<Scalars['Float']>;
};

/** 이석관리 정보 목록 카운트 요청 결과 */
export type CountListOfPlaceFreeManagementOutput = {
  __typename?: 'CountListOfPlaceFreeManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 이석관리 정보 총 개수 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재관리 처리 및 조회 목록 카운트 요청 결과 */
export type CountListOfProcessingInquiryOutput = {
  __typename?: 'CountListOfProcessingInquiryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 처리 및 조회 데이터 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재관리 신청 및 조회 목록 카운트 요청 결과 */
export type CountListOfRequestInquiryOutput = {
  __typename?: 'CountListOfRequestInquiryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 처리 및 조회 데이터 목록 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 근무관리 정보 목록 카운트 요청 결과 */
export type CountListOfWorkManagementOutput = {
  __typename?: 'CountListOfWorkManagementOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무관리정보 목록 카운트 결과 */
  total?: Maybe<Scalars['Float']>;
};

/** 통계 정보 카운트 요청 결과 */
export type CountStatisticsInfoOutput = {
  __typename?: 'CountStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 통계 자료 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 출퇴근 장치 타입 */
export enum Device_Type {
  App = 'APP',
  Desktop = 'DESKTOP'
}

/** Dashboard 정보 */
export type DashBoard = {
  __typename?: 'DashBoard';
  /** 기간 */
  dataPerio: Scalars['String'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 사용자 ID */
  empId: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 연장근무 */
  nameOne?: Maybe<Scalars['String']>;
  /** 기타 */
  nameThree?: Maybe<Scalars['String']>;
  /** 휴가 */
  nameTwo?: Maybe<Scalars['String']>;
  /** 연장 시간 */
  otExtTime?: Maybe<Scalars['String']>;
  /** 연장 시간 구간 */
  otExtTimeSection: Scalars['String'];
  /** 근로 시간 */
  otTotTime?: Maybe<Scalars['String']>;
  /** 근로 시간 구간 */
  otTotTimeSection: Scalars['String'];
  /** 정규 시간 */
  otWorkTime?: Maybe<Scalars['String']>;
  /** 정규 시간 구간 */
  otWorkTimeSection: Scalars['String'];
  /** 정규근무 */
  workOx: Scalars['String'];
};

/** Dashboard 엑셀 결과 */
export type DashBoardExcelExcelOutput = {
  __typename?: 'DashBoardExcelExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 삭제 요청 결과 */
export type DeleteAlarmEventOutput = {
  __typename?: 'DeleteAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 삭제 요청 결과 */
export type DeleteApprovalLineOutput = {
  __typename?: 'DeleteApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 삭제 요청 결과 */
export type DeleteApprovalNotificationTemplateOutput = {
  __typename?: 'DeleteApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 회수를 위한 요청 결과 */
export type DeleteApprovalRequestOutput = {
  __typename?: 'DeleteApprovalRequestOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 서명 이미지 또는 텍스트 삭제 요청 결과 */
export type DeleteApprovalSignImageOrTextOutput = {
  __typename?: 'DeleteApprovalSignImageOrTextOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 템플릿 삭제 요청 결과 */
export type DeleteApprovalTemplateFormOutput = {
  __typename?: 'DeleteApprovalTemplateFormOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 임시저장 데이터 삭제 요청 결과 */
export type DeleteApprovalTemporaryStorageOutput = {
  __typename?: 'DeleteApprovalTemporaryStorageOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 삭제 요청 결과 */
export type DeleteCommuteGpsInfoOutput = {
  __typename?: 'DeleteCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책 적용된 직원 삭제 요청 결과 */
export type DeleteDepartmentAppliedToTheTemplateOutput = {
  __typename?: 'DeleteDepartmentAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책에 적용된 직원 삭제 요청 결과 */
export type DeleteEmployeeAppliedOutput = {
  __typename?: 'DeleteEmployeeAppliedOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 삭제 요청 결과 */
export type DeleteListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'DeleteListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 목록을 삭제하는 요청 결과 */
export type DeleteReservationCategoryOutput = {
  __typename?: 'DeleteReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약정보 삭제하는 요청 */
export type DeleteReservationOutput = {
  __typename?: 'DeleteReservationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 삭제 요청 결과 */
export type DeleteReservationTypeOutput = {
  __typename?: 'DeleteReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 카운트 삭제 결과 */
export type DeleteStatisticsInfoOutput = {
  __typename?: 'DeleteStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 삭제 요청 결과 */
export type DeleteTemplateScheduleInfoOutput = {
  __typename?: 'DeleteTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 삭제 요청 결과 */
export type DeleteTimeTemplateOutput = {
  __typename?: 'DeleteTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 삭제하기 위한 요청 결과 */
export type DeleteVehicleDrivingInformationOutput = {
  __typename?: 'DeleteVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteVehicleInformationOutput = {
  __typename?: 'DeleteVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 변경된 연차 정보 엑셀 다운로드 요청 결과 */
export type DownloadAnnualHistoryAsExcelOutput = {
  __typename?: 'DownloadAnnualHistoryAsExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 변경된 연차 정보 엑셀 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약상세정보 엑셀로 다운로드 요청 */
export type DownloadExcelListOfReservationDetailInformationOutput = {
  __typename?: 'DownloadExcelListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약상세정보 엑셀정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 목록 엑셀로 다운로드 요청 결과 */
export type DownloadExcelListOfReservationInformationOutput = {
  __typename?: 'DownloadExcelListOfReservationInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약종류 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책적용부서 목록 엑셀 요청 결과 */
export type DownloadListOfApprovalNotificationDepartmentExcelOutput = {
  __typename?: 'DownloadListOfApprovalNotificationDepartmentExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책적용직원 엑셀 다운로드 요청 결과 */
export type DownloadListOfEmployeeAppliedToTheTemplateExcelOutput = {
  __typename?: 'DownloadListOfEmployeeAppliedToTheTemplateExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 관리정보 엑셀 다운로드 요청 결과 */
export type DownloadListOfEmployeeCommuteManagementExcelOutput = {
  __typename?: 'DownloadListOfEmployeeCommuteManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 출퇴근 관리정보 엑셀 데이터 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원정보목록 엑셀 요청 결과 */
export type DownloadListOfEmployeeInformationExcelOutput = {
  __typename?: 'DownloadListOfEmployeeInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** PC정보목록 엑셀 요청 결과 */
export type DownloadListOfPcInformationExcelOutput = {
  __typename?: 'DownloadListOfPcInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 이석 관리 정보 엑셀로 다운로드 하는 요청 결과 */
export type DownloadListOfPlaceFreeManagementExcelOutput = {
  __typename?: 'DownloadListOfPlaceFreeManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 이석관리 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 목록 엑셀 다운로드 요청 결과 */
export type DownloadListOfProcessingInquiryToExcelOutput = {
  __typename?: 'DownloadListOfProcessingInquiryToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 신청 및 조회 목록 엑셀 다운로드 요청 결과 */
export type DownloadListOfRequestInquiryToExcelOutput = {
  __typename?: 'DownloadListOfRequestInquiryToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무관리정보 엑셀 다운로드 요청 결과 */
export type DownloadListOfWorkManagementExcelOutput = {
  __typename?: 'DownloadListOfWorkManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 엑셀 요청 결과 */
export type DownloadStatisticsToExcelOutput = {
  __typename?: 'DownloadStatisticsToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계 자료 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 차량 주행 정보 목록 엑셀 요청 결과 */
export type DownloadVehicleDrivingInformationExcelOutput = {
  __typename?: 'DownloadVehicleDrivingInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 차량 정보 목록 엑셀 요청 결과 */
export type DownloadVehicleInformationExcelOutput = {
  __typename?: 'DownloadVehicleInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 대시보드 결재목록 설정 수정 요청 결과 */
export type EditApprovalAdminSettingOutput = {
  __typename?: 'EditApprovalAdminSettingOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 수정 요청 결과 */
export type EditApprovalLineOutput = {
  __typename?: 'EditApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재선 idx */
  lineIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

export type EmployeeAppliedToTheTemplate = {
  __typename?: 'EmployeeAppliedToTheTemplate';
  /** 알람 이름 */
  alarmName: Scalars['String'];
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 직원 ID */
  employeeId: Scalars['String'];
  /** 정책 에외 */
  isExpect: IsException;
  /** 직원 이름 */
  name: Scalars['String'];
  /** 템플릿 Idx */
  templateIdx: Scalars['Float'];
};

/** 직원 정보 */
export type EmployeeInformation = {
  __typename?: 'EmployeeInformation';
  /** 승인 정의 */
  approvalDefine: Scalars['Float'];
  /** 근무시간계획정책 */
  asTimeTemplateName: Scalars['String'];
  /** col1 */
  col1: Scalars['String'];
  /** col2 */
  col2: Scalars['String'];
  /** col3 */
  col3: Scalars['String'];
  /** col4 */
  col4: Scalars['String'];
  /** col5 */
  col5: Scalars['String'];
  /** col6 */
  col6: Scalars['String'];
  /** col7 */
  col7: Scalars['String'];
  /** col8 */
  col8: Scalars['String'];
  /** col9 */
  col9: Scalars['String'];
  /** col10 */
  col10: Scalars['String'];
  /** col11 */
  col11: Scalars['String'];
  /** col12 */
  col12: Scalars['String'];
  /** col13 */
  col13: Scalars['String'];
  /** col14 */
  col14: Scalars['String'];
  /** col15 */
  col15: Scalars['String'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 부서 아이디 */
  departmentId: Scalars['String'];
  /** 직원 아이디 */
  employeeId: Scalars['String'];
  /** Endpoint 수량 */
  endpointCount: Scalars['Float'];
  /** 전체 부서명 */
  fullDepartmentName: Scalars['String'];
  /** 입력구분(0: 수동, 1:자동) */
  insertType: Scalars['Float'];
  /** 직원 이름 */
  name: Scalars['String'];
  /** 금일 근무 시간 */
  todayWorkingTime?: Maybe<Scalars['String']>;
  /** 직급 */
  userPosition?: Maybe<Scalars['String']>;
  /** 총 연장근무 가능 시간 */
  weekMaxExtWorkHour?: Maybe<Scalars['String']>;
  /** 총 근무 가능시간 */
  weekMaxWorkHour?: Maybe<Scalars['String']>;
  /** 근무정책명 */
  workingName: Scalars['String'];
  /** 근무정책 Idx */
  workingTemplateIdx?: Maybe<Scalars['Float']>;
  /** 총 연장근무한 시간 */
  wtAsextto?: Maybe<Scalars['String']>;
  /** 총 근무한 시간 */
  wtTot?: Maybe<Scalars['String']>;
};

export type FieldSort = {
  name: Scalars['String'];
  sort: SortColumType;
};

/** 알람 상태 정보 요청 결과 */
export type GetAlarmEventStatusOutput = {
  __typename?: 'GetAlarmEventStatusOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 알람 상태값 */
  status?: Maybe<AlarmStatus>;
};

/** 연차 설정 정보 요청 결과 */
export type GetAnnualEnvInfoOutput = {
  __typename?: 'GetAnnualEnvInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 회사 연차 설정 정보 */
  info?: Maybe<AnnualEnvInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 변경된 연차 정보 이력 요청 결과 */
export type GetAnnualHistoryOutput = {
  __typename?: 'GetAnnualHistoryOutput';
  error?: Maybe<Scalars['String']>;
  history?: Maybe<Array<AnnualHistoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 대시보드 결재목록 설정값 요청 결과 */
export type GetApprovalAdminSettingOutput = {
  __typename?: 'GetApprovalAdminSettingOutput';
  /** 결재목록 개수 */
  dashboardListCount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 미리보기 값 */
  preview?: Maybe<Approval_Admin_Setting_Preview_Type>;
};

/** 결재 양식 상세 데이터 요청 결과 */
export type GetApprovalFormDetailOutput = {
  __typename?: 'GetApprovalFormDetailOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 상세 정보 */
  information?: Maybe<ApprovalDetailInformationEntity>;
  /** 결재자 목록 */
  listOfApprovalUser?: Maybe<Array<ListOfApprovalUser>>;
  /** 수신 부서 정보 */
  listOfReceiveDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 수신자 정보 */
  listOfReceiveUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  /** 참조 부서 정보 */
  listOfReferenceDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 참조자 정보 */
  listOfReferenceUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  ok: Scalars['Boolean'];
};

/** 결재 비밀번호 및 서명 설정 정보 요청 결과 */
export type GetApprovalPasswordAndSignSettingInfoOutput = {
  __typename?: 'GetApprovalPasswordAndSignSettingInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 비밀번호 및 서명 설정 정보 */
  info?: Maybe<ApprovalPasswordAndSignSettingInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 결재 임시 저장 상세 정보 요청 결과 */
export type GetApprovalTemporaryStorageDetailInformationOutput = {
  __typename?: 'GetApprovalTemporaryStorageDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 상세 정보 */
  information?: Maybe<ApprovalDetailInformationEntity>;
  /** 결재자 목록 */
  listOfApprovalUser?: Maybe<Array<ListOfApprovalUser>>;
  /** 수신 부서 정보 */
  listOfReceiveDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 수신자 정보 */
  listOfReceiveUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  /** 참조 부서 정보 */
  listOfReferenceDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 참조자 정보 */
  listOfReferenceUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  ok: Scalars['Boolean'];
};

/** 사용자 정보 요청 결과 */
export type GetBasicUserInfoOutput = {
  __typename?: 'GetBasicUserInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  user?: Maybe<BasicUserInfoEntity>;
};

/** 채팅 서버 ip 요청 결과 */
export type GetChatServerIpOutput = {
  __typename?: 'GetChatServerIpOutput';
  /** 채팅 서버 IP */
  chatServerIp?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 반경설정 on/off 값 요청 결과 */
export type GetCommuteGpsAreaUseTypeOutput = {
  __typename?: 'GetCommuteGpsAreaUseTypeOutput';
  /** 사용자 출퇴근 지역 정보 추가 ID */
  commuteAreaUseType?: Maybe<CommuteAreaUseType>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 회사 정보 요청 */
export type GetCompanyListOutput = {
  __typename?: 'GetCompanyListOutput';
  error?: Maybe<Scalars['String']>;
  /** 회사 정보 목록 */
  list?: Maybe<Array<CompanyEntity>>;
  ok: Scalars['Boolean'];
};

/** DashBoard 데이터 목록 요청 결과 */
export type GetDashBoardOutput = {
  __typename?: 'GetDashBoardOutput';
  error?: Maybe<Scalars['String']>;
  /** Dashboard 데이터 목록 */
  listOfDashBoard?: Maybe<Array<DashBoard>>;
  ok: Scalars['Boolean'];
};

/** DashBoard 총 데이터 수 요청 결과 */
export type GetDashBoardTotalOutput = {
  __typename?: 'GetDashBoardTotalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** DashBoard 총 데이터 수 */
  total?: Maybe<Scalars['Float']>;
};

/** 직원 정보 요청 결과 */
export type GetEmployeeInformationOutput = {
  __typename?: 'GetEmployeeInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 직원 정보 목록 */
  listOfEmployee?: Maybe<Array<EmployeeInformation>>;
  ok: Scalars['Boolean'];
};

/** 그룹 정보 요청 결과 */
export type GetGroupOutput = {
  __typename?: 'GetGroupOutput';
  error?: Maybe<Scalars['String']>;
  listOfGroup?: Maybe<Array<GroupEntity>>;
  ok: Scalars['Boolean'];
};

/** 만 1년 미만(입사년도) 정보 요청 결과 */
export type GetInfoLessThanAYearOutput = {
  __typename?: 'GetInfoLessThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년 미만(입사년도) 정보 */
  info?: Maybe<InfoLessThanAYearEntity>;
  ok: Scalars['Boolean'];
};

/** 만 1년 이상 설정 정보 요청 결과 */
export type GetInfoMoreThanAYearOutput = {
  __typename?: 'GetInfoMoreThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년 이상 설정 정보 */
  info?: Maybe<InfoMoreThanAYearEntity>;
  ok: Scalars['Boolean'];
};

/** 만 1년미만(입사차기년도) 정보 요청 결과 */
export type GetInfoNextYearOfEmploymentOutput = {
  __typename?: 'GetInfoNextYearOfEmploymentOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년미만(입사차기년도) 정보 */
  info?: Maybe<InfoNextYearOfEmploymentEntity>;
  ok: Scalars['Boolean'];
};

/** 결재 양식 목록 요청 결과 */
export type GetListOtApprovalFormOutput = {
  __typename?: 'GetListOTApprovalFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 목록 */
  list?: Maybe<Array<OtApprovalFormEntity>>;
  ok: Scalars['Boolean'];
};

/** 알람 목록 요청 결과 */
export type GetListOfAlarmOutput = {
  __typename?: 'GetListOfAlarmOutput';
  error?: Maybe<Scalars['String']>;
  /** 알람 종류 */
  list?: Maybe<Array<AlarmSettingEntity>>;
  ok: Scalars['Boolean'];
};

/** 기본 결재선 사용자 목록 요청 결과 */
export type GetListOfApprovalBasicLineUserOutput = {
  __typename?: 'GetListOfApprovalBasicLineUserOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 사용자 목록 */
  list?: Maybe<Array<UserOfSearchedApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 양식 목록 정보 요청 결과 */
export type GetListOfApprovalLineFormOutput = {
  __typename?: 'GetListOfApprovalLineFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 양식 목록 */
  list?: Maybe<Array<ApprovalLineFormEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 목록 요청 결과 */
export type GetListOfApprovalLineOutput = {
  __typename?: 'GetListOfApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 목록 */
  list?: Maybe<Array<ApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 목록 요청 결과 */
export type GetListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'GetListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재정보 알림 계획 목록 */
  list?: Maybe<Array<ApprovalNotificationScheduleByTemplate>>;
  ok: Scalars['Boolean'];
};

/** DashBoard 데이터 목록 요청 결과 */
export type GetListOfApprovalNotificationTemplateOutput = {
  __typename?: 'GetListOfApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재정보 알림 템플릿 목록 */
  list?: Maybe<Array<ApprovalNotificationTemplate>>;
  ok: Scalars['Boolean'];
};

/** 결재 템플릿 목록 요청 */
export type GetListOfApprovalTemplateOutput = {
  __typename?: 'GetListOfApprovalTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 템플릿 목록 */
  list?: Maybe<Array<ApprovalTemplateEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 임시저장 정보 목록 요청 결과 */
export type GetListOfApprovalTemporaryStorageOutput = {
  __typename?: 'GetListOfApprovalTemporaryStorageOutput';
  error?: Maybe<Scalars['String']>;
  list?: Maybe<Array<ListOfApprovalTemporaryStorageEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅방 이미지 목록 요청 결과 */
export type GetListOfChatImageOutput = {
  __typename?: 'GetListOfChatImageOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅방 이미지 목록 */
  listOfImage?: Maybe<Array<ListOfChatImageEntity>>;
  ok: Scalars['Boolean'];
};

/** 메시지 목록 요청 결과 */
export type GetListOfChatMessageOutput = {
  __typename?: 'GetListOfChatMessageOutput';
  error?: Maybe<Scalars['String']>;
  /** 메시지 목록 */
  list?: Maybe<Array<ChatMessageEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅방 목록 요청 결과 */
export type GetListOfChatRoomOutput = {
  __typename?: 'GetListOfChatRoomOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅방 목록 */
  list?: Maybe<Array<ListOfChatRoom>>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 목록 정보 요청 결과 */
export type GetListOfCommuteGpsInfoOutput = {
  __typename?: 'GetListOfCommuteGpsInfoOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 사용자 출퇴근 지역 목록 */
  list?: Maybe<Array<MapRegisterEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅을 위한 부서 목록 요청 결과 */
export type GetListOfDepartmentForChatOutput = {
  __typename?: 'GetListOfDepartmentForChatOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅을 위한 부서 목록 */
  list?: Maybe<Array<ListOfDepartmentForChatEntity>>;
  ok: Scalars['Boolean'];
};

/** 근무 시간 타입 카테고리 목록 요청 결과 */
export type GetListOfOtTImeTypeCategoryOutput = {
  __typename?: 'GetListOfOtTImeTypeCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 시간 타입 카테고리 목록 */
  list?: Maybe<Array<OtTimeTypeCategoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 차량을 위한 근무 카테고리 목록 요청 결과 */
export type GetListOfOtTimeTypeCategoryForVehicleOutput = {
  __typename?: 'GetListOfOtTimeTypeCategoryForVehicleOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 시간 목록 */
  list?: Maybe<Array<OtTimeTypeCategory>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 목록 요청 결과 */
export type GetListOfProcessingInquiryOutput = {
  __typename?: 'GetListOfProcessingInquiryOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 */
  listOfProcessingInquiry?: Maybe<Array<ProcessingInquiry>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 신청 및 조회 목록 요청 결과 */
export type GetListOfRequestInquiryOutput = {
  __typename?: 'GetListOfRequestInquiryOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 */
  listOfRequestInquiry?: Maybe<Array<RequestInquiry>>;
  ok: Scalars['Boolean'];
};

/** 예약 목록 가져오는 요청 결과 */
export type GetListOfReservationCategoryOutput = {
  __typename?: 'GetListOfReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약 목록 */
  list?: Maybe<Array<ReservationCategoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약상세정보 목록 요청 결과 */
export type GetListOfReservationDetailInformationOutput = {
  __typename?: 'GetListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약상세정보 목록 */
  list?: Maybe<Array<ReservationDetailInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약정보 목록 요청 결과 */
export type GetListOfReservationInformationOutput = {
  __typename?: 'GetListOfReservationInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약정보 목록 */
  list?: Maybe<Array<ReservationInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약종류 목록 가져오는 요청 결과 */
export type GetListOfReservationTypeOutput = {
  __typename?: 'GetListOfReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약종류 목록 */
  list?: Maybe<Array<ReservationTypeEntity>>;
  ok: Scalars['Boolean'];
};

/** 선택된 결재선 사용자 목록 요청 결과 */
export type GetListOfSearchedApprovalLineUserOutput = {
  __typename?: 'GetListOfSearchedApprovalLineUserOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 사용자 목록 */
  list?: Maybe<Array<UserOfSearchedApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 정보 목록 요청 결과 */
export type GetListOfTemplateScheduleInfoOutput = {
  __typename?: 'GetListOfTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 템플릿 근무 계획 정보 목록 */
  list?: Maybe<Array<TemplateTimeScheduleInfo>>;
  ok: Scalars['Boolean'];
};

/** 근무 계획 타입 카테고리 타입 목록 요청 결과 */
export type GetListOfTimeTypeCategoryOutput = {
  __typename?: 'GetListOfTimeTypeCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 계획 타입 카테고리 목록 */
  list?: Maybe<Array<TimeTypeCategory>>;
  ok: Scalars['Boolean'];
};

/** 차량 주행 정보 목록 요청 결과 */
export type GetListOfVehicleDrivingInformationOutput = {
  __typename?: 'GetListOfVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 차량 주행 정보 목록 */
  list?: Maybe<Array<VehicleDrivingInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 차량 정보 */
export type GetListOfVehicleInformationOutput = {
  __typename?: 'GetListOfVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 차량정보 */
  list?: Maybe<Array<VehicleInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** PC 정보 요청 결과 */
export type GetPcInformationOutput = {
  __typename?: 'GetPcInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 선택된 사용자의 PC 목록 정보 */
  listOfPcInformation?: Maybe<Array<PcInformation>>;
  ok: Scalars['Boolean'];
};

/** 개인 근무 템플릿 목록 요청 결과 */
export type GetPersonalTimeTemplateOutput = {
  __typename?: 'GetPersonalTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 개인 근무 템플릿 목록 */
  list?: Maybe<Array<PersonalTimeTemplate>>;
  ok: Scalars['Boolean'];
};

/** 공용 근무 템플릿 목록 요청 결과 */
export type GetPublicTimeTemplateOutput = {
  __typename?: 'GetPublicTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 공용 근무 템플릿 목록 */
  list?: Maybe<Array<PublicTimeTemplate>>;
  ok: Scalars['Boolean'];
};

/** 금일 및 금주 인정시간 데이터 요청 결과 */
export type GetRecognitionWorkTimeOutput = {
  __typename?: 'GetRecognitionWorkTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 금주 인정시간 데이터 */
  thisWeekRecognitionWorkTime?: Maybe<RecognitionWorkTimeEntity>;
  /** 금일 인정시간 데이터 */
  todayRecognitionWorkTime?: Maybe<RecognitionWorkTimeEntity>;
};

/** 예약 종류 목록 total 정보 요청 결과 */
export type GetToTalListOfReservationTypeOutput = {
  __typename?: 'GetToTalListOfReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 예약 종류 목록 total */
  total?: Maybe<Scalars['Int']>;
};

/** 예약상세정보 목록 total정보 요청 결과 */
export type GetTotalListOfReservationDetailInformationOutput = {
  __typename?: 'GetTotalListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 예약상세정보 total 정보 */
  total?: Maybe<Scalars['Int']>;
};

/** 차량 주행 목록 합계 정보 요청 결과 */
export type GetTotalNumberOfVehicleDrivingInformationOutput = {
  __typename?: 'GetTotalNumberOfVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 차량 주행 목록 합계 정보 */
  total?: Maybe<Scalars['Int']>;
};

/** 차량 합계 정보 요청 결과 */
export type GetTotalNumberOfVehicleInformationOutput = {
  __typename?: 'GetTotalNumberOfVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 차량 합계 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 사용자 연차 정보 요청 결과 */
export type GetUserAnnualInfoOutput = {
  __typename?: 'GetUserAnnualInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 사용자 연차 정보 */
  userAnnualInfo?: Maybe<UserAnnualInfoEntity>;
};

/** 사용자 정보 요청 결과 */
export type GetUserOutput = {
  __typename?: 'GetUserOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  user?: Maybe<Array<UserEntity>>;
};

/** 그룹 정보 */
export type GroupEntity = {
  __typename?: 'GroupEntity';
  /** 전체 직원수 */
  countEmployee?: Maybe<Scalars['Float']>;
  /** 전체 하위 그룹수 */
  countEndpoint?: Maybe<Scalars['Float']>;
  /** 부서 ID */
  departmentId: Scalars['Int'];
  /** 그룹 종류 */
  deptSort: Scalars['Float'];
  /** 전체 부서명 */
  fullGroupName: Scalars['String'];
  /** Insert type */
  insertType: Scalars['Float'];
  /** 레벨 */
  level: Scalars['String'];
  /** 메모 1 */
  memo1?: Maybe<Scalars['String']>;
  /** 메모 1 */
  memo2?: Maybe<Scalars['String']>;
  /** 부서 ID */
  name: Scalars['String'];
  /** 부모 부서 ID */
  parentDepartmentId: Scalars['Int'];
  /** 근무 정책 */
  workingName?: Maybe<Scalars['String']>;
};

export type IListOfApprovalLineUser = {
  /** 사용자 ID */
  employee_id: Scalars['String'];
  /** 결재 순서 */
  order: Scalars['Int'];
  /** 결재 타입 */
  type: Approval_User_Type;
};

export type IReceiveAndReferenceForDepartment = {
  /** 사용자 부서 */
  department_id: Scalars['Int'];
};

export type IReceiveAndReferenceForUser = {
  /** 사용자 ID */
  employee_id: Scalars['String'];
};

/** 기본 결재선 미사용 및 사용 타입 */
export enum Is_Default_Approval_Line {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 결재 알림 사용 타입 */
export enum Is_Sign_Alarm_Type {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 결재 비밀번호 사용 타입 */
export enum Is_Sign_Password {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 값의 참과 거짓 구분 값 */
export enum Is_Truthy_Falsy {
  Falsy = 'FALSY',
  Truthy = 'TRUTHY'
}

/** 1년 미만 설정 정보 Entity */
export type InfoLessThanAYearEntity = {
  __typename?: 'InfoLessThanAYearEntity';
  /** 연차발생방식설정 */
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  /** 고정일부여일자 */
  fixCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스연차적용설정 */
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  /** 월별발생기준시점 */
  monthAccrualType: Month_Accrual_Type;
};

/** 만 1년 이상 */
export type InfoMoreThanAYearEntity = {
  __typename?: 'InfoMoreThanAYearEntity';
  /** 가산일수설정(일추가부여) */
  addedDayCount: Scalars['Float'];
  /** 최대가산일수 */
  addedMaxDayCount: Scalars['Float'];
  /** 가산일수설정(년마다) */
  addedYearCount: Scalars['Float'];
  /** 이월연차적용범위 */
  carriedforwardRangeType: Carriedforward_Range_Type;
  /** 이월연차적용설정 */
  carriedforwardType: Carriedforward_Type;
  /** 기본일수설정 */
  defaultDayCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스 연차 적용 설정 */
  minusAnnualType: Minus_Annual_Type;
};

/** 만 1년미만(입사차기년도) 정보 */
export type InfoNextYearOfEmploymentEntity = {
  __typename?: 'InfoNextYearOfEmploymentEntity';
  /** 연차발생방식설정 */
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  /** 이월연차적용범위 */
  carriedforwardRangeType: Carriedforward_Range_Type;
  /** 이월연차적용설정 */
  carriedforwardType: Carriedforward_Type;
  /** 고정일부여일자 */
  fixCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스 연차 적용 설정 */
  minusAnnualType: Minus_Annual_Type;
  /** 월별발생기준시점 */
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
};

/** 결재 비밀번호 사용여부 확인 요청 결과 */
export type IsActiveApprovalPasswordOutput = {
  __typename?: 'IsActiveApprovalPasswordOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책 사용 여부 타입 */
export enum IsControl {
  Disable = 'DISABLE',
  Enable = 'ENABLE'
}

/** 정책 사용 여부 타입 */
export enum IsEnable {
  Disable = 'DISABLE',
  Enable = 'ENABLE'
}

/** 정책에외 포함 및 에외 타입 */
export enum IsException {
  Exception = 'EXCEPTION',
  Including = 'INCLUDING'
}

/** On Off 타입 */
export enum IsOnOff {
  Off = 'OFF',
  On = 'ON'
}

/** 알림 사용 여부 */
export enum IsUsingNotification {
  False = 'FALSE',
  True = 'TRUE'
}

/** 방에서 나가기위한 요청 */
export type LeavingTheRoomOutput = {
  __typename?: 'LeavingTheRoomOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 적책적용된 부서 목록 요청 결과 */
export type ListOfApprovalNotificationDepartmentOutput = {
  __typename?: 'ListOfApprovalNotificationDepartmentOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 정책적용된 부서 목록 */
  list?: Maybe<Array<ApprovalNotificationDepartment>>;
  ok: Scalars['Boolean'];
};

/** 결재 목록 데이터 요청 결과 */
export type ListOfApprovalOutput = {
  __typename?: 'ListOfApprovalOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 목록 */
  list?: Maybe<Array<ApprovalEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 임시저장 목록 데이터 양식 */
export type ListOfApprovalTemporaryStorageEntity = {
  __typename?: 'ListOfApprovalTemporaryStorageEntity';
  /** 결재 ID */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 결재 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 연장 종료 날짜 */
  extendEndDate: Scalars['String'];
  /** 연장 종료 날짜 시간 */
  extendEndDateTime: Scalars['String'];
  /** 연장 시작 날짜 */
  extendStartDate: Scalars['String'];
  /** 연장 시작 날짜 시간 */
  extendStartDateTime: Scalars['String'];
  /** 근무 결재신청양식 IDX */
  formIdx?: Maybe<Scalars['Int']>;
  /** 결재 템플릿 양식 idx */
  formTemplateIdx: Scalars['Int'];
  /** 기안자 */
  name: Scalars['String'];
  /** 작성일 */
  signUpDatetime?: Maybe<Scalars['String']>;
};

/** 결재 양식 결재자 정보 */
export type ListOfApprovalUser = {
  __typename?: 'ListOfApprovalUser';
  /** 결재 순서 */
  approvalOrder: Scalars['Int'];
  /** 결재 idx */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 결과 */
  approvalResult?: Maybe<Approval_Status>;
  /** 결재 시간 */
  approvalTime?: Maybe<Scalars['String']>;
  /** 결재자 ID */
  approverId: Scalars['String'];
  /** 사용자 결재 타입 */
  approverType: Approval_User_Type;
  /** 결재자 이름 */
  name: Scalars['String'];
  /** 사용자 서명 이미지 */
  signImage?: Maybe<Scalars['String']>;
  /** 서명 text */
  signText?: Maybe<Scalars['String']>;
  /** 서명 타입 */
  signType?: Maybe<SignType>;
  /** 서명 image */
  sign_image?: Maybe<Array<Scalars['String']>>;
  /** 열람 시간 */
  viewTime?: Maybe<Scalars['String']>;
};

/** 채팅방 이미지 타입 */
export type ListOfChatImageEntity = {
  __typename?: 'ListOfChatImageEntity';
  /** 사용자 ID */
  employee_id: Scalars['String'];
  /** 사용자 사진 */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 사진 buffer 타입 */
  my_picture?: Maybe<Array<Scalars['String']>>;
  /** 사용자 이름 */
  name: Scalars['String'];
};

/** 채팅 룸 */
export type ListOfChatRoom = {
  __typename?: 'ListOfChatRoom';
  /** 채팅방 IDX */
  chatroom_idx: Scalars['Int'];
  /** 채팅방 메시지 */
  chatroom_message_data?: Maybe<Scalars['String']>;
  /** 읽지 않은 메시지 카운트 */
  noreadcount?: Maybe<Scalars['Int']>;
  /** 채팅방 이름 */
  roomname: Scalars['String'];
};

/** 채팅에서 사용할 부서 및 사용자 목록 */
export type ListOfDepartmentForChatEntity = {
  __typename?: 'ListOfDepartmentForChatEntity';
  /** 부서 ID */
  departmentId: Scalars['Int'];
  /** 전체 부서명 */
  fullName?: Maybe<Scalars['String']>;
  /** 부서명 */
  name?: Maybe<Scalars['String']>;
  /** 부모 부서 ID */
  parentDepartmentId: Scalars['Int'];
};

/** 출퇴근 관리 정보 요청 결과 */
export type ListOfEmployeeCommuteManagementOutput = {
  __typename?: 'ListOfEmployeeCommuteManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 출퇴근 관리 정보 목록 */
  list?: Maybe<Array<CommuteManagement>>;
  ok: Scalars['Boolean'];
};

/** 정책적용직원 정보 요청 결과 */
export type ListOfEmployeesAppliedToTheTemplateOutput = {
  __typename?: 'ListOfEmployeesAppliedToTheTemplateOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 정책적용된 직원 목록 */
  listEmployeesAppliedToTheTemplate?: Maybe<Array<EmployeeAppliedToTheTemplate>>;
  ok: Scalars['Boolean'];
};

/** 이석관리 정보 목록 요청 결과 */
export type ListOfPlaceFreeManagementOutput = {
  __typename?: 'ListOfPlaceFreeManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 이석관리 정보 목록 */
  list?: Maybe<Array<PlaceFreeManagement>>;
  ok: Scalars['Boolean'];
};

/** 수신 및 참조 부서 정보 */
export type ListOfReceiveAndReferenceDepartmentInformation = {
  __typename?: 'ListOfReceiveAndReferenceDepartmentInformation';
  /** 사용자 부서 ID */
  departmentId: Scalars['Int'];
  /** 사용자 부서 이름 */
  departmentName: Scalars['String'];
};

/** 수신자 및 참조자 정보 */
export type ListOfReceiveAndReferenceUserInformation = {
  __typename?: 'ListOfReceiveAndReferenceUserInformation';
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
};

/** 근무관리 정보 목록 요청 결과 */
export type ListOfWorkManagementOutput = {
  __typename?: 'ListOfWorkManagementOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무관리정보 목록 */
  list?: Maybe<Array<WorkManagement>>;
  ok: Scalars['Boolean'];
};

/** 마이너스 연차 적용 설정 타입 */
export enum Minus_Annual_Type {
  LimitByBasicDayWithMinusAnnual = 'LIMIT_BY_BASIC_DAY_WITH_MINUS_ANNUAL',
  LimitByMinusAllowedAnnual = 'LIMIT_BY_MINUS_ALLOWED_ANNUAL',
  UnLimit = 'UN_LIMIT',
  UnUsed = 'UN_USED'
}

/** 마이너스연차적용설정 */
export enum Minus_Annual_Type_For_Less_Than_A_Year {
  Apply = 'APPLY',
  NotApplied = 'NOT_APPLIED'
}

/** 월별발생기준시점 */
export enum Month_Accrual_Type {
  AFullMonthsEmployment = 'A_FULL_MONTHS_EMPLOYMENT',
  TheBaseDateForJoining = 'THE_BASE_DATE_FOR_JOINING',
  TheMonthOfEmployment = 'THE_MONTH_OF_EMPLOYMENT'
}

/** 월별발생기준시점 타입 */
export enum Month_Accrual_Type_For_Next_Year_Of_Employment {
  AFullMonthsEmployment = 'A_FULL_MONTHS_EMPLOYMENT',
  TheBaseDateForJoining = 'THE_BASE_DATE_FOR_JOINING',
  TheMonthOfEmployment = 'THE_MONTH_OF_EMPLOYMENT'
}

/** 사용자 출퇴근 정보 */
export type MapRegisterEntity = {
  __typename?: 'MapRegisterEntity';
  /** 사용자 출퇴근 지역 정보 키값(areaIdx) */
  areaIdx: Scalars['Int'];
  /** 장소 이름 */
  areaName: Scalars['String'];
  customerSend: Scalars['Int'];
  /** gps에 따른 상세주소 */
  gpsAddress: Scalars['String'];
  /** gps 위도 */
  gpsLatitude: Scalars['String'];
  /** gps 위도 */
  gpsLongitude: Scalars['String'];
  /** 적용 반경 */
  radiusMiter: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAlarmEvent: AddAlarmEventOutput;
  addApprovalLine: AddApprovalLineOutput;
  addApprovalNotificationDepartment: AddDepartmentAppliedToTheTemplateOutput;
  addApprovalNotificationSchedule: AddListOfApprovalNotificationScheduleByTemplateOutput;
  addApprovalNotificationTemplate: AddApprovalNotificationTemplateOutput;
  addApprovalTemporaryStorage: ApplicationApprovalOutput;
  addChatRoom: AddChatRoomOutput;
  addCommuteDateTime: AddCommuteDateTimeOutput;
  addCommuteGpsInfo: AddCommuteGpsInfoOutput;
  addEmployeeAppliedToTheTemplate: AddEmployeeAppliedToTheTemplateOutput;
  addReservation: AddReservationOutput;
  addReservationCategory: AddReservationCategoryOutput;
  addReservationType: AddReservationTypeOutput;
  addTemplateScheduleInfo: AddTemplateScheduleInfoOutput;
  addTimeTemplate: AddTimeTemplateOutput;
  addUserFromChatRoom: AddUserFromChatRoomOutput;
  addVehicleDrivingInformation: AddVehicleDrivingInformationOutput;
  addVehicleInformation: AddVehicleInformationOutput;
  applicationApproval: ApplicationApprovalOutput;
  controlApprovalForm: ControlApprovalFormOutput;
  deleteAlarmEvent: DeleteAlarmEventOutput;
  deleteApprovalLine: DeleteApprovalLineOutput;
  deleteApprovalNotificationDepartment: DeleteDepartmentAppliedToTheTemplateOutput;
  deleteApprovalNotificationSchedule: DeleteListOfApprovalNotificationScheduleByTemplateOutput;
  deleteApprovalNotificationTemplate: DeleteApprovalNotificationTemplateOutput;
  deleteApprovalRequest: DeleteApprovalRequestOutput;
  deleteApprovalSignImageOrText: DeleteApprovalSignImageOrTextOutput;
  deleteApprovalTemplateForm: DeleteApprovalTemplateFormOutput;
  deleteApprovalTemporaryStorage: DeleteApprovalTemporaryStorageOutput;
  deleteCommuteGpsInfo: DeleteCommuteGpsInfoOutput;
  deleteEmployeeApplied: DeleteEmployeeAppliedOutput;
  deleteReservation: DeleteReservationOutput;
  deleteReservationCategory: DeleteReservationCategoryOutput;
  deleteReservationType: DeleteReservationTypeOutput;
  deleteStatisticsInfo: DeleteStatisticsInfoOutput;
  deleteTemplateScheduleInfo: DeleteTemplateScheduleInfoOutput;
  deleteTimeTemplate: DeleteTimeTemplateOutput;
  deleteVehicleDrivingInformation: DeleteVehicleDrivingInformationOutput;
  deleteVehicleInformation: DeleteVehicleInformationOutput;
  editApprovalAdminSetting: EditApprovalAdminSettingOutput;
  editApprovalLine: EditApprovalLineOutput;
  leavingTheRoom: LeavingTheRoomOutput;
  rejectApproval: RejectApprovalOutput;
  requestForApproval: RequestForApprovalOutput;
  saveApprovalPasswordAndSignSetting: SaveApprovalPasswordAndSignSettingOutput;
  saveStatisticsInfo: SaveStatisticsInfoOutput;
  signApproval: SignApprovalOutput;
  updateAlarmEvent: UpdateAlarmEventOutput;
  updateAlarmEventStatus: UpdateAlarmEventStatusOutput;
  updateAnnualEnvInfo: UpdateAnnualEnvInfoOutput;
  updateApprovalNotificationSchedule: UpdateListOfApprovalNotificationScheduleByTemplateOutput;
  updateApprovalNotificationTemplate: UpdateApprovalNotificationTemplateOutput;
  updateBasicUserinfo: UpdateBasicUserInfoOutput;
  updateCommuteDateTime: UpdateCommuteDateTimeOutput;
  updateCommuteGpsAreaUseType: UpdateCommuteGpsAreaUseTypeOutput;
  updateCommuteGpsInfo: UpdateCommuteGpsInfoOutput;
  updateInfoLessThanYear: UpdateInfoLessThanAYearOutput;
  updateInfoMoreThanAYear: UpdateInfoMoreThanAYearOutput;
  updateInfoNextYearOfEmployment: UpdateInfoNextYearOfEmploymentOutput;
  updatePlaceFreeManagement: UpdatePlaceFreeManagementOutput;
  updateProcessingInQuiryResult: UpdateProcessingInquiryResultOutput;
  updateReservation: UpdateReservationOutput;
  updateReservationCategory: UpdateReservationCategoryOutput;
  updateReservationType: UpdateReservationTypeOutput;
  updateTemplateScheduleInfo: UpdateTemplateScheduleInfoOutput;
  updateTimeTemplate: UpdateTimeTemplateOutput;
  updateUserAnnualInfo: UpdateUserAnnualInfoOutput;
  updateVehicleDrivingInformation: UpdateVehicleDrivingInformationOutput;
  updateVehicleInformation: UpdateVehicleInformationOutput;
};


export type MutationAddAlarmEventArgs = {
  description?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['Int']>;
  linkUrl?: InputMaybe<Scalars['String']>;
  pushMin?: InputMaybe<Scalars['Int']>;
  targetDevice?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationAddApprovalLineArgs = {
  approvalLineControlType: ApprovalLineControlType;
  approvalLineName: Scalars['String'];
  count: Scalars['Int'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  list: Array<ApprovalLineUserEntity>;
  user: Scalars['String'];
};


export type MutationAddApprovalNotificationDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationAddApprovalNotificationScheduleArgs = {
  approvalTimeType: ApprovalTimeType;
  categoryId: Scalars['Int'];
  dayCount: Scalars['Int'];
  dayType?: InputMaybe<ApprovalTimeType>;
  isApp: IsUsingNotification;
  isMail: IsUsingNotification;
  minCount?: InputMaybe<Scalars['Int']>;
  minType?: InputMaybe<ApprovalTimeType>;
  templateIdx: Scalars['Int'];
};


export type MutationAddApprovalNotificationTemplateArgs = {
  alarmName: Scalars['String'];
};


export type MutationAddApprovalTemporaryStorageArgs = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationAddChatRoomArgs = {
  partnerId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddCommuteDateTimeArgs = {
  checkDateTime: Scalars['String'];
  commuteType: CommuteType;
  deviceType: Device_Type;
  selectedEmployeeId: Scalars['String'];
};


export type MutationAddCommuteGpsInfoArgs = {
  address: Scalars['String'];
  gps: Scalars['String'];
  name: Scalars['String'];
  radius: Scalars['Int'];
};


export type MutationAddEmployeeAppliedToTheTemplateArgs = {
  isExpect: IsException;
  selectedEmployeeId: Scalars['String'];
  templateIdx: Scalars['Float'];
};


export type MutationAddReservationArgs = {
  categoryIdx: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  requestEmployeeId: Scalars['String'];
  reserveTitle: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
};


export type MutationAddReservationCategoryArgs = {
  categoryDescription: Scalars['String'];
  categoryName: Scalars['String'];
  groupIdx: Scalars['Int'];
};


export type MutationAddReservationTypeArgs = {
  groupName: Scalars['String'];
};


export type MutationAddTemplateScheduleInfoArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['String'];
  isFriday?: InputMaybe<Scalars['Int']>;
  isMonday?: InputMaybe<Scalars['Int']>;
  isSaturday?: InputMaybe<Scalars['Int']>;
  isSunday?: InputMaybe<Scalars['Int']>;
  isThursday?: InputMaybe<Scalars['Int']>;
  isTuesDay?: InputMaybe<Scalars['Int']>;
  isWednesday?: InputMaybe<Scalars['Int']>;
  periodDateEnd?: InputMaybe<Scalars['String']>;
  periodDateStart: Scalars['String'];
  startTime: Scalars['String'];
  timeTemplateIdx: Scalars['Int'];
};


export type MutationAddTimeTemplateArgs = {
  timeName: Scalars['String'];
};


export type MutationAddUserFromChatRoomArgs = {
  chatRoomIdx: Scalars['Int'];
  inviteeName?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddVehicleDrivingInformationArgs = {
  categoryId: Scalars['Int'];
  endDateTime: Scalars['String'];
  endEmployeeId: Scalars['String'];
  endGpsAddress: Scalars['String'];
  endKm: Scalars['Int'];
  startDateTime: Scalars['String'];
  startEmployeeId: Scalars['String'];
  startGpsAddress: Scalars['String'];
  startKm: Scalars['Int'];
  vehicleNumber: Scalars['String'];
};


export type MutationAddVehicleInformationArgs = {
  vehicleName: Scalars['String'];
  vehicleNumber: Scalars['String'];
};


export type MutationApplicationApprovalArgs = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationControlApprovalFormArgs = {
  approvalSort: Approval_Sort;
  categoryId: Scalars['Int'];
  controlApprovalFormType: Type_Control_Approval_Form;
  description?: InputMaybe<Scalars['String']>;
  formIdx: Scalars['Int'];
  formTemplateIdx?: InputMaybe<Scalars['Int']>;
  templateName: Scalars['String'];
};


export type MutationDeleteAlarmEventArgs = {
  settingIndex: Scalars['Int'];
};


export type MutationDeleteApprovalLineArgs = {
  lineIdx: Scalars['Int'];
  user: Scalars['String'];
};


export type MutationDeleteApprovalNotificationDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationDeleteApprovalNotificationScheduleArgs = {
  scheduleIdx: Scalars['Int'];
};


export type MutationDeleteApprovalNotificationTemplateArgs = {
  templateIdx: Scalars['Int'];
};


export type MutationDeleteApprovalRequestArgs = {
  approvalRequestIdx: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationDeleteApprovalSignImageOrTextArgs = {
  deleteSignType: SignType;
  employeeId: Scalars['String'];
};


export type MutationDeleteApprovalTemplateFormArgs = {
  listOfFormTemplateIdx: Array<Scalars['Int']>;
};


export type MutationDeleteApprovalTemporaryStorageArgs = {
  approvalRequestIdx: Scalars['Int'];
};


export type MutationDeleteCommuteGpsInfoArgs = {
  areaIdx: Scalars['Int'];
};


export type MutationDeleteEmployeeAppliedArgs = {
  selectedEmployeeId: Scalars['String'];
  templateIdx: Scalars['Float'];
};


export type MutationDeleteReservationArgs = {
  listOfInfoIdx: Array<Scalars['Int']>;
};


export type MutationDeleteReservationCategoryArgs = {
  listOfCategoryIdx: Array<Scalars['Int']>;
};


export type MutationDeleteReservationTypeArgs = {
  listOfGroupIdx: Array<Scalars['Int']>;
};


export type MutationDeleteStatisticsInfoArgs = {
  index: Scalars['Float'];
};


export type MutationDeleteTemplateScheduleInfoArgs = {
  timeIdx: Scalars['Int'];
};


export type MutationDeleteTimeTemplateArgs = {
  timeTemplateIdx: Scalars['Int'];
};


export type MutationDeleteVehicleDrivingInformationArgs = {
  listOfLogIdx: Array<Scalars['Int']>;
};


export type MutationDeleteVehicleInformationArgs = {
  listOfVehicleNumber: Array<Scalars['String']>;
};


export type MutationEditApprovalAdminSettingArgs = {
  count: Scalars['Int'];
  preview: Approval_Admin_Setting_Preview_Type;
};


export type MutationEditApprovalLineArgs = {
  approvalLineControlType: ApprovalLineControlType;
  approvalLineName: Scalars['String'];
  count: Scalars['Int'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  lineIdx: Scalars['Int'];
  list: Array<ApprovalLineUserEntity>;
  user: Scalars['String'];
};


export type MutationLeavingTheRoomArgs = {
  chatRoomIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type MutationRejectApprovalArgs = {
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
};


export type MutationRequestForApprovalArgs = {
  comment: Scalars['String'];
  endAlterWorkDate?: InputMaybe<Scalars['String']>;
  endAlterWorkTime?: InputMaybe<Scalars['String']>;
  endDayOffDate: Scalars['String'];
  endDayOffTime: Scalars['String'];
  listOfApprover: Array<Scalars['String']>;
  overtimeCategoryId: Scalars['String'];
  startAlterWorkDate?: InputMaybe<Scalars['String']>;
  startAlterWorkTime?: InputMaybe<Scalars['String']>;
  startDayOffDate: Scalars['String'];
  startDayOffTime: Scalars['String'];
};


export type MutationSaveApprovalPasswordAndSignSettingArgs = {
  employeeId: Scalars['String'];
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  isSignPassword: Is_Sign_Password;
  signImage?: InputMaybe<Array<Scalars['String']>>;
  signPassword?: InputMaybe<Scalars['String']>;
  signText?: InputMaybe<Scalars['String']>;
  signType: SignType;
};


export type MutationSaveStatisticsInfoArgs = {
  queryContent: QueryContent;
};


export type MutationSignApprovalArgs = {
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
};


export type MutationUpdateAlarmEventArgs = {
  description?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['Int']>;
  linkUrl?: InputMaybe<Scalars['String']>;
  pushMin?: InputMaybe<Scalars['Int']>;
  settingIndex: Scalars['Int'];
  targetDevice?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAlarmEventStatusArgs = {
  alarmUse: AlarmStatus;
};


export type MutationUpdateAnnualEnvInfoArgs = {
  accountingMonth: Scalars['Int'];
  annualAccrualType: Annual_Accrual_Type;
  annualDeductionPriorityType: Scalars['Int'];
  annualFixType: Annual_Fix_Type;
  annualMaxCount: Scalars['Int'];
  companyCode: Scalars['String'];
};


export type MutationUpdateApprovalNotificationScheduleArgs = {
  approvalTimeType: ApprovalTimeType;
  categoryId: Scalars['Int'];
  dayCount: Scalars['Int'];
  dayType?: InputMaybe<ApprovalTimeType>;
  isApp: IsUsingNotification;
  isMail: IsUsingNotification;
  minCount?: InputMaybe<Scalars['Int']>;
  minType?: InputMaybe<ApprovalTimeType>;
  scheduleIdx: Scalars['Int'];
  templateIdx: Scalars['Int'];
};


export type MutationUpdateApprovalNotificationTemplateArgs = {
  alarmName: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationUpdateBasicUserinfoArgs = {
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  joiningDate?: InputMaybe<Scalars['String']>;
  leavingDate?: InputMaybe<Scalars['String']>;
  myPicture: Scalars['String'];
  nameChinese?: InputMaybe<Scalars['String']>;
  nameEnglish?: InputMaybe<Scalars['String']>;
  phoneNum?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userJob?: InputMaybe<Scalars['String']>;
  userPosition?: InputMaybe<Scalars['String']>;
  userRank?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCommuteDateTimeArgs = {
  approvalFlag: ApprovalFlag;
  checkDateTime: Scalars['String'];
  checkDateTimeModify: Scalars['String'];
  selectedEmployeeId: Scalars['String'];
};


export type MutationUpdateCommuteGpsAreaUseTypeArgs = {
  commuteAreaUseType: CommuteAreaUseType;
};


export type MutationUpdateCommuteGpsInfoArgs = {
  address: Scalars['String'];
  areaIdx: Scalars['Int'];
  gps: Scalars['String'];
  name: Scalars['String'];
  radius: Scalars['Int'];
};


export type MutationUpdateInfoLessThanYearArgs = {
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  companyCode: Scalars['String'];
  fixCount: Scalars['Float'];
  minusAnnualDayCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  monthAccrualType: Month_Accrual_Type;
};


export type MutationUpdateInfoMoreThanAYearArgs = {
  addedDayCount: Scalars['Int'];
  addedMaxDayCount: Scalars['Int'];
  addedYearCount: Scalars['Int'];
  carriedforwardRangeType: Carriedforward_Range_Type;
  carriedforwardType: Carriedforward_Type;
  companyCode: Scalars['String'];
  defaultDayCount: Scalars['Int'];
  minusAnnualDayCount: Scalars['Int'];
  minusAnnualType: Minus_Annual_Type;
};


export type MutationUpdateInfoNextYearOfEmploymentArgs = {
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  carriedforwardRangeType: Carriedforward_Range_Type;
  carriedforwardType: Carriedforward_Type;
  companyCode: Scalars['String'];
  fixCount: Scalars['Float'];
  minusAnnualDayCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type;
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
};


export type MutationUpdatePlaceFreeManagementArgs = {
  categoryId: Scalars['Float'];
  employeeId: Scalars['String'];
  endDateTime: Scalars['String'];
  leftSeatLogIdx: Scalars['Float'];
  logAttendee?: InputMaybe<Scalars['String']>;
  logDesc?: InputMaybe<Scalars['String']>;
  logPlace: Scalars['String'];
  startDateTime: Scalars['String'];
};


export type MutationUpdateProcessingInQuiryResultArgs = {
  approvalRequestIndex: Scalars['String'];
  approvalResult: Rejected_Or_Approved_Or_Others;
  approverId: Scalars['String'];
  comment: Scalars['String'];
};


export type MutationUpdateReservationArgs = {
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  infoIdx: Scalars['Int'];
  requestEmployeeId: Scalars['String'];
  reserveTitle: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
};


export type MutationUpdateReservationCategoryArgs = {
  categoryDescription: Scalars['String'];
  categoryIdx: Scalars['Int'];
  categoryName: Scalars['String'];
};


export type MutationUpdateReservationTypeArgs = {
  groupIdx: Scalars['Int'];
  groupName: Scalars['String'];
};


export type MutationUpdateTemplateScheduleInfoArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['String'];
  isFriday?: InputMaybe<Scalars['Int']>;
  isMonday?: InputMaybe<Scalars['Int']>;
  isSaturday?: InputMaybe<Scalars['Int']>;
  isSunday?: InputMaybe<Scalars['Int']>;
  isThursday?: InputMaybe<Scalars['Int']>;
  isTuesDay?: InputMaybe<Scalars['Int']>;
  isWednesday?: InputMaybe<Scalars['Int']>;
  periodDateEnd?: InputMaybe<Scalars['String']>;
  periodDateStart: Scalars['String'];
  startTime: Scalars['String'];
  timeIdx: Scalars['Int'];
};


export type MutationUpdateTimeTemplateArgs = {
  timeName: Scalars['String'];
  timeTemplateIdx: Scalars['Int'];
};


export type MutationUpdateUserAnnualInfoArgs = {
  addDay: Scalars['Int'];
  applyYear: Scalars['String'];
  basicDay: Scalars['Int'];
  basicModifyDay: Scalars['Int'];
  createSubject: Scalars['String'];
  createSubjectEmployeeId: Scalars['String'];
  employeeId: Scalars['String'];
  leaveCarriedForwardDay: Scalars['Int'];
  modifyReason?: InputMaybe<Scalars['String']>;
  periodateEnd: Scalars['String'];
  periodateStart: Scalars['String'];
  statusFlag?: InputMaybe<Scalars['String']>;
  useModifyDay: Scalars['Int'];
};


export type MutationUpdateVehicleDrivingInformationArgs = {
  categoryId: Scalars['Int'];
  endDateTime: Scalars['String'];
  endEmployeeId: Scalars['String'];
  endGpsAddress: Scalars['String'];
  endKm: Scalars['Int'];
  logIdx: Scalars['Int'];
  startDateTime: Scalars['String'];
  startEmployeeId: Scalars['String'];
  startGpsAddress: Scalars['String'];
  startKm: Scalars['Int'];
};


export type MutationUpdateVehicleInformationArgs = {
  updateVehicleNumber: Scalars['String'];
  vehicleName: Scalars['String'];
  vehicleNumber: Scalars['String'];
};

/** 결재 양식 */
export type OtApprovalFormEntity = {
  __typename?: 'OtApprovalFormEntity';
  /** 결재 양식 idx */
  formIdx: Scalars['Int'];
  /** 결재 양식 이름 */
  formName: Scalars['String'];
};

/** 초과 근무 카테고리 정보 */
export type OtTimeTypeCategory = {
  __typename?: 'OtTimeTypeCategory';
  /** alLocateDay */
  alLocateDay?: Maybe<Scalars['Int']>;
  /** alLocateDayType */
  alLocateDayType: Scalars['Int'];
  /** beforeEndCtrlMsgTemplateIdx */
  beforeEndCtrlMsgTemplateIdx?: Maybe<Scalars['Int']>;
  /** beforeEndWorkingMin */
  beforeEndWorkingMin?: Maybe<Scalars['Int']>;
  /** category ID */
  categoryId: Scalars['Int'];
  /** ctrlMsgTemplateIdx */
  ctrlMsgTemplateIdx?: Maybe<Scalars['Int']>;
  /** fpProcess */
  fpProcess: Scalars['Int'];
  /** fpProcessTwo */
  fpProcessTwo: Scalars['Int'];
  /** isBasicData */
  isBasicData?: Maybe<Scalars['Int']>;
  /** leftSeatApplyTimeUse */
  leftSeatApplyTimeUse: Scalars['Int'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** policyUpdateStatus */
  policyUpdateStatus: Scalars['Int'];
  /** policyUpdateStatusTwo */
  policyUpdateStatusTwo: Scalars['Int'];
  /** priorityRank */
  priorityRank: Scalars['Int'];
  /** timeCalcType */
  timeCalcType: Scalars['Int'];
  /** useTableType */
  useTableType: Scalars['Int'];
};

/** 근무 시간 타입 */
export type OtTimeTypeCategoryEntity = {
  __typename?: 'OtTimeTypeCategoryEntity';
  /** 근무 시간 타입 id */
  categoryId: Scalars['Int'];
  /** 근무 시간 타입 이름 */
  name: Scalars['String'];
};

/** PC 정보 */
export type PcInformation = {
  __typename?: 'PcInformation';
  /** autoUpdateFlag */
  autoUpdateFlag: IsEnable;
  /** 컴퓨터 이름 */
  computerName: Scalars['String'];
  /** 정책 사용 여부 */
  controlFlag: IsControl;
  /** 부서명 */
  departName: Scalars['String'];
  /** 부서 아이디 */
  departmentId: Scalars['String'];
  /** 컴퓨터 설명 */
  description?: Maybe<Scalars['String']>;
  /** 장치 이름 */
  deviceName: Scalars['String'];
  /** 장치 타입 */
  deviceType?: Maybe<Scalars['Int']>;
  /** 사용자 아이디 */
  employeeId?: Maybe<Scalars['String']>;
  /** endpoint 아이디 */
  endpointId: Scalars['String'];
  /** 전체 컴퓨터 이름 */
  fullName?: Maybe<Scalars['String']>;
  /** OS 설치날짜 */
  installDate?: Maybe<Scalars['String']>;
  /** 온라인 상태 */
  isOnOff?: Maybe<IsOnOff>;
  /** 마지막 부팅 시간 */
  lastBootTime: Scalars['String'];
  /** 로컬아이피 */
  localIp: Scalars['String'];
  /** 로컬아이피2 */
  localIp2?: Maybe<Scalars['String']>;
  /** 로컬아이피3 */
  localIp3?: Maybe<Scalars['String']>;
  /** 로컬 Mac */
  localMac: Scalars['String'];
  /** 로컬 Mac2 */
  localMac2?: Maybe<Scalars['String']>;
  /** 로컬 Mac3 */
  localMac3?: Maybe<Scalars['String']>;
  /** 관리자 메모2 */
  memo1?: Maybe<Scalars['String']>;
  /** 관리자 메모2 */
  memo2?: Maybe<Scalars['String']>;
  /** 성명 */
  name?: Maybe<Scalars['String']>;
  /** 새 데이터 */
  newData?: Maybe<Scalars['String']>;
  /** 설치 시간 */
  newDataTime: Scalars['String'];
  /** OS Bit */
  osBit?: Maybe<Scalars['String']>;
  /** OS 이름 */
  osName?: Maybe<Scalars['String']>;
  /** 플랫폼 타입 */
  platformType?: Maybe<Scalars['Int']>;
  /** redirectSignFlag */
  redirectSignFlag: IsEnable;
  /** selfProtectFlag */
  selfProtectFlag: IsEnable;
  /** verDiffCount */
  verDiffCount?: Maybe<Scalars['Float']>;
  /** 작업 그룹 */
  workGroup: Scalars['String'];
  /** 근무 정책 */
  workingName?: Maybe<Scalars['String']>;
  /** 작업 템플릿 Idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 개인 근무 템플릿 */
export type PersonalTimeTemplate = {
  __typename?: 'PersonalTimeTemplate';
  /** 부서 이름 */
  departmentName?: Maybe<Scalars['String']>;
  /** 사용자 ID */
  employeeId?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  employeeName?: Maybe<Scalars['String']>;
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

/** 이석관리 정보 타입 */
export type PlaceFreeManagement = {
  __typename?: 'PlaceFreeManagement';
  /** 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 수정된 카테고리 이름 */
  categoryNameModify?: Maybe<Scalars['String']>;
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 이석 종료 날짜 및 시간 */
  endDateTime: Scalars['String'];
  /** 수정된 이석 종료 날짜 및 시간 */
  endDateTimeModify?: Maybe<Scalars['String']>;
  /** 이석관리 테이블 Idx */
  leftSeatLogIdx: Scalars['String'];
  /** 이석 참여자 */
  logAttendee: Scalars['String'];
  /** 수정된 이석 참여자 */
  logAttendeeModify?: Maybe<Scalars['String']>;
  /** 이석 사유 */
  logDesc: Scalars['String'];
  /** 수정된 이석 사유 */
  logDescModify?: Maybe<Scalars['String']>;
  /** 이석 장소 */
  logPlace: Scalars['String'];
  /** 수정된 이석 장소 */
  logPlaceModify?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name: Scalars['String'];
  /** 이석 시작 날짜 및 시간 */
  startDateTime: Scalars['String'];
  /** 수정된 이석 시작 날짜 및 시간 */
  startDateTimeModify?: Maybe<Scalars['String']>;
};

/** 이석관리 정보 폼 타입 */
export type PlaceFreeManagementForm = {
  __typename?: 'PlaceFreeManagementForm';
  /** 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 이석 종료 날짜 및 시간 */
  endDateTime: Scalars['String'];
  /** 이석관리 테이블 Idx */
  leftSeatLogIdx: Scalars['String'];
  /** 이석 참여자 */
  logAttendee?: Maybe<Scalars['String']>;
  /** 이석 사유 */
  logDesc?: Maybe<Scalars['String']>;
  /** 이석 장소 */
  logPlace: Scalars['String'];
  /** 이석 시작 날짜 및 시간 */
  startDateTime: Scalars['String'];
};

/** 이석관리정보 타입 정보 */
export type PlaceFreeManagementFormType = {
  __typename?: 'PlaceFreeManagementFormType';
  categoryId: Scalars['Float'];
  categoryName: Scalars['String'];
};

/** 이석 관리 정보 폼 데이터 요청 결과 */
export type PlaceFreeManagementOutput = {
  __typename?: 'PlaceFreeManagementOutput';
  error?: Maybe<Scalars['String']>;
  /** 이석 관리 정보 폼 데이터 */
  formData?: Maybe<PlaceFreeManagementForm>;
  /** 이석관리 폼 타입 목록 */
  listOfFormType?: Maybe<Array<PlaceFreeManagementFormType>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 - 처리및조회 정보 데이터 타입 */
export type ProcessingInquiry = {
  __typename?: 'ProcessingInquiry';
  /** 결재 문서 idx */
  approvalRequestIdx: Scalars['String'];
  /** 근무 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 결재처리 사유 */
  comment?: Maybe<Scalars['String']>;
  /** 결재 대기자 */
  currentApproverName?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 부서명 */
  departmentName?: Maybe<Scalars['String']>;
  /** 요청된 사용자 ID */
  employeeIdRequest: Scalars['String'];
  /** 신청사유 */
  extendDescription: Scalars['String'];
  /** 신청 종료일 */
  extendEndDate?: Maybe<Scalars['String']>;
  /** 신청 종료시간 */
  extendEndDateTime: Scalars['String'];
  /** 신청 시작일 */
  extendStartDate?: Maybe<Scalars['String']>;
  /** 신청 시작시간 */
  extendStartDateTime: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  oesExtendEndDate?: Maybe<Scalars['String']>;
  oesExtendEndDateTime?: Maybe<Scalars['String']>;
  oesExtendStartDate?: Maybe<Scalars['String']>;
  oesExtendStartDateTime?: Maybe<Scalars['String']>;
  /** 결재 상태 값 */
  originResultValue?: Maybe<Scalars['Float']>;
  overTimeCategoryTimeCalcType?: Maybe<Scalars['Float']>;
  /** 연장근무 카테고리 이름 */
  overtimeCategoryName?: Maybe<Scalars['String']>;
  /** 결재 일시 */
  resultDateTime?: Maybe<Scalars['String']>;
  /** 결재비고 */
  resultDescription?: Maybe<Scalars['String']>;
  /** 결재 상태 */
  resultValue?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTime?: Maybe<Scalars['String']>;
};

/** 처리 및 조회 검색 타입 */
export enum ProcessingInquirySearchType {
  EmployeeId = 'EMPLOYEE_ID',
  Name = 'NAME'
}

/** 근무 템플릿(공용) */
export type PublicTimeTemplate = {
  __typename?: 'PublicTimeTemplate';
  /** 템플릿 이름 */
  timeName?: Maybe<Scalars['String']>;
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  confirmIsApprovalPassword: ConfirmIsApprovalPasswordOutput;
  countListEmployeeAppliedToTheTemplate: CountListOfEmployeeAppliedToTheTemplateOutput;
  countListOfApproval: CountListOfApprovalOutput;
  countListOfApprovalTemporaryStorage: CountListOfApprovalTemporaryStorageOutput;
  countListOfChatRoom: CountListOfChatRoomOutput;
  countListOfCommuteGpsInfo: CountListOfCommuteGpsInfoOutput;
  countListOfEmployeeCommuteManagement: CountListOfEmployeeCommuteManagementOutput;
  countListOfEmployeeInformation: CountListOfEmployeeInformationOutput;
  countListOfPcInformation: CountListOfPcInformationOutput;
  countListOfPlaceFreeManagement: CountListOfPlaceFreeManagementOutput;
  countListOfProcessingInquiry: CountListOfProcessingInquiryOutput;
  countListOfRequestInquiry: CountListOfRequestInquiryOutput;
  countListOfWorkManagement: CountListOfWorkManagementOutput;
  countStatisticsInfo: CountStatisticsInfoOutput;
  countlistOfApprovalNotificationDepartment: CountListOfApprovalNotificationDepartmentOutput;
  dashBoardExcel: DashBoardExcelExcelOutput;
  downloadAnnualHistoryAsExcel: DownloadAnnualHistoryAsExcelOutput;
  downloadExcelListOfReservationDetailInformation: DownloadExcelListOfReservationDetailInformationOutput;
  downloadListOfEmployeeAppliedToTheTemplateExcel: DownloadListOfEmployeeAppliedToTheTemplateExcelOutput;
  downloadListOfEmployeeCommuteManagementExcel: DownloadListOfEmployeeCommuteManagementExcelOutput;
  downloadListOfEmployeeInformationExcel: DownloadListOfEmployeeInformationExcelOutput;
  downloadListOfPcInformationExcel: DownloadListOfPcInformationExcelOutput;
  downloadListOfProcessingInquiryToExcel: DownloadListOfProcessingInquiryToExcelOutput;
  downloadListOfRequestInquiryToExcel: DownloadListOfRequestInquiryToExcelOutput;
  downloadListOfReservationInformation: DownloadExcelListOfReservationInformationOutput;
  downloadListOfWorkManagementExcel: DownloadListOfWorkManagementExcelOutput;
  downloadListOfePlaceFreeManagementExcel: DownloadListOfPlaceFreeManagementExcelOutput;
  downloadStatisticsToExcel: DownloadStatisticsToExcelOutput;
  downloadVehicleDrivingInformationExcel: DownloadVehicleDrivingInformationExcelOutput;
  downloadVehicleInformationExcel: DownloadVehicleInformationExcelOutput;
  downloadlistOfApprovalNotificationDepartmentExcel: DownloadListOfApprovalNotificationDepartmentExcelOutput;
  getAlarmEventStatus: GetAlarmEventStatusOutput;
  getAllEmployeeInformationInDepartment: GetEmployeeInformationOutput;
  getAllEmployeeInformationInDepartmentForAllEmployees: GetEmployeeInformationOutput;
  getAnnualEnvInfo: GetAnnualEnvInfoOutput;
  getAnnualHistory: GetAnnualHistoryOutput;
  getApprovalAdminSetting: GetApprovalAdminSettingOutput;
  getApprovalFormDetail: GetApprovalFormDetailOutput;
  getApprovalPasswordAndSignSettingInfo: GetApprovalPasswordAndSignSettingInfoOutput;
  getApprovalTemporaryStorageDetailInformation: GetApprovalTemporaryStorageDetailInformationOutput;
  getBasicUserInfo: GetBasicUserInfoOutput;
  getChatServerIp: GetChatServerIpOutput;
  getCommuteGpsAreaUseType: GetCommuteGpsAreaUseTypeOutput;
  getCompanyList: GetCompanyListOutput;
  getDashBoard: GetDashBoardOutput;
  getDashBoardTotal: GetDashBoardTotalOutput;
  getEmployeeInformation: GetEmployeeInformationOutput;
  getGroups: GetGroupOutput;
  getGroupsForAll: GetGroupOutput;
  getInfoLessThanAYear: GetInfoLessThanAYearOutput;
  getInfoMoreThanAYear: GetInfoMoreThanAYearOutput;
  getInfoNextYearOfEmployment: GetInfoNextYearOfEmploymentOutput;
  getListOfAlarm: GetListOfAlarmOutput;
  getListOfApproval: ListOfApprovalOutput;
  getListOfApprovalBasicLineUser: GetListOfApprovalBasicLineUserOutput;
  getListOfApprovalForm: GetListOtApprovalFormOutput;
  getListOfApprovalHistory: ApprovalHistoryOutput;
  getListOfApprovalLine: GetListOfApprovalLineOutput;
  getListOfApprovalLineForm: GetListOfApprovalLineFormOutput;
  getListOfApprovalNotificationScheduleByTemplate: GetListOfApprovalNotificationScheduleByTemplateOutput;
  getListOfApprovalNotificationTemplate: GetListOfApprovalNotificationTemplateOutput;
  getListOfApprovalTemplate: GetListOfApprovalTemplateOutput;
  getListOfApprovalTemporaryStorage: GetListOfApprovalTemporaryStorageOutput;
  getListOfChatImage: GetListOfChatImageOutput;
  getListOfChatMessage: GetListOfChatMessageOutput;
  getListOfChatRoom: GetListOfChatRoomOutput;
  getListOfCommuteGpsInfo: GetListOfCommuteGpsInfoOutput;
  getListOfDepartmentForChat: GetListOfDepartmentForChatOutput;
  getListOfOtTimeTypeCategory: GetListOfOtTImeTypeCategoryOutput;
  getListOfOtTimeTypeCategoryForVehicle: GetListOfOtTimeTypeCategoryForVehicleOutput;
  getListOfProcessingInquiry: GetListOfProcessingInquiryOutput;
  getListOfRequestInquiry: GetListOfRequestInquiryOutput;
  getListOfReservationCategory: GetListOfReservationCategoryOutput;
  getListOfReservationDetailInformation: GetListOfReservationDetailInformationOutput;
  getListOfReservationInformation: GetListOfReservationInformationOutput;
  getListOfReservationType: GetListOfReservationTypeOutput;
  getListOfSearchedApprovalLineUser: GetListOfSearchedApprovalLineUserOutput;
  getListOfTemplateScheduleInfo: GetListOfTemplateScheduleInfoOutput;
  getListOfTimeTypeCategory: GetListOfTimeTypeCategoryOutput;
  getListOfVehicleDrivingInformation: GetListOfVehicleDrivingInformationOutput;
  getListOfVehicleInformation: GetListOfVehicleInformationOutput;
  getPcInformation: GetPcInformationOutput;
  getPersonalTimeTemplate: GetPersonalTimeTemplateOutput;
  getPublicTimeTemplate: GetPublicTimeTemplateOutput;
  getRecognitionWorkTime: GetRecognitionWorkTimeOutput;
  getTotalListOfReservationDetailInformation: GetTotalListOfReservationDetailInformationOutput;
  getTotalListOfReservationType: GetToTalListOfReservationTypeOutput;
  getTotalNumberOfVehicleDrivingInformation: GetTotalNumberOfVehicleDrivingInformationOutput;
  getTotalNumberOfVehicleInformation: GetTotalNumberOfVehicleInformationOutput;
  getUser: GetUserOutput;
  getUserAnnualInfo: GetUserAnnualInfoOutput;
  isActiveApprovalPassword: IsActiveApprovalPasswordOutput;
  listOfApprovalNotificationDepartment: ListOfApprovalNotificationDepartmentOutput;
  listOfEmployeeAppliedToTheTemplate: ListOfEmployeesAppliedToTheTemplateOutput;
  listOfEmployeeCommuteInformation: ListOfEmployeeCommuteManagementOutput;
  listOfPlaceFreeManagement: ListOfPlaceFreeManagementOutput;
  listOfWorkManagement: ListOfWorkManagementOutput;
  placeFreeManagementForm: PlaceFreeManagementOutput;
  statisticsInfo: StatisticsInfoOutput;
};


export type QueryConfirmIsApprovalPasswordArgs = {
  password: Scalars['String'];
};


export type QueryCountListEmployeeAppliedToTheTemplateArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Float'];
};


export type QueryCountListOfApprovalArgs = {
  approvalType: Approval_For_List_Type;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryCountListOfApprovalTemporaryStorageArgs = {
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryCountListOfChatRoomArgs = {
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCountListOfCommuteGpsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCountListOfEmployeeCommuteManagementArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryCountListOfEmployeeInformationArgs = {
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryCountListOfPcInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryCountListOfPlaceFreeManagementArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryCountListOfProcessingInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  originResultValue: Scalars['String'];
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryCountListOfRequestInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryCountListOfWorkManagementArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryCountStatisticsInfoArgs = {
  queryContent: QueryContent;
};


export type QueryCountlistOfApprovalNotificationDepartmentArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Int'];
};


export type QueryDashBoardExcelArgs = {
  date?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
};


export type QueryDownloadAnnualHistoryAsExcelArgs = {
  applyYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryDownloadExcelListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadListOfEmployeeAppliedToTheTemplateExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Float'];
};


export type QueryDownloadListOfEmployeeCommuteManagementExcelArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDownloadListOfEmployeeInformationExcelArgs = {
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryDownloadListOfPcInformationExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryDownloadListOfProcessingInquiryToExcelArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  originResultValue: Scalars['String'];
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryDownloadListOfRequestInquiryToExcelArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryDownloadListOfReservationInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadListOfWorkManagementExcelArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryDownloadListOfePlaceFreeManagementExcelArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDownloadStatisticsToExcelArgs = {
  queryContent: QueryContent;
};


export type QueryDownloadVehicleDrivingInformationExcelArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type QueryDownloadVehicleInformationExcelArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadlistOfApprovalNotificationDepartmentExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Int'];
};


export type QueryGetAllEmployeeInformationInDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
};


export type QueryGetAllEmployeeInformationInDepartmentForAllEmployeesArgs = {
  selectedDepartmentId: Scalars['String'];
};


export type QueryGetAnnualEnvInfoArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetAnnualHistoryArgs = {
  applyYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryGetApprovalFormDetailArgs = {
  approvalRequestIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type QueryGetApprovalPasswordAndSignSettingInfoArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetApprovalTemporaryStorageDetailInformationArgs = {
  approvalRequestIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type QueryGetBasicUserInfoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetDashBoardArgs = {
  date?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['String'];
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetDashBoardTotalArgs = {
  date?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['String'];
  employeeId: Scalars['String'];
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
};


export type QueryGetEmployeeInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetGroupsArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetInfoLessThanAYearArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetInfoMoreThanAYearArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetInfoNextYearOfEmploymentArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetListOfApprovalArgs = {
  approvalType: Approval_For_List_Type;
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfApprovalBasicLineUserArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalHistoryArgs = {
  approvalRequestIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalLineArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalLineFormArgs = {
  employeeId: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalNotificationScheduleByTemplateArgs = {
  templateIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalTemplateArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalTemporaryStorageArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfChatImageArgs = {
  chatroom_idx: Scalars['Int'];
  imageCount?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfChatMessageArgs = {
  chatRoomIdx: Scalars['Int'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};


export type QueryGetListOfChatRoomArgs = {
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfCommuteGpsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfDepartmentForChatArgs = {
  parentDepartmentId: Scalars['Int'];
};


export type QueryGetListOfProcessingInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  originResultValue: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfRequestInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfReservationCategoryArgs = {
  groupIdx: Scalars['Int'];
};


export type QueryGetListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfReservationInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfSearchedApprovalLineUserArgs = {
  employeeId: Scalars['String'];
  lineIdx: Scalars['Int'];
};


export type QueryGetListOfTemplateScheduleInfoArgs = {
  timeTemplateIdx: Scalars['Int'];
};


export type QueryGetListOfVehicleDrivingInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  vehicleNumber: Scalars['String'];
};


export type QueryGetListOfVehicleInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetPcInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetPersonalTimeTemplateArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetRecognitionWorkTimeArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalListOfReservationTypeArgs = {
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalNumberOfVehicleDrivingInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type QueryGetTotalNumberOfVehicleInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserAnnualInfoArgs = {
  annualYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryListOfApprovalNotificationDepartmentArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  templateIdx: Scalars['Int'];
};


export type QueryListOfEmployeeAppliedToTheTemplateArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  templateIdx: Scalars['Float'];
};


export type QueryListOfEmployeeCommuteInformationArgs = {
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  listOfEmployeeId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryListOfPlaceFreeManagementArgs = {
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  listOfEmployeeId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryListOfWorkManagementArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryPlaceFreeManagementFormArgs = {
  leftSeatLogIdx: Scalars['String'];
};


export type QueryStatisticsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  queryContent: QueryContent;
  take?: InputMaybe<Scalars['Float']>;
};

/** QueryContent 정보 */
export type QueryContent = {
  /** 기본 쿼리 */
  base_query?: InputMaybe<Scalars['String']>;
  /** 부서 정보 */
  departments: Array<Scalars['Float']>;
  /** 상세 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 사용자 정보 */
  employees: Array<Scalars['String']>;
  /** 종료 날짜 */
  end_date?: InputMaybe<Scalars['String']>;
  /** Index */
  index: Scalars['Float'];
  /** 시작 날짜 */
  start_date?: InputMaybe<Scalars['String']>;
  /** 쿼리 이름 */
  title?: InputMaybe<Scalars['String']>;
};

/** 결재 처리를 위한 값 */
export enum Rejected_Or_Approved_Or_Others {
  Approved = 'APPROVED',
  Reject = 'REJECT',
  RejectedByOthers = 'REJECTED_BY_OTHERS'
}

/** 인정근무시간 정보 */
export type RecognitionWorkTimeEntity = {
  __typename?: 'RecognitionWorkTimeEntity';
  /** 실제 연장근무시간 */
  realextmin?: Maybe<Scalars['String']>;
  /** 실제 근무시간 */
  realworkmin?: Maybe<Scalars['String']>;
};

/** 결재 반려 요청 결과 */
export type RejectApprovalOutput = {
  __typename?: 'RejectApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 신청 요청 결과 */
export type RequestForApprovalOutput = {
  __typename?: 'RequestForApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 - 처리및조회 정보 데이터 타입 */
export type RequestInquiry = {
  __typename?: 'RequestInquiry';
  /** 결재 문서 idx */
  approvalRequestIdx: Scalars['String'];
  /** 근무 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 결재처리 사유 */
  comment?: Maybe<Scalars['String']>;
  /** 결재 대기자 */
  currentApproverName?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 부서명 */
  departmentName?: Maybe<Scalars['String']>;
  /** 요청된 사용자 ID */
  employeeIdRequest: Scalars['String'];
  /** 신청사유 */
  extendDescription: Scalars['String'];
  /** 신청 종료일 */
  extendEndDate?: Maybe<Scalars['String']>;
  /** 신청 종료시간 */
  extendEndDateTime: Scalars['String'];
  /** 신청 시작일 */
  extendStartDate?: Maybe<Scalars['String']>;
  /** 신청 시작시간 */
  extendStartDateTime: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  oesExtendEndDate?: Maybe<Scalars['String']>;
  oesExtendEndDateTime?: Maybe<Scalars['String']>;
  oesExtendStartDate?: Maybe<Scalars['String']>;
  oesExtendStartDateTime?: Maybe<Scalars['String']>;
  /** 결재 상태 값 */
  originResultValue?: Maybe<Scalars['Float']>;
  overTimeCategoryTimeCalcType?: Maybe<Scalars['Float']>;
  /** 연장근무 카테고리 이름 */
  overtimeCategoryName?: Maybe<Scalars['String']>;
  /** 결재 일시 */
  resultDateTime?: Maybe<Scalars['String']>;
  /** 결재비고 */
  resultDescription?: Maybe<Scalars['String']>;
  /** 결재 상태 */
  resultValue?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTime?: Maybe<Scalars['String']>;
};

/** 예약 목록 entity */
export type ReservationCategoryEntity = {
  __typename?: 'ReservationCategoryEntity';
  /** 예약목록 상세 설명 */
  categoryDescription: Scalars['String'];
  /** 예약목록 IDX */
  categoryIdx: Scalars['Int'];
  /** 예약 목록 이름 */
  categoryName: Scalars['String'];
  /** 예약그룹 IDX */
  groupIdx: Scalars['Int'];
};

/** 예약상세정보 */
export type ReservationDetailInformationEntity = {
  __typename?: 'ReservationDetailInformationEntity';
  /** 예약목록 이름 */
  categoryName?: Maybe<Scalars['String']>;
  /** 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 예약종료일 */
  endDate?: Maybe<Scalars['String']>;
  /** 예약종료시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 예약그룹 이름 */
  groupName?: Maybe<Scalars['String']>;
  /** 신청 IDX */
  infoIdx: Scalars['Int'];
  /** 신청사원이름 */
  name?: Maybe<Scalars['String']>;
  /** 신청사번 */
  requestEmployeeId?: Maybe<Scalars['String']>;
  /** 예약명/목적 */
  reserveTitle?: Maybe<Scalars['String']>;
  /** 예약시작일 */
  startDate?: Maybe<Scalars['String']>;
  /** 예약시작시간 */
  startTime?: Maybe<Scalars['String']>;
};

/** 예약 정보 */
export type ReservationInformationEntity = {
  __typename?: 'ReservationInformationEntity';
  /** 카테고리 상세설명 */
  categoryDescription?: Maybe<Scalars['String']>;
  /** 카테고리 번호 */
  categoryIdx: Scalars['Int'];
  /** 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 그룹 IDX */
  groupIdx: Scalars['Int'];
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 부모그룹 IDX */
  parentGroupIdx: Scalars['Int'];
};

/** 예약 종류 */
export type ReservationTypeEntity = {
  __typename?: 'ReservationTypeEntity';
  /** 그룹 IDX */
  groupIdx: Scalars['Int'];
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 부모그룹 IDX */
  parentGroupIdx: Scalars['Int'];
};

/** DashBoard 시간에 따른 검색을 위한 타입 */
export enum Search_Date_Type_For_Dash_Board {
  LessThan_6Hours = 'LESS_THAN_6_HOURS',
  LessThan_34Hours = 'LESS_THAN_34_HOURS',
  LessThan_40Hours = 'LESS_THAN_40_HOURS',
  MoreThan_6HoursLessThan_8Hours = 'MORE_THAN_6_HOURS_LESS_THAN_8_HOURS',
  MoreThan_8HoursLessThan_10Hours = 'MORE_THAN_8_HOURS_LESS_THAN_10_HOURS',
  MoreThan_10HoursLessThan_12Hours = 'MORE_THAN_10_HOURS_LESS_THAN_12_HOURS',
  MoreThan_12Hours = 'MORE_THAN_12_HOURS',
  MoreThan_34HoursLessThan_36Hours = 'MORE_THAN_34_HOURS_LESS_THAN_36_HOURS',
  MoreThan_36HoursLessThan_38Hours = 'MORE_THAN_36_HOURS_LESS_THAN_38_HOURS',
  MoreThan_38HoursLessThan_40Hours = 'MORE_THAN_38_HOURS_LESS_THAN_40_HOURS',
  MoreThan_40Hours = 'MORE_THAN_40_HOURS',
  MoreThan_40HoursLessThan_45Hours = 'MORE_THAN_40_HOURS_LESS_THAN_45_HOURS',
  MoreThan_45HoursLessThan_50Hours = 'MORE_THAN_45_HOURS_LESS_THAN_50_HOURS',
  MoreThan_50HoursLessThan_52Hours = 'MORE_THAN_50_HOURS_LESS_THAN_52_HOURS',
  MoreThan_52Hours = 'MORE_THAN_52_HOURS'
}

/** DashBoard DB 검색을 위한 타입 */
export enum Search_Db_Type_For_Dash_Board {
  All = 'all',
  DataPerio = 'dataPerio',
  DepartName = 'departName',
  EmpId = 'empId',
  EmpName = 'empName',
  NameOne = 'nameOne',
  NameThree = 'nameThree',
  NameTwo = 'nameTwo',
  OtExtTime = 'otExtTime',
  OtExtTimeSection = 'otExtTimeSection',
  OtTotTime = 'otTotTime',
  OtTotTimeSection = 'otTotTimeSection',
  OtWorkTime = 'otWorkTime',
  OtWorkTimeSection = 'otWorkTimeSection',
  WorkOx = 'workOx'
}

/** 예약상세정보 검색 타입 */
export enum Search_Type_For_Reservation_Detail_Information_Type {
  CategoryName = 'categoryName',
  EndDate = 'endDate',
  GroupName = 'groupName',
  Name = 'name',
  StartDate = 'startDate'
}

/** 예약정보 검색 타입 */
export enum Search_Type_For_Reservation_Information_Type {
  CategoryDescription = 'categoryDescription',
  CategoryName = 'categoryName',
  GroupName = 'groupName'
}

/** 차량 주행 정보 검색을 위한 타입 */
export enum Search_Type_For_Vehicle_Driving_Information {
  EndEmployeeId = 'endEmployeeId',
  EndEmployeeName = 'endEmployeeName',
  EndGpsAddress = 'endGpsAddress',
  StartEmployeeId = 'startEmployeeId',
  StartEmployeeName = 'startEmployeeName',
  StartGpsAddress = 'startGpsAddress',
  VehicleName = 'vehicleName',
  VehicleNumber = 'vehicleNumber'
}

/** 차량 정보 검색을 위한 타입 */
export enum Search_Type_For_Vehicle_Information {
  VehicleCompany = 'vehicleCompany',
  VehicleName = 'vehicleName',
  VehicleNumber = 'vehicleNumber'
}

/** 결재 비밀번호 및 서명 설정 정보 저장 요청 결과 */
export type SaveApprovalPasswordAndSignSettingOutput = {
  __typename?: 'SaveApprovalPasswordAndSignSettingOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 저장 요청 결과 */
export type SaveStatisticsInfoOutput = {
  __typename?: 'SaveStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 저장된 통계 정보 ID */
  id?: Maybe<Scalars['Float']>;
  ok: Scalars['Boolean'];
};

/** 결재 승인 요청 결과 */
export type SignApprovalOutput = {
  __typename?: 'SignApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 서명 타입 */
export enum SignType {
  Image = 'IMAGE',
  Text = 'TEXT'
}

/** 컬럼 정렬 타입 */
export enum SortColumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** 통계 정보 요청 결과 */
export type StatisticsInfoOutput = {
  __typename?: 'StatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계 정보 */
  list?: Maybe<Array<Scalars['JSONObject']>>;
  ok: Scalars['Boolean'];
};

/** 결재 템플릿 추가, 수정, 삭제를 위한 타입 */
export enum Type_Control_Approval_Form {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

/** 정책적용된 부서 검색 타입 */
export enum Type_Of_Approval_Notification_Department {
  AlarmName = 'alarmName',
  DepartName = 'departName'
}

/** 정책적용직원 검색 타입 */
export enum Type_Of_Employee_Applied_To_The_Template {
  AlarmName = 'alarmName',
  DepartName = 'departName',
  EmployeeId = 'employeeId',
  IsExpect = 'isExpect',
  Name = 'name'
}

/** 직원정보 타입 */
export enum Type_Of_Employee_Information {
  ApprovalDefine = 'approvalDefine',
  AsTimeTemplateName = 'asTimeTemplateName',
  Col1 = 'col1',
  Col2 = 'col2',
  Col3 = 'col3',
  Col4 = 'col4',
  Col5 = 'col5',
  Col6 = 'col6',
  Col7 = 'col7',
  Col8 = 'col8',
  Col9 = 'col9',
  Col10 = 'col10',
  Col11 = 'col11',
  Col12 = 'col12',
  Col13 = 'col13',
  Col14 = 'col14',
  Col15 = 'col15',
  DepartName = 'departName',
  DepartmentId = 'departmentId',
  EmployeeId = 'employeeId',
  EndpointCount = 'endpointCount',
  FullDepartmentName = 'fullDepartmentName',
  InsertType = 'insertType',
  Name = 'name',
  TodayWorkingTime = 'todayWorkingTime',
  WeekMaxExtWorkHour = 'weekMaxExtWorkHour',
  WeekMaxWorkHour = 'weekMaxWorkHour',
  WorkingName = 'workingName',
  WorkingTemplateIdx = 'workingTemplateIdx',
  WtAsextto = 'wtAsextto',
  WtTot = 'wtTot'
}

/** PC정보 타입 */
export enum Type_Of_Pc_Information {
  ComputerName = 'computerName',
  DepartName = 'departName',
  DeviceName = 'deviceName',
  IsOnOff = 'isOnOff',
  Name = 'name',
  WorkingName = 'workingName'
}

/** 템플릿의 근무 계획 정보 */
export type TemplateTimeScheduleInfo = {
  __typename?: 'TemplateTimeScheduleInfo';
  /** 근무계획 구분 ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** 종료 시간 */
  endTime: Scalars['String'];
  /** 금요일 */
  isFriday?: Maybe<Scalars['Int']>;
  /** 월요일 */
  isMonday?: Maybe<Scalars['Int']>;
  /** 토요일 */
  isSaturday?: Maybe<Scalars['Int']>;
  /** 일요일 */
  isSunday?: Maybe<Scalars['Int']>;
  /** 목요일 */
  isThursday?: Maybe<Scalars['Int']>;
  /** 화요일 */
  isTuesDay?: Maybe<Scalars['Int']>;
  /** 수요일 */
  isWednesday?: Maybe<Scalars['Int']>;
  /** 운영기간 종료 */
  periodDateEnd?: Maybe<Scalars['String']>;
  /** 운영기간 시작 */
  periodDateStart: Scalars['String'];
  /** 시작 시간 */
  startTime: Scalars['String'];
  /** 템플릿 근무 계획 Idx */
  timeIdx: Scalars['Int'];
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

/** 근무 계획 카테고리 타입 정보 */
export type TimeTypeCategory = {
  __typename?: 'TimeTypeCategory';
  /** 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 계산된 시간 타입 */
  timeCalcType: Scalars['String'];
  /** 테이블 타입 */
  useTableType: Scalars['Int'];
};

/** 알람 수정 요청 결과 */
export type UpdateAlarmEventOutput = {
  __typename?: 'UpdateAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 상태값 수정 요청 결과 */
export type UpdateAlarmEventStatusOutput = {
  __typename?: 'UpdateAlarmEventStatusOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 연차 정보 설정 갱신 및 저장을 위한 요청 결과 */
export type UpdateAnnualEnvInfoOutput = {
  __typename?: 'UpdateAnnualEnvInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 수정 요청 결과 */
export type UpdateApprovalNotificationTemplateOutput = {
  __typename?: 'UpdateApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 기본 정보 갱신 요청 결과 */
export type UpdateBasicUserInfoOutput = {
  __typename?: 'UpdateBasicUserInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 날짜 및 시간 변경 요청 결과 */
export type UpdateCommuteDateTimeOutput = {
  __typename?: 'UpdateCommuteDateTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 반경설정 on/off 값 변경 요청 결과 */
export type UpdateCommuteGpsAreaUseTypeOutput = {
  __typename?: 'UpdateCommuteGpsAreaUseTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 수정 요청 결과 */
export type UpdateCommuteGpsInfoOutput = {
  __typename?: 'UpdateCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년 미만(입사년도) 정보 갱신 및 저장 요청 결과 */
export type UpdateInfoLessThanAYearOutput = {
  __typename?: 'UpdateInfoLessThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년 이상 설정 정보 갱신 및 저장 요청 */
export type UpdateInfoMoreThanAYearOutput = {
  __typename?: 'UpdateInfoMoreThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년차미만(입사차기년도) 정보 갱신 및 저장 요청 결과 */
export type UpdateInfoNextYearOfEmploymentOutput = {
  __typename?: 'UpdateInfoNextYearOfEmploymentOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 수정 요청 결과 */
export type UpdateListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'UpdateListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdatePlaceFreeManagementOutput = {
  __typename?: 'UpdatePlaceFreeManagementOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 결재 요청 결과 */
export type UpdateProcessingInquiryResultOutput = {
  __typename?: 'UpdateProcessingInquiryResultOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 요청 결과 ID */
  id?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 목록 수정하는 요청 결과 */
export type UpdateReservationCategoryOutput = {
  __typename?: 'UpdateReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 등록을 위한 요청 */
export type UpdateReservationOutput = {
  __typename?: 'UpdateReservationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 수정 요청 결과 */
export type UpdateReservationTypeOutput = {
  __typename?: 'UpdateReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 수정 요청 결과 */
export type UpdateTemplateScheduleInfoOutput = {
  __typename?: 'UpdateTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 수정 요청 결과 */
export type UpdateTimeTemplateOutput = {
  __typename?: 'UpdateTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 연차 정보 갱신 요청 결과 */
export type UpdateUserAnnualInfoOutput = {
  __typename?: 'UpdateUserAnnualInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 수정을 위한 요청 결과 */
export type UpdateVehicleDrivingInformationOutput = {
  __typename?: 'UpdateVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateVehicleInformationOutput = {
  __typename?: 'UpdateVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 연차 정보 */
export type UserAnnualInfoEntity = {
  __typename?: 'UserAnnualInfoEntity';
  /** 가산일수 */
  addDay: Scalars['Int'];
  /** 귀속년도 */
  applyYear: Scalars['String'];
  /** 기본일수 */
  basicDay: Scalars['Int'];
  /** 기본조정 */
  basicModifyDay: Scalars['Int'];
  /** 생성자 */
  createSubject?: Maybe<Scalars['String']>;
  /** 사용자 사번 */
  employeeId: Scalars['String'];
  /** 생성일자 */
  insertDatetime: Scalars['String'];
  /** 이월연차 */
  leaveCarriedForwardDay: Scalars['Int'];
  /** 잔여일 */
  leaveDay: Scalars['Float'];
  /** 변경일자 */
  modifyDatetime?: Maybe<Scalars['String']>;
  /** 변경사유 */
  modifyReason?: Maybe<Scalars['String']>;
  /** 연차종료일 */
  periodateEnd?: Maybe<Scalars['String']>;
  /** 연차시작일 */
  periodateStart?: Maybe<Scalars['String']>;
  /** 상태 */
  statusFlag?: Maybe<Scalars['String']>;
  /** 사용일수 */
  useDay: Scalars['Float'];
  /** 사용조정 */
  useModifyDay: Scalars['Int'];
};

/** 사용자 정보 */
export type UserEntity = {
  __typename?: 'UserEntity';
  /** 전화번호 */
  appPhoneNum?: Maybe<Scalars['String']>;
  /** approvalDefine */
  approvalDefine: Scalars['Int'];
  /** approvalEndDate */
  approvalEndDate?: Maybe<Scalars['String']>;
  /** approvalStartDate */
  approvalStartDate?: Maybe<Scalars['String']>;
  /** asonicWidgetUse */
  asonicWidgetUse?: Maybe<Scalars['Int']>;
  /** col1 */
  col1?: Maybe<Scalars['String']>;
  /** col2 */
  col2?: Maybe<Scalars['String']>;
  /** col3 */
  col3?: Maybe<Scalars['String']>;
  /** col4 */
  col4?: Maybe<Scalars['String']>;
  /** col5 */
  col5?: Maybe<Scalars['String']>;
  /** col6 */
  col6?: Maybe<Scalars['String']>;
  /** col7 */
  col7?: Maybe<Scalars['String']>;
  /** col8 */
  col8?: Maybe<Scalars['String']>;
  /** col9 */
  col9?: Maybe<Scalars['String']>;
  /** col10 */
  col10?: Maybe<Scalars['String']>;
  /** col11 */
  col11?: Maybe<Scalars['String']>;
  /** col12 */
  col12?: Maybe<Scalars['String']>;
  /** col13 */
  col13?: Maybe<Scalars['String']>;
  /** col14 */
  col14?: Maybe<Scalars['String']>;
  /** col15 */
  col15?: Maybe<Scalars['String']>;
  /** delegator ID */
  delegatorId?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId?: Maybe<Scalars['Int']>;
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** extWorkingMin */
  extWorkingMin?: Maybe<Scalars['Int']>;
  /** fcmToken */
  fcmToken?: Maybe<Scalars['String']>;
  /** fp Process */
  fpProcess: Scalars['Int'];
  /** fp Process Two */
  fpProcessTwo: Scalars['Int'];
  /** 부서명 */
  fullDepartmentName?: Maybe<Scalars['String']>;
  /** 입력구분(0:수동, 1:자동) */
  insertType?: Maybe<Scalars['Int']>;
  /** leftSeatUse */
  leftSeatUse: Scalars['Int'];
  /** 관리상태(0:관리, 1:삭제) */
  manageable?: Maybe<Scalars['Int']>;
  /** memo */
  memo?: Maybe<Scalars['String']>;
  /** myPicture */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name?: Maybe<Scalars['String']>;
  /** newData */
  newData?: Maybe<Scalars['Int']>;
  /** newDataTime */
  newDataTime?: Maybe<Scalars['String']>;
  /** 사용자 패스워드 */
  password: Scalars['String'];
  /** policy Update Status */
  policyUpdateStatus: Scalars['Int'];
  /** policy Update Status Two */
  policyUpdateStatusTwo: Scalars['Int'];
  /** policyWorkingMin */
  policyWorkingMin?: Maybe<Scalars['Int']>;
  /** policy_ext_working_min */
  policy_ext_working_min?: Maybe<Scalars['Int']>;
  /** 근무 시간 계획 정책 템플릿 Idx */
  timeTemplateIdx?: Maybe<Scalars['Int']>;
  /** tokenField */
  tokenField?: Maybe<Scalars['Int']>;
  /** 사용자 email */
  userEmail?: Maybe<Scalars['String']>;
  /** 직급 */
  userPosition?: Maybe<Scalars['String']>;
  /** web, app 권한 */
  webAppAuth?: Maybe<Scalars['Int']>;
  /** workingMin */
  workingMin?: Maybe<Scalars['Int']>;
  /** 근무 정책 템플릿 Idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

export type UserOfSearchedApprovalLineEntity = {
  __typename?: 'UserOfSearchedApprovalLineEntity';
  /** 결재 정보 */
  approvalInfo?: Maybe<Scalars['String']>;
  /** 순서 */
  approvalOrder: Scalars['Int'];
  /** 사용자 결재선 타입 */
  approvalType: Approval_User_Type;
  /** 사용자 id */
  employeeId: Scalars['String'];
  /** 결재선 idx */
  lineIdx: Scalars['Int'];
};

/** 차량 주행 정보 */
export type VehicleDrivingInformationEntity = {
  __typename?: 'VehicleDrivingInformationEntity';
  /** 사용 목적 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 사용 목적 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 도착 날짜 및 시간 */
  endDateTime?: Maybe<Scalars['String']>;
  /** 도착 운전자 사번 */
  endEmployeeId?: Maybe<Scalars['String']>;
  /** 도착 운전자 이름 */
  endEmployeeName?: Maybe<Scalars['String']>;
  /** 도착지 */
  endGpsAddress?: Maybe<Scalars['String']>;
  /** 도착 후 거리 */
  endKm?: Maybe<Scalars['Int']>;
  /** logIdx */
  logIdx: Scalars['Int'];
  /** 출발 날짜 및 시간 */
  startDateTime?: Maybe<Scalars['String']>;
  /** 출발 운전자 사번 */
  startEmployeeId: Scalars['String'];
  /** 출발 운전자 이름 */
  startEmployeeName?: Maybe<Scalars['String']>;
  /** 출발지 */
  startGpsAddress?: Maybe<Scalars['String']>;
  /** 출발 전 거리 */
  startKm?: Maybe<Scalars['Int']>;
  /** 차량 이름 */
  vehicleName?: Maybe<Scalars['String']>;
  /** 차량 번호 */
  vehicleNumber: Scalars['String'];
};

/** 차량 정보 */
export type VehicleInformationEntity = {
  __typename?: 'VehicleInformationEntity';
  /** 제조사 */
  vehicleCompany?: Maybe<Scalars['String']>;
  /** 차량 이름 */
  vehicleName?: Maybe<Scalars['String']>;
  /** 차량 번호 */
  vehicleNumber: Scalars['String'];
};

/** 근무관리 정보 */
export type WorkManagement = {
  __typename?: 'WorkManagement';
  /** 근무타입 ID */
  categoryId?: Maybe<Scalars['Float']>;
  /** 근무타입 */
  categoryName?: Maybe<Scalars['String']>;
  /** 부서명 */
  departName: Scalars['String'];
  /** 직원 ID */
  employeeId: Scalars['String'];
  /** 종료주소 */
  endAddress?: Maybe<Scalars['String']>;
  /** 종료날짜 */
  endDate?: Maybe<Scalars['String']>;
  /** 종료 gps lat */
  endGpsLat?: Maybe<Scalars['String']>;
  /** 종료 gps long */
  endGpsLong?: Maybe<Scalars['String']>;
  /** 근무종료사진 */
  endImage?: Maybe<Scalars['String']>;
  /** 종료시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 시작주소 */
  startAddress?: Maybe<Scalars['String']>;
  /** 시작날짜 */
  startDate?: Maybe<Scalars['String']>;
  /** 시작 gps lat */
  startGpsLat?: Maybe<Scalars['String']>;
  /** 시작 gps long */
  startGpsLong?: Maybe<Scalars['String']>;
  /** 근무시작사진 */
  startImage?: Maybe<Scalars['String']>;
  /** 시작시간 */
  startTime?: Maybe<Scalars['String']>;
};

export type GetCompanyListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyListQuery = { __typename?: 'Query', getCompanyList: { __typename?: 'GetCompanyListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'CompanyEntity', companyCode: string, companyName: string }> | null } };

export type GetAnnualEnvInfoQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetAnnualEnvInfoQuery = { __typename?: 'Query', getAnnualEnvInfo: { __typename?: 'GetAnnualEnvInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'AnnualEnvInfoEntity', annualAccrualType: Annual_Accrual_Type, accountingMonth: number, annualMaxCount: number, annualDeductionPriorityType: number, annualFixType: Annual_Fix_Type } | null } };

export type UpdateAnnualEnvInfoMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type;
  accountingMonth: Scalars['Int'];
  annualMaxCount: Scalars['Int'];
  annualDeductionPriorityType: Scalars['Int'];
  annualFixType: Annual_Fix_Type;
}>;


export type UpdateAnnualEnvInfoMutation = { __typename?: 'Mutation', updateAnnualEnvInfo: { __typename?: 'UpdateAnnualEnvInfoOutput', ok: boolean, error?: string | null } };

export type GetInfoMoreThanAYearQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoMoreThanAYearQuery = { __typename?: 'Query', getInfoMoreThanAYear: { __typename?: 'GetInfoMoreThanAYearOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoMoreThanAYearEntity', defaultDayCount: number, addedYearCount: number, addedDayCount: number, addedMaxDayCount: number, minusAnnualType: Minus_Annual_Type, minusAnnualDayCount: number, carriedforwardType: Carriedforward_Type, carriedforwardRangeType: Carriedforward_Range_Type } | null } };

export type UpdateInfoMoreThanAYearMutationVariables = Exact<{
  companyCode: Scalars['String'];
  defaultDayCount: Scalars['Int'];
  addedYearCount: Scalars['Int'];
  addedDayCount: Scalars['Int'];
  addedMaxDayCount: Scalars['Int'];
  minusAnnualType: Minus_Annual_Type;
  minusAnnualDayCount: Scalars['Int'];
  carriedforwardType: Carriedforward_Type;
  carriedforwardRangeType: Carriedforward_Range_Type;
}>;


export type UpdateInfoMoreThanAYearMutation = { __typename?: 'Mutation', updateInfoMoreThanAYear: { __typename?: 'UpdateInfoMoreThanAYearOutput', ok: boolean, error?: string | null } };

export type GetInfoLessThanAYearQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoLessThanAYearQuery = { __typename?: 'Query', getInfoLessThanAYear: { __typename?: 'GetInfoLessThanAYearOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoLessThanAYearEntity', annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year, monthAccrualType: Month_Accrual_Type, fixCount: number, minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year, minusAnnualDayCount: number } | null } };

export type UpdateInfoLessThanYearMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  monthAccrualType: Month_Accrual_Type;
  fixCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  minusAnnualDayCount: Scalars['Float'];
}>;


export type UpdateInfoLessThanYearMutation = { __typename?: 'Mutation', updateInfoLessThanYear: { __typename?: 'UpdateInfoLessThanAYearOutput', ok: boolean, error?: string | null } };

export type GetInfoNextYearOfEmploymentQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoNextYearOfEmploymentQuery = { __typename?: 'Query', getInfoNextYearOfEmployment: { __typename?: 'GetInfoNextYearOfEmploymentOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoNextYearOfEmploymentEntity', annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment, monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment, fixCount: number, minusAnnualType: Minus_Annual_Type, minusAnnualDayCount: number, carriedforwardType: Carriedforward_Type, carriedforwardRangeType: Carriedforward_Range_Type } | null } };

export type UpdateInfoNextYearOfEmploymentMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
  fixCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type;
  minusAnnualDayCount: Scalars['Float'];
  carriedforwardType: Carriedforward_Type;
  carriedforwardRangeType: Carriedforward_Range_Type;
}>;


export type UpdateInfoNextYearOfEmploymentMutation = { __typename?: 'Mutation', updateInfoNextYearOfEmployment: { __typename?: 'UpdateInfoNextYearOfEmploymentOutput', ok: boolean, error?: string | null } };

export type ApplicationApprovalMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type ApplicationApprovalMutation = { __typename?: 'Mutation', applicationApproval: { __typename?: 'ApplicationApprovalOutput', ok: boolean, error?: string | null } };

export type AddApprovalTemporaryStorageMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type AddApprovalTemporaryStorageMutation = { __typename?: 'Mutation', addApprovalTemporaryStorage: { __typename?: 'ApplicationApprovalOutput', ok: boolean, error?: string | null } };

export type IsActiveApprovalPasswordQueryVariables = Exact<{ [key: string]: never; }>;


export type IsActiveApprovalPasswordQuery = { __typename?: 'Query', isActiveApprovalPassword: { __typename?: 'IsActiveApprovalPasswordOutput', ok: boolean, error?: string | null } };

export type ConfirmIsApprovalPasswordQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type ConfirmIsApprovalPasswordQuery = { __typename?: 'Query', confirmIsApprovalPassword: { __typename?: 'ConfirmIsApprovalPasswordOutput', ok: boolean, error?: string | null } };

export type SignApprovalMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
}>;


export type SignApprovalMutation = { __typename?: 'Mutation', signApproval: { __typename?: 'SignApprovalOutput', ok: boolean, error?: string | null } };

export type RejectApprovalMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
}>;


export type RejectApprovalMutation = { __typename?: 'Mutation', rejectApproval: { __typename?: 'RejectApprovalOutput', ok: boolean, error?: string | null } };

export type DeleteApprovalRequestMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type DeleteApprovalRequestMutation = { __typename?: 'Mutation', deleteApprovalRequest: { __typename?: 'DeleteApprovalRequestOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalQueryVariables = Exact<{
  approvalType: Approval_For_List_Type;
  searchType: ApprovalSearchType;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetListOfApprovalQuery = { __typename?: 'Query', getListOfApproval: { __typename?: 'ListOfApprovalOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalEntity', approvalRequestIdx: number, formTemplateIdx: number, approvalType: Approval_Sort, approvalTitle?: string | null, categoryId: number, formIdx?: number | null, docNumber?: string | null, approvalStatus: ApprovalStatus, extendStartDate: string, extendEndDate: string, extendStartDateTime: string, extendEndDateTime: string, signUpDateTime?: string | null, name: string, departName: string, attachedFileName?: string | null, attachedFileLink?: string | null }> | null } };

export type GetGroupsQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetGroupsQuery = { __typename?: 'Query', getGroups: { __typename?: 'GetGroupOutput', ok: boolean, error?: string | null, listOfGroup?: Array<{ __typename?: 'GroupEntity', departmentId: number, parentDepartmentId: number, name: string, level: string, fullGroupName: string, insertType: number, countEmployee?: number | null, countEndpoint?: number | null, memo1?: string | null, memo2?: string | null, workingName?: string | null, deptSort: number }> | null } };

export type GetAllEmployeeInformationInDepartmentQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
}>;


export type GetAllEmployeeInformationInDepartmentQuery = { __typename?: 'Query', getAllEmployeeInformationInDepartment: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col1: string, col2: string, col3: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, todayWorkingTime?: string | null, weekMaxWorkHour?: string | null, wtTot?: string | null, weekMaxExtWorkHour?: string | null, wtAsextto?: string | null, userPosition?: string | null }> | null } };

export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
}>;


export type GetAllEmployeeInformationInDepartmentForAllEmployeesQuery = { __typename?: 'Query', getAllEmployeeInformationInDepartmentForAllEmployees: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col1: string, col2: string, col3: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, todayWorkingTime?: string | null, weekMaxWorkHour?: string | null, wtTot?: string | null, weekMaxExtWorkHour?: string | null, wtAsextto?: string | null, userPosition?: string | null }> | null } };

export type CountListOfApprovalQueryVariables = Exact<{
  approvalType: Approval_For_List_Type;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfApprovalQuery = { __typename?: 'Query', countListOfApproval: { __typename?: 'CountListOfApprovalOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetApprovalFormDetailQueryVariables = Exact<{
  employeeId: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetApprovalFormDetailQuery = { __typename?: 'Query', getApprovalFormDetail: { __typename?: 'GetApprovalFormDetailOutput', ok: boolean, error?: string | null, listOfApprovalUser?: Array<{ __typename?: 'ListOfApprovalUser', approvalRequestIdx: number, approverId: string, name: string, approvalOrder: number, approverType: Approval_User_Type, approvalResult?: Approval_Status | null, approvalTime?: string | null, signType?: SignType | null, signText?: string | null, sign_image?: Array<string> | null, signImage?: string | null, viewTime?: string | null }> | null, information?: { __typename?: 'ApprovalDetailInformationEntity', approvalRequestIdx: number, formTemplateTitle: string, employeeIdRequest: string, departmentName: string, employeeName: string, userPosition?: string | null, docNumber?: string | null, signUpDateTime?: string | null, categoryName: string, storePeriod?: string | null, formName?: string | null, extendSDate?: string | null, extendSDateTime?: string | null, extendEDate?: string | null, extendEDateTime?: string | null, approvalTitle?: string | null, extendDescription?: string | null, attachedFileName?: string | null, attachedFileLink?: string | null } | null, listOfReceiveDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReceiveUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null, listOfReferenceDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReferenceUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null } };

export type GetListOfApprovalHistoryQueryVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetListOfApprovalHistoryQuery = { __typename?: 'Query', getListOfApprovalHistory: { __typename?: 'ApprovalHistoryOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalHistoryEntity', numRank: number, empName: string, userPosition?: string | null, departmentName: string, approvalTime?: string | null, approvalResult?: Approval_Status | null, descriptionComment?: string | null, viewTime?: string | null }> | null } };

export type GetListOfApprovalTemporaryStorageQueryVariables = Exact<{
  searchType: ApprovalSearchType;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetListOfApprovalTemporaryStorageQuery = { __typename?: 'Query', getListOfApprovalTemporaryStorage: { __typename?: 'GetListOfApprovalTemporaryStorageOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ListOfApprovalTemporaryStorageEntity', approvalRequestIdx: number, formTemplateIdx: number, approvalType: Approval_Sort, signUpDatetime?: string | null, approvalTitle?: string | null, categoryId: number, formIdx?: number | null, extendStartDate: string, extendEndDate: string, extendStartDateTime: string, extendEndDateTime: string, name: string, attachedFileName?: string | null, attachedFileLink?: string | null }> | null } };

export type CountListOfApprovalTemporaryStorageQueryVariables = Exact<{
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfApprovalTemporaryStorageQuery = { __typename?: 'Query', countListOfApprovalTemporaryStorage: { __typename?: 'CountListOfApprovalTemporaryStorageOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetApprovalTemporaryStorageDetailInformationQueryVariables = Exact<{
  employeeId: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetApprovalTemporaryStorageDetailInformationQuery = { __typename?: 'Query', getApprovalTemporaryStorageDetailInformation: { __typename?: 'GetApprovalTemporaryStorageDetailInformationOutput', ok: boolean, error?: string | null, listOfApprovalUser?: Array<{ __typename?: 'ListOfApprovalUser', approvalRequestIdx: number, approverId: string, name: string, approvalOrder: number, approverType: Approval_User_Type, approvalResult?: Approval_Status | null, approvalTime?: string | null, signType?: SignType | null, signText?: string | null, sign_image?: Array<string> | null, signImage?: string | null }> | null, information?: { __typename?: 'ApprovalDetailInformationEntity', approvalRequestIdx: number, formTemplateTitle: string, employeeIdRequest: string, departmentName: string, employeeName: string, userPosition?: string | null, docNumber?: string | null, signUpDateTime?: string | null, categoryName: string, storePeriod?: string | null, formName?: string | null, extendSDate?: string | null, extendSDateTime?: string | null, extendEDate?: string | null, extendEDateTime?: string | null, approvalTitle?: string | null, temporaryExtendDescription?: string | null, attachedFileName?: string | null, attachedFileLink?: string | null } | null, listOfReceiveDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReceiveUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null, listOfReferenceDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReferenceUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null } };

export type DeleteApprovalTemporaryStorageMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
}>;


export type DeleteApprovalTemporaryStorageMutation = { __typename?: 'Mutation', deleteApprovalTemporaryStorage: { __typename?: 'DeleteApprovalTemporaryStorageOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalBasicLineUserQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalBasicLineUserQuery = { __typename?: 'Query', getListOfApprovalBasicLineUser: { __typename?: 'GetListOfApprovalBasicLineUserOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserOfSearchedApprovalLineEntity', approvalOrder: number, approvalType: Approval_User_Type, approvalInfo?: string | null, lineIdx: number, employeeId: string }> | null } };

export type GetListOfApprovalTemplateQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalTemplateQuery = { __typename?: 'Query', getListOfApprovalTemplate: { __typename?: 'GetListOfApprovalTemplateOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalTemplateEntity', numRank: number, formTemplateIdx: number, templateTitle: string, name: string, formIdx: number, formName: string, approvalType: Approval_Sort, description?: string | null }> | null } };

export type GetListOfApprovalFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListOfApprovalFormQuery = { __typename?: 'Query', getListOfApprovalForm: { __typename?: 'GetListOTApprovalFormOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'OtApprovalFormEntity', formIdx: number, formName: string }> | null } };

export type GetListOfOtTimeTypeCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListOfOtTimeTypeCategoryQuery = { __typename?: 'Query', getListOfOtTimeTypeCategory: { __typename?: 'GetListOfOtTImeTypeCategoryOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'OtTimeTypeCategoryEntity', categoryId: number, name: string }> | null } };

export type GetListOfApprovalLineQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalLineQuery = { __typename?: 'Query', getListOfApprovalLine: { __typename?: 'GetListOfApprovalLineOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalLineEntity', lineIdx: number, newTitle: string, basicLine: Is_Default_Approval_Line }> | null } };

export type ControlApprovalFormMutationVariables = Exact<{
  controlApprovalFormType: Type_Control_Approval_Form;
  formIdx: Scalars['Int'];
  templateName: Scalars['String'];
  categoryId: Scalars['Int'];
  approvalSort: Approval_Sort;
  formTemplateIdx?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type ControlApprovalFormMutation = { __typename?: 'Mutation', controlApprovalForm: { __typename?: 'ControlApprovalFormOutput', ok: boolean, error?: string | null, formTemplateIdx?: number | null } };

export type DeleteApprovalTemplateFormMutationVariables = Exact<{
  listOfFormTemplateIdx: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteApprovalTemplateFormMutation = { __typename?: 'Mutation', deleteApprovalTemplateForm: { __typename?: 'DeleteApprovalTemplateFormOutput', ok: boolean, error?: string | null } };

export type GetListOfSearchedApprovalLineUserQueryVariables = Exact<{
  employeeId: Scalars['String'];
  lineIdx: Scalars['Int'];
}>;


export type GetListOfSearchedApprovalLineUserQuery = { __typename?: 'Query', getListOfSearchedApprovalLineUser: { __typename?: 'GetListOfSearchedApprovalLineUserOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserOfSearchedApprovalLineEntity', approvalOrder: number, approvalType: Approval_User_Type, approvalInfo?: string | null, lineIdx: number, employeeId: string }> | null } };

export type AddApprovalLineMutationVariables = Exact<{
  approvalLineControlType: ApprovalLineControlType;
  user: Scalars['String'];
  approvalLineName: Scalars['String'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  count: Scalars['Int'];
  list: Array<ApprovalLineUserEntity> | ApprovalLineUserEntity;
}>;


export type AddApprovalLineMutation = { __typename?: 'Mutation', addApprovalLine: { __typename?: 'AddApprovalLineOutput', ok: boolean, error?: string | null, lineIdx?: number | null } };

export type EditApprovalLineMutationVariables = Exact<{
  approvalLineControlType: ApprovalLineControlType;
  user: Scalars['String'];
  approvalLineName: Scalars['String'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  count: Scalars['Int'];
  list: Array<ApprovalLineUserEntity> | ApprovalLineUserEntity;
  lineIdx: Scalars['Int'];
}>;


export type EditApprovalLineMutation = { __typename?: 'Mutation', editApprovalLine: { __typename?: 'EditApprovalLineOutput', ok: boolean, error?: string | null, lineIdx?: number | null } };

export type DeleteApprovalLineMutationVariables = Exact<{
  lineIdx: Scalars['Int'];
  user: Scalars['String'];
}>;


export type DeleteApprovalLineMutation = { __typename?: 'Mutation', deleteApprovalLine: { __typename?: 'DeleteApprovalLineOutput', ok: boolean, error?: string | null } };

export type GetApprovalAdminSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalAdminSettingQuery = { __typename?: 'Query', getApprovalAdminSetting: { __typename?: 'GetApprovalAdminSettingOutput', ok: boolean, error?: string | null, preview?: Approval_Admin_Setting_Preview_Type | null, dashboardListCount?: number | null } };

export type EditApprovalAdminSettingMutationVariables = Exact<{
  preview: Approval_Admin_Setting_Preview_Type;
  count: Scalars['Int'];
}>;


export type EditApprovalAdminSettingMutation = { __typename?: 'Mutation', editApprovalAdminSetting: { __typename?: 'EditApprovalAdminSettingOutput', ok: boolean, error?: string | null } };

export type GetApprovalPasswordAndSignSettingInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetApprovalPasswordAndSignSettingInfoQuery = { __typename?: 'Query', getApprovalPasswordAndSignSettingInfo: { __typename?: 'GetApprovalPasswordAndSignSettingInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'ApprovalPasswordAndSignSettingInfoEntity', employeeId: string, signType: SignType, signText?: string | null, signImage?: string | null, signPassword: string, isSignPassword: Is_Sign_Password, isSignEmailAlarm: Is_Sign_Alarm_Type, isSignMobileAlarm: Is_Sign_Alarm_Type } | null } };

export type SaveApprovalPasswordAndSignSettingMutationVariables = Exact<{
  employeeId: Scalars['String'];
  signType: SignType;
  isSignPassword: Is_Sign_Password;
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  signText?: InputMaybe<Scalars['String']>;
  signImage?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  signPassword?: InputMaybe<Scalars['String']>;
}>;


export type SaveApprovalPasswordAndSignSettingMutation = { __typename?: 'Mutation', saveApprovalPasswordAndSignSetting: { __typename?: 'SaveApprovalPasswordAndSignSettingOutput', ok: boolean, error?: string | null } };

export type DeleteApprovalSignImageOrTextMutationVariables = Exact<{
  employeeId: Scalars['String'];
  deleteSignType: SignType;
}>;


export type DeleteApprovalSignImageOrTextMutation = { __typename?: 'Mutation', deleteApprovalSignImageOrText: { __typename?: 'DeleteApprovalSignImageOrTextOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalLineFormQueryVariables = Exact<{
  employeeId: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
}>;


export type GetListOfApprovalLineFormQuery = { __typename?: 'Query', getListOfApprovalLineForm: { __typename?: 'GetListOfApprovalLineFormOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalLineFormEntity', numRank: number, templateTitle: string, approvalType: Approval_Sort, formName: string, name: string, descr?: string | null, formTemplateIdx: number, formIdx: number }> | null } };

export type GetBasicUserInfoQueryVariables = Exact<{
  getBasicUserInfoId?: InputMaybe<Scalars['String']>;
}>;


export type GetBasicUserInfoQuery = { __typename?: 'Query', getBasicUserInfo: { __typename?: 'GetBasicUserInfoOutput', ok: boolean, error?: string | null, user?: { __typename?: 'BasicUserInfoEntity', empName: string, my_picture?: Array<string> | null, myPicture?: string | null, departmentId: number, nameChinese?: string | null, nameEnglish?: string | null, departmentName: string, userJob?: string | null, userPosition?: string | null, userRank?: string | null, joiningDate?: string | null, leavingDate?: string | null, phoneNum?: string | null, userEmail?: string | null } | null } };

export type UpdateBasicUserinfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  myPicture: Scalars['String'];
  nameChinese?: InputMaybe<Scalars['String']>;
  nameEnglish?: InputMaybe<Scalars['String']>;
  userJob?: InputMaybe<Scalars['String']>;
  userPosition?: InputMaybe<Scalars['String']>;
  userRank?: InputMaybe<Scalars['String']>;
  joiningDate?: InputMaybe<Scalars['String']>;
  leavingDate?: InputMaybe<Scalars['String']>;
  phoneNum?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBasicUserinfoMutation = { __typename?: 'Mutation', updateBasicUserinfo: { __typename?: 'UpdateBasicUserInfoOutput', ok: boolean, error?: string | null } };

export type GetUserAnnualInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
  annualYear: Scalars['String'];
}>;


export type GetUserAnnualInfoQuery = { __typename?: 'Query', getUserAnnualInfo: { __typename?: 'GetUserAnnualInfoOutput', ok: boolean, error?: string | null, userAnnualInfo?: { __typename?: 'UserAnnualInfoEntity', employeeId: string, applyYear: string, statusFlag?: string | null, insertDatetime: string, createSubject?: string | null, periodateStart?: string | null, periodateEnd?: string | null, basicDay: number, basicModifyDay: number, addDay: number, leaveCarriedForwardDay: number, useModifyDay: number, modifyReason?: string | null, modifyDatetime?: string | null, useDay: number, leaveDay: number } | null } };

export type UpdateUserAnnualInfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
  createSubject: Scalars['String'];
  createSubjectEmployeeId: Scalars['String'];
  periodateStart: Scalars['String'];
  periodateEnd: Scalars['String'];
  basicDay: Scalars['Int'];
  basicModifyDay: Scalars['Int'];
  addDay: Scalars['Int'];
  leaveCarriedForwardDay: Scalars['Int'];
  useModifyDay: Scalars['Int'];
  statusFlag?: InputMaybe<Scalars['String']>;
  modifyReason?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserAnnualInfoMutation = { __typename?: 'Mutation', updateUserAnnualInfo: { __typename?: 'UpdateUserAnnualInfoOutput', ok: boolean, error?: string | null } };

export type GetAnnualHistoryQueryVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
}>;


export type GetAnnualHistoryQuery = { __typename?: 'Query', getAnnualHistory: { __typename?: 'GetAnnualHistoryOutput', ok: boolean, error?: string | null, history?: Array<{ __typename?: 'AnnualHistoryEntity', logIdx: number, employeeId: string, applyYear: string, statusFlag: string, createSubject: string, periodateStart: string, periodateEnd: string, basicDay: number, basicModifyDay: number, addDay: number, leaveCarriedForwardDay: number, useModifyDay: number, modifyReason?: string | null, modifyDatetime: string }> | null } };

export type DownloadAnnualHistoryAsExcelQueryVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
}>;


export type DownloadAnnualHistoryAsExcelQuery = { __typename?: 'Query', downloadAnnualHistoryAsExcel: { __typename?: 'DownloadAnnualHistoryAsExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type CountListOfEmployeeInformationQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfEmployeeInformationQuery = { __typename?: 'Query', countListOfEmployeeInformation: { __typename?: 'CountListOfEmployeeInformationOutput', ok: boolean, error?: string | null, total?: number | null } };

export type GetEmployeeInformationQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetEmployeeInformationQuery = { __typename?: 'Query', getEmployeeInformation: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col3: string, col2: string, col1: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, wtAsextto?: string | null, weekMaxExtWorkHour?: string | null, wtTot?: string | null, weekMaxWorkHour?: string | null, todayWorkingTime?: string | null }> | null } };

export type DownloadListOfEmployeeInformationExcelQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type DownloadListOfEmployeeInformationExcelQuery = { __typename?: 'Query', downloadListOfEmployeeInformationExcel: { __typename?: 'DownloadListOfEmployeeInformationExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetUserQueryVariables = Exact<{
  getUserId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'GetUserOutput', ok: boolean, error?: string | null, user?: Array<{ __typename?: 'UserEntity', employeeId: string, departmentId?: number | null, fullDepartmentName?: string | null, workingTemplateIdx?: number | null, timeTemplateIdx?: number | null, leftSeatUse: number, asonicWidgetUse?: number | null, approvalDefine: number, name?: string | null, password: string, newData?: number | null, newDataTime?: string | null, manageable?: number | null, insertType?: number | null, policyWorkingMin?: number | null, workingMin?: number | null, policy_ext_working_min?: number | null, extWorkingMin?: number | null, tokenField?: number | null, myPicture?: string | null, fcmToken?: string | null, delegatorId?: string | null, approvalStartDate?: string | null, approvalEndDate?: string | null, userEmail?: string | null, webAppAuth?: number | null, appPhoneNum?: string | null, col1?: string | null, col2?: string | null, col3?: string | null, col4?: string | null, col5?: string | null, col6?: string | null, col7?: string | null, col8?: string | null, col9?: string | null, col10?: string | null, col11?: string | null, col12?: string | null, col13?: string | null, col14?: string | null, col15?: string | null, fpProcess: number, policyUpdateStatus: number, fpProcessTwo: number, policyUpdateStatusTwo: number, memo?: string | null, userPosition?: string | null }> | null } };

export type GetGroupsForAllQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsForAllQuery = { __typename?: 'Query', getGroupsForAll: { __typename?: 'GetGroupOutput', ok: boolean, error?: string | null, listOfGroup?: Array<{ __typename?: 'GroupEntity', departmentId: number, parentDepartmentId: number, name: string, level: string, fullGroupName: string, insertType: number, countEmployee?: number | null, countEndpoint?: number | null, memo1?: string | null, memo2?: string | null, workingName?: string | null, deptSort: number }> | null } };


export const GetCompanyListDocument = gql`
    query GetCompanyList {
  getCompanyList {
    ok
    error
    list {
      companyCode
      companyName
    }
  }
}
    `;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
      }
export function useGetCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = Apollo.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetAnnualEnvInfoDocument = gql`
    query GetAnnualEnvInfo($companyCode: String!) {
  getAnnualEnvInfo(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      accountingMonth
      annualMaxCount
      annualDeductionPriorityType
      annualFixType
    }
  }
}
    `;

/**
 * __useGetAnnualEnvInfoQuery__
 *
 * To run a query within a React component, call `useGetAnnualEnvInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualEnvInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualEnvInfoQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetAnnualEnvInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>(GetAnnualEnvInfoDocument, options);
      }
export function useGetAnnualEnvInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>(GetAnnualEnvInfoDocument, options);
        }
export type GetAnnualEnvInfoQueryHookResult = ReturnType<typeof useGetAnnualEnvInfoQuery>;
export type GetAnnualEnvInfoLazyQueryHookResult = ReturnType<typeof useGetAnnualEnvInfoLazyQuery>;
export type GetAnnualEnvInfoQueryResult = Apollo.QueryResult<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>;
export const UpdateAnnualEnvInfoDocument = gql`
    mutation UpdateAnnualEnvInfo($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE!, $accountingMonth: Int!, $annualMaxCount: Int!, $annualDeductionPriorityType: Int!, $annualFixType: ANNUAL_FIX_TYPE!) {
  updateAnnualEnvInfo(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    accountingMonth: $accountingMonth
    annualMaxCount: $annualMaxCount
    annualDeductionPriorityType: $annualDeductionPriorityType
    annualFixType: $annualFixType
  ) {
    ok
    error
  }
}
    `;
export type UpdateAnnualEnvInfoMutationFn = Apollo.MutationFunction<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>;

/**
 * __useUpdateAnnualEnvInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAnnualEnvInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnualEnvInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnualEnvInfoMutation, { data, loading, error }] = useUpdateAnnualEnvInfoMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      accountingMonth: // value for 'accountingMonth'
 *      annualMaxCount: // value for 'annualMaxCount'
 *      annualDeductionPriorityType: // value for 'annualDeductionPriorityType'
 *      annualFixType: // value for 'annualFixType'
 *   },
 * });
 */
export function useUpdateAnnualEnvInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>(UpdateAnnualEnvInfoDocument, options);
      }
export type UpdateAnnualEnvInfoMutationHookResult = ReturnType<typeof useUpdateAnnualEnvInfoMutation>;
export type UpdateAnnualEnvInfoMutationResult = Apollo.MutationResult<UpdateAnnualEnvInfoMutation>;
export type UpdateAnnualEnvInfoMutationOptions = Apollo.BaseMutationOptions<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>;
export const GetInfoMoreThanAYearDocument = gql`
    query GetInfoMoreThanAYear($companyCode: String!) {
  getInfoMoreThanAYear(companyCode: $companyCode) {
    ok
    error
    info {
      defaultDayCount
      addedYearCount
      addedDayCount
      addedMaxDayCount
      minusAnnualType
      minusAnnualDayCount
      carriedforwardType
      carriedforwardRangeType
    }
  }
}
    `;

/**
 * __useGetInfoMoreThanAYearQuery__
 *
 * To run a query within a React component, call `useGetInfoMoreThanAYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoMoreThanAYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoMoreThanAYearQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoMoreThanAYearQuery(baseOptions: Apollo.QueryHookOptions<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>(GetInfoMoreThanAYearDocument, options);
      }
export function useGetInfoMoreThanAYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>(GetInfoMoreThanAYearDocument, options);
        }
export type GetInfoMoreThanAYearQueryHookResult = ReturnType<typeof useGetInfoMoreThanAYearQuery>;
export type GetInfoMoreThanAYearLazyQueryHookResult = ReturnType<typeof useGetInfoMoreThanAYearLazyQuery>;
export type GetInfoMoreThanAYearQueryResult = Apollo.QueryResult<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>;
export const UpdateInfoMoreThanAYearDocument = gql`
    mutation UpdateInfoMoreThanAYear($companyCode: String!, $defaultDayCount: Int!, $addedYearCount: Int!, $addedDayCount: Int!, $addedMaxDayCount: Int!, $minusAnnualType: MINUS_ANNUAL_TYPE!, $minusAnnualDayCount: Int!, $carriedforwardType: CARRIEDFORWARD_TYPE!, $carriedforwardRangeType: CARRIEDFORWARD_RANGE_TYPE!) {
  updateInfoMoreThanAYear(
    companyCode: $companyCode
    defaultDayCount: $defaultDayCount
    addedYearCount: $addedYearCount
    addedDayCount: $addedDayCount
    addedMaxDayCount: $addedMaxDayCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
    carriedforwardType: $carriedforwardType
    carriedforwardRangeType: $carriedforwardRangeType
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoMoreThanAYearMutationFn = Apollo.MutationFunction<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>;

/**
 * __useUpdateInfoMoreThanAYearMutation__
 *
 * To run a mutation, you first call `useUpdateInfoMoreThanAYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoMoreThanAYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoMoreThanAYearMutation, { data, loading, error }] = useUpdateInfoMoreThanAYearMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      defaultDayCount: // value for 'defaultDayCount'
 *      addedYearCount: // value for 'addedYearCount'
 *      addedDayCount: // value for 'addedDayCount'
 *      addedMaxDayCount: // value for 'addedMaxDayCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *      carriedforwardType: // value for 'carriedforwardType'
 *      carriedforwardRangeType: // value for 'carriedforwardRangeType'
 *   },
 * });
 */
export function useUpdateInfoMoreThanAYearMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>(UpdateInfoMoreThanAYearDocument, options);
      }
export type UpdateInfoMoreThanAYearMutationHookResult = ReturnType<typeof useUpdateInfoMoreThanAYearMutation>;
export type UpdateInfoMoreThanAYearMutationResult = Apollo.MutationResult<UpdateInfoMoreThanAYearMutation>;
export type UpdateInfoMoreThanAYearMutationOptions = Apollo.BaseMutationOptions<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>;
export const GetInfoLessThanAYearDocument = gql`
    query GetInfoLessThanAYear($companyCode: String!) {
  getInfoLessThanAYear(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      monthAccrualType
      fixCount
      minusAnnualType
      minusAnnualDayCount
    }
  }
}
    `;

/**
 * __useGetInfoLessThanAYearQuery__
 *
 * To run a query within a React component, call `useGetInfoLessThanAYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoLessThanAYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoLessThanAYearQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoLessThanAYearQuery(baseOptions: Apollo.QueryHookOptions<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>(GetInfoLessThanAYearDocument, options);
      }
export function useGetInfoLessThanAYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>(GetInfoLessThanAYearDocument, options);
        }
export type GetInfoLessThanAYearQueryHookResult = ReturnType<typeof useGetInfoLessThanAYearQuery>;
export type GetInfoLessThanAYearLazyQueryHookResult = ReturnType<typeof useGetInfoLessThanAYearLazyQuery>;
export type GetInfoLessThanAYearQueryResult = Apollo.QueryResult<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>;
export const UpdateInfoLessThanYearDocument = gql`
    mutation UpdateInfoLessThanYear($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE_FOR_LESS_THAN_A_YEAR!, $monthAccrualType: MONTH_ACCRUAL_TYPE!, $fixCount: Float!, $minusAnnualType: MINUS_ANNUAL_TYPE_FOR_LESS_THAN_A_YEAR!, $minusAnnualDayCount: Float!) {
  updateInfoLessThanYear(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    monthAccrualType: $monthAccrualType
    fixCount: $fixCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoLessThanYearMutationFn = Apollo.MutationFunction<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>;

/**
 * __useUpdateInfoLessThanYearMutation__
 *
 * To run a mutation, you first call `useUpdateInfoLessThanYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoLessThanYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoLessThanYearMutation, { data, loading, error }] = useUpdateInfoLessThanYearMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      monthAccrualType: // value for 'monthAccrualType'
 *      fixCount: // value for 'fixCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *   },
 * });
 */
export function useUpdateInfoLessThanYearMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>(UpdateInfoLessThanYearDocument, options);
      }
export type UpdateInfoLessThanYearMutationHookResult = ReturnType<typeof useUpdateInfoLessThanYearMutation>;
export type UpdateInfoLessThanYearMutationResult = Apollo.MutationResult<UpdateInfoLessThanYearMutation>;
export type UpdateInfoLessThanYearMutationOptions = Apollo.BaseMutationOptions<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>;
export const GetInfoNextYearOfEmploymentDocument = gql`
    query GetInfoNextYearOfEmployment($companyCode: String!) {
  getInfoNextYearOfEmployment(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      monthAccrualType
      fixCount
      minusAnnualType
      minusAnnualDayCount
      carriedforwardType
      carriedforwardRangeType
    }
  }
}
    `;

/**
 * __useGetInfoNextYearOfEmploymentQuery__
 *
 * To run a query within a React component, call `useGetInfoNextYearOfEmploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoNextYearOfEmploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoNextYearOfEmploymentQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoNextYearOfEmploymentQuery(baseOptions: Apollo.QueryHookOptions<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>(GetInfoNextYearOfEmploymentDocument, options);
      }
export function useGetInfoNextYearOfEmploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>(GetInfoNextYearOfEmploymentDocument, options);
        }
export type GetInfoNextYearOfEmploymentQueryHookResult = ReturnType<typeof useGetInfoNextYearOfEmploymentQuery>;
export type GetInfoNextYearOfEmploymentLazyQueryHookResult = ReturnType<typeof useGetInfoNextYearOfEmploymentLazyQuery>;
export type GetInfoNextYearOfEmploymentQueryResult = Apollo.QueryResult<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>;
export const UpdateInfoNextYearOfEmploymentDocument = gql`
    mutation UpdateInfoNextYearOfEmployment($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE_FOR_NEXT_YEAR_OF_EMPLOYMENT!, $monthAccrualType: MONTH_ACCRUAL_TYPE_FOR_NEXT_YEAR_OF_EMPLOYMENT!, $fixCount: Float!, $minusAnnualType: MINUS_ANNUAL_TYPE!, $minusAnnualDayCount: Float!, $carriedforwardType: CARRIEDFORWARD_TYPE!, $carriedforwardRangeType: CARRIEDFORWARD_RANGE_TYPE!) {
  updateInfoNextYearOfEmployment(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    monthAccrualType: $monthAccrualType
    fixCount: $fixCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
    carriedforwardType: $carriedforwardType
    carriedforwardRangeType: $carriedforwardRangeType
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoNextYearOfEmploymentMutationFn = Apollo.MutationFunction<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>;

/**
 * __useUpdateInfoNextYearOfEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateInfoNextYearOfEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoNextYearOfEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoNextYearOfEmploymentMutation, { data, loading, error }] = useUpdateInfoNextYearOfEmploymentMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      monthAccrualType: // value for 'monthAccrualType'
 *      fixCount: // value for 'fixCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *      carriedforwardType: // value for 'carriedforwardType'
 *      carriedforwardRangeType: // value for 'carriedforwardRangeType'
 *   },
 * });
 */
export function useUpdateInfoNextYearOfEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>(UpdateInfoNextYearOfEmploymentDocument, options);
      }
export type UpdateInfoNextYearOfEmploymentMutationHookResult = ReturnType<typeof useUpdateInfoNextYearOfEmploymentMutation>;
export type UpdateInfoNextYearOfEmploymentMutationResult = Apollo.MutationResult<UpdateInfoNextYearOfEmploymentMutation>;
export type UpdateInfoNextYearOfEmploymentMutationOptions = Apollo.BaseMutationOptions<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>;
export const ApplicationApprovalDocument = gql`
    mutation ApplicationApproval($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  applicationApproval(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type ApplicationApprovalMutationFn = Apollo.MutationFunction<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>;

/**
 * __useApplicationApprovalMutation__
 *
 * To run a mutation, you first call `useApplicationApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicationApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicationApprovalMutation, { data, loading, error }] = useApplicationApprovalMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useApplicationApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>(ApplicationApprovalDocument, options);
      }
export type ApplicationApprovalMutationHookResult = ReturnType<typeof useApplicationApprovalMutation>;
export type ApplicationApprovalMutationResult = Apollo.MutationResult<ApplicationApprovalMutation>;
export type ApplicationApprovalMutationOptions = Apollo.BaseMutationOptions<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>;
export const AddApprovalTemporaryStorageDocument = gql`
    mutation AddApprovalTemporaryStorage($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  addApprovalTemporaryStorage(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type AddApprovalTemporaryStorageMutationFn = Apollo.MutationFunction<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>;

/**
 * __useAddApprovalTemporaryStorageMutation__
 *
 * To run a mutation, you first call `useAddApprovalTemporaryStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovalTemporaryStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovalTemporaryStorageMutation, { data, loading, error }] = useAddApprovalTemporaryStorageMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useAddApprovalTemporaryStorageMutation(baseOptions?: Apollo.MutationHookOptions<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>(AddApprovalTemporaryStorageDocument, options);
      }
export type AddApprovalTemporaryStorageMutationHookResult = ReturnType<typeof useAddApprovalTemporaryStorageMutation>;
export type AddApprovalTemporaryStorageMutationResult = Apollo.MutationResult<AddApprovalTemporaryStorageMutation>;
export type AddApprovalTemporaryStorageMutationOptions = Apollo.BaseMutationOptions<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>;
export const IsActiveApprovalPasswordDocument = gql`
    query IsActiveApprovalPassword {
  isActiveApprovalPassword {
    ok
    error
  }
}
    `;

/**
 * __useIsActiveApprovalPasswordQuery__
 *
 * To run a query within a React component, call `useIsActiveApprovalPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsActiveApprovalPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsActiveApprovalPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsActiveApprovalPasswordQuery(baseOptions?: Apollo.QueryHookOptions<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>(IsActiveApprovalPasswordDocument, options);
      }
export function useIsActiveApprovalPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>(IsActiveApprovalPasswordDocument, options);
        }
export type IsActiveApprovalPasswordQueryHookResult = ReturnType<typeof useIsActiveApprovalPasswordQuery>;
export type IsActiveApprovalPasswordLazyQueryHookResult = ReturnType<typeof useIsActiveApprovalPasswordLazyQuery>;
export type IsActiveApprovalPasswordQueryResult = Apollo.QueryResult<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>;
export const ConfirmIsApprovalPasswordDocument = gql`
    query ConfirmIsApprovalPassword($password: String!) {
  confirmIsApprovalPassword(password: $password) {
    ok
    error
  }
}
    `;

/**
 * __useConfirmIsApprovalPasswordQuery__
 *
 * To run a query within a React component, call `useConfirmIsApprovalPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmIsApprovalPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmIsApprovalPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmIsApprovalPasswordQuery(baseOptions: Apollo.QueryHookOptions<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>(ConfirmIsApprovalPasswordDocument, options);
      }
export function useConfirmIsApprovalPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>(ConfirmIsApprovalPasswordDocument, options);
        }
export type ConfirmIsApprovalPasswordQueryHookResult = ReturnType<typeof useConfirmIsApprovalPasswordQuery>;
export type ConfirmIsApprovalPasswordLazyQueryHookResult = ReturnType<typeof useConfirmIsApprovalPasswordLazyQuery>;
export type ConfirmIsApprovalPasswordQueryResult = Apollo.QueryResult<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>;
export const SignApprovalDocument = gql`
    mutation SignApproval($approvalRequestIdx: Int!, $description: String!) {
  signApproval(approvalRequestIdx: $approvalRequestIdx, description: $description) {
    ok
    error
  }
}
    `;
export type SignApprovalMutationFn = Apollo.MutationFunction<SignApprovalMutation, SignApprovalMutationVariables>;

/**
 * __useSignApprovalMutation__
 *
 * To run a mutation, you first call `useSignApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signApprovalMutation, { data, loading, error }] = useSignApprovalMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSignApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SignApprovalMutation, SignApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignApprovalMutation, SignApprovalMutationVariables>(SignApprovalDocument, options);
      }
export type SignApprovalMutationHookResult = ReturnType<typeof useSignApprovalMutation>;
export type SignApprovalMutationResult = Apollo.MutationResult<SignApprovalMutation>;
export type SignApprovalMutationOptions = Apollo.BaseMutationOptions<SignApprovalMutation, SignApprovalMutationVariables>;
export const RejectApprovalDocument = gql`
    mutation RejectApproval($approvalRequestIdx: Int!, $description: String!) {
  rejectApproval(
    approvalRequestIdx: $approvalRequestIdx
    description: $description
  ) {
    ok
    error
  }
}
    `;
export type RejectApprovalMutationFn = Apollo.MutationFunction<RejectApprovalMutation, RejectApprovalMutationVariables>;

/**
 * __useRejectApprovalMutation__
 *
 * To run a mutation, you first call `useRejectApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApprovalMutation, { data, loading, error }] = useRejectApprovalMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useRejectApprovalMutation(baseOptions?: Apollo.MutationHookOptions<RejectApprovalMutation, RejectApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectApprovalMutation, RejectApprovalMutationVariables>(RejectApprovalDocument, options);
      }
export type RejectApprovalMutationHookResult = ReturnType<typeof useRejectApprovalMutation>;
export type RejectApprovalMutationResult = Apollo.MutationResult<RejectApprovalMutation>;
export type RejectApprovalMutationOptions = Apollo.BaseMutationOptions<RejectApprovalMutation, RejectApprovalMutationVariables>;
export const DeleteApprovalRequestDocument = gql`
    mutation DeleteApprovalRequest($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $approvalRequestIdx: Int!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  deleteApprovalRequest(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    approvalRequestIdx: $approvalRequestIdx
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type DeleteApprovalRequestMutationFn = Apollo.MutationFunction<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>;

/**
 * __useDeleteApprovalRequestMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalRequestMutation, { data, loading, error }] = useDeleteApprovalRequestMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useDeleteApprovalRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>(DeleteApprovalRequestDocument, options);
      }
export type DeleteApprovalRequestMutationHookResult = ReturnType<typeof useDeleteApprovalRequestMutation>;
export type DeleteApprovalRequestMutationResult = Apollo.MutationResult<DeleteApprovalRequestMutation>;
export type DeleteApprovalRequestMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>;
export const GetListOfApprovalDocument = gql`
    query GetListOfApproval($approvalType: APPROVAL_FOR_LIST_TYPE!, $searchType: ApprovalSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getListOfApproval(
    approvalType: $approvalType
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      approvalRequestIdx
      formTemplateIdx
      approvalType
      approvalTitle
      categoryId
      formIdx
      docNumber
      approvalStatus
      extendStartDate
      extendEndDate
      extendStartDateTime
      extendEndDateTime
      signUpDateTime
      name
      departName
      attachedFileName
      attachedFileLink
    }
  }
}
    `;

/**
 * __useGetListOfApprovalQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalQuery({
 *   variables: {
 *      approvalType: // value for 'approvalType'
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetListOfApprovalQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>(GetListOfApprovalDocument, options);
      }
export function useGetListOfApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>(GetListOfApprovalDocument, options);
        }
export type GetListOfApprovalQueryHookResult = ReturnType<typeof useGetListOfApprovalQuery>;
export type GetListOfApprovalLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLazyQuery>;
export type GetListOfApprovalQueryResult = Apollo.QueryResult<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($employeeId: String!) {
  getGroups(employeeId: $employeeId) {
    ok
    error
    listOfGroup {
      departmentId
      parentDepartmentId
      name
      level
      fullGroupName
      insertType
      countEmployee
      countEndpoint
      memo1
      memo2
      workingName
      deptSort
    }
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetAllEmployeeInformationInDepartmentDocument = gql`
    query GetAllEmployeeInformationInDepartment($selectedDepartmentId: String!) {
  getAllEmployeeInformationInDepartment(
    selectedDepartmentId: $selectedDepartmentId
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      todayWorkingTime
      weekMaxWorkHour
      wtTot
      weekMaxExtWorkHour
      wtAsextto
      userPosition
    }
  }
}
    `;

/**
 * __useGetAllEmployeeInformationInDepartmentQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeInformationInDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeInformationInDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeInformationInDepartmentQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *   },
 * });
 */
export function useGetAllEmployeeInformationInDepartmentQuery(baseOptions: Apollo.QueryHookOptions<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>(GetAllEmployeeInformationInDepartmentDocument, options);
      }
export function useGetAllEmployeeInformationInDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>(GetAllEmployeeInformationInDepartmentDocument, options);
        }
export type GetAllEmployeeInformationInDepartmentQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentQuery>;
export type GetAllEmployeeInformationInDepartmentLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentLazyQuery>;
export type GetAllEmployeeInformationInDepartmentQueryResult = Apollo.QueryResult<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>;
export const GetAllEmployeeInformationInDepartmentForAllEmployeesDocument = gql`
    query GetAllEmployeeInformationInDepartmentForAllEmployees($selectedDepartmentId: String!) {
  getAllEmployeeInformationInDepartmentForAllEmployees(
    selectedDepartmentId: $selectedDepartmentId
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      todayWorkingTime
      weekMaxWorkHour
      wtTot
      weekMaxExtWorkHour
      wtAsextto
      userPosition
    }
  }
}
    `;

/**
 * __useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *   },
 * });
 */
export function useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>(GetAllEmployeeInformationInDepartmentForAllEmployeesDocument, options);
      }
export function useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>(GetAllEmployeeInformationInDepartmentForAllEmployeesDocument, options);
        }
export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery>;
export type GetAllEmployeeInformationInDepartmentForAllEmployeesLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery>;
export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryResult = Apollo.QueryResult<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>;
export const CountListOfApprovalDocument = gql`
    query CountListOfApproval($approvalType: APPROVAL_FOR_LIST_TYPE!, $searchType: ApprovalSearchType!, $searchValue: String) {
  countListOfApproval(
    approvalType: $approvalType
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useCountListOfApprovalQuery__
 *
 * To run a query within a React component, call `useCountListOfApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfApprovalQuery({
 *   variables: {
 *      approvalType: // value for 'approvalType'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useCountListOfApprovalQuery(baseOptions: Apollo.QueryHookOptions<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>(CountListOfApprovalDocument, options);
      }
export function useCountListOfApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>(CountListOfApprovalDocument, options);
        }
export type CountListOfApprovalQueryHookResult = ReturnType<typeof useCountListOfApprovalQuery>;
export type CountListOfApprovalLazyQueryHookResult = ReturnType<typeof useCountListOfApprovalLazyQuery>;
export type CountListOfApprovalQueryResult = Apollo.QueryResult<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>;
export const GetApprovalFormDetailDocument = gql`
    query GetApprovalFormDetail($employeeId: String!, $approvalRequestIdx: Int!) {
  getApprovalFormDetail(
    employeeId: $employeeId
    approvalRequestIdx: $approvalRequestIdx
  ) {
    ok
    error
    listOfApprovalUser {
      approvalRequestIdx
      approverId
      name
      approvalOrder
      approverType
      approvalResult
      approvalTime
      signType
      signText
      sign_image
      signImage
      viewTime
    }
    information {
      approvalRequestIdx
      formTemplateTitle
      employeeIdRequest
      departmentName
      employeeName
      userPosition
      docNumber
      signUpDateTime
      categoryName
      storePeriod
      formName
      extendSDate
      extendSDateTime
      extendEDate
      extendEDateTime
      approvalTitle
      extendDescription
      attachedFileName
      attachedFileLink
    }
    listOfReceiveDepartment {
      departmentId
      departmentName
    }
    listOfReceiveUser {
      employeeId
      employeeName
    }
    listOfReferenceDepartment {
      departmentId
      departmentName
    }
    listOfReferenceUser {
      employeeId
      employeeName
    }
  }
}
    `;

/**
 * __useGetApprovalFormDetailQuery__
 *
 * To run a query within a React component, call `useGetApprovalFormDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalFormDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalFormDetailQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetApprovalFormDetailQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>(GetApprovalFormDetailDocument, options);
      }
export function useGetApprovalFormDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>(GetApprovalFormDetailDocument, options);
        }
export type GetApprovalFormDetailQueryHookResult = ReturnType<typeof useGetApprovalFormDetailQuery>;
export type GetApprovalFormDetailLazyQueryHookResult = ReturnType<typeof useGetApprovalFormDetailLazyQuery>;
export type GetApprovalFormDetailQueryResult = Apollo.QueryResult<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>;
export const GetListOfApprovalHistoryDocument = gql`
    query GetListOfApprovalHistory($approvalRequestIdx: Int!) {
  getListOfApprovalHistory(approvalRequestIdx: $approvalRequestIdx) {
    ok
    error
    list {
      numRank
      empName
      userPosition
      departmentName
      approvalTime
      approvalResult
      descriptionComment
      viewTime
    }
  }
}
    `;

/**
 * __useGetListOfApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalHistoryQuery({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetListOfApprovalHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>(GetListOfApprovalHistoryDocument, options);
      }
export function useGetListOfApprovalHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>(GetListOfApprovalHistoryDocument, options);
        }
export type GetListOfApprovalHistoryQueryHookResult = ReturnType<typeof useGetListOfApprovalHistoryQuery>;
export type GetListOfApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalHistoryLazyQuery>;
export type GetListOfApprovalHistoryQueryResult = Apollo.QueryResult<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>;
export const GetListOfApprovalTemporaryStorageDocument = gql`
    query GetListOfApprovalTemporaryStorage($searchType: ApprovalSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getListOfApprovalTemporaryStorage(
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      approvalRequestIdx
      formTemplateIdx
      approvalType
      signUpDatetime
      approvalTitle
      categoryId
      formIdx
      extendStartDate
      extendEndDate
      extendStartDateTime
      extendEndDateTime
      name
      attachedFileName
      attachedFileLink
    }
  }
}
    `;

/**
 * __useGetListOfApprovalTemporaryStorageQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalTemporaryStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalTemporaryStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalTemporaryStorageQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetListOfApprovalTemporaryStorageQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>(GetListOfApprovalTemporaryStorageDocument, options);
      }
export function useGetListOfApprovalTemporaryStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>(GetListOfApprovalTemporaryStorageDocument, options);
        }
export type GetListOfApprovalTemporaryStorageQueryHookResult = ReturnType<typeof useGetListOfApprovalTemporaryStorageQuery>;
export type GetListOfApprovalTemporaryStorageLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalTemporaryStorageLazyQuery>;
export type GetListOfApprovalTemporaryStorageQueryResult = Apollo.QueryResult<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>;
export const CountListOfApprovalTemporaryStorageDocument = gql`
    query CountListOfApprovalTemporaryStorage($searchType: ApprovalSearchType!, $searchValue: String) {
  countListOfApprovalTemporaryStorage(
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useCountListOfApprovalTemporaryStorageQuery__
 *
 * To run a query within a React component, call `useCountListOfApprovalTemporaryStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfApprovalTemporaryStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfApprovalTemporaryStorageQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useCountListOfApprovalTemporaryStorageQuery(baseOptions: Apollo.QueryHookOptions<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>(CountListOfApprovalTemporaryStorageDocument, options);
      }
export function useCountListOfApprovalTemporaryStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>(CountListOfApprovalTemporaryStorageDocument, options);
        }
export type CountListOfApprovalTemporaryStorageQueryHookResult = ReturnType<typeof useCountListOfApprovalTemporaryStorageQuery>;
export type CountListOfApprovalTemporaryStorageLazyQueryHookResult = ReturnType<typeof useCountListOfApprovalTemporaryStorageLazyQuery>;
export type CountListOfApprovalTemporaryStorageQueryResult = Apollo.QueryResult<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>;
export const GetApprovalTemporaryStorageDetailInformationDocument = gql`
    query GetApprovalTemporaryStorageDetailInformation($employeeId: String!, $approvalRequestIdx: Int!) {
  getApprovalTemporaryStorageDetailInformation(
    employeeId: $employeeId
    approvalRequestIdx: $approvalRequestIdx
  ) {
    ok
    error
    listOfApprovalUser {
      approvalRequestIdx
      approverId
      name
      approvalOrder
      approverType
      approvalResult
      approvalTime
      signType
      signText
      sign_image
      signImage
    }
    information {
      approvalRequestIdx
      formTemplateTitle
      employeeIdRequest
      departmentName
      employeeName
      userPosition
      docNumber
      signUpDateTime
      categoryName
      storePeriod
      formName
      extendSDate
      extendSDateTime
      extendEDate
      extendEDateTime
      approvalTitle
      temporaryExtendDescription
      attachedFileName
      attachedFileLink
    }
    listOfReceiveDepartment {
      departmentId
      departmentName
    }
    listOfReceiveUser {
      employeeId
      employeeName
    }
    listOfReferenceDepartment {
      departmentId
      departmentName
    }
    listOfReferenceUser {
      employeeId
      employeeName
    }
  }
}
    `;

/**
 * __useGetApprovalTemporaryStorageDetailInformationQuery__
 *
 * To run a query within a React component, call `useGetApprovalTemporaryStorageDetailInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalTemporaryStorageDetailInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalTemporaryStorageDetailInformationQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetApprovalTemporaryStorageDetailInformationQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>(GetApprovalTemporaryStorageDetailInformationDocument, options);
      }
export function useGetApprovalTemporaryStorageDetailInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>(GetApprovalTemporaryStorageDetailInformationDocument, options);
        }
export type GetApprovalTemporaryStorageDetailInformationQueryHookResult = ReturnType<typeof useGetApprovalTemporaryStorageDetailInformationQuery>;
export type GetApprovalTemporaryStorageDetailInformationLazyQueryHookResult = ReturnType<typeof useGetApprovalTemporaryStorageDetailInformationLazyQuery>;
export type GetApprovalTemporaryStorageDetailInformationQueryResult = Apollo.QueryResult<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>;
export const DeleteApprovalTemporaryStorageDocument = gql`
    mutation DeleteApprovalTemporaryStorage($approvalRequestIdx: Int!) {
  deleteApprovalTemporaryStorage(approvalRequestIdx: $approvalRequestIdx) {
    ok
    error
  }
}
    `;
export type DeleteApprovalTemporaryStorageMutationFn = Apollo.MutationFunction<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>;

/**
 * __useDeleteApprovalTemporaryStorageMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalTemporaryStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalTemporaryStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalTemporaryStorageMutation, { data, loading, error }] = useDeleteApprovalTemporaryStorageMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useDeleteApprovalTemporaryStorageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>(DeleteApprovalTemporaryStorageDocument, options);
      }
export type DeleteApprovalTemporaryStorageMutationHookResult = ReturnType<typeof useDeleteApprovalTemporaryStorageMutation>;
export type DeleteApprovalTemporaryStorageMutationResult = Apollo.MutationResult<DeleteApprovalTemporaryStorageMutation>;
export type DeleteApprovalTemporaryStorageMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>;
export const GetListOfApprovalBasicLineUserDocument = gql`
    query GetListOfApprovalBasicLineUser($employeeId: String!) {
  getListOfApprovalBasicLineUser(employeeId: $employeeId) {
    ok
    error
    list {
      approvalOrder
      approvalType
      approvalInfo
      lineIdx
      employeeId
    }
  }
}
    `;

/**
 * __useGetListOfApprovalBasicLineUserQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalBasicLineUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalBasicLineUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalBasicLineUserQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalBasicLineUserQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>(GetListOfApprovalBasicLineUserDocument, options);
      }
export function useGetListOfApprovalBasicLineUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>(GetListOfApprovalBasicLineUserDocument, options);
        }
export type GetListOfApprovalBasicLineUserQueryHookResult = ReturnType<typeof useGetListOfApprovalBasicLineUserQuery>;
export type GetListOfApprovalBasicLineUserLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalBasicLineUserLazyQuery>;
export type GetListOfApprovalBasicLineUserQueryResult = Apollo.QueryResult<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>;
export const GetListOfApprovalTemplateDocument = gql`
    query GetListOfApprovalTemplate($employeeId: String!) {
  getListOfApprovalTemplate(employeeId: $employeeId) {
    ok
    error
    list {
      numRank
      formTemplateIdx
      templateTitle
      name
      formIdx
      formName
      approvalType
      description
    }
  }
}
    `;

/**
 * __useGetListOfApprovalTemplateQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalTemplateQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>(GetListOfApprovalTemplateDocument, options);
      }
export function useGetListOfApprovalTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>(GetListOfApprovalTemplateDocument, options);
        }
export type GetListOfApprovalTemplateQueryHookResult = ReturnType<typeof useGetListOfApprovalTemplateQuery>;
export type GetListOfApprovalTemplateLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalTemplateLazyQuery>;
export type GetListOfApprovalTemplateQueryResult = Apollo.QueryResult<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>;
export const GetListOfApprovalFormDocument = gql`
    query GetListOfApprovalForm {
  getListOfApprovalForm {
    ok
    error
    list {
      formIdx
      formName
    }
  }
}
    `;

/**
 * __useGetListOfApprovalFormQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListOfApprovalFormQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>(GetListOfApprovalFormDocument, options);
      }
export function useGetListOfApprovalFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>(GetListOfApprovalFormDocument, options);
        }
export type GetListOfApprovalFormQueryHookResult = ReturnType<typeof useGetListOfApprovalFormQuery>;
export type GetListOfApprovalFormLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalFormLazyQuery>;
export type GetListOfApprovalFormQueryResult = Apollo.QueryResult<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>;
export const GetListOfOtTimeTypeCategoryDocument = gql`
    query GetListOfOtTimeTypeCategory {
  getListOfOtTimeTypeCategory {
    ok
    error
    list {
      categoryId
      name
    }
  }
}
    `;

/**
 * __useGetListOfOtTimeTypeCategoryQuery__
 *
 * To run a query within a React component, call `useGetListOfOtTimeTypeCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfOtTimeTypeCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfOtTimeTypeCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListOfOtTimeTypeCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>(GetListOfOtTimeTypeCategoryDocument, options);
      }
export function useGetListOfOtTimeTypeCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>(GetListOfOtTimeTypeCategoryDocument, options);
        }
export type GetListOfOtTimeTypeCategoryQueryHookResult = ReturnType<typeof useGetListOfOtTimeTypeCategoryQuery>;
export type GetListOfOtTimeTypeCategoryLazyQueryHookResult = ReturnType<typeof useGetListOfOtTimeTypeCategoryLazyQuery>;
export type GetListOfOtTimeTypeCategoryQueryResult = Apollo.QueryResult<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>;
export const GetListOfApprovalLineDocument = gql`
    query GetListOfApprovalLine($employeeId: String!) {
  getListOfApprovalLine(employeeId: $employeeId) {
    ok
    error
    list {
      lineIdx
      newTitle
      basicLine
    }
  }
}
    `;

/**
 * __useGetListOfApprovalLineQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalLineQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalLineQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>(GetListOfApprovalLineDocument, options);
      }
export function useGetListOfApprovalLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>(GetListOfApprovalLineDocument, options);
        }
export type GetListOfApprovalLineQueryHookResult = ReturnType<typeof useGetListOfApprovalLineQuery>;
export type GetListOfApprovalLineLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLineLazyQuery>;
export type GetListOfApprovalLineQueryResult = Apollo.QueryResult<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>;
export const ControlApprovalFormDocument = gql`
    mutation ControlApprovalForm($controlApprovalFormType: TYPE_CONTROL_APPROVAL_FORM!, $formIdx: Int!, $templateName: String!, $categoryId: Int!, $approvalSort: APPROVAL_SORT!, $formTemplateIdx: Int, $description: String) {
  controlApprovalForm(
    controlApprovalFormType: $controlApprovalFormType
    formIdx: $formIdx
    templateName: $templateName
    categoryId: $categoryId
    approvalSort: $approvalSort
    formTemplateIdx: $formTemplateIdx
    description: $description
  ) {
    ok
    error
    formTemplateIdx
  }
}
    `;
export type ControlApprovalFormMutationFn = Apollo.MutationFunction<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>;

/**
 * __useControlApprovalFormMutation__
 *
 * To run a mutation, you first call `useControlApprovalFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useControlApprovalFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [controlApprovalFormMutation, { data, loading, error }] = useControlApprovalFormMutation({
 *   variables: {
 *      controlApprovalFormType: // value for 'controlApprovalFormType'
 *      formIdx: // value for 'formIdx'
 *      templateName: // value for 'templateName'
 *      categoryId: // value for 'categoryId'
 *      approvalSort: // value for 'approvalSort'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useControlApprovalFormMutation(baseOptions?: Apollo.MutationHookOptions<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>(ControlApprovalFormDocument, options);
      }
export type ControlApprovalFormMutationHookResult = ReturnType<typeof useControlApprovalFormMutation>;
export type ControlApprovalFormMutationResult = Apollo.MutationResult<ControlApprovalFormMutation>;
export type ControlApprovalFormMutationOptions = Apollo.BaseMutationOptions<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>;
export const DeleteApprovalTemplateFormDocument = gql`
    mutation DeleteApprovalTemplateForm($listOfFormTemplateIdx: [Int!]!) {
  deleteApprovalTemplateForm(listOfFormTemplateIdx: $listOfFormTemplateIdx) {
    ok
    error
  }
}
    `;
export type DeleteApprovalTemplateFormMutationFn = Apollo.MutationFunction<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>;

/**
 * __useDeleteApprovalTemplateFormMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalTemplateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalTemplateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalTemplateFormMutation, { data, loading, error }] = useDeleteApprovalTemplateFormMutation({
 *   variables: {
 *      listOfFormTemplateIdx: // value for 'listOfFormTemplateIdx'
 *   },
 * });
 */
export function useDeleteApprovalTemplateFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>(DeleteApprovalTemplateFormDocument, options);
      }
export type DeleteApprovalTemplateFormMutationHookResult = ReturnType<typeof useDeleteApprovalTemplateFormMutation>;
export type DeleteApprovalTemplateFormMutationResult = Apollo.MutationResult<DeleteApprovalTemplateFormMutation>;
export type DeleteApprovalTemplateFormMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>;
export const GetListOfSearchedApprovalLineUserDocument = gql`
    query GetListOfSearchedApprovalLineUser($employeeId: String!, $lineIdx: Int!) {
  getListOfSearchedApprovalLineUser(employeeId: $employeeId, lineIdx: $lineIdx) {
    ok
    error
    list {
      approvalOrder
      approvalType
      approvalInfo
      lineIdx
      employeeId
    }
  }
}
    `;

/**
 * __useGetListOfSearchedApprovalLineUserQuery__
 *
 * To run a query within a React component, call `useGetListOfSearchedApprovalLineUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfSearchedApprovalLineUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfSearchedApprovalLineUserQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      lineIdx: // value for 'lineIdx'
 *   },
 * });
 */
export function useGetListOfSearchedApprovalLineUserQuery(baseOptions: Apollo.QueryHookOptions<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>(GetListOfSearchedApprovalLineUserDocument, options);
      }
export function useGetListOfSearchedApprovalLineUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>(GetListOfSearchedApprovalLineUserDocument, options);
        }
export type GetListOfSearchedApprovalLineUserQueryHookResult = ReturnType<typeof useGetListOfSearchedApprovalLineUserQuery>;
export type GetListOfSearchedApprovalLineUserLazyQueryHookResult = ReturnType<typeof useGetListOfSearchedApprovalLineUserLazyQuery>;
export type GetListOfSearchedApprovalLineUserQueryResult = Apollo.QueryResult<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>;
export const AddApprovalLineDocument = gql`
    mutation AddApprovalLine($approvalLineControlType: ApprovalLineControlType!, $user: String!, $approvalLineName: String!, $isDefaultApprovalLine: IS_DEFAULT_APPROVAL_LINE!, $count: Int!, $list: [ApprovalLineUserEntity!]!) {
  addApprovalLine(
    approvalLineControlType: $approvalLineControlType
    user: $user
    approvalLineName: $approvalLineName
    isDefaultApprovalLine: $isDefaultApprovalLine
    count: $count
    list: $list
  ) {
    ok
    error
    lineIdx
  }
}
    `;
export type AddApprovalLineMutationFn = Apollo.MutationFunction<AddApprovalLineMutation, AddApprovalLineMutationVariables>;

/**
 * __useAddApprovalLineMutation__
 *
 * To run a mutation, you first call `useAddApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovalLineMutation, { data, loading, error }] = useAddApprovalLineMutation({
 *   variables: {
 *      approvalLineControlType: // value for 'approvalLineControlType'
 *      user: // value for 'user'
 *      approvalLineName: // value for 'approvalLineName'
 *      isDefaultApprovalLine: // value for 'isDefaultApprovalLine'
 *      count: // value for 'count'
 *      list: // value for 'list'
 *   },
 * });
 */
export function useAddApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<AddApprovalLineMutation, AddApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApprovalLineMutation, AddApprovalLineMutationVariables>(AddApprovalLineDocument, options);
      }
export type AddApprovalLineMutationHookResult = ReturnType<typeof useAddApprovalLineMutation>;
export type AddApprovalLineMutationResult = Apollo.MutationResult<AddApprovalLineMutation>;
export type AddApprovalLineMutationOptions = Apollo.BaseMutationOptions<AddApprovalLineMutation, AddApprovalLineMutationVariables>;
export const EditApprovalLineDocument = gql`
    mutation EditApprovalLine($approvalLineControlType: ApprovalLineControlType!, $user: String!, $approvalLineName: String!, $isDefaultApprovalLine: IS_DEFAULT_APPROVAL_LINE!, $count: Int!, $list: [ApprovalLineUserEntity!]!, $lineIdx: Int!) {
  editApprovalLine(
    approvalLineControlType: $approvalLineControlType
    user: $user
    approvalLineName: $approvalLineName
    isDefaultApprovalLine: $isDefaultApprovalLine
    count: $count
    list: $list
    lineIdx: $lineIdx
  ) {
    ok
    error
    lineIdx
  }
}
    `;
export type EditApprovalLineMutationFn = Apollo.MutationFunction<EditApprovalLineMutation, EditApprovalLineMutationVariables>;

/**
 * __useEditApprovalLineMutation__
 *
 * To run a mutation, you first call `useEditApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApprovalLineMutation, { data, loading, error }] = useEditApprovalLineMutation({
 *   variables: {
 *      approvalLineControlType: // value for 'approvalLineControlType'
 *      user: // value for 'user'
 *      approvalLineName: // value for 'approvalLineName'
 *      isDefaultApprovalLine: // value for 'isDefaultApprovalLine'
 *      count: // value for 'count'
 *      list: // value for 'list'
 *      lineIdx: // value for 'lineIdx'
 *   },
 * });
 */
export function useEditApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<EditApprovalLineMutation, EditApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditApprovalLineMutation, EditApprovalLineMutationVariables>(EditApprovalLineDocument, options);
      }
export type EditApprovalLineMutationHookResult = ReturnType<typeof useEditApprovalLineMutation>;
export type EditApprovalLineMutationResult = Apollo.MutationResult<EditApprovalLineMutation>;
export type EditApprovalLineMutationOptions = Apollo.BaseMutationOptions<EditApprovalLineMutation, EditApprovalLineMutationVariables>;
export const DeleteApprovalLineDocument = gql`
    mutation DeleteApprovalLine($lineIdx: Int!, $user: String!) {
  deleteApprovalLine(lineIdx: $lineIdx, user: $user) {
    ok
    error
  }
}
    `;
export type DeleteApprovalLineMutationFn = Apollo.MutationFunction<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>;

/**
 * __useDeleteApprovalLineMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalLineMutation, { data, loading, error }] = useDeleteApprovalLineMutation({
 *   variables: {
 *      lineIdx: // value for 'lineIdx'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>(DeleteApprovalLineDocument, options);
      }
export type DeleteApprovalLineMutationHookResult = ReturnType<typeof useDeleteApprovalLineMutation>;
export type DeleteApprovalLineMutationResult = Apollo.MutationResult<DeleteApprovalLineMutation>;
export type DeleteApprovalLineMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>;
export const GetApprovalAdminSettingDocument = gql`
    query GetApprovalAdminSetting {
  getApprovalAdminSetting {
    ok
    error
    preview
    dashboardListCount
  }
}
    `;

/**
 * __useGetApprovalAdminSettingQuery__
 *
 * To run a query within a React component, call `useGetApprovalAdminSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalAdminSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalAdminSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovalAdminSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>(GetApprovalAdminSettingDocument, options);
      }
export function useGetApprovalAdminSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>(GetApprovalAdminSettingDocument, options);
        }
export type GetApprovalAdminSettingQueryHookResult = ReturnType<typeof useGetApprovalAdminSettingQuery>;
export type GetApprovalAdminSettingLazyQueryHookResult = ReturnType<typeof useGetApprovalAdminSettingLazyQuery>;
export type GetApprovalAdminSettingQueryResult = Apollo.QueryResult<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>;
export const EditApprovalAdminSettingDocument = gql`
    mutation EditApprovalAdminSetting($preview: APPROVAL_ADMIN_SETTING_PREVIEW_TYPE!, $count: Int!) {
  editApprovalAdminSetting(preview: $preview, count: $count) {
    ok
    error
  }
}
    `;
export type EditApprovalAdminSettingMutationFn = Apollo.MutationFunction<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>;

/**
 * __useEditApprovalAdminSettingMutation__
 *
 * To run a mutation, you first call `useEditApprovalAdminSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApprovalAdminSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApprovalAdminSettingMutation, { data, loading, error }] = useEditApprovalAdminSettingMutation({
 *   variables: {
 *      preview: // value for 'preview'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useEditApprovalAdminSettingMutation(baseOptions?: Apollo.MutationHookOptions<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>(EditApprovalAdminSettingDocument, options);
      }
export type EditApprovalAdminSettingMutationHookResult = ReturnType<typeof useEditApprovalAdminSettingMutation>;
export type EditApprovalAdminSettingMutationResult = Apollo.MutationResult<EditApprovalAdminSettingMutation>;
export type EditApprovalAdminSettingMutationOptions = Apollo.BaseMutationOptions<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>;
export const GetApprovalPasswordAndSignSettingInfoDocument = gql`
    query GetApprovalPasswordAndSignSettingInfo($employeeId: String!) {
  getApprovalPasswordAndSignSettingInfo(employeeId: $employeeId) {
    ok
    error
    info {
      employeeId
      signType
      signText
      signImage
      signPassword
      isSignPassword
      isSignEmailAlarm
      isSignMobileAlarm
    }
  }
}
    `;

/**
 * __useGetApprovalPasswordAndSignSettingInfoQuery__
 *
 * To run a query within a React component, call `useGetApprovalPasswordAndSignSettingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPasswordAndSignSettingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPasswordAndSignSettingInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetApprovalPasswordAndSignSettingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>(GetApprovalPasswordAndSignSettingInfoDocument, options);
      }
export function useGetApprovalPasswordAndSignSettingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>(GetApprovalPasswordAndSignSettingInfoDocument, options);
        }
export type GetApprovalPasswordAndSignSettingInfoQueryHookResult = ReturnType<typeof useGetApprovalPasswordAndSignSettingInfoQuery>;
export type GetApprovalPasswordAndSignSettingInfoLazyQueryHookResult = ReturnType<typeof useGetApprovalPasswordAndSignSettingInfoLazyQuery>;
export type GetApprovalPasswordAndSignSettingInfoQueryResult = Apollo.QueryResult<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>;
export const SaveApprovalPasswordAndSignSettingDocument = gql`
    mutation SaveApprovalPasswordAndSignSetting($employeeId: String!, $signType: SignType!, $isSignPassword: IS_SIGN_PASSWORD!, $isSignEmailAlarm: IS_SIGN_ALARM_TYPE!, $isSignMobileAlarm: IS_SIGN_ALARM_TYPE!, $signText: String, $signImage: [String!], $signPassword: String) {
  saveApprovalPasswordAndSignSetting(
    employeeId: $employeeId
    signType: $signType
    isSignPassword: $isSignPassword
    isSignEmailAlarm: $isSignEmailAlarm
    isSignMobileAlarm: $isSignMobileAlarm
    signText: $signText
    signImage: $signImage
    signPassword: $signPassword
  ) {
    ok
    error
  }
}
    `;
export type SaveApprovalPasswordAndSignSettingMutationFn = Apollo.MutationFunction<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>;

/**
 * __useSaveApprovalPasswordAndSignSettingMutation__
 *
 * To run a mutation, you first call `useSaveApprovalPasswordAndSignSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApprovalPasswordAndSignSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApprovalPasswordAndSignSettingMutation, { data, loading, error }] = useSaveApprovalPasswordAndSignSettingMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      signType: // value for 'signType'
 *      isSignPassword: // value for 'isSignPassword'
 *      isSignEmailAlarm: // value for 'isSignEmailAlarm'
 *      isSignMobileAlarm: // value for 'isSignMobileAlarm'
 *      signText: // value for 'signText'
 *      signImage: // value for 'signImage'
 *      signPassword: // value for 'signPassword'
 *   },
 * });
 */
export function useSaveApprovalPasswordAndSignSettingMutation(baseOptions?: Apollo.MutationHookOptions<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>(SaveApprovalPasswordAndSignSettingDocument, options);
      }
export type SaveApprovalPasswordAndSignSettingMutationHookResult = ReturnType<typeof useSaveApprovalPasswordAndSignSettingMutation>;
export type SaveApprovalPasswordAndSignSettingMutationResult = Apollo.MutationResult<SaveApprovalPasswordAndSignSettingMutation>;
export type SaveApprovalPasswordAndSignSettingMutationOptions = Apollo.BaseMutationOptions<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>;
export const DeleteApprovalSignImageOrTextDocument = gql`
    mutation DeleteApprovalSignImageOrText($employeeId: String!, $deleteSignType: SignType!) {
  deleteApprovalSignImageOrText(
    employeeId: $employeeId
    deleteSignType: $deleteSignType
  ) {
    ok
    error
  }
}
    `;
export type DeleteApprovalSignImageOrTextMutationFn = Apollo.MutationFunction<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>;

/**
 * __useDeleteApprovalSignImageOrTextMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalSignImageOrTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalSignImageOrTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalSignImageOrTextMutation, { data, loading, error }] = useDeleteApprovalSignImageOrTextMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      deleteSignType: // value for 'deleteSignType'
 *   },
 * });
 */
export function useDeleteApprovalSignImageOrTextMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>(DeleteApprovalSignImageOrTextDocument, options);
      }
export type DeleteApprovalSignImageOrTextMutationHookResult = ReturnType<typeof useDeleteApprovalSignImageOrTextMutation>;
export type DeleteApprovalSignImageOrTextMutationResult = Apollo.MutationResult<DeleteApprovalSignImageOrTextMutation>;
export type DeleteApprovalSignImageOrTextMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>;
export const GetListOfApprovalLineFormDocument = gql`
    query GetListOfApprovalLineForm($employeeId: String!, $formTemplateIdx: Int!) {
  getListOfApprovalLineForm(
    employeeId: $employeeId
    formTemplateIdx: $formTemplateIdx
  ) {
    ok
    error
    list {
      numRank
      templateTitle
      approvalType
      formName
      name
      descr
      formTemplateIdx
      formIdx
    }
  }
}
    `;

/**
 * __useGetListOfApprovalLineFormQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalLineFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalLineFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalLineFormQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *   },
 * });
 */
export function useGetListOfApprovalLineFormQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>(GetListOfApprovalLineFormDocument, options);
      }
export function useGetListOfApprovalLineFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>(GetListOfApprovalLineFormDocument, options);
        }
export type GetListOfApprovalLineFormQueryHookResult = ReturnType<typeof useGetListOfApprovalLineFormQuery>;
export type GetListOfApprovalLineFormLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLineFormLazyQuery>;
export type GetListOfApprovalLineFormQueryResult = Apollo.QueryResult<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>;
export const GetBasicUserInfoDocument = gql`
    query GetBasicUserInfo($getBasicUserInfoId: String) {
  getBasicUserInfo(id: $getBasicUserInfoId) {
    ok
    error
    user {
      empName
      my_picture
      myPicture
      departmentId
      nameChinese
      nameEnglish
      departmentName
      userJob
      userPosition
      userRank
      joiningDate
      leavingDate
      phoneNum
      userEmail
    }
  }
}
    `;

/**
 * __useGetBasicUserInfoQuery__
 *
 * To run a query within a React component, call `useGetBasicUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicUserInfoQuery({
 *   variables: {
 *      getBasicUserInfoId: // value for 'getBasicUserInfoId'
 *   },
 * });
 */
export function useGetBasicUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>(GetBasicUserInfoDocument, options);
      }
export function useGetBasicUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>(GetBasicUserInfoDocument, options);
        }
export type GetBasicUserInfoQueryHookResult = ReturnType<typeof useGetBasicUserInfoQuery>;
export type GetBasicUserInfoLazyQueryHookResult = ReturnType<typeof useGetBasicUserInfoLazyQuery>;
export type GetBasicUserInfoQueryResult = Apollo.QueryResult<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>;
export const UpdateBasicUserinfoDocument = gql`
    mutation UpdateBasicUserinfo($employeeId: String!, $employeeName: String!, $myPicture: String!, $nameChinese: String, $nameEnglish: String, $userJob: String, $userPosition: String, $userRank: String, $joiningDate: String, $leavingDate: String, $phoneNum: String, $userEmail: String) {
  updateBasicUserinfo(
    employeeId: $employeeId
    employeeName: $employeeName
    myPicture: $myPicture
    nameChinese: $nameChinese
    nameEnglish: $nameEnglish
    userJob: $userJob
    userPosition: $userPosition
    userRank: $userRank
    joiningDate: $joiningDate
    leavingDate: $leavingDate
    phoneNum: $phoneNum
    userEmail: $userEmail
  ) {
    ok
    error
  }
}
    `;
export type UpdateBasicUserinfoMutationFn = Apollo.MutationFunction<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>;

/**
 * __useUpdateBasicUserinfoMutation__
 *
 * To run a mutation, you first call `useUpdateBasicUserinfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicUserinfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicUserinfoMutation, { data, loading, error }] = useUpdateBasicUserinfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      employeeName: // value for 'employeeName'
 *      myPicture: // value for 'myPicture'
 *      nameChinese: // value for 'nameChinese'
 *      nameEnglish: // value for 'nameEnglish'
 *      userJob: // value for 'userJob'
 *      userPosition: // value for 'userPosition'
 *      userRank: // value for 'userRank'
 *      joiningDate: // value for 'joiningDate'
 *      leavingDate: // value for 'leavingDate'
 *      phoneNum: // value for 'phoneNum'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUpdateBasicUserinfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>(UpdateBasicUserinfoDocument, options);
      }
export type UpdateBasicUserinfoMutationHookResult = ReturnType<typeof useUpdateBasicUserinfoMutation>;
export type UpdateBasicUserinfoMutationResult = Apollo.MutationResult<UpdateBasicUserinfoMutation>;
export type UpdateBasicUserinfoMutationOptions = Apollo.BaseMutationOptions<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>;
export const GetUserAnnualInfoDocument = gql`
    query GetUserAnnualInfo($employeeId: String!, $annualYear: String!) {
  getUserAnnualInfo(employeeId: $employeeId, annualYear: $annualYear) {
    ok
    error
    userAnnualInfo {
      employeeId
      applyYear
      statusFlag
      insertDatetime
      createSubject
      periodateStart
      periodateEnd
      basicDay
      basicModifyDay
      addDay
      leaveCarriedForwardDay
      useModifyDay
      modifyReason
      modifyDatetime
      useDay
      leaveDay
    }
  }
}
    `;

/**
 * __useGetUserAnnualInfoQuery__
 *
 * To run a query within a React component, call `useGetUserAnnualInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnnualInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnnualInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      annualYear: // value for 'annualYear'
 *   },
 * });
 */
export function useGetUserAnnualInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>(GetUserAnnualInfoDocument, options);
      }
export function useGetUserAnnualInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>(GetUserAnnualInfoDocument, options);
        }
export type GetUserAnnualInfoQueryHookResult = ReturnType<typeof useGetUserAnnualInfoQuery>;
export type GetUserAnnualInfoLazyQueryHookResult = ReturnType<typeof useGetUserAnnualInfoLazyQuery>;
export type GetUserAnnualInfoQueryResult = Apollo.QueryResult<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>;
export const UpdateUserAnnualInfoDocument = gql`
    mutation UpdateUserAnnualInfo($employeeId: String!, $applyYear: String!, $createSubject: String!, $createSubjectEmployeeId: String!, $periodateStart: String!, $periodateEnd: String!, $basicDay: Int!, $basicModifyDay: Int!, $addDay: Int!, $leaveCarriedForwardDay: Int!, $useModifyDay: Int!, $statusFlag: String, $modifyReason: String) {
  updateUserAnnualInfo(
    employeeId: $employeeId
    applyYear: $applyYear
    createSubject: $createSubject
    createSubjectEmployeeId: $createSubjectEmployeeId
    periodateStart: $periodateStart
    periodateEnd: $periodateEnd
    basicDay: $basicDay
    basicModifyDay: $basicModifyDay
    addDay: $addDay
    leaveCarriedForwardDay: $leaveCarriedForwardDay
    useModifyDay: $useModifyDay
    statusFlag: $statusFlag
    modifyReason: $modifyReason
  ) {
    ok
    error
  }
}
    `;
export type UpdateUserAnnualInfoMutationFn = Apollo.MutationFunction<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>;

/**
 * __useUpdateUserAnnualInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserAnnualInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAnnualInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAnnualInfoMutation, { data, loading, error }] = useUpdateUserAnnualInfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *      createSubject: // value for 'createSubject'
 *      createSubjectEmployeeId: // value for 'createSubjectEmployeeId'
 *      periodateStart: // value for 'periodateStart'
 *      periodateEnd: // value for 'periodateEnd'
 *      basicDay: // value for 'basicDay'
 *      basicModifyDay: // value for 'basicModifyDay'
 *      addDay: // value for 'addDay'
 *      leaveCarriedForwardDay: // value for 'leaveCarriedForwardDay'
 *      useModifyDay: // value for 'useModifyDay'
 *      statusFlag: // value for 'statusFlag'
 *      modifyReason: // value for 'modifyReason'
 *   },
 * });
 */
export function useUpdateUserAnnualInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>(UpdateUserAnnualInfoDocument, options);
      }
export type UpdateUserAnnualInfoMutationHookResult = ReturnType<typeof useUpdateUserAnnualInfoMutation>;
export type UpdateUserAnnualInfoMutationResult = Apollo.MutationResult<UpdateUserAnnualInfoMutation>;
export type UpdateUserAnnualInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>;
export const GetAnnualHistoryDocument = gql`
    query GetAnnualHistory($employeeId: String!, $applyYear: String!) {
  getAnnualHistory(employeeId: $employeeId, applyYear: $applyYear) {
    ok
    error
    history {
      logIdx
      employeeId
      applyYear
      statusFlag
      createSubject
      periodateStart
      periodateEnd
      basicDay
      basicModifyDay
      addDay
      leaveCarriedForwardDay
      useModifyDay
      modifyReason
      modifyDatetime
    }
  }
}
    `;

/**
 * __useGetAnnualHistoryQuery__
 *
 * To run a query within a React component, call `useGetAnnualHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualHistoryQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *   },
 * });
 */
export function useGetAnnualHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>(GetAnnualHistoryDocument, options);
      }
export function useGetAnnualHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>(GetAnnualHistoryDocument, options);
        }
export type GetAnnualHistoryQueryHookResult = ReturnType<typeof useGetAnnualHistoryQuery>;
export type GetAnnualHistoryLazyQueryHookResult = ReturnType<typeof useGetAnnualHistoryLazyQuery>;
export type GetAnnualHistoryQueryResult = Apollo.QueryResult<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>;
export const DownloadAnnualHistoryAsExcelDocument = gql`
    query DownloadAnnualHistoryAsExcel($employeeId: String!, $applyYear: String!) {
  downloadAnnualHistoryAsExcel(employeeId: $employeeId, applyYear: $applyYear) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useDownloadAnnualHistoryAsExcelQuery__
 *
 * To run a query within a React component, call `useDownloadAnnualHistoryAsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAnnualHistoryAsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAnnualHistoryAsExcelQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *   },
 * });
 */
export function useDownloadAnnualHistoryAsExcelQuery(baseOptions: Apollo.QueryHookOptions<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>(DownloadAnnualHistoryAsExcelDocument, options);
      }
export function useDownloadAnnualHistoryAsExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>(DownloadAnnualHistoryAsExcelDocument, options);
        }
export type DownloadAnnualHistoryAsExcelQueryHookResult = ReturnType<typeof useDownloadAnnualHistoryAsExcelQuery>;
export type DownloadAnnualHistoryAsExcelLazyQueryHookResult = ReturnType<typeof useDownloadAnnualHistoryAsExcelLazyQuery>;
export type DownloadAnnualHistoryAsExcelQueryResult = Apollo.QueryResult<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>;
export const CountListOfEmployeeInformationDocument = gql`
    query CountListOfEmployeeInformation($selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String) {
  countListOfEmployeeInformation(
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    total
  }
}
    `;

/**
 * __useCountListOfEmployeeInformationQuery__
 *
 * To run a query within a React component, call `useCountListOfEmployeeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfEmployeeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfEmployeeInformationQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useCountListOfEmployeeInformationQuery(baseOptions: Apollo.QueryHookOptions<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>(CountListOfEmployeeInformationDocument, options);
      }
export function useCountListOfEmployeeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>(CountListOfEmployeeInformationDocument, options);
        }
export type CountListOfEmployeeInformationQueryHookResult = ReturnType<typeof useCountListOfEmployeeInformationQuery>;
export type CountListOfEmployeeInformationLazyQueryHookResult = ReturnType<typeof useCountListOfEmployeeInformationLazyQuery>;
export type CountListOfEmployeeInformationQueryResult = Apollo.QueryResult<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>;
export const GetEmployeeInformationDocument = gql`
    query GetEmployeeInformation($page: Float = 1, $take: Float = 10, $selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String, $fieldSort: FieldSort) {
  getEmployeeInformation(
    page: $page
    take: $take
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
    fieldSort: $fieldSort
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col3
      col2
      col1
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      wtAsextto
      weekMaxExtWorkHour
      wtTot
      weekMaxWorkHour
      todayWorkingTime
    }
  }
}
    `;

/**
 * __useGetEmployeeInformationQuery__
 *
 * To run a query within a React component, call `useGetEmployeeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeInformationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetEmployeeInformationQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>(GetEmployeeInformationDocument, options);
      }
export function useGetEmployeeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>(GetEmployeeInformationDocument, options);
        }
export type GetEmployeeInformationQueryHookResult = ReturnType<typeof useGetEmployeeInformationQuery>;
export type GetEmployeeInformationLazyQueryHookResult = ReturnType<typeof useGetEmployeeInformationLazyQuery>;
export type GetEmployeeInformationQueryResult = Apollo.QueryResult<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>;
export const DownloadListOfEmployeeInformationExcelDocument = gql`
    query DownloadListOfEmployeeInformationExcel($selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String) {
  downloadListOfEmployeeInformationExcel(
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useDownloadListOfEmployeeInformationExcelQuery__
 *
 * To run a query within a React component, call `useDownloadListOfEmployeeInformationExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadListOfEmployeeInformationExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadListOfEmployeeInformationExcelQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useDownloadListOfEmployeeInformationExcelQuery(baseOptions: Apollo.QueryHookOptions<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>(DownloadListOfEmployeeInformationExcelDocument, options);
      }
export function useDownloadListOfEmployeeInformationExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>(DownloadListOfEmployeeInformationExcelDocument, options);
        }
export type DownloadListOfEmployeeInformationExcelQueryHookResult = ReturnType<typeof useDownloadListOfEmployeeInformationExcelQuery>;
export type DownloadListOfEmployeeInformationExcelLazyQueryHookResult = ReturnType<typeof useDownloadListOfEmployeeInformationExcelLazyQuery>;
export type DownloadListOfEmployeeInformationExcelQueryResult = Apollo.QueryResult<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($getUserId: String, $name: String, $departmentId: Int) {
  getUser(id: $getUserId, name: $name, departmentId: $departmentId) {
    ok
    error
    user {
      employeeId
      departmentId
      fullDepartmentName
      workingTemplateIdx
      timeTemplateIdx
      leftSeatUse
      asonicWidgetUse
      approvalDefine
      name
      password
      newData
      newDataTime
      manageable
      insertType
      policyWorkingMin
      workingMin
      policy_ext_working_min
      extWorkingMin
      tokenField
      myPicture
      fcmToken
      delegatorId
      approvalStartDate
      approvalEndDate
      userEmail
      webAppAuth
      appPhoneNum
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      fpProcess
      policyUpdateStatus
      fpProcessTwo
      policyUpdateStatusTwo
      memo
      userPosition
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *      name: // value for 'name'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetGroupsForAllDocument = gql`
    query GetGroupsForAll {
  getGroupsForAll {
    ok
    error
    listOfGroup {
      departmentId
      parentDepartmentId
      name
      level
      fullGroupName
      insertType
      countEmployee
      countEndpoint
      memo1
      memo2
      workingName
      deptSort
    }
  }
}
    `;

/**
 * __useGetGroupsForAllQuery__
 *
 * To run a query within a React component, call `useGetGroupsForAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsForAllQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>(GetGroupsForAllDocument, options);
      }
export function useGetGroupsForAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>(GetGroupsForAllDocument, options);
        }
export type GetGroupsForAllQueryHookResult = ReturnType<typeof useGetGroupsForAllQuery>;
export type GetGroupsForAllLazyQueryHookResult = ReturnType<typeof useGetGroupsForAllLazyQuery>;
export type GetGroupsForAllQueryResult = Apollo.QueryResult<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>;