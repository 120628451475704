import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";

const APPROVAL_MANAGEMENT = "결재관리",
  STATISTICS_MANAGEMENT = "통계관리",
  WORK_MANAGEMENT_INFO = "근무관리정보",
  ENVIRONMENT_SETTINGS = "환경설정",
  BASIC_SETTING = "기본설정",
  // ANNUAL_SETTING = "연차환경설정",
  SUPER_ADMIN_SETTINGS = "Super admin 설정",
  CALENDAR = "달력",
  ADMIN_STATISTICS = "관리자 통계",
  USERS_STATISTICS = "사용자 통계",
  DASH_BOARD = "DashBoard",
  DASH_BOARD_MASTER = "팀장",
  DASH_BOARD_PERSONAL = "개인",
  VEHICLE_DRIVING_MANAGEMENT = "차량주행관리",
  RESERVATION_MANAGEMENT = "예약정보관리",
  APPROVAL_DASHBOARD = "대시보드",
  APPROVAL_LIST = "결재 목록",
  APPROVAL_TEMPORARY_DOCUMENT = "임시 저장 문서",
  APPROVAL_SETTING = "결재 설정";

const useTitleOfTitleBar = () => {
  const isMounted = useRef<boolean>(false);
  const [mainTitle, setMainTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const pathName = useLocation().pathname;
  const handleTitle = useCallback(() => {
    if (isMounted.current) {
      switch (pathName) {
        case routes.pageRoutes.home:
          setMainTitle(DASH_BOARD);
          setSubTitle(DASH_BOARD_MASTER);
          break;
        case routes.pageRoutes.personal:
          setMainTitle(DASH_BOARD);
          setSubTitle(DASH_BOARD_PERSONAL);
          break;
        case routes.pageRoutes.approvalDashboard:
          setMainTitle(APPROVAL_MANAGEMENT);
          setSubTitle(APPROVAL_DASHBOARD);
          break;
        case routes.pageRoutes.approvalList:
          setMainTitle(APPROVAL_MANAGEMENT);
          setSubTitle(APPROVAL_LIST);
          break;
        case routes.pageRoutes.approvalTemporaryDocument:
          setMainTitle(APPROVAL_MANAGEMENT);
          setSubTitle(APPROVAL_TEMPORARY_DOCUMENT);
          break;
        case routes.pageRoutes.approvalSetting:
          setMainTitle(APPROVAL_MANAGEMENT);
          setSubTitle(APPROVAL_SETTING);
          break;
        case routes.pageRoutes.statisticsAdmin:
          setMainTitle(STATISTICS_MANAGEMENT);
          setSubTitle(ADMIN_STATISTICS);
          break;
        case routes.pageRoutes.statisticsUser:
          setMainTitle(STATISTICS_MANAGEMENT);
          setSubTitle(USERS_STATISTICS);
          break;
        case routes.pageRoutes.workManagementInformation:
          setMainTitle(WORK_MANAGEMENT_INFO);
          setSubTitle("");
          break;
        case routes.pageRoutes.vehicleDrivingManagement:
          setMainTitle(VEHICLE_DRIVING_MANAGEMENT);
          setSubTitle("");
          break;
        case routes.pageRoutes.reservationManagement:
          setMainTitle(RESERVATION_MANAGEMENT);
          setSubTitle("");
          break;
        case routes.pageRoutes.settings:
          setMainTitle(ENVIRONMENT_SETTINGS);
          setSubTitle(BASIC_SETTING);
          break;
        case routes.pageRoutes.superAdminSettings:
          setMainTitle(SUPER_ADMIN_SETTINGS);
          setSubTitle("");
          break;
        case routes.pageRoutes.calendar:
          setMainTitle(CALENDAR);
          setSubTitle("");
          break;
        case routes.pageRoutes.annualEnvSetting:
          setMainTitle(ENVIRONMENT_SETTINGS);
          setSubTitle(ADMIN_STATISTICS);
          break;
        default:
          break;
      }
    }
  }, [pathName]);

  useEffect(() => {
    isMounted.current = true;
    handleTitle();
    return () => {
      isMounted.current = false;
    };
  }, [handleTitle]);
  return { mainTitle, subTitle };
};

export default useTitleOfTitleBar;
