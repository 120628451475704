import { useCallback, useMemo, useState } from "react";

interface IUseAsonicTabProps<P> {
  tabs: P;
}

export interface ITab<T> {
  key: T;
  value: string;
}

function useAsonicTab<P extends {}, T extends string>({
  tabs
}: IUseAsonicTabProps<P>) {
  const list: ITab<T>[] = useMemo((): ITab<T>[] => {
    return Object.entries(tabs).map(([key, value]) => {
      return {
        key: key as T,
        value: value as string
      };
    });
  }, [tabs]);

  const [selectedTab, setSelectedTab] = useState<T>(list[0].key);
  const handleSelectTab = useCallback((payload: T) => {
    setSelectedTab(payload);
  }, []);
  return { list, selectedTab, handleSelectTab };
}

export default useAsonicTab;
