import { AutoSizer } from "react-virtualized";
import AsonicTableTitleBar, {
  IAsonicTableTitleBarProps
} from "../asonic-table/asonic-table-title-bar";
import AsonicRenderRow, {
  IAsonicRenderRowProps,
  IAsonicRow
} from "../asonic-table/asonic-render-row";
import { FixedSizeList as List } from "react-window";
import ReactLoading from "react-loading";
import styled from "styled-components";
import * as ReactTable from "react-table";
import { colors } from "../GlobalStyle/GlobalStyle";
import { FieldSort } from "../../generated/graphql";
import AsonicPageController, {
  IAsonicPageControllerProps
} from "../asonic-table/asonic-page-controller";
import { useMemo } from "react";
import AsonicTableHeaderRow from "../asonic-table/asonic-table-header-row";
import AsonicTableBody from "../asonic-table/asonic-table-body";
import AsonicTableContainer from "../asonic-table/asonic-table-container";
import AsonicTableHead from "../asonic-table/asonic-table-head";
import AsonicTableLayout from "../asonic-table/asonic-table-layout";
import NewAsonicTableColumn, {
  TMoveColumn
} from "./new-asonic-table-column/new-asonic-table-column";

interface IAsonicHeaderGroup<P extends {}>
  extends ReactTable.HeaderGroup<P extends object ? P : {}> {}

interface IAsonicHeaderGroups<P extends {}> extends IAsonicHeaderGroup<P> {}

export interface IAsonicTableProps<P extends object>
  extends IAsonicTableTitleBarProps<P>,
    Partial<IAsonicPageControllerProps>,
    IAsonicRenderRowProps<P> {
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  headerGroups: IAsonicHeaderGroups<P>[];
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: IAsonicRow<P>[];
  isLoading?: boolean;
  handleFieldSort?: (payload?: FieldSort) => void;
  fieldSort?: FieldSort;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  isTitleBar?: boolean;
  itemSize?: number;
  flexIndex?: number;
}

const Content = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  align-items: ${props => (props.isLoading ? "center" : "normal")};
  flex-direction: column;
`;

function NewAsonicTable<P extends object>({
  title,
  total,
  take,
  currentPage,
  handleCurrentPage,
  handleTake,
  downloadExcel,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  handleSelectRow,
  isLoading,
  listOfSearchType,
  handleSearch,
  handleContextMenu,
  selectedRow,
  handleDate,
  handleFieldSort,
  fieldSort,
  listOfColumnDisabled,
  columns,
  toggleHideColumn,
  moveColumn,
  isTitleBar = true,
  flexIndex,
  itemSize = 30
}: IAsonicTableProps<P>) {
  const totalPage = useMemo(
    () => take && total && Math.ceil(total / take),
    [take, total]
  );
  return (
    <AsonicTableLayout>
      {isTitleBar && (
        <AsonicTableTitleBar
          total={total}
          title={title}
          handleTake={handleTake}
          take={take}
          downloadExcel={downloadExcel}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          handleDate={handleDate}
          columns={columns}
          toggleHideColumn={toggleHideColumn}
        />
      )}
      <Content isLoading={isLoading}>
        {isLoading ? (
          <ReactLoading
            type="spokes"
            color={colors.green}
            height={200}
            width={170}
          />
        ) : (
          <AsonicTableContainer {...getTableProps()}>
            <AsonicTableHead>
              {headerGroups.map(headerGroup => (
                <AsonicTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <NewAsonicTableColumn
                      key={column.id}
                      column={column}
                      index={index}
                      fieldSort={fieldSort}
                      handleFieldSort={handleFieldSort}
                      listOfColumnDisabled={listOfColumnDisabled}
                      moveColumn={moveColumn}
                      title={title}
                      isFlex={flexIndex === index}
                      isLastFlex={flexIndex && flexIndex >= 0 ? false : true}
                    />
                  ))}
                </AsonicTableHeaderRow>
              ))}
            </AsonicTableHead>
            <AsonicTableBody {...getTableBodyProps()}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      height={height}
                      itemCount={rows.length}
                      itemData={rows}
                      itemSize={itemSize}
                      width={width}
                    >
                      {AsonicRenderRow({
                        prepareRow: prepareRow,
                        handleSelectRow: handleSelectRow,
                        handleContextMenu: handleContextMenu,
                        selectedRow: selectedRow,
                        flexIndex
                      })}
                    </List>
                  );
                }}
              </AutoSizer>
            </AsonicTableBody>
          </AsonicTableContainer>
        )}
        {totalPage !== undefined &&
          currentPage &&
          handleCurrentPage &&
          take &&
          handleTake && (
            <AsonicPageController
              totalPage={totalPage}
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              take={take}
              handleTake={handleTake}
            />
          )}
      </Content>
    </AsonicTableLayout>
  );
}

export default NewAsonicTable;
