import PropTypes from "prop-types";
import {
  Container,
  TitleContainer,
  Divider,
  DragLine,
  DragContainer
} from "./styled";
import uiString from "./uiString.json";
import List from "./List";
import SearchContainer from "../../../../containers/main-view/WorkManagementInfoContainer/SearchContainer";
import RightMenuContainer from "../../../Rightmenu/RightMenuContainer";
import WorkingTemplateManagementMenu from "../../../Rightmenu/working-template-management-menu/working-template-management-menu";
import { useRef } from "react";
import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useWorkingTemplateManagement from "../../../../hooks/right-menu-hook/use-working-template-management/use-working-template-management";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import SubTitle from "../../../shared/sub-title/sub-title";

const Tree = props => {
  const {
    drawTreeFromData,
    treeData,
    treeWindowMouseDown,
    fullDepartmentNameList,
    term,
    updateTerm,
    updateSearchDe,
    searchedDe,
    upItem,
    downItem,
    searchSelectedIndex,
    selectedTreeItem,
    setSelectedTreeItem,
    workingTemplate,
    treeWidth,
    isMenuOpen
  } = props;
  const rootDom = useRef();
  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const {
    selectedWorkingTemplate,
    selectTemplate,
    handleDepartmentWorkingTemplate
  } = useWorkingTemplateManagement({
    selectedDepartment: selectedTreeItem,
    handleIsOpen,
    handleConfirmMessage,
    handleIsToastMessageOpen,
    handleMessage,
    handleToastMessageType
  });

  return (
    <DragContainer isMenuOpen={isMenuOpen} treeWidth={treeWidth}>
      <Container>
        <Divider>
          <TitleContainer>
            <SubTitle title={uiString.title} />
          </TitleContainer>
          <SearchContainer
            term={term}
            updateTerm={updateTerm}
            updateSearchDe={updateSearchDe}
            upItem={upItem}
            downItem={downItem}
          />
          <List
            ref={rootDom}
            treeData={treeData}
            drawTreeFromData={drawTreeFromData}
            fullDepartmentNameList={fullDepartmentNameList}
            selectedTreeItem={selectedTreeItem}
            searchedDe={searchedDe}
            searchSelectedIndex={searchSelectedIndex}
            setSelectedTreeItem={setSelectedTreeItem}
          />
        </Divider>
      </Container>
      <DragLine onMouseDown={treeWindowMouseDown} />
      <RightMenuContainer rootDom={rootDom.current}>
        <WorkingTemplateManagementMenu
          workingTemplate={workingTemplate}
          selectTemplate={selectTemplate}
        />
      </RightMenuContainer>
      {selectedWorkingTemplate && isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleDepartmentWorkingTemplate}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </DragContainer>
  );
};

Tree.propTypes = {
  drawTreeFromData: PropTypes.func.isRequired,
  treeData: PropTypes.array.isRequired,
  treeWindowMouseDown: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  fullDepartmentNameList: PropTypes.object,
  term: PropTypes.string,
  updateTerm: PropTypes.func.isRequired,
  updateSearchDe: PropTypes.func.isRequired
};

export default Tree;
