import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import { MessageTypes } from "../../../toast-message/toast-message";
import { Fragment, useEffect } from "react";

const AlertDialog = props => {
  const { confirmTitle, confirmParagraph, handleConfirmMessage, confirmType } =
    useConfirmDialog();

  useEffect(() => {
    handleConfirmMessage({
      title: `결재신청`,
      p: `해당 결재를 상신하시겠습니까?`,
      messageTypes: MessageTypes.INFO
    });
  }, [handleConfirmMessage]);
  return (
    <Fragment>
      {props.open && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          handleIsOpen={value => {
            props.handleClose("Disagree")();
          }}
          confirmType={confirmType}
          handleConfirm={() => {
            props.handleClose("Agree")();
          }}
        />
      )}
    </Fragment>
  );
};

export default AlertDialog;
