import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DragContainer = styled.div`
  min-width: 200px;
  width: ${props => {
    if (props.treeWidth) {
      if (props.isMenuOpen) {
        if (props.treeWidth > 1000) {
          return `${1000 - 200}px`;
        } else {
          return `${props.treeWidth - 200}px`;
        }
      } else if (!props.isMenuOpen) {
        if (props.treeWidth > 1000) {
          return `${1000 - 50}px`;
        } else {
          return `${props.treeWidth - 50}px`;
        }
      }
    } else {
      return "25%";
    }
  }};
  display: flex;
  justify-content: space-between;
`;

export const DragLine = styled.div`
  cursor: col-resize;
  height: 100%;
  border-right: 2px solid ${props => props.theme.colors.frameGrey};
  user-select: none;
  :hover {
    border-right: 2px solid ${props => props.theme.colors.lightMidNightBlue};
  }
  :active {
    border-right: 2px solid ${props => props.theme.colors.lightGreen};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  position: sticky;
  top: 0px;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ItemList = styled.ul`
  display: ${props => (props.isOpened ? "block" : "none")};
  padding: 0px 0px 0px 12px;
`;

export const ContentTitle = styled.span`
  width: max-content;
  min-width: max-content;
  text-decoration: ${props => (props.searched ? "underline" : "none")};
  border-bottom: ${props => (props.searched ? "1px solid black" : "none")};
  color: ${props => {
    if (props.searched) {
      return "#686de0";
    }
    return props.selectedTreeItem
      ? props.theme.colors.lightGreen
      : props.theme.colors.black;
  }};
`;

export const Item = styled.li`
  width: 100%;
  min-width: max-content;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightMidNightBlue};
    ${ContentTitle} {
      color: white;
    }
  }
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 1;
  color: ${props => props.theme.colors.green};
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  :not(:last-child) {
    margin: 0 0 8px 0;
  }
`;

export const Divider = styled.div`
  overflow: auto;
  flex: auto;
  padding: 10px;
`;

export const SearchContainer = styled.div`
  box-sizing: border-box;
  position: sticky;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SearchInput = styled.input`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  outline: 0;
  min-width: 40px;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const UpBtn = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 10px;
  color: ${props => props.theme.colors.green};
  transform: rotate(-90deg);
  :active {
    transform: rotate(-90deg) scale(0.9);
  }
  margin-bottom: 2px;
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
`;

export const DownBtn = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.colors.green};
  font-size: 10px;
  transform: rotate(90deg);
  :active {
    transform: rotate(90deg) scale(0.9);
  }
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
`;
