import * as React from "react";
import Communication from "../../../../components/electron/communication";
import CommunicationWithMac, {
  EPacketType
} from "../../../../components/electron/communication-with-mac";

const useGoShortcutButton = () => {
  const handleShortCutButton = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const token = localStorage.getItem("token") ?? undefined;
      Communication.getInstance()?.openUrlInElectron(
        `${window.location.protocol}//${window.location.host}`,
        token
      );
      const instance = CommunicationWithMac.getInstance();
      if (instance) {
        instance.sendCommunicationPacket({
          type: EPacketType.OpenUrl,
          payload: {
            url: `${window.location.protocol}//${window.location.host}`
          }
        });
      }
    },
    []
  );
  return { handleShortCutButton };
};

export default useGoShortcutButton;
