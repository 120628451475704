import { useCallback, useState } from "react";
import { FieldSort } from "../../generated/graphql";

function useNewSortBy() {
  const [fieldSort, setFieldSort] = useState<FieldSort | undefined>();
  const [listOfColumnDisabled, setListOfColumnDisabled] = useState<string[]>(
    []
  );

  const handleListOfColumnDisabled = useCallback((payload: string[]) => {
    setListOfColumnDisabled(payload);
  }, []);

  const handleFieldSort = useCallback((payload?: FieldSort) => {
    setFieldSort(payload);
  }, []);

  return {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  };
}

export default useNewSortBy;
