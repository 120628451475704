import moment from "moment";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SearchFieldValues } from "../asonic-search";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import {
  IS_TRUTHY_FALSY,
  SEARCH_DATE_TYPE_FOR_DASH_BOARD,
  SEARCH_DB_TYPE_FOR_DASH_BOARD
} from "../../../__generated__/globalTypes";
import { useEffect, useMemo, useRef } from "react";

export interface IFieldSearchByDateWithType extends SearchFieldValues {
  date: string;
  searchType: SEARCH_DB_TYPE_FOR_DASH_BOARD;
  searchValue: string;
  searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD;
  searchValueForTruthyFalsy: IS_TRUTHY_FALSY;
}

enum IS_VALUE_OR_TIME_OR_OX {
  VALUE = "VALUE",
  TIME = "TIME",
  OX = "OX",
  ALL = "ALL"
}

export enum SELECT_IS_TRUTHY_FALSY {
  FALSY = "X",
  TRUTHY = "O"
}

export enum OT_TOT_TIME_SECTION {
  MORE_THAN_52_HOURS = "52시간초과",
  MORE_THAN_50_HOURS_LESS_THAN_52_HOURS = "50~52시간",
  MORE_THAN_45_HOURS_LESS_THAN_50_HOURS = "45~50시간",
  MORE_THAN_40_HOURS_LESS_THAN_45_HOURS = "40~45시간",
  LESS_THAN_40_HOURS = "40시간미만"
}

export enum OT_WORK_TIME_SECTION {
  MORE_THAN_40_HOURS = "40시간초과",
  MORE_THAN_38_HOURS_LESS_THAN_40_HOURS = "38~40시간",
  MORE_THAN_36_HOURS_LESS_THAN_38_HOURS = "36~38시간",
  MORE_THAN_34_HOURS_LESS_THAN_36_HOURS = "34~36시간",
  LESS_THAN_34_HOURS = "34시간미만"
}

export enum OT_EXT_TIME_SECTION {
  MORE_THAN_12_HOURS = "12시간초과",
  MORE_THAN_10_HOURS_LESS_THAN_12_HOURS = "10~12시간",
  MORE_THAN_8_HOURS_LESS_THAN_10_HOURS = "08~10시간",
  MORE_THAN_6_HOURS_LESS_THAN_8_HOURS = "06~08시간",
  LESS_THAN_6_HOURS = "06시간미만"
}
export interface ISearchByDateWithTypeProps {
  handleDateWithType: (data: IFieldSearchByDateWithType) => void;
  searchType: SEARCH_DB_TYPE_FOR_DASH_BOARD;
  handleSearchType: (value: SEARCH_DB_TYPE_FOR_DASH_BOARD) => void;
  searchValue: string;
  handleSearchValue: (value: string) => void;
  searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD;
  handleSearchValueForTime: (value: SEARCH_DATE_TYPE_FOR_DASH_BOARD) => void;
  searchValueForTruthyFalsy: IS_TRUTHY_FALSY;
  handleSearchValueForTruthyFalsy: (value: IS_TRUTHY_FALSY) => void;
  isAdditionalSearchTimeValue: boolean;
}

export enum SEARCH_TYPE_FOR_VALUE {
  all = "전체직원",
  empName = "성명",
  empId = "아이디",
  departName = "부서명",
  otTotTimeSection = "근로시간구간",
  otWorkTimeSection = "정규시간구간",
  otExtTimeSection = "연장시간구간",
  workOx = "정규근무",
  nameOne = "연장근무",
  nameTwo = "휴가",
  nameThree = "기타"
}

export type SEARCH_TYPE_FOR_DASH_BOARD_TABLE =
  keyof typeof SEARCH_TYPE_FOR_VALUE;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Date = styled.input`
  border: 2px solid rgba(0, 0, 0, 0.3);
  outline: none;
  font-weight: bold;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.9);
  }
  cursor: pointer;
`;

const Input = styled.input`
  width: 134px;
`;

const Select = styled.select<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "block" : "none")};
  visibility: ${props => (props.isShow ? "visible" : "hidden")};
  opacity: ${props => (props.isShow ? "1" : "0")};
`;

const Option = styled.option<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "block" : "none")};
  visibility: ${props => (props.isShow ? "visible" : "hidden")};
  opacity: ${props => (props.isShow ? "1" : "0")};
`;

function AsonicSearchByDateWithType({
  handleDateWithType,
  searchType,
  handleSearchType,
  handleSearchValueForTime,
  handleSearchValueForTruthyFalsy,
  searchValueForTime,
  isAdditionalSearchTimeValue
}: ISearchByDateWithTypeProps) {
  const selectRef = useRef<any>(null);
  const selectedTimeRef = useRef<any>(null);
  const selectedOXRef = useRef<any>(null);

  const { register, handleSubmit, getValues, setValue, watch } =
    useForm<IFieldSearchByDateWithType>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        searchValue: "",
        searchValueForTime: SEARCH_DATE_TYPE_FOR_DASH_BOARD.LESS_THAN_34_HOURS,
        searchValueForTruthyFalsy: IS_TRUTHY_FALSY.TRUTHY,
        type: searchType,
        date: moment().subtract(7, "d").format("YYYY-MM-DD")
      }
    });
  const { ref, ...searchTypeRegister } = register("searchType");
  const searchValueRegister = register("searchValue");
  const { ref: timeRef, ...searchValueForTimeRegister } =
    register("searchValueForTime");
  const { ref: oxRef, ...searchValueForTruthyFalsyRegister } = register(
    "searchValueForTruthyFalsy"
  );

  const { searchType: searchTypeForField } = watch();

  const isValueTimeOX: IS_VALUE_OR_TIME_OR_OX = useMemo(() => {
    switch (searchTypeForField) {
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.all:
        return IS_VALUE_OR_TIME_OR_OX.ALL;
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.empId:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.empName:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.departName:
        return IS_VALUE_OR_TIME_OR_OX.VALUE;
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTime:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTimeSection:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTime:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTime:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTimeSection:
        return IS_VALUE_OR_TIME_OR_OX.TIME;
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.workOx:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.nameOne:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.nameTwo:
      case SEARCH_DB_TYPE_FOR_DASH_BOARD.nameThree:
        return IS_VALUE_OR_TIME_OR_OX.OX;
      default:
        return IS_VALUE_OR_TIME_OR_OX.VALUE;
    }
  }, [searchTypeForField]);

  useEffect(() => {
    selectRef.current.value = searchTypeForField;
    if (!isAdditionalSearchTimeValue) {
      if (
        searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection
      ) {
        selectedTimeRef.current.value =
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_52_HOURS;
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_52_HOURS
        );
      }
      if (
        searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTimeSection
      ) {
        selectedTimeRef.current.value =
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_40_HOURS;
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_40_HOURS
        );
      }
      if (
        searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTimeSection
      ) {
        selectedTimeRef.current.value =
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_12_HOURS;
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_12_HOURS
        );
      }
      if (
        (searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.nameTwo ||
          searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.nameThree) &&
        handleSearchValueForTruthyFalsy
      ) {
        setValue("searchValueForTruthyFalsy", IS_TRUTHY_FALSY.TRUTHY);
        handleSearchValueForTruthyFalsy(IS_TRUTHY_FALSY.TRUTHY);
      }
    }
  }, [
    searchTypeForField,
    handleSearchValueForTruthyFalsy,
    handleSearchValueForTime,
    isAdditionalSearchTimeValue,
    setValue
  ]);

  useEffect(() => {
    selectRef.current.value = searchType;
    setValue("searchType", searchType);
    if (!isAdditionalSearchTimeValue) {
      if (searchType === SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection) {
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_52_HOURS
        );
      }
      if (searchType === SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTimeSection) {
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_40_HOURS
        );
      }
      if (searchType === SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTimeSection) {
        handleSearchValueForTime(
          SEARCH_DATE_TYPE_FOR_DASH_BOARD.MORE_THAN_12_HOURS
        );
      }
    }
  }, [
    searchType,
    setValue,
    isAdditionalSearchTimeValue,
    handleSearchValueForTime
  ]);

  useEffect(() => {
    if (searchValueForTime) {
      setValue("searchValueForTime", searchValueForTime);
      selectedTimeRef.current.value = searchValueForTime;
    }
  }, [searchValueForTime, setValue]);

  return (
    <Container>
      <Date type="date" {...register("date")} />
      <select
        id="row"
        {...searchTypeRegister}
        ref={event => {
          ref(event);
          selectRef.current = event; // you can still assign to ref
        }}
        onChange={event => {
          searchTypeRegister.onChange(event);
          const searchType = getValues("searchType");
          if (searchType) {
            handleSearchType(searchType);
          }
        }}
      >
        {Object.keys(SEARCH_TYPE_FOR_VALUE).map((item, index) => (
          <option key={index} value={item}>
            {SEARCH_TYPE_FOR_VALUE[item as SEARCH_TYPE_FOR_DASH_BOARD_TABLE]}
          </option>
        ))}
      </select>
      {isValueTimeOX === IS_VALUE_OR_TIME_OR_OX.VALUE && (
        <Input type="text" {...searchValueRegister} />
      )}
      <Select
        id="row"
        isShow={isValueTimeOX === IS_VALUE_OR_TIME_OR_OX.TIME}
        {...searchValueForTimeRegister}
        ref={event => {
          timeRef(event);
          selectedTimeRef.current = event;
        }}
        onChange={event => {
          searchValueForTimeRegister.onChange(event);
          const selectedSearchValueForTime = getValues("searchValueForTime");
          if (selectedSearchValueForTime) {
            handleSearchValueForTime(selectedSearchValueForTime);
          }
        }}
      >
        {Object.keys(OT_TOT_TIME_SECTION).map((item, index) => (
          <Option
            isShow={
              searchTypeForField ===
                SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTimeSection ||
              searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otTotTime
            }
            key={index}
            value={item}
          >
            {OT_TOT_TIME_SECTION[item as keyof typeof OT_TOT_TIME_SECTION]}
          </Option>
        ))}
        {Object.keys(OT_WORK_TIME_SECTION).map((item, index) => (
          <Option
            isShow={
              searchTypeForField ===
                SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTimeSection ||
              searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otWorkTime
            }
            key={index}
            value={item}
          >
            {OT_WORK_TIME_SECTION[item as keyof typeof OT_WORK_TIME_SECTION]}
          </Option>
        ))}
        {Object.keys(OT_EXT_TIME_SECTION).map((item, index) => (
          <Option
            isShow={
              searchTypeForField ===
                SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTimeSection ||
              searchTypeForField === SEARCH_DB_TYPE_FOR_DASH_BOARD.otExtTime
            }
            key={index}
            value={item}
          >
            {OT_EXT_TIME_SECTION[item as keyof typeof OT_EXT_TIME_SECTION]}
          </Option>
        ))}
      </Select>
      <Select
        id="row"
        isShow={isValueTimeOX === IS_VALUE_OR_TIME_OR_OX.OX}
        ref={event => {
          oxRef(event);
          selectedOXRef.current = event;
        }}
        {...searchValueForTruthyFalsyRegister}
        onChange={event => {
          searchValueForTruthyFalsyRegister.onChange(event);
          const searchValueForTruthyFalsy = getValues(
            "searchValueForTruthyFalsy"
          );
          if (searchValueForTruthyFalsy) {
            handleSearchValueForTruthyFalsy(searchValueForTruthyFalsy);
          }
        }}
      >
        {Object.keys(IS_TRUTHY_FALSY).map((item, index) => (
          <option key={index} value={item}>
            {SELECT_IS_TRUTHY_FALSY[item as IS_TRUTHY_FALSY]}
          </option>
        ))}
      </Select>
      <IconContainer
        onClick={handleDateWithType && handleSubmit(handleDateWithType)}
      >
        <Icon icon={SearchIcon} />
      </IconContainer>
    </Container>
  );
}

export default AsonicSearchByDateWithType;
