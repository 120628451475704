import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import styled from "styled-components";
import useDateTime from "../../../../../../hooks/detail-view-hook/use-date-time";
import {
  AddCommuteDateTime,
  AddCommuteDateTimeVariables
} from "../../../../../../__generated__/AddCommuteDateTime";
import {
  DEVICE_TYPE,
  CommuteType
} from "../../../../../../__generated__/globalTypes";
import { ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list } from "../../../../../../__generated__/ListOfEmployeeCommuteInformation";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";
import DateWithTimeContainer from "../../../../../DateWithTime/DateWithTimeContainer";
import Button from "../../../../../globalComponents/Button";
import { MessageTypes } from "../../../../../toast-message/toast-message";

type SelectOnchange = React.ChangeEvent<HTMLSelectElement>;

interface IProps {
  handleClose: (value: boolean) => void;
  selectedRow?: ListOfEmployeeCommuteInformation_listOfEmployeeCommuteInformation_list;
  handleIsOpenToast: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
  listIdForCache?: string | null;
}

const Container = styled.div`
  flex: 14;
  display: flex;
  flex-direction: column;
  padding: 2%;
`;

const TopSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
`;

const Select = styled.select`
  margin: 5px;
`;

const Option = styled.option``;

const Label = styled.label``;

const MUTATION_ADD_COMMUTE_DATE_TIME = gql`
  mutation AddCommuteDateTime(
    $selectedEmployeeId: String!
    $checkDateTime: String!
    $commuteType: CommuteType!
    $deviceType: DEVICE_TYPE!
  ) {
    addCommuteDateTime(
      selectedEmployeeId: $selectedEmployeeId
      checkDateTime: $checkDateTime
      commuteType: $commuteType
      deviceType: $deviceType
    ) {
      ok
      error
    }
  }
`;

export const AddCommuteHistoryDialog = ({
  handleClose,
  selectedRow,
  handleIsOpenToast,
  handleMessage,
  handleToastMessageType,
  listIdForCache
}: IProps) => {
  const { startDateTime: dateWithTime, handleDateTime } = useDateTime();
  const [commuteType, setCommuteType] = useState<CommuteType>(
    CommuteType.ATTENDANCE
  );
  const [deviceType, setDeviceType] = useState<DEVICE_TYPE>(
    DEVICE_TYPE.DESKTOP
  );

  const [addCommuteDateTime, { client }] = useMutation<
    AddCommuteDateTime,
    AddCommuteDateTimeVariables
  >(MUTATION_ADD_COMMUTE_DATE_TIME, {
    async update(cache, { data }) {
      let messsage = "출근기록을 추가하였습니다.";
      if (commuteType === CommuteType.LEAVE) {
        messsage = "퇴근기록을 추가하였습니다.";
      }
      if (data?.addCommuteDateTime.ok && selectedRow && listIdForCache) {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage(messsage);
        cache.modify({
          id: `CountListOfEmployeeCommuteManagementOutput`,
          fields: {
            total(prev) {
              return prev + 1;
            }
          }
        });
      } else if (data?.addCommuteDateTime.error) {
        handleMessage(data?.addCommuteDateTime.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpenToast(true);
      handleClose(false);
      await client.resetStore();
    }
  });

  const handleCommuteType = useCallback((event: SelectOnchange) => {
    if (event.target.value === CommuteType.ATTENDANCE) {
      setCommuteType(CommuteType.ATTENDANCE);
    } else {
      setCommuteType(CommuteType.LEAVE);
    }
  }, []);

  const handleDeviceType = useCallback((event: SelectOnchange) => {
    if (event.target.value === DEVICE_TYPE.DESKTOP) {
      setDeviceType(DEVICE_TYPE.DESKTOP);
    } else {
      setDeviceType(DEVICE_TYPE.APP);
    }
  }, []);

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      addCommuteDateTime({
        variables: {
          selectedEmployeeId: selectedRow.employeeId,
          checkDateTime: dateWithTime,
          deviceType,
          commuteType
        }
      });
    }
  }, [addCommuteDateTime, selectedRow, dateWithTime, deviceType, commuteType]);

  return (
    <AsonicDialog
      title={`${selectedRow?.employeeId} - 출퇴근 기록 추가`}
      handleClose={handleClose}
      width="400px"
      height="20%"
      minHeight="200px"
    >
      <Container>
        <TopSection>
          <DateWithTimeContainer
            title="출/퇴근 시간"
            dateWithTime={dateWithTime}
            handleDateWithTime={handleDateTime("start")}
          />
        </TopSection>
        <BottomSection>
          <SelectContainer>
            <Label htmlFor="COMMUTE">출/퇴근</Label>
            <Select id="COMMUTE" onChange={handleCommuteType}>
              <Option value={CommuteType.ATTENDANCE}>출근</Option>
              <Option value={CommuteType.LEAVE}>퇴근</Option>
            </Select>
          </SelectContainer>
          <SelectContainer>
            <Label htmlFor="DEVICE">기기선택</Label>
            <Select id="DEVICE" onChange={handleDeviceType}>
              <Option value={DEVICE_TYPE.DESKTOP}>PC</Option>
              <Option value={DEVICE_TYPE.APP}>APP</Option>
            </Select>
          </SelectContainer>
        </BottomSection>
        <Footer>
          <Button onClick={handleConfirm}>확인</Button>
        </Footer>
      </Container>
    </AsonicDialog>
  );
};
