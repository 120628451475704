import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GetEmployeeInformationVariables } from "../__generated__/GetEmployeeInformation";
import { v4 as uuid } from "uuid";
import { GetPcInformationVariables } from "../__generated__/GetPcInformation";
import { ListOfEmployeeAppliedToTheTemplateVariables } from "../__generated__/ListOfEmployeeAppliedToTheTemplate";
import { ListOfEmployeeCommuteInformationVariables } from "../__generated__/ListOfEmployeeCommuteInformation";
import { ListOfPlaceFreeManagementVariables } from "../__generated__/ListOfPlaceFreeManagement";
import { GetListOfProcessingInquiryVariables } from "../__generated__/GetListOfProcessingInquiry";
import { GetListOfRequestInquiryVariables } from "../__generated__/GetListOfRequestInquiry";
import { GetListOfChatRoomVariables } from "../__generated__/GetListOfChatRoom";
import apolloTypePolicies, {
  CalculatePagination
} from "./apollo-type-policies";

const PORT = 18081;

const URI =
  window.location.protocol !== "http:" && process.env.NODE_ENV !== "development"
    ? `https://${window.location.hostname}/graphql`
    : `http://${window.location.hostname}:${PORT}/graphql`;

const httpLink = createHttpLink({
  uri: URI
});

export const TOKEN = "token";

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem(TOKEN)
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      EmployeeInformation: {
        keyFields(EmployeeInformation) {
          return `EmployeeInformation:${EmployeeInformation.employeeId}`;
        }
      },
      GetEmployeeInformationOutput: {
        fields: {
          listOfEmployee: {
            read(existing, { variables }) {
              const { page, take } =
                variables as GetEmployeeInformationVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const {
                page,
                take,
                searchType: type
              } = variables as GetEmployeeInformationVariables;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields() {
          return `GetEmployeeInformationOutput:${uuid().slice(0, 8)}`;
        }
      },
      PcInformation: {
        keyFields(PcInformation) {
          return `PcInformation:${PcInformation.employeeId}`;
        }
      },
      GetPcInformationOutput: {
        fields: {
          listOfPcInformation: {
            read(existing, { variables }) {
              const { page, take } = variables as GetPcInformationVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const {
                page,
                take,
                searchType: type
              } = variables as GetPcInformationVariables;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });

              return pagination.computedData();
            }
          }
        },
        keyFields() {
          return `GetPcInformationOutput:${uuid().slice(0, 8)}`;
        }
      },
      EmployeeAppliedToTheTemplate: {
        keyFields(EmployeeAppliedToTheTemplate) {
          return `EmployeeAppliedToTheTemplate:${EmployeeAppliedToTheTemplate.employeeId}`;
        }
      },
      ListOfEmployeesAppliedToTheTemplateOutput: {
        fields: {
          listEmployeesAppliedToTheTemplate: {
            read(existing, { variables }) {
              const { page, take } =
                variables as ListOfEmployeeAppliedToTheTemplateVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const {
                page,
                take,
                searchType: type
              } = variables as ListOfEmployeeAppliedToTheTemplateVariables;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(ListOfEmployeesAppliedToTheTemplateOutput) {
          return `ListOfEmployeesAppliedToTheTemplateOutput:${ListOfEmployeesAppliedToTheTemplateOutput.cacheId}`;
        }
      },
      CountListOfEmployeeAppliedToTheTemplateOutput: {
        keyFields(CountListOfEmployeeAppliedToTheTemplateOutput) {
          return `CountListOfEmployeeAppliedToTheTemplateOutput`;
        }
      },
      ApprovalNotificationDepartment: {
        keyFields(ApprovalNotificationDepartment) {
          return `ApprovalNotificationDepartment:${ApprovalNotificationDepartment.departmentId}`;
        }
      },
      ListOfApprovalNotificationDepartmentOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as ListOfEmployeeAppliedToTheTemplateVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const {
                page,
                take,
                searchType: type
              } = variables as ListOfEmployeeAppliedToTheTemplateVariables;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(ListOfApprovalNotificationDepartmentOutput) {
          return `ListOfApprovalNotificationDepartmentOutput:${ListOfApprovalNotificationDepartmentOutput.cacheId}`;
        }
      },
      CountListOfApprovalNotificationDepartmentOutput: {
        keyFields(CountListOfEmployeeAppliedToTheTemplateOutput) {
          return `CountListOfApprovalNotificationDepartmentOutput`;
        }
      },
      ListOfEmployeeCommuteManagementOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as ListOfEmployeeCommuteInformationVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const { page, take, startDate, endDate } =
                variables as ListOfEmployeeCommuteInformationVariables;
              const type = `${startDate},${endDate}`;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(ListOfEmployeeCommuteManagementOutput) {
          return `ListOfEmployeeCommuteManagementOutput:${ListOfEmployeeCommuteManagementOutput.cacheId}`;
        }
      },
      CommuteManagement: {
        keyFields(CommuteManagement) {
          return `CommuteManagement:${CommuteManagement.checkDateTime}`;
        }
      },
      CountListOfEmployeeCommuteManagementOutput: {
        keyFields(CountListOfEmployeeCommuteManagementOutput) {
          return `CountListOfEmployeeCommuteManagementOutput`;
        }
      },
      ListOfPlaceFreeManagementOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as ListOfPlaceFreeManagementVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const { page, take, startDate, endDate } =
                variables as ListOfPlaceFreeManagementVariables;
              const type = `${startDate},${endDate}`;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(ListOfPlaceFreeManagementOutput) {
          return `ListOfPlaceFreeManagementOutput:${ListOfPlaceFreeManagementOutput.cacheId}`;
        }
      },
      PlaceFreeManagement: {
        keyFields(PlaceFreeManagement) {
          return `PlaceFreeManagement:${PlaceFreeManagement.leftSeatLogIdx}`;
        }
      },
      CountListOfPlaceFreeManagementOutput: {
        keyFields(CountListOfPlaceFreeManagementOutput) {
          return `CountListOfPlaceFreeManagementOutput:${CountListOfPlaceFreeManagementOutput.cacheId}`;
        }
      },
      GetListOfProcessingInquiryOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as GetListOfProcessingInquiryVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const {
                page,
                take,
                startDateToSearch,
                endDateToSearch,
                searchType
              } = variables as GetListOfProcessingInquiryVariables;
              const type = `${startDateToSearch},${endDateToSearch},${searchType}`;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(GetListOfProcessingInquiryOutput) {
          return `GetListOfProcessingInquiryOutput:${GetListOfProcessingInquiryOutput.cacheId}`;
        }
      },
      ProcessingInquiry: {
        keyFields(ProcessingInquiry) {
          return `ProcessingInquiry:${ProcessingInquiry.approvalRequestIdx}`;
        }
      },
      GetListOfRequestInquiryOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as GetListOfRequestInquiryVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const { page, take, startDateToSearch, endDateToSearch } =
                variables as GetListOfRequestInquiryVariables;
              const type = `${startDateToSearch},${endDateToSearch}`;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                type,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(GetListOfRequestInquiryOutput) {
          return `GetListOfRequestInquiryOutput:${GetListOfRequestInquiryOutput.cacheId}`;
        }
      },
      RequestInquiry: {
        keyFields(RequestInquiry) {
          return `RequestInquiry:${RequestInquiry.approvalRequestIdx}`;
        }
      },
      MapRegisterEntity: {
        keyFields(MapRegisterEntity) {
          return `MapRegisterEntity:${MapRegisterEntity.areaIdx}`;
        }
      },
      GetPublicTimeTemplateOutput: {
        keyFields(GetPublicTimeTemplateOutput) {
          return `GetPublicTimeTemplateOutput`;
        }
      },
      PublicTimeTemplate: {
        keyFields(PublicTimeTemplate) {
          return `PublicTimeTemplate:${PublicTimeTemplate.timeTemplateIdx}`;
        }
      },
      GetListOfTemplateScheduleInfoOutput: {
        keyFields(GetListOfTemplateScheduleInfoOutput) {
          return `GetListOfTemplateScheduleInfoOutput`;
        }
      },
      TemplateTimeScheduleInfo: {
        keyFields(TemplateTimeScheduleInfo) {
          return `TemplateTimeScheduleInfo:${TemplateTimeScheduleInfo.timeIdx}`;
        }
      },
      GetListOfAlarmOutput: {
        keyFields(GetListOfAlarmOutput) {
          return `GetListOfAlarmOutput`;
        }
      },
      AlarmSettingEntity: {
        keyFields(AlarmSettingEntity) {
          return `AlarmSettingEntity:${AlarmSettingEntity.settingIndex}`;
        }
      },
      GetListOfApprovalNotificationTemplateOutput: {
        keyFields(GetListOfApprovalNotificationTemplateOutput) {
          return `GetListOfApprovalNotificationTemplateOutput`;
        }
      },
      ApprovalNotificationTemplate: {
        keyFields(ApprovalNotificationTemplate) {
          return `ApprovalNotificationTemplate:${ApprovalNotificationTemplate.templateIdx}`;
        }
      },
      GetListOfApprovalNotificationScheduleByTemplateOutput: {
        keyFields(GetListOfApprovalNotificationScheduleByTemplateOutput) {
          return `GetListOfApprovalNotificationScheduleByTemplateOutput`;
        }
      },
      ApprovalNotificationScheduleByTemplate: {
        keyFields(ApprovalNotificationScheduleByTemplate) {
          return `ApprovalNotificationScheduleByTemplate:${ApprovalNotificationScheduleByTemplate.scheduleIdx}`;
        }
      },
      GetListOfVehicleInformationOutput: {
        keyFields(GetListOfVehicleInformationOutput) {
          return `GetListOfVehicleInformationOutput`;
        }
      },
      GetTotalNumberOfVehicleInformationOutput: {
        keyFields(GetTotalNumberOfVehicleInformationOutput) {
          return `GetTotalNumberOfVehicleInformationOutput`;
        }
      },
      VehicleInformationEntity: {
        keyFields(VehicleInformationEntity) {
          return `VehicleInformationEntity:${VehicleInformationEntity.vehicleNumber}`;
        }
      },
      GetTotalNumberOfVehicleDrivingInformationOutput: {
        keyFields(GetTotalNumberOfVehicleDrivingInformationOutput) {
          return `GetTotalNumberOfVehicleDrivingInformationOutput`;
        }
      },
      GetListOfVehicleDrivingInformationOutput: {
        keyFields(GetListOfVehicleDrivingInformationOutput) {
          return `GetListOfVehicleDrivingInformationOutput`;
        }
      },
      VehicleDrivingInformationEntity: {
        keyFields(VehicleDrivingInformationEntity) {
          return `VehicleDrivingInformationEntity:${VehicleDrivingInformationEntity.logIdx}`;
        }
      },
      GetListOfReservationTypeOutput: {
        keyFields(GetListOfReservationTypeOutput) {
          return `GetListOfReservationTypeOutput`;
        }
      },
      ReservationTypeEntity: {
        keyFields(ReservationTypeEntity) {
          return `ReservationTypeEntity:${ReservationTypeEntity.groupIdx}`;
        }
      },
      GetListOfReservationCategoryOutput: {
        keyFields(GetListOfReservationCategoryOutput) {
          return `GetListOfReservationCategoryOutput`;
        }
      },
      ReservationCategoryEntity: {
        keyFields(ReservationCategoryEntity) {
          return `ReservationCategoryEntity:${ReservationCategoryEntity.categoryIdx}`;
        }
      },
      GetListOfReservationDetailInformationOutput: {
        keyFields(GetListOfReservationDetailInformationOutput) {
          return `GetListOfReservationDetailInformationOutput`;
        }
      },
      ReservationDetailInformationEntity: {
        keyFields(ReservationDetailInformationEntity) {
          return `ReservationDetailInformationEntity:${ReservationDetailInformationEntity.infoIdx}`;
        }
      },
      GetTotalListOfReservationDetailInformationOutput: {
        keyFields(GetTotalListOfReservationDetailInformationOutput) {
          return `GetTotalListOfReservationDetailInformationOutput`;
        }
      },
      GetListOfChatRoomOutput: {
        fields: {
          list: {
            read(existing, { variables }) {
              const { page, take } =
                variables as GetListOfRequestInquiryVariables;
              const pagination = new CalculatePagination({
                existing,
                take,
                page
              });
              return pagination.read();
            },
            merge(existing, incoming = [], { variables }) {
              const { page, take } = variables as GetListOfChatRoomVariables;
              const pagination = new CalculatePagination({
                existing,
                incoming,
                take,
                page
              });
              return pagination.computedData();
            }
          }
        },
        keyFields(GetListOfChatRoomOutput) {
          return `GetListOfChatRoomOutput`;
        }
      },
      ListOfChatRoom: {
        keyFields(ListOfChatRoom) {
          return `ListOfChatRoom:${ListOfChatRoom.chatroom_idx}`;
        }
      },
      ...apolloTypePolicies
    }
  })
});
