import styled from "styled-components";

const AsonicTableContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  border-spacing: 0;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export default AsonicTableContainer;
